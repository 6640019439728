//
import * as React from "react";
import { useState, useEffect } from "react";

// import PaymentPop from "./PaymentPop";
//CSS
import "./Payment.css";

//Images
import left_arrow from "../../assets/vector/left_arrow.svg";
import { useNavigate } from "react-router";
import useUserCtx from "../../hooks/useUserCtx";
import { initOrder, paymentInit } from "../../api/OrdersApi";
import { getGstDetails } from "../../api/miscellaneous";
import { Checkbox, FormControlLabel, useMediaQuery } from "@mui/material";
import NothingImage from "../../assets/png/your_order_image.png";
import { toast } from "react-toastify";
import { getSearchedProduct } from "../../api/Product";
import { currencyFormat, isOfferActive, truncatedText, isCouponActive } from "../../utils";
import { useTranslation } from "react-i18next";
import { checkCoupon } from "../../api/couponApi";
import EditDetailsModal from "./EditDetailsModal";

// Component definition
const Payment = () => {
    const isMobile = useMediaQuery(`(max-width: 500px)`);
    const {
        userContext,
        userAddress,
        orderInit,
        setOrderInit,
        // userDefaultAddress,
        setCartArray,
        appliedCoupon,
        priceBoxDetails,
        setAppliedCoupon,
        cartArray,
        userDefaultAddress
    } = useUserCtx();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const userData = React.useMemo(() => {
        if (userContext.JWT) {
            return {
                fullName: userContext.fullName,
                email: userContext.email,
                phone: userContext.mobileNumber
            }
        }
    }, [userContext])
    // state definitions
    const [disable, setDisable] = useState(true);
    const [initProcessing, setInitProcessing] = useState(false);
    const [comboProducts, setComboProducts] = useState([]);
    const [gst, setGst] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [claimGst, setClaimGst] = useState(false);
    // const [hasOnsitego, setHasOnsitego] = useState(false);
    // const [serviceabilityCheckDone, setServiceabilityCheckDone] = useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const [checked, setChecked] = useState(false);
    const fetchGstDetails = React.useCallback(async () => {
        try {
            const response = await getGstDetails();
            if (response.status === "success") {
                setGst(response.gst)
            }
        } catch (error) {
            toast.error("Something went wrong in Fetching GST")
        }
    }, [])

    useEffect(() => {
        fetchGstDetails();
    }, [fetchGstDetails]);

    useEffect(() => {
        setOrderInit((prev) => ({
            ...prev,
            shippingAddressId: userAddress?.address?.length > 0 ?
                userAddress?.address.find(e => e.isDefault === true)?._id
                : null,
            claimGst
        }));
        setDisable(false);
    }, [claimGst, setOrderInit, userAddress?.address]);


    useEffect(() => {
        if (cartArray.no_of_carts > 0) {
            let productId = [];
            let quantity = [];

            for (const item of cartArray.cart) {
                productId.push(item._id);
                quantity.push(parseInt(item.quantity));
                // check the product has combo offer and if yes fetch that product
                const comboOffer = item.discount?.combo;
                if (comboOffer?.value && isOfferActive(comboOffer)) {
                    fetchComboProduct(comboOffer.childEan)
                }
            }

            const manualFlow = {};
            const check = cartArray.cart.some((item) => item.from?.filter(e => !!e)?.length > 0);
            if (!orderInit.brand_store_id || !orderInit.type) {
                if (check) {
                    manualFlow["brand_store_id"] = cartArray.cart[0]?.from[0];
                    manualFlow["type"] = "Manual";
                }
            }
            setOrderInit((prev) => ({
                ...prev,
                ...manualFlow,
                productId: productId,
                quantity: quantity,
            }));
        }

        // cleanup on componentWillUnmount
        return () => {
            setComboProducts([]);
        }
    }, [cartArray]);

    useEffect(() => {
        updateCartComboDetails(cartArray);

        // cleanup on componentWillUnmount
        return () => {
            setComboProducts([]);
        }
    }, [])

    const fetchComboProduct = async (productEan) => {
        const response = await getSearchedProduct(`ean=${productEan}`);
        const product = response.products[0];
        /* setComboProducts(prev => {
            return [...prev, { ...product, isComboProduct: true }];
        }) */

        return product;
    }

    const validateUserData = () => {
        let isValid = true;
        if (!userContext.email) {
            toast.error("Please add Email Id.");
            isValid = false;
        }
        else if (!userAddress.address.length) {
            toast.error("Please Add Shipping Address.");
            isValid = false;
        }
        return isValid;
    }

    const handleOrderInit = async (e) => {
        if (validateUserData()) {
            e.preventDefault();
            setInitProcessing(true);

            try {
                const OrderinitRes = await initOrder(orderInit); //saves order in DB and returns the order
                if (!OrderinitRes) return setInitProcessing(false);

                let orderId = OrderinitRes._id; //returns order id

                const payInitRes = await paymentInit(orderId);
                // 1) Takes Order ID and checks its presence in Order Collection in DB.
                // 2) Creates Payment collection in DB and sends payment link.
                window.open(payInitRes, "_parent");
            } catch(err) {
                console.error({ err });
                toast.error(err?.response?.data?.status === "error" ? err.response.data.message : "Something went wrong")

            } finally {
                setInitProcessing(false);
            }

        }
    };

    /* const checkServiceAvailability = async (item, pincode) => {
        const response = await checkProdServiceabilityStatus(item, pincode);
        const payload = response.data;
        if (payload.status === 'error' && !payload.data?.length) {
            setCartArray(prev => {
                return {
                    ...prev,
                    cart: prev.cart.filter(prod => prod.ean !== item.ean)
                };
            })
            toast.error(`Service unavailable for ${item.title}`)
        }
    }

    const addOnsitego = (event) => {
        setHasOnsitego(event.target.checked);
    }

    const handleClaimGstChange = (event) => {
        setClaimGst(event.target.checked);
    }

    const initServiceAvailabilityCheck = async () => {
        if (cartArray && cartArray.no_of_carts > 0 && userDefaultAddress.loaded) {
            let itemArray = cartArray.cart.map((item) => {
                let itemObj = {
                    ean: item.ean,
                    title: item.title,
                    quantity: item.quantity,
                };
                return itemObj;
            });

            const pincode = userDefaultAddress?.address?.zip;
            for (const item of itemArray) {
                await checkServiceAvailability(item, pincode);
            }
            setServiceabilityCheckDone(true)
        }
    } */

    const getComboProdPrice = (comboProdMop, comboOffer) => {
        let result = comboProdMop;
        const discountVal = parseFloat(comboOffer.value);

        if (comboOffer.discountType !== "flat") {
            result = result - (result * discountVal) / 100;
        } else {
            result = result - discountVal;
        }
        return result;
    };

    // product container component
    const CardDiv = ({
        name,
        price,
        details = "",
        image,
        hierarchyL2 = '',
        mrp,
        isComboProduct = false
    }) => {
        return (
            <div className="cardDiv" style={{ overflow: "unset" }}>
                <div className="card__square">
                    <img
                        className="w-100 h-100"
                        alt="card"
                        src={image ?? NothingImage}
                    />
                    {isComboProduct && (
                        <div className="tag-item tag-best-seller fs-6 my-1">
                            Combo Product
                        </div>
                    )}
                </div>
                <div className="card__info">
                    <h2 className="card__heading">{name}</h2>
                    <p className="card__details">{details}</p>
                    <p className="card__price">
                        <strong className="me-2">
                            {currencyFormat(price)}
                        </strong>
                        {
                            mrp > price &&
                            <strong className="text-decoration-line-through" style={{
                                fontSize: "1.04rem",
                                bottom: "1.5em"
                            }}>
                                {currencyFormat(mrp)}
                            </strong>
                        }
                    </p>
                </div>
                {/* {hierarchyL2 === 'Smartphone' && (
                    <p className="cart_Text text-success fs-6 m-0 p-0">
                        Extended warranty upto 1 year on Smartphone Purchase
                    </p>
                )} */}
            </div>
        );
    };

    // checks whether entered coupon code is valid and fetches the details of that coupon
    const checkEnteredCoupon = async () => {
        const couponCode = document.getElementById("coupon_code")?.value ?? '';
        if (!couponCode) return toast.error('Enter Coupon Code...');
        try {
            const response = await checkCoupon(couponCode);
            const payload = response.data;

            if (payload.status === 'success') {
                const coupon = payload.data.coupon;
                const productEan = cartArray.cart.map(prod => prod.ean);
                // check whether coupon max amount is in limits to the total purchase
                // and is applicable to any product in the purchase
                let isCouponApplicable = productEan.some((ean) => {
                    return (
                        coupon.products.includes(ean) &&
                        +priceBoxDetails.totalAmount <= coupon.maxAmount
                    );
                });

                if (+coupon.discount <= 0 || !isCouponActive(coupon)) {
                    isCouponApplicable = false
                }

                if (isCouponApplicable) {
                    setAppliedCoupon(coupon)
                    setOrderInit(prev => ({ ...prev, coupon: coupon.code }));
                    toast.success('Coupon applied successfully!')
                } else {
                    toast.error('No applicable coupon found!');
                }
            }
        } catch (err) {
            let message = 'Something went wrong';
            if (err.response?.data?.message) message = err.response?.data?.message;
            toast.error(message)
        }
    }

    function handleCheckBoxChange(event) {
        setChecked(event.target.checked);
    }

    useEffect(() => {
        setOrderInit(prev => ({ ...prev, manualCnmtReceive: checked }))
    }, [checked]);

    const getStoreName = React.useCallback(() => {
        let name;
        if (cartArray?.loaded) {
            cartArray.cart.forEach(cart => {
                if (cart?.from?.length > 0 && cart.from[0]) name = cart.from[0]
                return name
            })
        }
        return name ? name : false
    }, [cartArray?.loaded, cartArray.cart])

    const updateCartComboDetails = async (cartArray) => {
        let newProd = [];
        for (const prod of cartArray.cart) {
            // check the product has combo offer and if yes fetch that product
            const comboOffer = prod.discount?.combo;

            if (comboOffer?.value > 0 && isOfferActive(comboOffer)) {
                const cProd = await fetchComboProduct(comboOffer.childEan);
                const cProdArr = prod["comboProductDetails"] ?? [];
                if (!prod["comboProductDetails"]?.length) {
                    const comboProdSet = new Set([...cProdArr, cProd])
                    prod["comboProductDetails"] = [...comboProdSet];
                }
            }

            newProd.push(prod);
        }

        setCartArray({
            loaded: true,
            no_of_carts: newProd.length,
            cart: newProd,
            combo: cartArray.combo,
        });
    }

    const getFinalDisplayPrice = (productData) => {
        const mop = parseFloat(productData.price?.mop);
        if (!mop) return 0;
        const flatDiscount = productData.discount?.flatDiscount;

        let result = mop;

        if (flatDiscount?.value > 0 && isOfferActive(flatDiscount)) {
            if (flatDiscount?.discountType === "flat") {
                result -= flatDiscount?.value;
            } else {
                result -= (result * flatDiscount?.value) / 100;
            }
        }

        return result;
    };

    const onEditOrAddAddress = () => {
        if (userDefaultAddress?.address?.customerName) {
            // navigate("/editaddress",
            //     {
            //         state: userDefaultAddress.address
            //     }
            // )
            navigate("/yourAddresses",  {
                state: {
                    from: "payment"
                }
            });
        } else {
            navigate("/profile/yourAddresses/newAddress")
        }
    }

    // main renderer
    return (
        <>
            <div className="page_Wrapper">
                <div style={{ marginTop: "1rem" }}>
                    <img src={left_arrow} alt="backIcon" className="backIcon" />
                    <span className="backSpan" onClick={() => navigate(-1)}>
                        {t('back')}
                    </span>
                    <span className="checkoutSpan">{t("cart.checkout")}</span>
                </div>
                <div className="main_wrapper">
                    <div className="main_container ">
                        <div className="main_heading">
                            <h3 className="text-uppercase">{t("shippingInformation")}</h3>
                        </div>
                        <div className="shippment_details">
                            <div className="shipment_div row">
                                <span className="keys col-sm-3">{t('login.name')}</span>{" "}
                                <span className="values col-sm-9">
                                    {userContext.fullName}
                                </span>
                            </div>
                            <div className="shipment_div row">
                                <span className="keys col-sm-3">{t('login.email')}</span>{" "}
                                <span className="values col-sm-7" style={{
                                    color: userContext.email === "" ? "red" : "initial"
                                }}>
                                    {userContext.email !== "" ? userContext.email : "No Email Found"}
                                </span>
                                <span className="col-sm-2 email-edit-btn" onClick={handleOpen}>Edit</span>
                            </div>
                            <div className="shipment_div row">
                                <span className="keys col-sm-3">
                                    {t("shippingAddress")}
                                </span>{" "}
                                {userDefaultAddress?.address?.customerName ?
                                    <span className="values col-sm-7">{`${userDefaultAddress?.address?.address_line1} ${userDefaultAddress?.address?.city} ${userDefaultAddress?.address?.country} ${userDefaultAddress?.address?.zip}`}
                                    </span> : <span style={{
                                        fontWeight: "bold"
                                    }} className="col-sm-7 text-danger d-flex align-items-center">No Address Found</span>}
                                <span className="col-sm-2 email-edit-btn" onClick={onEditOrAddAddress}>{userDefaultAddress?.address?.customerName ? "Edit" : "Add"}</span>
                            </div>
                            <div className="shipment_div row border-0">
                                <span className="keys col-sm-3">{t("phoneNo")}</span>{" "}
                                <span className="values col-sm-9">
                                    {userContext.mobileNumber}
                                </span>
                            </div>
                        </div>

                        <div className="cart-coupon">
                            <div>
                                <div className="coupon-head">
                                    <span className="head-size text-uppercase">{t("coupon")}</span>
                                    <div className="hr-coupon"></div>
                                </div>

                                <div className="input-coupon">
                                    <div className="instruct-coupon">
                                        {t("couponEnter")}
                                    </div>
                                    <input
                                        className="search-input inpt"
                                        type="text"
                                        placeholder={t("couponCodePlaceholder")}
                                        name="coupon_code"
                                        id="coupon_code"
                                    />
                                    <div
                                        className="coupon-btn inpt"
                                        onClick={checkEnteredCoupon}
                                    >
                                        <span className="apply-coupon-btn">
                                            {t("apply")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="second_container">
                        <div className="second_container_main_heading">
                            <h3 className="secondHeading text-uppercase">{t("order-page.yourOrder")}</h3>
                        </div>
                        {cartArray?.no_of_carts > 0 &&
                            cartArray?.cart?.map((product, index) => {
                                return (
                                    <>
                                        <CardDiv
                                            key={index}
                                            image={product?.images?.[0]}
                                            name={product.title}
                                            price={getFinalDisplayPrice(product) * product.quantity}
                                            mrp={product.price.mrp * product.quantity}
                                            details={truncatedText(
                                                product.description[0]?.split("\n")[0],
                                                40
                                            )}
                                            hierarchyL2={product.hierarchyL2}
                                        />

                                        {product['comboProductDetails']?.map(cProd => {
                                            return (
                                                <CardDiv
                                                    key={index}
                                                    image={cProd?.images?.[0]}
                                                    name={cProd.title}
                                                    price={getComboProdPrice(cProd.price?.mop, product.discount.combo)}
                                                    mrp={cProd.price.mrp}
                                                    details={truncatedText(
                                                        cProd.description[0]?.split("\n")[0],
                                                        40
                                                    )}
                                                    category={cProd.hierarchyL2}
                                                    isComboProduct={true}
                                                />
                                            )
                                        })
                                        }
                                    </>
                                )
                            })}

                        {/* <CardDiv name="Headphone pink color" price="₹4369.00" />

                    <CardDiv name="Headphone pink color" price="₹4369.00" />
                    <CardDiv name="Headphone pink color" price="₹4369.00" /> */}
                        {/* {gst && (
                        <p className="cart_Text section_Wrapper marginLeft">
                            <FormControlLabel
                                label="Claim GST"
                                control={
                                    <Checkbox onChange={handleClaimGstChange} />
                                }
                            />
                        </p>
                    )} */}
                        {/* <p className="cart_Text section_Wrapper marginLeft">
                        <FormControlLabel
                            label={t("SecureWithOnsitego")}
                            control={
                                <Checkbox onChange={addOnsitego} />
                            }
                        />
                    </p> */}
                        <hr className="order_break" />
                        <div className="subtotal">
                            <div className="subtotall_keys">
                                <p>Subtotal</p>
                                <p>Discount</p>
                                <p>Shipping Charges</p>
                                <p>Coupon Applied</p>
                                <p>Combo Products</p>
                                <span>TOTAL</span>
                            </div>
                            <div className="subtotall_value">
                                <p>
                                    {currencyFormat(priceBoxDetails.cartItemsPrice)}
                                </p>
                                <p>
                                    {currencyFormat(priceBoxDetails.totalDiscount)}
                                </p>
                                <p>
                                    {currencyFormat(
                                        priceBoxDetails.totalDeliveryCharge
                                    )}
                                </p>
                                <p>{appliedCoupon?.code || "-"}</p>
                                {comboProducts.length > 0 ? (
                                    <p>{comboProducts.length}</p>
                                ) : (
                                    <p>{t("cart.none")}</p>
                                )}
                                <span>
                                    {currencyFormat(priceBoxDetails.totalAmount)}
                                </span>
                            </div>
                        </div>
                        {(orderInit.type === "Manual" && getStoreName()) && <div style={{ marginBlock: "1rem", textAlign: "center" }}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={checked}
                                    onChange={handleCheckBoxChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />}
                                label={`Collect Product(s) from Store: ${getStoreName()}`} />
                        </div>}
                        <button
                            className="submit-button buy-now"
                            style={{ padding: "20px" }}
                            disabled={
                                disable || initProcessing || !cartArray.cart.length
                            }
                            onClick={handleOrderInit}
                        >
                            <h4
                                className="submit-button-textPop"
                                style={{ fontSize: "26px" }}
                            >
                                {t("payNow")}
                            </h4>
                        </button>
                    </div>
                </div>
            </div>
            {openModal && <EditDetailsModal
                open={openModal}
                handleClose={handleClose}
                setOpenModal={setOpenModal}
                isMobile={isMobile}
                data={userData}
            />}
        </>
    );
};

export default Payment;
