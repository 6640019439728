import SearchIcon from "@mui/icons-material/Search";
import driverImage from "../../assets/png/your_order_driver.png";
import { CircularProgress } from "@mui/material";
import { currencyFormat, formatDate } from "../../utils";
import { useTranslation } from "react-i18next";

function YourOrderDetails({ isMobile, orderDetails, loading , searchValue, handleChange}) {
  const { t } = useTranslation();
  return (
    loading ? <div className="loader-div"><CircularProgress color="inherit" style={{marginBlock: "1rem"}}/></div> :
    <div className="your_order_details">
      <div className="your_order_placed">
        {isMobile && (
          <div className="your_order_id">
            <p>{t("order-page.orderId")}: {orderDetails._id}</p>
          </div>
        )}
      </div>
      <div className="your_order_div">
        <div className="your_order_tracks">
          <div className="your_order_tracks_inside">
            <div className="your_order_header">
              <h1>{t("order-page.yourOrder")}</h1>
              <p>{t("order-page.trackOrder")}</p>
            </div>
            {!isMobile && (
              <div className="your_order_id">
                <p><span className="text-uppercase">{t("order-page.orderId")}</span>: {orderDetails._id}</p>
              </div>
            )}
            <div className="your_search_container">
              <div className="your_order_search">
                <SearchIcon className="your_ordersearch_icon" />
                <input
                  type="search"
                  placeholder={t("order-page.searchPlaceholder")}
                  className="your_order_searchbar"
                  value={searchValue}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="your_order_tracks_second">
            <div className="your_order_date">
              <h1 className="your_order_title">{t("order-page.orderPlaced")}</h1>
              <h1 className="your_order_placed_date">{formatDate(orderDetails.createdAt)}</h1>
            </div>
            <div className="your_order_total_price">
              <h1 className="text-uppercase">{t("total")}</h1>
              <h1>{currencyFormat(orderDetails.totalPrice)}</h1>
            </div>
            {isMobile && (
              <div className="your_order_image">
                <img src={driverImage} alt="driverImage" />
              </div>
            )}
          </div>
        </div>
        {!isMobile && (
          <div className="your_order_image">
            <img src={driverImage} alt="driverImage" />
          </div>
        )}
      </div>
    </div>
  );
}

export default YourOrderDetails;
