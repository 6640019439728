import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

//CSS
import "./MyCart.css";

//Components
import CartSection from "./CartSection";
import { getCartData } from "../../api/Cart";
import { getSaveForLater } from "../../api/SaveForLaterApi";
import { toast } from "react-toastify";
import useUserCtx from "../../hooks/useUserCtx";
import { useTranslation } from "react-i18next";
import { currencyFormat, formatNumber, isOfferActive } from "../../utils";

//mui
import { Box, Modal, Typography, useMediaQuery } from "@mui/material";
import CartHand from "../../assets/vector/cartHandIcon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProductCard from "../../components/Cards/ProductCard";


// API
import { getSearchedProduct, suggestedProducts } from "../../api/Product";

// Utils
import { PAYMENT_STATUS } from "../../utils/Constants";

// Component definitions
const MyCart = () => {
    const { t } = useTranslation();
    const {
        cartArray,
        setCartArray,
        userWishlist,
        setUserSaveForLater,
        priceBoxDetails,
        appliedCoupon,
        userDefaultAddress,
    } = useUserCtx();
    const [emptyCartFeaturedProducts, setEmptyCartFeaturedProducts] = useState(
        []
    );
    const nav = useNavigate();
    const isMobile = useMediaQuery("(max-width: 540px)");

    // const location = useLocation();
    const [searchParams] = useSearchParams();
    // const [coupon, setCoupon] = useState("");
    const [openAddInstruction, setOpenAddInstruction] = useState(false);
    // products that are added as combo offer to product in cart
    const [comboProducts, setComboProducts] = useState([]);

    useEffect(() => {
        const paymentStatus = searchParams.get("paymentStatus");
        const reason = searchParams.get("reason");
        if (!paymentStatus || !reason) return;
        if (paymentStatus === PAYMENT_STATUS.FAILURE) {
            setTimeout(() => {
                toast.error(t("cart.payment-failed"), {
                    toastId: "payment-fail",
                });
            }, 200);
            nav("/mycart", { replace: true });
        }
    }, []);

    useEffect(() => {
        suggestedProducts().then(res => {
            setEmptyCartFeaturedProducts(res.data?.status === "success" ? res.data.productsWithRatings : []);
        })
    }, []);

    useEffect(() => {
        fetchNSetCartDetails();
    }, []);

    useEffect(() => {
        getSaveForLater().then((res) => {
            if (res) {
                setUserSaveForLater({
                    loaded: true,
                    no_of_save_for_later_items: res.no_of_save_for_later_items,
                    save_for_later_items: res.save_for_later_items,
                });
            }
        });
    }, []);

    const fetchNSetCartDetails = async () => {
        const res = await getCartData();

        if (res) {
            let prod = []
            for (const item of res.cart) {
                if (item?.productId) {
                    let product = { ...item?.productId };
                    product["quantity"] = item.qty;
                    product["from"] = item.from;
                    const combo = item?.productId?.discount?.combo;
                    // fetch child product detail for combo offer if any
                    if (combo?.value > 0 && isOfferActive(combo)) {
                        const cProd = await fetchComboProduct(combo.childEan);
                        const cProdArr = product["comboProductDetails"] ?? [];
                        const comboProdsSet = new Set([...cProdArr, cProd]);
                        product["comboProductDetails"] = [...comboProdsSet];
                        setComboProducts([...comboProdsSet]);
                    }
                    prod.push(product);
                }
            }

            setCartArray({
                loaded: true,
                no_of_carts: prod.length,
                cart: prod,
                combo: res.combo,
            });
        }
    }

    // fetches the child product in combo offer
    const fetchComboProduct = async (productEan) => {
        const response = await getSearchedProduct(`ean=${productEan}`);
        const product = response.products[0];
        return product;
    };

    // main renderer
    return (
        <div className="page_Wrapper">
            {/* <BreadCrumbs data={breadCrumbsData} /> */}
            {/* removing save for later items condition for now */}
            {cartArray.no_of_carts === 0 ? (
                <>
                    <div className="empty_order_sec">
                        <p className="empty_order_text">
                            {t("cart.empty-cart")}
                        </p>
                        <button
                            type="submit"
                            className="submit-button"
                            onClick={() => nav("/")}
                        >
                            <p>{t("start-shopping")}</p>
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="w-100 h-100 py-4">
                        <div className="d-flex justify-content-evenly flex-column flex-lg-row w-100 px-2 px-lg-5 py-3">
                            <div className="address-detail-con user-cart-address-itm d-block d-md-flex justify-content-between justify-content-lg-center">
                                <div className="address-cart-box add-itm1">
                                    <span
                                        className="fw-bold fs-5"
                                        id="cart-heading"
                                    >
                                        1 Delivery address:
                                    </span>
                                </div>
                                <div className="address-cart-box add-itm2">
                                    {userDefaultAddress?.address?.customerName ? <>
                                        <div
                                            className="text-end cursor-pointer text-xs text-link"
                                            onClick={() =>
                                                nav(
                                                    "/profile/yourAddresses/newAddress"
                                                )
                                            }
                                        >
                                            <span>Change</span>
                                        </div>

                                        <span id="cart-address-detail">
                                            {
                                                userDefaultAddress?.address
                                                    ?.customerName
                                            }{" "}
                                            &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                                            {
                                                userDefaultAddress?.address
                                                    ?.address_line1
                                            }
                                            ,{userDefaultAddress?.address?.city},{" "}
                                            {userDefaultAddress?.address?.state},{" "}
                                            {userDefaultAddress?.address?.country},{" "}
                                            {userDefaultAddress?.address?.zip}
                                        </span>
                                        {/* <span
                                                onClick={() =>
                                                    setOpenAddInstruction(true)
                                                }
                                                className="cursor-pointer text-xs text-link mt-3"
                                                id="cart-address-detail2"
                                            >
                                                Add delivery instructions
                                            </span> */}
                                    </> : <span
                                        onClick={() =>
                                            nav(
                                                "/profile/yourAddresses/newAddress"
                                            )
                                        }
                                        className="cursor-pointer text-xs text-link mt-3 fs-6 fs-lg-5"
                                        id="cart-address-detail2"
                                    >
                                        Add new address
                                    </span>
                                    }
                                    <Modal
                                        open={openAddInstruction}
                                        onClose={() =>
                                            setOpenAddInstruction(false)
                                        }
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        {/* <AddressModal/> */}
                                        <Box
                                            sx={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform:
                                                    "translate(-50%, -50%)",
                                                width: isMobile
                                                    ? "340px"
                                                    : "600px",
                                                display: "flex",
                                                bgcolor: "background.paper",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                p: 4,
                                                outline: "none",
                                                padding: isMobile
                                                    ? "10px"
                                                    : "30px 20px",
                                            }}
                                        >
                                            <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                sx={{
                                                    fontSize: isMobile
                                                        ? "14px"
                                                        : "17px",
                                                    width: "30%",
                                                }}
                                            >
                                                Add your Address
                                            </Typography>
                                            <input
                                                style={{ width: "70%" }}
                                                type="text"
                                                className="addressModalInput"
                                            />
                                        </Box>
                                    </Modal>
                                </div>
                            </div>
                            <div className="aside-total-price user-cart-address-itm">
                                <div className="w-100 fs-6 px-4 py-3 d-flex justify-content-center flex-column fw-bold">
                                    <div className="final-price-comp d-flex justify-content-between">
                                        <span className="price-item">
                                            Subtotal
                                        </span>
                                        <span className="text-gray w-25">
                                            ₹
                                            {formatNumber(
                                                priceBoxDetails.cartItemsPrice
                                            )}
                                        </span>
                                    </div>
                                    <hr
                                        className="border-secondary opacity-75 my-2"
                                        style={{ width: "100%" }}
                                    />
                                    <div className="final-price-comp d-flex justify-content-between">
                                        <span className="price-item">
                                            Discount
                                        </span>
                                        <span
                                            className="text-gray w-25"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            -₹
                                            {formatNumber(
                                                priceBoxDetails.totalDiscount
                                            )}
                                        </span>
                                    </div>
                                    <hr
                                        className="border-secondary opacity-75 my-2"
                                        style={{ width: "100%" }}
                                    />
                                    <div className="final-price-comp d-flex justify-content-between">
                                        <span className="price-item">
                                            Coupon Apply
                                        </span>
                                        <span className="text-gray w-25">
                                            {appliedCoupon?.code ||
                                                t("cart.none")}
                                        </span>
                                    </div>
                                    <hr
                                        className="border-secondary opacity-75 my-2"
                                        style={{ width: "100%" }}
                                    />
                                    <div className="final-price-comp d-flex justify-content-between">
                                        <span className="price-item">
                                            Shipping
                                        </span>
                                        <span className="text-gray w-25">
                                            {currencyFormat(
                                                priceBoxDetails.totalDeliveryCharge
                                            )}
                                        </span>
                                    </div>
                                    <hr
                                        className="border-secondary opacity-75 my-2"
                                        style={{ width: "100%" }}
                                    />
                                    <div className="final-price-comp d-flex justify-content-between">
                                        <span className="price-item c-clack">
                                            TOTAL
                                        </span>
                                        <span className="text-gray w-25">
                                            {currencyFormat(
                                                priceBoxDetails.totalAmount
                                            )}
                                        </span>
                                    </div>
                                    <hr
                                        className="border-secondary opacity-75 my-2"
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                <div>
                                    <button
                                        className="yellow-theme-btn"
                                        onClick={() =>
                                            nav("/payment", { replace: true })
                                        }
                                    >
                                        Checkout
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <aside className="side_Section" style={{ padding: "0", background: "none" }}>
                            <PriceDetailsBox
                                HideDetails={false}
                                classes={{ containerClass: "" }}
                            />
                        </aside> */}
                        <CartSection
                            comboProducts={comboProducts}
                            resetCartDetails={fetchNSetCartDetails}
                        />

                        {/* coupon section */}
                        <div className="cart-coupon flex-column flex-md-row">
                            {/* <div className="cart-coupon-child1">
                                <div className="coupon-head">
                                    <span className="head-size">COUPON</span>
                                    <div className="hr-coupon"></div>
                                </div>
                                
                                <div className="input-coupon">
                                    <div className="instruct-coupon">
                                        Enter your coupon code if you have one.
                                    </div>
                                    <input
                                        className="search-input inpt"
                                        type="text"
                                        placeholder="Voucher Code"
                                        value={coupon}
                                        onChange={(e) => setCoupon(e.target.value)}
                                    />
                                    <div className="coupon-btn inpt">
                                        <span className="apply-coupon-btn">Apply</span>
                                    </div>
                                </div>
                            </div> */}
                            <div className="cart-coupon-child2">
                                <div className="coupon-details">
                                    <div className="d-flex">
                                        <span className="coupon-typography">
                                            Coupon Apply
                                        </span>
                                        <span className="coupon-typogrphy ml2px txt-clr-grey">
                                            {appliedCoupon?.code ||
                                                t("cart.none")}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        <span className="coupon-typography txt-clr-grey">
                                            TOTAL
                                        </span>
                                        <span
                                            className="coupon-typography"
                                            id="text2xl"
                                        >
                                            ₹
                                            {formatNumber(
                                                priceBoxDetails.totalAmount
                                            )}
                                        </span>
                                        {priceBoxDetails.cartItemsPrice !==
                                            priceBoxDetails.totalAmount ? (
                                            <span className="coupon-typography text-decoration-line-through">
                                                M.R.P.: ₹
                                                {formatNumber(
                                                    priceBoxDetails.cartItemsPrice
                                                )}
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="d-flex">
                                        <span className="coupon-typography">
                                            Shipping
                                        </span>
                                        <span className="coupon-typography txt-clr-grey">
                                            ₹
                                            {formatNumber(
                                                priceBoxDetails.totalDeliveryCharge
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {/* <div class="cart-detail-section2 mt-2 align-items-center py-2">
                                    <div className="cart-sub-detail d-flex">
                                        <div className="sub-details">
                                            <img src={CartHand} alt="" />
                                        </div>
                                        <div className="sub-details">
                                            <span>Easy Payment Plans</span>
                                            <span>
                                                EMI Starts At ₹78.00/Month
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-link p-3 fw-bold">
                                            <span>Details</span>
                                            <ExpandMoreIcon fontSize="small" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="cart-detail-section3 pb-4">
                                    <button
                                        className="yellow-theme-btn"
                                        onClick={() =>
                                            nav("/payment", { replace: true })
                                        }
                                    >
                                        <p style={
                                            {
                                                fontSize: "14px",
                                                lineHeight: "16px",
                                                fontWeight: "500",
                                                letterSpacing: "-0.5px",
                                                display: "inline-block",
                                                transform: "skew(20deg)",
                                                margin: 0,
                                                color: " #ffffff"
                                            }
                                        } >
                                            Buy Now
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/*Frequently products */}
            <div className="suggested-product-container">
                <div className="suggested-heading d-flex justify-content-center">
                    <div className="sub-head-cart1  d-flex justify-content-start w-100">
                        <span className="text4_xl">
                            {t('FrequentlyBroughtTogether')}
                        </span>
                    </div>
                    {/* <div className="sub-head-cart2" id="head-bar-sugges"></div> */}
                </div>
                <div className="suggested-products d-flex  flex-lg-row">
                    {
                        emptyCartFeaturedProducts
                            .map((e, i) => (
                                <ProductCard
                                    key={`${i}-${e?._id}`}
                                    id={e?._id ?? ""}
                                    productImg={e?.images?.[0] ?? null}
                                    productName={e?.title}
                                    productDescription={e?.description?.[0]?.split(" ")?.slice(0, 18)?.join(" ") ?? ''}
                                    productRating={e?.rating ?? 0}
                                    productPriceObj={e?.price}
                                    productDiscountObj={e?.discount}
                                    productSlug={e?.slug}
                                    wishlist={userWishlist.wishlist_items?.some(obj => obj?._id?.toString() === e?._id?.toString())}
                                    cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some(obj => obj?._id?.toString() === e?._id?.toString())}
                                />
                            ))}
                </div>
            </div>
        </div>
    );
};

export default MyCart;
