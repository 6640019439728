import { Box, Button, CardContent, Paper, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { addCouponsToStores, getAllCouponsOffers } from '../../api/OfferAPIs';
import DashboardLoader from '../../components/DashboardContent/DashboardLoader';
import { Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

function AdminOfferStoreCoupons() {
    const [loader, setLoader] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [searchText, setSearchText] = useState("");
    const nav = useNavigate();

    useEffect(() => {
        fetchTotalCoupons();
    }, []);

    const fetchTotalCoupons = (value = '') => {
        getAllCouponsOffers(value).then((res) => {
            setCoupons(res);
            setLoader(false);
        });
    }

    function handleSearchSubmit() {
        nav(`/admin/admin-offers/coupons/${searchText}`)
    }

    const CARD = (coupon, index) => (
        <React.Fragment>
            <CardContent key={index} className='mt-2'>
                <Typography variant="h5" component="div" className='mb-2'>
                    Coupons: <b>{coupon?.prizeText}</b>
                </Typography>
                < TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Coupon ID</TableCell>
                                <TableCell>Coupon Name</TableCell>
                                <TableCell align='center'>Coupons Remaining</TableCell>
                                <TableCell align='center'>Total Coupons</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {coupon.prizeIndex}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {coupon.prizeText}
                                </TableCell>
                                <TableCell align="center">{coupon.coupons_remaining}</TableCell>
                                <TableCell align="center">{coupon.total_no_of_coupons}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableFooter>
                    {
                        coupon?.offerRevealingTimeline &&
                        <React.Fragment>
                            <Typography component="div" className='mt-4'>
                                <b>Offer Revealing Timeline</b>
                            </Typography>
                            {
                                Object.entries(coupon.offerRevealingTimeline).map((arr, ind) => (
                                    <TableRow key={`row_offer_timeline_${ind}`}>
                                        {
                                            arr.map((ele, i) => (
                                                <TableCell key={`cell_offer_timeline_${i}`}>
                                                    {i === 0 ? `${ele} Jan` : ele}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                ))
                            }
                        </React.Fragment>
                    }
                    {
                        coupon.added_coupon.length > 0 &&
                        <Box>
                            <Typography component="div" className='mt-4'>
                                <b>Store Coupons Info</b>
                            </Typography>
                            {
                                coupon.added_coupon.map((c, i) => (
                                    <Box key={`row_offer_store_${i}`} sx={{
                                        borderBottom: "1px solid"
                                    }}>
                                        <TableRow>
                                            <TableCell>Store Name</TableCell>
                                            <TableCell>{c.fc_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Store ID</TableCell>
                                            <TableCell>{c.store_id}</TableCell>
                                        </TableRow>
                                        {
                                            c.offerDetails.map((details, index) => (
                                                <Box key={`cell_offer_timeline_details_${index}`}>
                                                    <TableRow >
                                                        <TableCell>
                                                            Offer ID
                                                        </TableCell>
                                                        <TableCell>
                                                            {details.offerId}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Offer Reveal Date
                                                        </TableCell>
                                                        <TableCell>
                                                            {details.offerRevealDate}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow >
                                                        <TableCell>
                                                            User Index
                                                        </TableCell>
                                                        <TableCell>
                                                            {details.userIndex}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Offer Revealed ?
                                                        </TableCell>
                                                        <TableCell>
                                                            {details.isRevealed ? "True" : "False"}
                                                        </TableCell>
                                                    </TableRow>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                ))
                            }
                        </Box>
                    }
                </TableFooter>
            </CardContent>
        </React.Fragment >
    );

    const [formData, setFormData] = useState({
        store_sap_code: 0,
        offerId: 0,
        offerRevealDate: 0,
        userIndex: 0
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data submitted:', formData);

        if (![1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(Number(formData.offerId))) return toast.error('Invalid Offer ID');

        if (![24, 25, 26, 27].includes(Number(formData.offerRevealDate))) return toast.error('Invalid Offer Reveal Date');

        if (Number(formData.userIndex) === 0) return toast.error('Invalid User Index');

        const payload = {
            store_sap_code: Number(formData.store_sap_code),
            offerDetails: {
                offerId: Number(formData.offerId),
                offerRevealDate: Number(formData.offerRevealDate),
                userIndex: Number(formData.userIndex)
            }
        };

        addCouponsToStores(payload).then((res) => {
            if (res.data.status === "success") {
                setCoupons(res);
                setLoader(false);
                toast.success("Added Coupon to Store!!!");
            } else {
                toast.error(res.data.message);
                setFormData(prev => ({ ...prev, store_sap_code: 0, offerId: 0, offerRevealDate: 0, userIndex: 0 }));
            }

        });
    };

    return loader ? (
        <DashboardLoader />
    ) : (
        <div className="container">
            <div className="d-flex justify-content-between">
                <h3>Total Coupons: {coupons.length > 0 && coupons.reduce((acc, currentValue) => acc + currentValue.coupons_remaining, 0)}</h3>
                <span className="d-flex justify-content-center align-items-center">
                    <TextField type="search" id="outlined-basic" size="small" className="me-2" label="Coupon ID" onChange={(e) => setSearchText(e.target.value)} value={searchText} variant="outlined" />
                    <Button className="btn-sm" variant='contained' onClick={handleSearchSubmit}>Search</Button>
                </span>
            </div>
            <Box>
                {coupons.map((coupon, index) => (
                    CARD(coupon, index)
                ))}
            </Box>

            <Container maxWidth="sm" className='my-5'>
                <Typography variant="h4" gutterBottom>
                    Add Coupons To Store
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Store ID"
                        name="store_sap_code"
                        value={formData.store_sap_code}
                        onChange={handleChange}
                        type='number'
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Offer ID"
                        type='number'
                        name="offerId"
                        value={formData.offerId}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        type='number'
                        label="Offer Reveal Date"
                        name="offerRevealDate"
                        value={formData.offerRevealDate}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        type='number'
                        label="User Index"
                        name="userIndex"
                        value={formData.userIndex}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                        Submit
                    </Button>
                </form>
            </Container>

        </div>
    )
}

export default AdminOfferStoreCoupons
