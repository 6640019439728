import React, { useEffect, useState } from "react";
import "./styles.css";
import Image from "../../assets/png/trackingPerson.png";
import { useParams, useLocation, Link } from "react-router-dom";
import * as moment from "moment";

// MUI Icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// API
import { getTrackingDetails, downloadInvoice } from "../../api/OrdersApi";

// react i18n
import { useTranslation } from "react-i18next";
// import { currencyFormat } from "../../utils";
import { useMediaQuery } from "@mui/material";
import ProductReviewModal from "../../components/ProductReview";

import { toast } from "react-toastify";

const TrackingOrder = () => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery("(max-width: 500px)");

    const [openModal, setOpenModal] = useState(false);
    const [productId, setProductId] = useState(null)

    // Constants
    const weekDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    const DATE_FORMAT = "dddd, MMM DD, YYYY, hh:mm A";


    const { id } = useParams();
    const location = useLocation();

    const breadCrumbsData = [
        {
            text: t('profile.profile') + " / ",
            url: "/myProfile",
            isActive: location.pathname === "/myProfile"
        },
        {
            text: t('order') + " / ",
            url: "/yourOrders",
            isActive: location.pathname === "/yourOrders"
        },
        {
            text: t('tracking'),
            url: `/trackingOrder/${id}`,
            isActive: location.pathname.includes("/trackingOrder")
        },
    ];

    // state declarations
    const [consignment, setConsignment] = useState(null);

    // ComponentDidMount / ComponentDidUpdate
    useEffect(() => {
        fetchTrackingDetails(id);
    }, [id]);

    const handleOpen = (id) => {
        setOpenModal(true);
        setProductId(id)
    }
    const handleClose = () => setOpenModal(false);

    /** Handler functions - starts */

    const fetchTrackingDetails = async (orderProdId) => {
        try {
            const response = await getTrackingDetails(orderProdId);
            const payload = response.data;
            if (payload.status === "success") {
                setConsignment(payload.data);
            }
        } catch {
            // handle error
        }
    };

    async function handleInvoiceDownload(orderId) {
        try {
            const response = await downloadInvoice(orderId);
            const file = new Blob([response], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            URL.revokeObjectURL(file);
        } catch (err) {
            let message = err.toString();
            if (err?.response) {
                message = err.response.data;
            }
            toast.error(message)
        }
    }

    /** Handler functions - ends */

    // Computed props
    const orderDate = moment(consignment?.createdAt).format(DATE_FORMAT);

    // shipped at
    const shippedDate = moment(
        consignment?.shipment_track_activities?.find((activity) => {
            return activity["sr-status"] === 6; // Consignment shipped
        })?.date ?? null
    ).format(DATE_FORMAT);

    // - estimated date of delivery
    const latestActivityEdd =
        consignment?.shipment_track_activities?.find((activity) => {
            return activity.status === "expected";
        }) ?? null;
    const etd = latestActivityEdd?.date ?? consignment?.etd;
    const arrivalDateIdx = etd ? moment(etd).day() : -1;
    const arrivalDay = arrivalDateIdx > -1 ? weekDays[arrivalDateIdx] : "Soon";

    // out for delivery
    const outForDelivery = moment(
        consignment?.shipment_track_activities?.find((activity) => {
            return activity["sr-status"] === 17; // Consignment out for delivery
        })?.date ?? null
    ).format(DATE_FORMAT);

    // delivered
    let delivered = moment(
        consignment?.type === "offline_sale" &&
            consignment?.status === "DELIVERY_SUCCESS"
            ? consignment?.delivered_at //Offline Sale
            : consignment?.shipment_track_activities?.find((activity) => {
                return activity["sr-status"] === "7"; // Consignment delivered
            })?.date ?? null
    ).format(DATE_FORMAT);

    if (delivered === 'Invalid date') {
        const deliveredAct = consignment?.shipment_track?.find(
            (el) => el.current_status === 'Delivered'
        ) ?? null;
        if (deliveredAct?.delivered_date) {
            delivered = moment(deliveredAct?.delivered_date).format(DATE_FORMAT);
        }
    }

    console.log({
        orderDate,
        shippedDate,
        latestActivityEdd,
        etd,
        arrivalDateIdx,
        arrivalDay,
        outForDelivery,
        delivered
    })

    // Main renderer
    return (
        <div className="trackingOrderWrapper">
            <div className="headerSection">
                <span className='breadcrumbs trackingOrderLeft'>
                    {breadCrumbsData.map((item, index) =>
                        <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>{item.text}
                        </Link>)}
                </span>
            </div>
            <div className="trackingOrderContent mt-5">
                <div className="trackingOrderLeft">
                    <div className="trackingOrderHeaderContainer">
                        <div className="trackingOrderHeader">
                            <h1 className="trackingOrderAbout1">
                                {t("order-page.trackPackage")}
                            </h1>
                            <p className="trackingOrderAbout2" style={{ paddingBottom: "20px" }}>
                                <span className="text-uppercase">{t("order-page.orderId")}</span>: {id.split("-")[0]}
                            </p>
                        </div>
                        <div className="trackingOrderImageContainer">
                            <img
                                src={Image}
                                alt="trackingOrderImage"
                                className="trackingOrderImage"
                            />
                        </div>
                    </div>

                    {location.hash && consignment?._id && consignment?.order?._id && (
                        <div
                            className="your_order_cart_buttons_3 mt-4"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                handleInvoiceDownload(consignment?.order?._id ?? consignment?.order)
                            }
                        >
                            <div className="your_cart_downloadicon_container">
                                <FileDownloadIcon className="your_cart_downloadicon" />
                            </div>
                            <div className="your_order_cart_buttons_3_text">
                                <p className="cart_texts">
                                    {t("order-page.download")}
                                </p>
                                <p className="cart_texts">
                                    {t("order-page.invoice")}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                <div className="trackingOrderTrack">
                    <h1 className="trackingOrderDate">
                        {delivered !== "Invalid date"
                            ? t("delivered")
                            : t("arriving") + ' ' + arrivalDay}
                    </h1>
                    <div className="orderedTodayContainer">
                        <input
                            type="checkbox"
                            checked
                            className="OrderTodayCheckbox"
                        />
                        <h1 className="trackingOrderNames">
                            {t("ordered")}: {orderDate}
                        </h1>
                    </div>
                    <div className="trackingOrderline" />
                    {consignment?.type !== "offline_sale" && (
                        <>
                            <div className="orderedTodayContainer">
                                <input
                                    type="checkbox"
                                    className="OrderTodayCheckbox"
                                    checked={shippedDate !== "Invalid date"}
                                />
                                <h1
                                    className={`trackingOrderNames ${shippedDate === "Invalid date"
                                        ? "notDone"
                                        : ""
                                        }`}
                                >
                                    {t("shipped")}{" "}
                                    {shippedDate !== "Invalid date" &&
                                        shippedDate}
                                </h1>
                            </div>
                            <div className="trackingOrderNotCompleted" />
                            <div className="orderedTodayContainer">
                                <input
                                    type="checkbox"
                                    className="OrderTodayCheckbox"
                                    checked={outForDelivery !== "Invalid date"}
                                />
                                <h1
                                    className={`trackingOrderNames ${outForDelivery === "Invalid date"
                                        ? "notDone"
                                        : ""
                                        }`}
                                >
                                    {
                                        outForDelivery !== "Invalid date"
                                            ? `${t("outForDelivery")}: ${outForDelivery}`
                                            : t("outForDelivery")}
                                </h1>
                            </div>
                        </>
                    )}
                    <div className="trackingOrderNotCompleted" />
                    <div className="orderedTodayContainer">
                        <input
                            type="checkbox"
                            className="OrderTodayCheckbox"
                            checked={delivered !== "Invalid date"}
                        />
                        <h1
                            className={`trackingOrderNames ${delivered === "Invalid date" ? "notDone" : ""
                                }`}
                        >
                            {delivered !== "Invalid date"
                                ? `${t("delivered")}: ${delivered}`
                                : t("arriving") + ' ' + arrivalDay}
                        </h1>
                    </div>
                </div>
            </div>
            <div className="order_total_price">
                {consignment?.status === "DELIVERY_SUCCESS" && <div style={{
                    fontSize: "1.2rem",
                }}
                    onClick={() => handleOpen(consignment?.order.productId[0])}
                    className="review_btn"
                >
                    {t("shareYourReview")}
                </div>}
            </div>
            {productId && <ProductReviewModal
                open={openModal}
                handleClose={handleClose}
                setOpenModal={setOpenModal}
                isMobile={isMobile}
                productId={productId}
            />}
        </div>
    );
};

export default TrackingOrder;
