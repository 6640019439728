import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import DashboardLoader from '../../components/DashboardContent/DashboardLoader';
import {
    faPenToSquare,
    faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import EditUserModal from '../../components/EditUserModal/EditUserModal';
import { allUsers, deletUser, getUserAddressForAdminAPI, singleUSer } from '../../api/AdminApis/Users';
import Pagination from '../../components/Pagination/Pagination';
import { formatDate } from '../../utils';
import UserAddressModal from '../../components/EditUserModal/UserAddressModal';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Component definition
const DashboardAlluser = () => {

    // state definitions
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [user, setUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalUsers, setTotalUsers] = useState(1);
    const [userAddressId, setUserAddressId] = useState(null);
    const [userAddressModalShow, setUserAddressModalShow] = useState(false);

    const usersPerPage = 100;

    const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        setLoader(true);
        allUsers(`limit=${usersPerPage}&page=${currentPage}`).then((res) => {
            if (res) {
                setData(res.users);
                setTotalUsers(res.total_users)
            }
        }).finally(() => setLoader(false))
    }, [currentPage]);

    const handleDelet = (id) => {
        deletUser(id)
            .then(res => {
                setData(data.filter(message => message._id !== id));
            })
            .catch(err => {
                console.log(err);
            })
    }
    const editUser = (id) => {
        singleUSer(id)
            .then(res => {
                setUser(res)
            })
        setModalShow(true)
    }

    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber)
        window.scrollTo(0, 0)
    }

    async function handleUserAddressClick(userId) {
        try {
            setUserAddressId(userId);
            const response = await getUserAddressForAdminAPI(userId);
            if (response?.length > 0) {
                setAddresses(response);
                setUserAddressModalShow(true);
            } else {
                toast.warn("No Address Found");
            }
        } catch (error) {
            toast.error(error?.response?.data?.status === "error" ? error?.response?.data?.message : "Oops! Something went wrong");
        }
    }

    return loader ? (
        <DashboardLoader />
    ) : (
        <>
            <EditUserModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                user={user}
                setUser={setUser}
                setModalShow={setModalShow}
            />
            {userAddressId && <UserAddressModal
                details={addresses}
                open={userAddressModalShow}
                onClose={() => setUserAddressModalShow(false)}
            />}
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h3>All Users</h3>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">S.No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">Created At</th>
                                <th scope="col">Addresses</th>
                                {/* <th scope="col">Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 && data?.map((item, index) => (
                                <tr key={index}>
                                    <td>{(currentPage - 1) * usersPerPage + index + 1}.</td>
                                    <td> {item.fullName ?? "-"} </td>
                                    <td>{item.email ?? "-"}</td>
                                    <td>{item.mobileNumber ?? "-"}</td>
                                    <td>{item?.createdAt ? formatDate(item.createdAt) : "-"}</td>
                                    <td>
                                        <Button onClick={() => handleUserAddressClick(item._id)}>
                                            Get Addresses
                                        </Button>
                                    </td>
                                    {/* <td>
                                        <button className='btn' onClick={() => editUser(item._id)}>
                                            <FontAwesomeIcon
                                                className={"table-icon"}
                                                icon={faPenToSquare}
                                            />
                                        </button>
                                        <button className='btn' onClick={() => handleDelet(item._id)}><FontAwesomeIcon className={"table-icon"} icon={faTrashCan} /></button>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="pagination_Container">
                    <Pagination productsPerPage={usersPerPage} totalProducts={totalUsers} pageChange={handlePageChange} currentPage={currentPage} />
                </div>
            </div>
        </>
    );
};

export default DashboardAlluser;