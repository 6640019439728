import React from 'react';
import HeartImage from "../../assets/aboutUs/heartImage.svg";
import ABmobile1 from "../../assets/aboutUs/ABmobile1.svg";
import ABmobile2 from "../../assets/aboutUs/ABmobile2.svg";
import ABmobile3 from "../../assets/aboutUs/ABmobileNew.svg";

function mobileAboutUs() {
  return (
    <>
        <div className='absection-1' style={{padding:"0 5%"}}>
            <div className='absec-1sub'>
                <span style={{fontSize:"15px"}}>Profile / Wish list</span>
                <span style={{fontSize:"25px"}}>About Us Page</span>
            </div>
        </div>

        <div style={{
            display:"flex",
            flexDirection:"column",
            gap:"10px",
            fontWeight:"700",
            padding:"15px 5%",
            paddingBottom:"25%",
            background:"#F8F8F8"
        }}>

            <span style={{
                fontSize:"15px"
            }}>O-LINE-O – DIGITAL INDIA KI APNI DUKAAN</span>
            <span style={{
                fontSize:"10px",
                color:"#707070"
            }}>Launched in 2019 by Mr. Vibhooti Prasad, 
                O-LINE-O is a one-of-a-kind brand that caters 
                to the needs of all multi-brand smartphones & 
                electronic gadgets in India. Starting with our 
                first store in, today we have become India’s 
                fastest-growing mobile retail chain serving 
                happiness with our 150+ stores located in 00+ 
                cities across Maharashtra, Rajasthan, and 
                Karnataka. In our journey of merely 4 years, 
                we have come a long way and achieved many 
                milestones to become one of the leading 
                retail powerhouses in the country.</span>
        </div>

        <div>
            <div>
                <img style={{width:"100%",padding:"0 5%"}} src={ABmobile1}/>
            </div>
            <div className='mobileParentcss'>
                <img  className='mobilecss' src={ABmobile2}/>

            </div>
        </div>

        <div style={{
            display:"flex",
            flexDirection:"column",
            gap:"10px",
            fontWeight:"700",
            padding:"15px 5%",
        }}>

            <span style={{
                fontSize:"15px"
            }}>WE DELIVER THE BEST VALUE, SERVICE, & CHOICES UNDER ONE ROOF</span>
            <span style={{
                fontSize:"10px",
                color:"#707070"
            }}>We bring the widest range of the best smartphones,
               tablets, laptops, TVs, and accessories from all top 
               global brands under one roof. O-LINE-O’s commitment 
               to fulfilling the wishlist of every customer has left 
               an indelible mark on the consumers’ minds, 
               making us a trusted name to be reckoned with. 
               It is our team’s deep perseverance, and product 
               knowledge at the market level, coupled with the 
               widest range of products available at the best 
               value across all the stores that make us India’s 
               personal favorite.
               </span>
        </div>

        <div style={{
            display:"flex",
            flexDirection:"column",
            gap:"10px",
            fontWeight:"700",
            padding:"15px 5%",
            background:"#F8F8F8"
        }}>

            <span style={{
                fontSize:"15px"
            }}>WE’RE MORE THAN JUST A STORE</span>
            <span style={{
                fontSize:"10px",
                color:"#707070"
            }}>We’ve created a trust-driven network of brands,
             products, and services powered by our in-house 
             distribution system, thus bringing their latest 
             inventories and services directly to you. We provide 
             an agglomeration of the most popular products & services
              at the most affordable prices along with a never-ending 
              series of best offers, schemes, and benefits. Also, 
              we are tirelessly working to add better services and 
              options to buy from at our stores. So whether it be 2 
              years warranty, exchange offer, assured cashback, 
              multiple finance options, 0% down payment, paper 
              finance, easy EMI options, or anything else, you 
              wish it, we have it!
              </span>
        </div>
        
        <div style={{
            display:"flex",
            flexDirection:"column",
            gap:"10px",
            fontWeight:"700",
            padding:"15px 5%",
        }}>

            <span style={{
                fontSize:"15px"
            }}>WE DELIVER THE BEST VALUE, SERVICE, & CHOICES UNDER ONE ROOF</span>
            <span style={{
                fontSize:"10px",
                color:"#707070"
            }}>We bring the widest range of the best smartphones, 
            tablets, laptops, TVs, and accessories from all top global brands 
            under one roof. O-LINE-O’s commitment to fulfilling the 
            wishlist of every customer has left an indelible mark on
            the consumers’ minds, making us a trusted name to be reckoned 
            with. It is our team’s deep perseverance, and product knowledge 
            at the market level, coupled with the widest range of products 
            available at the best value across all the stores that make us 
            India’s personal favorite.
            </span>
        </div>


        <div style={{paddingLeft:"5%"}}>
            <img src={ABmobile3} style={{width:"99%"}} />
        </div>

        <div style={{
            display:"flex",
            flexDirection:"column",
            gap:"10px",
            fontWeight:"700",
            padding:"15px 5%",
        }}>

            <span style={{
                fontSize:"15px"
            }}>WE ARE A FAMILY FIRST THAN YOUR STORE</span>
            <span style={{
                fontSize:"10px",
                color:"#707070"
            }}>We bring the widest range of the best smartphones, tablets, 
            laptops, TVs, and accessories from all top global brands 
            under one roof. O-LINE-O’s commitment to fulfilling the 
            wishlist of every customer has left an indelible mark on the 
            consumers’ minds, making us a trusted name to be reckoned 
            with. It is our team’s deep perseverance, and product knowledge 
            at the market level, coupled with the widest range of products 
            available at the best value across all the stores that make us 
            India’s personal favorite.
            </span>
        </div>

        <div style={{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",
            gap:"10px",
            padding:"10px 5%"
        }}>
            <img src={HeartImage} style={{
                height:"70px",
                width:"70px"
            }} />
            <span style={{
                fontSize:"1rem",
                fontWeight:"700"
            }}>WE’RE CLOSE TO YOUR HEART</span>
        </div>

    </>
  )
}

export default mobileAboutUs