import React from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import "../../pages/Dashboard/styles/dashAddproduct.css";
import { useState } from "react";
import {
    updateTopBannerProduct,
} from "../../api/AdminApis/Banner";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import useAdminCtx from "../../hooks/useAdminCtx";
import { useEffect } from "react";
import { getHomePageSections } from "../../api/AdminApis/HomePageSections";

const ScrollBanner = () => {
    const nav = useNavigate();

    const location = useLocation();

    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const { allHomePageSections, setAllHomePageSections } = useAdminCtx();
    const [isImage, setIsimage] = useState(true);
    const [productData, setProductData] = useState({
        title: "",
        targetUrl: "",
        image: null,
        imageUrl: null,
    });

    const handleFileUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
          if(e.target.files[0].type === "video/mp4") {
            setIsimage(false);
          } else {
            setIsimage(true)
          }
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setProductData({
                        ...productData,
                        image: e.target.files[0],
                        imageUrl: reader.result,
                    });
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            if (location.state.updateProduct)
                return setProductData({
                    ...productData,
                    image: null,
                    imageUrl: location.state.data.imgUrl,
                });
            setProductData({ ...productData, image: null, imageUrl: null });
        }
    };

    const handleChange = (e) => {
        setProductData({ ...productData, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        if (!productData.title) return toast.error("Please Enter the Title!");
        if (!productData.targetUrl)
            return toast.error("Please Enter the Target URL!");
        if (!location.state.updateProduct && !productData.image)
            return toast.error("Please Choose an Image!");

        const formData = new FormData();
        formData.append("title", productData.title);
        formData.append("targetUrl", productData.targetUrl);
        formData.append(
            "priority",
            allHomePageSections.length === 0
                ? 0
                : allHomePageSections[allHomePageSections.length - 1].priority +
                      1
        );

        if (!location.state.updateProduct) {
            formData.append("image", productData.image);
        } else if (location.state.updateProduct) {
            formData.append("oldImgKey", productData.oldImgKey);
            productData.image && formData.append("image", productData.image);
        }

        setSubmitBtnLoading(true);

        updateTopBannerProduct(
            formData,
            !location.state.updateProduct,
            (err, res) => {
                setSubmitBtnLoading(false);
                if (err) {
                    toast.error("Something went wrong!");
                    return;
                }
                if (res.status === 200) {
                    toast.success(res.data.message || "Success!");
                    nav(location.state.from);
                }
            }
        );
    };
    useEffect(() => {
        getHomePageSections((err, res) => {
            if (err) toast.error("Unable to Fetch the Sections properly");
            setAllHomePageSections(res.sections);
        });
    }, []);

    useEffect(() => {
        if (location.state.updateProduct) {
            setProductData({
                ...productData,
                title: location.state.data.title,
                targetUrl: location.state.data.targetUrl,
                image: null,
                imageUrl: location.state.data.imgUrl,
                oldImgKey: location.state.data.imgKey,
            });
            if(location.state.data.imgUrl.split(".").pop() === "mp4") {
              setIsimage(false);
            } else {
              setIsimage(true)
            }
        }
    }, [location]);

    return (
        <>
            <div>
                <h2>Add Scroll Banner</h2>
            </div>

            <Form className="mt-4" encType="multipart/form-data">
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Product Title</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        value={productData.title}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                >
                    <Form.Label>Target URL</Form.Label>
                    <Form.Control
                        type="text"
                        name="targetUrl"
                        value={productData.targetUrl}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                    <label
                        htmlFor="scrollBanner-upload"
                        className="custom-fileSelect"
                    >
                        Select Banner Image *
                    </label>
                    <input
                        type="file"
                        name="Gallery Images"
                        id="scrollBanner-upload"
                        accept=".jpg, .jpeg, .png, .mp4"
                        onChange={handleFileUpload}
                        hidden
                    />
                </Form.Group>
            </Form>

            {productData.imageUrl && (
                <>
                    <Form.Label className="w-100">Preview</Form.Label>
                    {isImage ? (
                        <img src={productData.imageUrl} width="400" alt="" />
                    ) : (
                        <video controls autoPlay loop muted>
                            <source src={productData.imageUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                </>
            )}
            <div className="my-5">
                <button
                    disabled={submitBtnLoading}
                    type="submit"
                    className="submit-button"
                    onClick={handleSubmit}
                >
                    <p>
                        {location.state.updateProduct === false
                            ? "Submit"
                            : "Update"}
                    </p>
                </button>
            </div>
        </>
    );
};

export default ScrollBanner;
