import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome,
    faTimes,
    faTags,
    faTruck,
    faGift
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import logo_desk from "../../assets/png/navbar_logo_desk.png";

const RetailerSideBar = ({ isOpen, toggle }) => {
    return (
        <div className={`sidebar ${isOpen && "is-open"}`}>
            <div className="sidebar-header">
                <Button
                    variant="link"
                    onClick={toggle}
                    style={{ color: "#fff" }}
                    className="mt-4"
                >
                    <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
                </Button>
                <h3>
                    <Link to={"/"} className="navLogo">
                        <img src={logo_desk} alt="" className="logo_deskk" />
                    </Link>
                </h3>
            </div>

            <Nav className="flex-column pt-2">
                <Nav.Item
                    className={(isActive) =>
                        "nav-link" + (!isActive ? " active" : "active")
                    }
                >
                    <Link className="dash_sidebar_a nav-link" to="/retailer">
                        <FontAwesomeIcon icon={faHome} className="mr-2" />
                        Home
                    </Link>
                </Nav.Item>

                <Nav.Item>
                    <Link className="dash_sidebar_a nav-link" to="consignments">
                        <FontAwesomeIcon icon={faTags} className="mr-2" />
                        Consignments
                    </Link>
                </Nav.Item>

                <Nav.Item>
                    <Link className="dash_sidebar_a nav-link" to="delivered-consignments">
                        <FontAwesomeIcon icon={faTruck} className="mr-2" />
                        Delivered Consignments
                    </Link>
                </Nav.Item>

                <Nav.Item>
                    <Link className="dash_sidebar_a nav-link" to="ifd">
                        <FontAwesomeIcon icon={faGift} className="mr-2" />
                         IFD 2023
                    </Link>
                </Nav.Item>
            </Nav>
        </div>
    );
};

export default RetailerSideBar;
