import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { writeToUS } from "../../api/CustomerSupport";
import { getQueryCategories } from "../../api/AdminApis/AdminQuiry";
import { toast } from "react-toastify";

//CSS
import "./CustomerSupport.css";

//Components

//Images
import phoneIconBlue from "../../assets/vector/phone_outline_blue.svg";
import useUserCtx from "../../hooks/useUserCtx";
import { useTranslation } from "react-i18next";

// toast.configure();

function updateTextAreaSize(textArea) {
    if(textArea == null) return
    textArea.style.height = "0";
    textArea.style.height = `${textArea.scrollHeight + 10}px`;
}

// Component definition
const WriteToUS = () => {
    const { t } = useTranslation();
    const { userContext } = useUserCtx();

    // state definitions
    const [queryData, setQueryData] = useState({ fullName: "", email: "", query: "", phone: "", category: "general" });
    const [queryCategories, setQueryCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const textAreaRef = useRef(null);

    useLayoutEffect(() => {
        updateTextAreaSize(textAreaRef.current);
    }, [queryData.query])

    useEffect(() => {
        fetchQueryCategories();
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (userContext && userContext?.JWT !== "")
            setQueryData((prev) => ({ ...prev, fullName: userContext.fullName, email: userContext.email, phone: userContext.mobileNumber }))
    }, [userContext?.JWT])


    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.values(queryData).some(e => e.toString().trim().length === 0)) return toast.error(t('writeToUs.all-fields'))
        const phonePattern = /^[0-9]{10}$/g;
        const namePattern = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g;
        if (!phonePattern.test(queryData.phone)) return toast.error(t('writeToUs.invalid-phone'))
        if (!namePattern.test(queryData.fullName)) return toast.error(t('writeToUs.invalid-name'))

        setLoading(true)
        writeToUS(queryData)
            .then((res) => {
                if (res) {
                    setQueryData({
                        fullName: userContext.fullName,
                        email: userContext.email,
                        query: "",
                        phone: userContext.mobileNumber,
                        category: 'general'
                    })
                    toast.success(t('writeToUs.query-received'))
                } else {
                    toast.error(t('writeToUs.error'))
                }
            })
            .finally(() => setLoading(false));
    };

    const handleInput = (prop, e) => {
        e.target
            ? setQueryData({ ...queryData, [prop]: e.target.value })
            : setQueryData({ ...queryData, [prop]: e.label });
    };

    const fetchQueryCategories = async () => {
        setLoading(true);
        try {
            const response = await getQueryCategories();
            if (response.status === "success") {
                setQueryCategories(response.data);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className=" support_Page_Wrapper">
            <div className="customer_Support_Container">
                <div className="contact_Phone_Container ">
                    <div className="contact_Phone_Details">
                        <p>{t('writeToUs.call')}</p>
                        <p>6390063900</p>
                    </div>
                    <a href="tel:+91-63900-63900" className="contact_Phone_Icon">
                        <img src={phoneIconBlue} alt="tel-icon" />
                    </a>
                </div>
                <form className="customer_Support_Form" onSubmit={handleSubmit}>
                    <p className="support_Form_Heading">
            
                        {t('writeToUs.heading')}
                    </p>

                    <input
                        type="text"
                        name="Name"
                        id="Name"
                        placeholder={t('writeToUs.name')}
                        className="support_Form_Input"
                        value={queryData.fullName}
                        onChange={(value) => handleInput("fullName", value)}
                    />
                    <input
                        type="text"
                        name="Email"
                        id="Email"
                        placeholder={t('writeToUs.email')}
                        className="support_Form_Input"
                        value={queryData.email}
                        onChange={(value) => handleInput("email", value)}
                    />
                    <input
                        type="text"
                        name="Phone"
                        id="Phone"
                        placeholder={t('writeToUs.phone')}
                        className="support_Form_Input"
                        value={queryData.phone}
                        onChange={(value) => handleInput("phone", value)}
                    />
                    
                    <select
                        name="category"
                        className="form-select mb-3 text-capitalize"
                        aria-label="Query Category"
                        title="Query Category"
                        onChange={(event) => handleInput("category", event)}
                    >
                        {queryCategories.map((el) => {
                            return (
                                <option
                                    key={el._id}
                                    value={el.category}
                                >
                                    {el.category}
                                </option>
                            )
                        })}
                    </select>
                    
                    <textarea
                        ref={textAreaRef}
                        type="text"
                        name="Query"
                        id="Query"
                        placeholder={t('writeToUs.query')}
                        className="support_Form_Input support_Form_Query"
                        value={queryData.query}
                       
                        onChange={(value) => handleInput("query", value)}
                    />

                    <div className="support_Form_Submit_Container">
                        <button
                            type="submit"
                            className="submit-button support_Form_Submit"
                            disabled={loading}
                        >
                            <p>
                                {!loading ? t('submit') : 'Please wait...'}
                            </p>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default WriteToUS;
