import React from 'react';
import CustomerSupportImage from "../../assets/aboutUs/CustomerSupportImage.svg"
import ChatBotDummyImage from "../../assets/aboutUs/ChatBotDummyImage.svg"
import "./CustomerSupport.css"
import WriteToUS from './WriteToUs';
import { useMediaQuery } from "@mui/material";


function NewCustomerSupport() {
  const isMobile = useMediaQuery("(max-width: 800px)");
  return (
    <div className="parentClassCustomerSupport" style={{padding: "19px 50px"}}>
        <div className="CustomerSupportHeader">
            <span className="CustomerSupportHeadingText" style={{margin: isMobile  ? "auto" : "initial"}}>Customer Support</span>
        </div>
        <div className="CustomerSupportMainSection" style={{flexDirection : isMobile ? "column" : "row"}}>
            <div style={{display: isMobile ? "flex" : "block"}}>
                <img className="CustSuppoLeftimg" style={{width: !isMobile ? "80%" : "48%"}} src={CustomerSupportImage} alt="Customer support" />
            </div>
      
                <WriteToUS/>
         
        </div>
    </div>
  )
}

export default NewCustomerSupport