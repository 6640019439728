import React from "react";
import { useTranslation } from "react-i18next";
import findStoreBurgerIcon from "../../assets/vector/findStoreBurgerIcon.svg";
import FindStoreContactPerson from "./findStoreContactPerson";

function FindStoreContact({ stores, totalStores, storeSearchKey, lastStoreRef, showStore }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="findStoreContactHeader">
        {
        totalStores ? <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
          <img
            alt="burgerIcon"
            src={findStoreBurgerIcon}
            className="findStoreContactBurgerIcon"
          />
          <h1 className="findStoreContactHeaderTitle">{`${t('store.found')} ${totalStores} ${t('store.stores-near')}`}</h1>
        </div> : null
        }
        {stores.map((store, index) => (
          <FindStoreContactPerson reference={(index + 1 === stores.length) ? lastStoreRef : null} key={`store_list_${index}`} store={store} /> 
        ))}
      </div>
    </div>
  );
}

export default React.memo(FindStoreContact)