import React from "react";
import { Container } from "react-bootstrap";
import NavBar from "./Navbar";
import { Outlet } from "react-router-dom";

class Content extends React.Component {
    render() {
        return (
            <Container fluid className={`content ${this.props.isOpen && "is-open"}`}>
                <NavBar toggle={this.props.toggle} userType={this.props.userType} heading={this.props.heading}/>
                <Outlet context={{ isOpen: this.props.isOpen }} />
            </Container>
        );
    }
}

export default Content;
