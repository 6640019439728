import axios from "./axiosInstance";

// fetches accepted consignments of a store
export const getAcceptedCnmts = async (storeId) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/consignments/accepted?storeId=${storeId}`, { headers })
    return response.data;

}

// fetches missed consignments of a store
export const getMissedCnmts = async (storeId) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };

    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/consignments/missed?storeId=${storeId}`, { headers })
    return response.data;

}

// fetches canceled consignments of a store
export const getCanceledCnmts = async (storeId) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/consignments/canceled?storeId=${storeId}`, { headers })
    return response.data;

}

// fetches delivered consignments of a store
export const getDeliveredCnmts = async (storeId) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/consignments/delivered?storeId=${storeId}`, { headers })
    return response.data;

}

// validate EAN for a consignment
export const validateEan = async(payload) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };

    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/retailer/validate-ean`, payload, { headers })
    return response.data;
}

// initiate a refund for consignment
export const initCnmtRefund = async (consignmentId) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };

    const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/payment/consignment/refund?consignmentId=${consignmentId}`,
        {},
        { headers }
    );
    return response;
}

// fetches refunds of a retailer
export const getRefunds = async (storeId) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };

    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/retailer/refunds?storeId=${storeId}`,
        headers
    );

    return response;
}

// initiates shiprocket shipment for an order
export const createShipment = async (consignmentId) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };

    const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/retailer/ship/consignment`,
        { consignmentId },
        { headers }
    );

    return response;
}

//Fetch A Consignment
export const fetchAConsignment = async(id) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };
    let response;

   await axios.get(`${process.env.REACT_APP_BASE_URL}/retailer/consignment/${id}`, { headers })
   .then(res => response = res)
   .catch(err => response = err.response)

    return response
}

//Sends OTP to User's MobileNumber For Manual Order Delivery
export const sendOTPForManualOrder = async(consignmentId) => {
    // let consignmentId  = "646c618d2dc48cdfeaf08700";
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };

    let response;

    await axios.post(`${process.env.REACT_APP_BASE_URL}/retailer/manual-order/send-otp`, { consignmentId },  { headers })
    .then(res => response = res)
    .catch(err => response = err.response)
 
     return response
}


//Sends OTP to User's MobileNumber For Manual Order Delivery
export const verifyOTPForManualOrder = async(otp, consignmentId) => {
    // let consignmentId = "646c618d2dc48cdfeaf08700";
    const retailerToken =  localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
        Authorization: `Bearer ${retailerToken}`,
    };

    let response;

    const body = { consignmentId, otp  };

    await axios.post(`${process.env.REACT_APP_BASE_URL}/retailer/manual-order/verify-otp` , body,  { headers })
    .then(res => response = res)
    .catch(err => response = err.response)
 
     return response
}

// invokes API to generate shipping label for a consignment/order from shiprocket
export const genShippingLbl = async (consignmentId) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY);

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };

    const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/retailer/generate-label/consignment`,
        { consignmentId },
        { headers }
    );

    return response;
}

//Stocks
export const getProductStocksForStore = async (query) => {
    const retailerToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY);

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${retailerToken}`,
    };
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/store/stock-sync/fetch/${query}`, { headers })
    return response.data;
}
