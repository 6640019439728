// axios lib
import axios from "axios";

const instance = axios.create()
// Response interceptor
instance.interceptors.response.use(function(response){
    return response;
}, function(err) {
    const currentPath = window.location.pathname;
    if (err.response.status === 401) {
        if (currentPath.includes('admin')) {
            // handle admin logout
            localStorage.removeItem('admin-token');
            window.location.href = currentPath + "?action=logout";
        }else if(currentPath.includes('retailer')) {
            // handle retailer logout
            localStorage.removeItem('retailer-token');
            localStorage.removeItem('retailer_id');
            window.location.href = currentPath + "?action=logout";
        } else {
            // logout the user on unauthorized status in response
            // do a hard refresh to reset the stale user context
            localStorage.removeItem('cart');
            localStorage.removeItem('user');
            window.location.href = '/?action=logout';
        }
    }
    return Promise.reject(err);
});

export default instance;
