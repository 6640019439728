import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    addNewCardSection,
    getHomePageSections,
    updateCardSection,
} from "../../api/AdminApis/HomePageSections";
import useAdminCtx from "../../hooks/useAdminCtx";

const ScrollCard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [id, setId] = useState(null);
    const [data, setData] = useState({
        sectionHeading: "",
        products: [],
        priority: null,
        targetUrl: ""
    });
    const { allHomePageSections, setAllHomePageSections } = useAdminCtx();

    
    useEffect(() => {
        getHomePageSections((err, res) => {
            if (err) toast.error("Unable to Fetch the Sections properly");
            setAllHomePageSections(res.sections);
        });
    }, []);

    useEffect(() => {
        setData((prev) => ({
            ...prev,
            sectionHeading: location.state?.data?.sectionHeading ?? "",
            products: location.state?.data?.products ?? [],
            priority: location.state?.data?.priority ?? null,
            targetUrl: location.state?.data?.targetUrl ?? ""
        }));
        setId(location.state?.data?._id ?? null);
    }, [location.state?.data]);

    const handleHeadingChnage = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };
    const handleTargetUrlChange = (e) => {
        setData((prev) => {
            return { ...prev, targetUrl: e.target.value };
        });
    };
    const handleProductChange = (e) => {
        const value = e.target.name !== "priority" ? e.target.value.replace(/\s/g, "").split(",") : e.target.value;
        setData((prev) => {
            return { ...prev, [e.target.name]: value };
        });
    };

    const handleSubmit = (e) => {
        const newData = {
            sectionHeading: data.sectionHeading,
            products: data.products.filter((ean) => ean !== ""),
            sectionType: "scroll_card",
            moreInfo: {},
            targetUrl: data.targetUrl
        };
        if (id) {
            if(location.state.data.priority === data.priority) {
                newData.priority = location.state.data.priority;
            } else {
                newData.priority = data.priority;
            }
            updateCardSection(newData, id).then((res) => {
                if (res) {
                    navigate("../admin-home-page");
                    toast.success("ScrollCard Updated!!");
                } else toast.error("Something Went wrong");
            });
        } else {
            newData.priority =
                 !data.priority ? (allHomePageSections.length === 0 
                    ? 1
                    : allHomePageSections[allHomePageSections.length - 1]
                          .priority + 1) : data.priority;
            addNewCardSection(newData).then((res) => {
                if (res) {
                    navigate("../admin-home-page");
                    toast.success("ScrollCard added!!");
                } else toast.error("Oops! Something Went wrong");
            });
        }
    };

    return (
        <div>
            <h2>Add Scroll Card Section</h2>
            <Form className="mt-4" encType="multipart/form-data">
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Section Heading *</Form.Label>
                    <Form.Control
                        type="text"
                        name="sectionHeading"
                        value={data.sectionHeading}
                        onChange={handleHeadingChnage}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                >
                    <Form.Label>
                        Products EAN numbers (comma separated) *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="products"
                        value={data?.products?.join(",")}
                        onChange={handleProductChange}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                >
                    <Form.Label>Target URL</Form.Label>
                    <Form.Control
                        type="text"
                        name="targetUrl"
                        value={data?.targetUrl}
                        onChange={handleTargetUrlChange}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                >
                    <Form.Label>Order (Optional)</Form.Label>
                    <Form.Control
                        type="number"
                        name="priority"
                        value={data.priority}
                        onChange={handleProductChange}
                    />
                </Form.Group>
            </Form>
            <div className="my-5">
                <button
                    type="submit"
                    className="submit-button"
                    onClick={handleSubmit}
                >
                    <p>{"Submit"}</p>
                </button>
            </div>
        </div>
    );
};

export default ScrollCard;
