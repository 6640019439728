import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
// import "react-modern-calendar-datepicker/lib/DatePicker.css"; //In use
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { AdminState } from "./Contexts/AdminContext";
import { OfferState } from "./Contexts/OffersContext";
import SiteContextProvider from "./Contexts/SiteContext";
import UserContextProvider from "./Contexts/UserContext";
import ProductContextProvider from "./Contexts/ProductContext";

import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { RetailerContextProvider } from "./Contexts/RetailerContext";
import { SmartBandhanState } from "./Contexts/SmartBandhanContext";

ReactDOM.render(
    <React.StrictMode>
        <AdminState>
            <RetailerContextProvider>
                <OfferState>
                    <SmartBandhanState>
                        <SiteContextProvider>
                            <UserContextProvider>
                                <ProductContextProvider>
                                    <BrowserRouter>
                                        <App />
                                    </BrowserRouter>
                                </ProductContextProvider>
                            </UserContextProvider>
                        </SiteContextProvider>
                    </SmartBandhanState>
                </OfferState>
            </RetailerContextProvider>
        </AdminState>
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
