import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import { userSignUp } from "../api/Auth";
import { toast } from "react-toastify";
import CircleLoading from "../components/CircleLoading";
import useUserCtx from "../hooks/useUserCtx";
import useWindowDimensions from "../hooks/useWindowDimensions";
import useSiteCtx from "../hooks/useSiteCtx";
import { useTranslation } from "react-i18next";
import CreateAccountBG from "../assets/productIcons/Create-account-BG.png"

// toast.configure();

// Component Definition
const Signup = () => {
    const { setLoginRedirect } = useSiteCtx();
    const { t } = useTranslation()
    const { width } = useWindowDimensions();
    const matches = width >= 768;
    const nav = useNavigate();
    const { setUserContext } = useUserCtx();
    const loc = useLocation();

    // state definitions
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [, setValidLength] = useState(false);
    const [btnDisable, setBtnDisable] = useState(true);
    const [loading, setLoading] = useState(false);

    const handleLength = (length) => {
        if (name !== "" && length === 10) {
            setValidLength(true);
            return setBtnDisable(false);
        }
        setValidLength(false);
        setBtnDisable(true);
    };

    const validateNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            setPhone(e.target.value);
        }
    };

    const formSubmit = (e) => {
        e.preventDefault();
        userSignUp(phone, name).then((res) => {
            setLoading(true);
            setBtnDisable(true);
            if(res?.statusCode) {
                setLoginRedirect(false);
                nav("/otp", { state: { ...loc.state, otpSentSuccess: true, mobileNumber: phone } });
                setUserContext((prev) => ({
                    ...prev,
                    id: res.userId,
                    fullName: name,
                    mobileNumber: phone,
                }))
            } else if(res.status === 422) {
               toast.error(`${res.data.error.map(obj => Object.values(obj).join())}`)
            } else if(!res?.data?.success) {
                toast.error(res.data.message)
            } else toast.error("Something went wrong..")
        }).finally(() => { 
            setLoading(false);
            setBtnDisable(false);
        })
    };

    return (
        <>
            <HeaderBar alternateWay={t('header.login')} alternateLink={"/login"} />
            <div className="signUpParentClass" style={{
                background: `url(${CreateAccountBG}) no-repeat`,
                backgroundSize:"cover",
            }}>
                <div className="signup-wrapper" style={{
                    backdropFilter:"blur(0)",
                    background:"none",
                    padding:"25px",
                    paddingBottom:"115px"
                }}>
                    <div className="signup-header">
                        <h1 className="page-heading">{t("createAccount.create-account")}</h1>
                    </div>
                    <form action="" className={"signup-form"} onSubmit={formSubmit} style={{gap:"20px"}}>
                            <div className="inputfield-Container" style={{
                                display:"flex",
                                flexDirection:"column",
                                gap:"6px"
                            }}>
                                 <div className="SignUpInputLable">{t('createAccount.first-lable')}</div>
                                <input
                                    type="text"
                                    name="Name"
                                    id="name"
                                    className="input-field"
                                    placeholder={t('login.name')}
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    required
                                />                                
                            </div>
                            <div className="inputfield-Container" style={{
                                display:"flex",
                                flexDirection:"column",
                                gap:"6px"
                            }}>
                                <div className="SignUpInputLable">{t('createAccount.second-lable')}</div>
                               <div>
                                    {matches ? (
                                        <input
                                            type="tel"
                                            onkeypress="return isNumberKey(event)"
                                            name="Phone"
                                            id="phone"
                                            className="input-field"
                                            value={phone}
                                            placeholder={t('login.phone')}
                                            maxLength={10}
                                            onChange={(e) => {
                                                validateNumber(e);
                                                handleLength(e.target.value.length);
                                            }}
                                            required
                                        />
                                    ) : (
                                        <input
                                            type="number"
                                            name="Phone"
                                            id="phone"
                                            className="input-field"
                                            value={phone}
                                            placeholder={t('login.phone')}
                                            maxLength={10}
                                            onChange={(e) => {
                                                setPhone(e.target.value);
                                                handleLength(e.target.value.length);
                                            }}
                                            required
                                        />
                                    )}
                                </div>                     
                            </div>
                            <div style={{  lineHeight:"12px"}}>
                                <span style={{
                                    fontSize:"11px",
                                    fontWeight:"700",
                                    color:"#707070",
                                    lineHeight:"12px"
                                }}>{t("createAccount.info")}</span>
                            </div>
                            <div className={"button-Container"}>
                                {loading ? (
                                    <div style={{ height: "15px", textAlign: "center", position: "absolute", top: "-25px" }} className="loading_wrapper">
                                        <CircleLoading />
                                    </div>
                                ) : (
                                    ""
                                )}
                                <button type="submit" className="submit-button" disabled={btnDisable}>
                                    <p>{t("continue")}</p>
                                </button>
                            </div>
                            <div className="TCStyle">
                                <span>{t("login.termsCondition")}</span>
                                <Link className="normal-link" to="/terms-condition"><span style={{ color:"#0066C0" }}>{t("login.extraTC")}</span></Link>
                            </div>
                    </form>
                </div>
            </div>
             
        </>
    );
};

export default Signup;
