import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-date-picker";
import deleteIcon from "../../assets/vector/delete_outline_blue.svg";
import { checkComboProductStockStatusAPICaller, updateProductOffers } from "../../api/CatalogueApi";
import { toast } from "react-toastify";
import { addCoupon } from "../../api/couponApi";
import { getSearchedProduct } from "../../api/Product";
import useSiteCtx from "../../hooks/useSiteCtx";
import TextField from '@mui/material/TextField';

// Utils
import { OFFER_TYPES } from "../../utils/Constants";

// toast.configure();

// Component definition
const AddOffers = () => {
    const { setHeaderData } = useSiteCtx();
    const nav = useNavigate();

    // state definitions
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [eanEntered, setEanEntered] = useState("");
    const [offerTypeSelected, setOfferTypeSelected] = useState("");
    const [offers, setoffers] = useState({
        flatDiscount: {
            value: "",
            from: null,
            to: null,
        },
        combo: {
            value: "",
            from: null,
            to: null,
        },
        container: [],
    });
    const [bankOffers, setBankOffers] = useState([]);
    const [holdContainerValue] = useState("");
    const [productExistingOffers, setProductExistingOffers] = useState([]);
    const [flatDisOffers, setFlatDisOffers] = useState({
        value: "",
        from: new Date(),
        to: new Date(),
        discountType: 'flat',
        remaining: '',
        totalOfferLimit: 0
    });

    const [comboOffersHold, setComboOffersHold] = useState({
        value: "",
        from: new Date(),
        to: new Date(),
        remaining: "",
        totalOfferLimit: 0,
        discountType: "flat",
        childEan: ''
    });
    const [childProd, setChildProd] = useState(null);

    const [contOffersHold, setContOffersHold] = useState({
        value: "",
        from: new Date(),
        to: new Date(),
        remaining: '',
        totalOfferLimit: 0
    });

    const [couponOffersHold, setCouponOffersHold] = useState({
        couponName: "",
        value: "",
        upTo: "",
        to: new Date(),
        remaining: '',
        totalOfferLimit: 0
    });

    const { DISCOUNT, COMBO, CONTAINER, COUPON, STORE } = OFFER_TYPES;
    const availOffers = [DISCOUNT, COMBO, CONTAINER, COUPON, STORE];
    const discountTypes = ["flat", "percentage"]

    useEffect(() => {
        if (holdContainerValue) {
            let containerArray = holdContainerValue.split(",");
            setContOffersHold((prev) => ({ ...prev, value: containerArray }));
        }
    }, [holdContainerValue]);

    const handleInput = (prop, prop2, prop3, e) => {
        e.target ? prop({ ...prop2, [prop3]: e.target.value }) : prop({ ...prop2, [prop3]: e.label });
    };

    useEffect(() => {
        if (flatDisOffers.value) {
            setoffers((prev) => ({ ...prev, flatDiscount: flatDisOffers }));
        }
    }, [flatDisOffers.value, flatDisOffers]);
    useEffect(() => {
        if (comboOffersHold.value) {
            setoffers((prev) => ({ ...prev, combo: comboOffersHold }));
        }
    }, [comboOffersHold.value, comboOffersHold]);
    useEffect(() => {
        if (contOffersHold.value) {
            setoffers((prev) => ({ ...prev, container: contOffersHold }));
        }
    }, [contOffersHold.value, contOffersHold]);

    const handleRemoveBankOffer = (index) => {
        let list = [...bankOffers];
        list.splice(index, 1);
        setBankOffers(list);
    };

    const handleAddBankOffer = (e) => {
        e.preventDefault();
        setBankOffers([...bankOffers, { offerHeading: "", storeId: "", offerName: "", offerAvail: "", from: null, to: null }]);
    };

    const handleBankOffer = (e, index) => {
        const { name, value } = e.target;
        let list = [...bankOffers];
        list[index][name] = value;
        setBankOffers(list);
    };

    const handleBankOfferDate = (e, prop, index) => {
        const value = e;
        let list = [...bankOffers];
        list[index][prop] = value;
        setBankOffers(list);
    };

    useEffect(() => {
        setHeaderData({
            header3Cond: true,
            headerText: "Catelogue",
            categoriesCond: false,
            header3Store: false,
            header3Cart: false,
            header3Profile: false,
        });
    }, []);

    const searchEAN = (e) => {
        e.preventDefault();
        let productSearched = [];
        let prodDiscountObject = [];
        if (eanEntered !== "" && eanEntered) {
            let ean = eanEntered.split(",");
            ean.forEach((item) => {
                let searchTerm = "ean=" + item;
                getSearchedProduct(searchTerm).then((res) => {
                    if (res.no_of_products > 0) {
                        let prod = res.products[0];
                        let ind = selectedProduct.findIndex((obj) => obj._id === prod._id);
                        if (ind === -1) {
                            productSearched.push(prod);
                            prodDiscountObject.push(prod.discount);
                            setSelectedProduct(productSearched);
                            setProductExistingOffers(prodDiscountObject);
                            // discounts applied on the fetched product
                            setFetchedProdDiscount(productSearched)
                        }
                    } else {
                        toast.error("No Such Product Exists");
                    }
                });
            });
        } else {
            toast.error("Enter Product EAN Number");
        }
    };

    useEffect(() => {
        if (selectedProduct.length > 0 && offerTypeSelected) {
            let prodOffers = [...productExistingOffers];
            if (offerTypeSelected === DISCOUNT.key && offers.hasOwnProperty("flatDiscount")) {
                prodOffers.forEach((offer) => {
                    offer.flatDiscount = offers.flatDiscount;
                });
            } else if (offerTypeSelected === COMBO.key && offers.hasOwnProperty("combo")) {
                prodOffers.forEach((offer) => {
                    offer.combo = offers.combo;
                });
            } else if (offerTypeSelected === CONTAINER.key && offers.hasOwnProperty("container")) {
                prodOffers.forEach((offer) => {
                    offer.container = offers.container;
                });
            } else {
                prodOffers.forEach((offer) => {
                    offer = offer;
                });
            }
        }
    }, [selectedProduct, offerTypeSelected, offers]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // performs offer validations and throws error
        if (!validateOffers()) return;

        if (selectedProduct.length > 0) {
            if (couponOffersHold.value) {
                let products = eanEntered.split(",");
                addCoupon(couponOffersHold, products).then((res) => {
                    nav("/admin/catelogue-page");
                });
            } else {
                let newProductArray = selectedProduct.map(function (elem, index) {
                    let prices = elem.price;
                    let existingBankOffers = [...elem.offers];
                    let newOffersArray;
                    if (offers && offers.flatDiscount && offers.flatDiscount.value) {
                        const mop = parseInt(elem.price.mop);
                        const mrp = parseInt(elem.price.mrp);
                        prices.discountPrice = mrp - (mrp - mop);
                    }
                    if (bankOffers.length > 0) {
                        newOffersArray = existingBankOffers.concat(bankOffers);
                    }
                    const currentDiscount = getCurrentDiscount();

                    let item = { ...elem, discount: currentDiscount, price: prices, offers: newOffersArray };
                    item.activeDiscountType = offerTypeSelected;
                    return item;
                });

                // Calls API to update/create offers on a product
                newProductArray.forEach((product) => updateProductOffers(product).then((res) => {
                    if (res) {
                        nav("/admin/catelogue-page")
                    }
                }));
            }
        } else {
            toast.error("Add/Search Product(s) First");
        }
    };

    // performs validations on offers and returns the status of validation
    const validateOffers = () => {
        let isOK = true;

        if ((flatDisOffers.discountType === 'flat' && flatDisOffers.value >= selectedProduct[0]?.price?.mop) ||
            (flatDisOffers.discountType === 'percentage' && flatDisOffers.value >= 100) ||
            (comboOffersHold.discountType === 'flat' && +comboOffersHold.value >= childProd?.price?.mop) ||
            (comboOffersHold.discountType === 'percentage' && comboOffersHold.value >= 100)
        ) {
            toast.error('Discount cannot be greater than product value');
            isOK = false;
        }

        if (offerTypeSelected === DISCOUNT.key && flatDisOffers.value <= 0) {
            toast.error('Discount cannot be less than or equal to 0');
            isOK = false;
        }

        if (offerTypeSelected === COMBO.key && !childProd) {
            toast.error('No child product details found. Please verify the child product')
            isOK = false;
        }

        return isOK;
    }

    // returns currently selected discount/offer object 
    const getCurrentDiscount = () => {
        let result = {}
        if (offerTypeSelected === DISCOUNT.key) {
            result = { [DISCOUNT.key]: flatDisOffers };
        }

        if (offerTypeSelected === COMBO.key) {
            result = { [COMBO.key]: comboOffersHold };
        }

        if (offerTypeSelected === CONTAINER.key) {
            result = { [CONTAINER.key]: contOffersHold };
        }

        if (offerTypeSelected === COUPON.key) {
            result = { [COUPON.key]: couponOffersHold }
        }

        return result;
    }

    const handleDate = (e, type, key) => {
        type((prev) => ({ ...prev, [key]: e }));
    };
    const onlyNumberRegex = (e, stateSetter) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            let value = e.target.value;
            const name = e.target.name
            stateSetter((prev) => ({ ...prev, [name]: value }));
        }
    };

    const removeWhiteSpace = (e) => {
        if (e.target.value !== "") {
            let value = e.target.value.replace(/\s/g, "");
            // setHoldContainerValue(value);
            setContOffersHold(prev => ({ ...prev, value }))
        }
    };

    /* useEffect(() => {
        if (selectedProduct.length > 0) {
            if (offerTypeSelected) {
                handleDataToBeAdded(offerTypeSelected);
            }
        }
    }, [selectedProduct, offerTypeSelected]); */

    const handleDataToBeAdded = (offerTypeSelected) => {
        switch (offerTypeSelected) {
            case DISCOUNT.key:
                setoffers({
                    flatDiscount: {
                        value: "",
                        from: null,
                        to: null,
                        remaining: ''
                    },
                });
                break;
            case COMBO.key:
                setoffers({
                    combo: {
                        value: "",
                        from: null,
                        to: null,
                        remaining: ''
                    },
                });
                break;
            case CONTAINER.key:
                setoffers({
                    container: [],
                });
                break;
            case COUPON.key:
                setoffers({
                    coupon: {
                        couponName: "",
                        value: "",
                        upTo: "",
                        to: null,
                        remaining: ''
                    },
                });
                break;
            case STORE.key:
                setoffers({});
                break;

            default:
                break;
        }
    };

    const handleOfferType = (offerTypeSelected) => {
        switch (offerTypeSelected) {
            case DISCOUNT.key:
                return (
                    <>
                        <input
                            type="text"
                            name="value"
                            id="value"
                            value={flatDisOffers.value}
                            className="input-field"
                            placeholder="Enter Product Discount Offer"
                            title="Product Discounter offer value"
                            onChange={(e) => onlyNumberRegex(e, setFlatDisOffers)}
                        />
                        <input
                            type="text"
                            name="remaining"
                            id="remaining"
                            value={flatDisOffers.remaining}
                            title="Product Discount offer remaining"
                            className="input-field"
                            placeholder="Enter Discount Offer Limit"
                            onChange={(e) => onlyNumberRegex(e, setFlatDisOffers)}
                        />
                        <DatePicker onChange={(e) => handleDate(e, setFlatDisOffers, "from")} value={new Date(flatDisOffers.from)} format="dd/MM/y" className={"input-field custom-date-picker"} />
                        <p className="catalogue_Hint offerPage_Hint">Enter Discount Offer Start Date</p>
                        <DatePicker onChange={(e) => handleDate(e, setFlatDisOffers, "to")} value={new Date(flatDisOffers.to)} format="dd/MM/y" className={"input-field custom-date-picker"} />
                        <p className="catalogue_Hint offerPage_Hint">Enter Discount Offer End Date</p>
                    </>
                );

            case COMBO.key:
                return (
                    <>
                        <div className="row w-100 g-0">
                            <div className="col-md-10">
                                <TextField
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    label="Child Product EAN"
                                    inputMode="text"
                                    value={comboOffersHold.childEan}
                                    title="Child Product EAN"
                                    className="input-field my-2"
                                    name="Product Offer"
                                    id="Product Offer"
                                    placeholder="Enter Child Product EAN for Combo offer"
                                    style={{
                                        height: "auto"
                                    }}
                                    onChange={(value) =>
                                        handleInput(
                                            setComboOffersHold,
                                            comboOffersHold,
                                            "childEan",
                                            value
                                        )
                                    }
                                />
                                <p className="catalogue_Hint offerPage_Hint">
                                    Verify Child product before creating the combo offer
                                </p>
                            </div>

                            <div className="col-md-2">
                                <button className="btn btn-sm btn-success" onClick={checkComboChildProduct}>Verify Product</button>
                            </div>
                        </div>
                        <TextField
                            type="tel"
                            size="small"
                            variant="outlined"
                            label="Product Discount Offer"
                            inputMode="numeric"
                            value={comboOffersHold.value}
                            title="Product Discount Offer"
                            className="input-field my-2"
                            name="value"
                            id="value"
                            placeholder="Enter Product Discount Offer"
                            style={{
                                height: "auto"
                            }}
                            onChange={(value) =>
                                handleInput(
                                    setComboOffersHold,
                                    comboOffersHold,
                                    "value",
                                    value
                                )
                            }
                        />
                        <TextField
                            type="number"
                            size="small"
                            variant="outlined"
                            label="Total Offer Limit"
                            inputMode="numeric"
                            name="totalOfferLimit"
                            id="totalOfferLimit"
                            value={comboOffersHold.totalOfferLimit}
                            title="Product Combo offer Total Limit"
                            className="input-field my-2"
                            placeholder="Enter Combo Total Offer Limit"
                            style={{
                                height: "auto"
                            }}
                            onChange={(e) =>
                                setComboOffersHold(prev => ({...prev, totalOfferLimit: parseInt(e.target.value) !== NaN ? parseInt(e.target.value) : 0}))
                            }
                        />
                        <TextField
                            type="tel"
                            size="small"
                            variant="outlined"
                            label="Total Offer Remaining"
                            inputMode="numeric"
                            title="Product Combo offer Remaining"
                            className="input-field my-2"
                            placeholder="Enter Combo Offer Remaining"
                            style={{
                                height: "auto"
                            }}
                            name="remaining"
                            id="remaining"
                            value={comboOffersHold.remaining}
                            onChange={(e) =>
                                onlyNumberRegex(e, setComboOffersHold)
                            }
                        />
                        <DatePicker
                            onChange={(e) =>
                                handleDate(e, setComboOffersHold, "from")
                            }
                            value={new Date(comboOffersHold.from)}
                            format="dd/MM/y"
                            className={"input-field custom-date-picker"}
                        />
                        <p className="catalogue_Hint offerPage_Hint">
                            Enter Combo Offer Start Date
                        </p>
                        <DatePicker
                            onChange={(e) =>
                                handleDate(e, setComboOffersHold, "to")
                            }
                            value={new Date(comboOffersHold.to)}
                            format="dd/MM/y"
                            className={"input-field custom-date-picker"}
                        />
                        <p className="catalogue_Hint offerPage_Hint">
                            Enter Combo Offer End Date
                        </p>
                    </>
                );

            case CONTAINER.key:
                return (
                    <>
                        <input
                            type="text"
                            name="Product Offer"
                            id="Product Offer"
                            className="input-field"
                            placeholder="Enter Product Product EAN for Container offer"
                            value={contOffersHold.value}
                            onChange={(e) => removeWhiteSpace(e)}
                        />
                        <p className="catalogue_Hint">Enter comma separated EAN numbers</p>


                        <input
                            type="text"
                            name="remaining"
                            id="remaining"
                            value={contOffersHold.remaining}
                            title="Product Container offer remaining"
                            className="input-field"
                            placeholder="Enter Container Offer Limit"
                            onChange={(e) => onlyNumberRegex(e, setContOffersHold)}
                        />

                        <DatePicker onChange={(e) => handleDate(e, setContOffersHold, "from")} value={contOffersHold.from} format="dd/MM/y" className={"input-field custom-date-picker"} />
                        <p className="catalogue_Hint offerPage_Hint">Enter Container Offer Start Date</p>

                        <DatePicker onChange={(e) => handleDate(e, setContOffersHold, "to")} value={contOffersHold.to} format="dd/MM/y" className={"input-field custom-date-picker"} />
                        <p className="catalogue_Hint offerPage_Hint">Enter Container Offer End Date</p>
                    </>
                );

            case COUPON.key:
                return (
                    <>
                        <input
                            type="text"
                            name="Coupon code"
                            id="Coupon code"
                            className="input-field"
                            placeholder="Enter Coupon Code"
                            value={couponOffersHold.couponName}
                            onChange={(value) => {
                                handleInput(setCouponOffersHold, couponOffersHold, "couponName", value);
                            }}
                        />
                        <input
                            type="text"
                            name="Coupon Discount"
                            id="Coupon Discount"
                            className="input-field"
                            placeholder="Enter Coupon Discount"
                            value={couponOffersHold.value}
                            onChange={(value) => handleInput(setCouponOffersHold, couponOffersHold, "value", value)}
                        />
                        <input
                            type="text"
                            name="Max Value"
                            id="Max Value"
                            className="input-field"
                            placeholder="Enter Coupon Maximum Amount"
                            value={couponOffersHold.upTo}
                            onChange={(value) => handleInput(setCouponOffersHold, couponOffersHold, "upTo", value)}
                        />

                        <input
                            type="text"
                            name="remaining"
                            id="remaining"
                            className="input-field"
                            placeholder="Enter Coupon Limit"
                            value={couponOffersHold.remaining}
                            onChange={(value) => handleInput(setCouponOffersHold, couponOffersHold, "remaining", value)}
                        />

                        <DatePicker onChange={(e) => handleDate(e, setCouponOffersHold, "to")} value={couponOffersHold.to} format="dd/MM/y" className={"input-field custom-date-picker"} />
                        <p className="catalogue_Hint offerPage_Hint">Enter Coupon Offer End Date</p>
                    </>
                );

            case STORE.key:
                return (
                    <>
                        <div className="addoffer_Input">
                            {bankOffers &&
                                bankOffers.length > 0 &&
                                bankOffers.map((offer, index) => (
                                    <>
                                        <div className="addoffer_Input2 bank_offer_heading">
                                            <div className="input_Delete">
                                                <img src={deleteIcon} alt="" onClick={() => handleRemoveBankOffer(index)} />
                                            </div>
                                            <p>Offer {index + 1}</p>
                                        </div>

                                        <input type="text" name="offerName" id="offerName" className="input-field" placeholder="Add Offer Name" value={offer.offerName} onChange={(e) => handleBankOffer(e, index)} />
                                        <input
                                            type="text"
                                            name="offerAvail"
                                            id="offerAvail"
                                            className="input-field"
                                            placeholder="Add Offer Conditions"
                                            value={offer.offerAvail}
                                            onChange={(e) => handleBankOffer(e, index)}
                                        />
                                        <p className="catalogue_Hint">Add "~" seperated Offer Conditions</p>
                                        {bankOffers[index]["offerHeading"] === "Store Offer" ? (
                                            <input type="text" name="storeId" id="storeId" className="input-field" placeholder="Add Store ID" value={offer.storeId} onChange={(e) => handleBankOffer(e, index)} />
                                        ) : (
                                            ""
                                        )}
                                        <DatePicker value={bankOffers[index].from} onChange={(e) => handleBankOfferDate(e, "from", index)} format="dd/MM/y" className={"input-field custom-date-picker"} />
                                        {bankOffers[index].offerName && !bankOffers[index].from && <p className="alert alert-danger">Enter Date!</p>}
                                        <DatePicker value={bankOffers[index].to} onChange={(e) => handleBankOfferDate(e, "to", index)} format="dd/MM/y" className={"input-field custom-date-picker"} />
                                        {bankOffers[index].offerName && !bankOffers[index].to && <p className="alert alert-danger">Enter Date!</p>}
                                        <br />
                                    </>
                                ))}
                            <div className={"button-Container"}>
                                <button type="submit" className="submit-button" onClick={handleAddBankOffer}>
                                    <p>Add Store Offers</p>
                                </button>
                            </div>
                        </div>
                    </>
                );

            default:
                return <></>;
        }
    };

    const setFetchedProdDiscount = (productSearched) => {
        const discount = productSearched[0]?.discount;
        if (discount?.flatDiscount?.value) {
            setFlatDisOffers(discount.flatDiscount);
        }

        if (discount?.combo?.value) {
            setComboOffersHold(discount.combo)
        }

        if (discount?.container.length > 0) {
            setContOffersHold(discount.container)
        }
    }

    // fetches product details of child-ean in combo offer
    const checkComboChildProduct = async () => {
        const ean = comboOffersHold.childEan;
        if (!ean) return;

        try {
            const response = await checkComboProductStockStatusAPICaller(`ean=${ean}`);
            if (response.status === "success") {
                setChildProd(response.data[0]);
                toast.success('Child product verified!')
            } else {
                toast.error('No product found')
            }
        } finally {
            //
        }
    }

    // console.log(comboOffersHold)
    return (
        <div className="page_Wrapper page_Margin_Top_Secondary">
            <div className="catelogue_Page section_Wrapper">
                <div className="catelogue_Page_Header">
                    <h4 className="catelogue_Page_Heading">Add Offer Page</h4>
                </div>
                <form onSubmit={searchEAN} className="catelogue_Product_Search">
                    <div className="addoffer_Input2">
                        <input type="text" id="EAN" name="EAN" className="input-field" placeholder="Enter Product EAN Number" onChange={(e) => setEanEntered(e.target.value)} />
                    </div>
                    <div className={"button-Container"}>
                        <button type="submit" className="submit-button">
                            <p>Search Product</p>
                        </button>
                    </div>
                </form>
                <br />
                <>
                    {selectedProduct.length > 0 && (
                        <fieldset className="catelogue_Fieldset">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <div className="catalogue_Dropdown">
                                        {offerTypeSelected
                                            ? <p>{availOffers.find(el => el.key === offerTypeSelected)?.label}</p>
                                            : <p>Select Offer Type</p>
                                        }
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {availOffers.map((item, index) => (
                                        <Dropdown.Item key={index} value={item} onClick={() => setOfferTypeSelected(item.key)} className="text-capitalize">
                                            {item.label}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>

                            {[DISCOUNT.key, COMBO.key].includes(offerTypeSelected) && (
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        {offerTypeSelected === DISCOUNT.key && (
                                            <div className="catalogue_Dropdown">
                                                {flatDisOffers.discountType ? <p className="text-capitalize">{flatDisOffers.discountType}</p> : <p>Select discount type</p>}
                                            </div>
                                        )}

                                        {offerTypeSelected === COMBO.key && (
                                            <div className="catalogue_Dropdown">
                                                {comboOffersHold.discountType ? <p className="text-capitalize">{comboOffersHold.discountType}</p> : <p>Select discount type</p>}
                                            </div>
                                        )}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {discountTypes.map((item, index) => (
                                            <Dropdown.Item
                                                key={index}
                                                value={item}
                                                onClick={() => {
                                                    if (offerTypeSelected === DISCOUNT.key) {
                                                        setFlatDisOffers(
                                                            (prev) => ({
                                                                ...prev,
                                                                discountType:
                                                                    item,
                                                            })
                                                        );
                                                    } else {
                                                        setComboOffersHold((prev) => ({
                                                            ...prev,
                                                            discountType: item
                                                        }))
                                                    }
                                                }}
                                                className="text-capitalize"
                                            >
                                                {item}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </fieldset>
                    )}
                </>
                <div className="offer_Section">{handleOfferType(offerTypeSelected)}</div>
                {offerTypeSelected && (
                    <div className={"button-Container"}>
                        <button type="submit" className="submit-button" onClick={handleSubmit}>
                            <p>Submit</p>
                        </button>
                    </div>
                )}
                <br />
                <br />
                <div className="offerPage_ProductList">
                    {selectedProduct.length > 0 && (
                        <>
                            <div className="catalogue_List_Item">
                                <div className="catalogue_List_Content">
                                    <p>Product EAN</p>
                                    <p>Product Name</p>
                                    <p>Product MRP</p>
                                    <p>Product MOP</p>
                                </div>
                            </div>
                            {selectedProduct.map((product, index) => (
                                <div className="catalogue_List_Item" key={index}>
                                    <div className="catalogue_List_Content">
                                        {product.ean && <p>{product.ean}</p>}
                                        {product.title && <p>{product.title}</p>}
                                        {product.price.mrp && <p>{product.price.mrp}</p>}
                                        {product.price.mop && <p>{product.price.mop}</p>}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddOffers;
