import React, { useEffect, useState } from 'react';
import { useRetailerContext } from '../../Contexts/RetailerContext';
import Moment from 'react-moment';
import { listFormat } from '../../utils';
import { APP_DATE_TIME_FORMAT } from '../../utils/Constants';
import { getDeliveredCnmts } from '../../api/Retailer';
import { toast } from 'react-toastify';
import { Accordion } from 'react-bootstrap';

const DeliveredConsignments = () => {

    const { store } = useRetailerContext();
    const [deliveredConsignments, setDeliveredConsignments] = useState([]);

    const fetchDeliveredCnmts = async (storeId) => {
        try {
            const data = await getDeliveredCnmts(storeId);
            setDeliveredConsignments(data);
        } catch (err) {
            toast.error(`Error while fetching delivered consignments. Refresh the Page.`);
        }
    }

    useEffect(() => {
        if(store?._id) fetchDeliveredCnmts(store._id)
    }, [store]);


    return (
        <div className="container">
            <div className="catelogue_Page section_Wrapper">
                <h3>Welcome, {store?.fc_name}</h3>
                <h4>Store Brand Store ID: {store?.brand_store_id}</h4>
                <h4>Store SAP Code: {store?.sap_code}</h4>
                <h4>Store ID: {store?.store_id}</h4>
                <h5>Store Pincode:  {store?.pincode}</h5>
                <Accordion className="mb-4">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Store Serviceable Pincodes: </Accordion.Header>
                        <Accordion.Body style={{
                            color: "black",
                            background: "white"
                        }}>
                            {Array.isArray(store?.serviceablePincodes) ? listFormat(store.serviceablePincodes) : ''}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <hr />
                <h4 style={{textAlign: "center"}}>Delivered Consignments</h4>
                {deliveredConsignments.length > 0 ?
                    <div style={{ overflowX: 'auto', width: '100%' }}>
                        <table className="table table-bordered table-responsive">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Consignment ID</th>
                                    <th>Product EAN</th>
                                    <th>Product Title</th>
                                    <th>Quantity</th>
                                    <th>Order Type</th>
                                    <th>Created At</th>
                                    <th>Delivered At</th>
                                </tr>
                            </thead>

                            <tbody>
                                {deliveredConsignments.map((consg, index) => (
                                    <tr key={consg?._id}>
                                        <td>{index + 1}</td>
                                        <td>{consg?._id}</td>
                                        <td>{consg?.product?.ean}</td>
                                        <td>{consg?.product?.title}</td>
                                        <td>{consg?.quantity}</td>
                                        <td>{consg?.order?.orderType}</td>
                                        <td>{<Moment date={consg?.createdAt} format={APP_DATE_TIME_FORMAT} />}</td>
                                        <td>{consg?.delivered_at ? <Moment date={consg.delivered_at} format={APP_DATE_TIME_FORMAT} /> : "N.A"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    : <div style={{textAlign: "center", marginBlock: "2rem"}}>No records found!</div>
                }
                <hr />
            </div>
        </div>
    );
}

export default DeliveredConsignments
