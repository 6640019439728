// top level imports
import React from "react";

// React-Router
import { useNavigate } from "react-router-dom";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { addCupon } from "../../api/AdminApis/Cupon";
import { toast } from "react-toastify";

// Component definition
function DashboardAddDiscount() {
    // hooks
    const navigate = useNavigate();

    const cuponSubmit = async (e) => {
        e.preventDefault();
        const data = {
            discount: e.target.discount.value,
            maxAmount: e.target.maxAmount.value,
            expire: e.target.expire.value,
            products: e.target.products.value,
            code: e.target.code.value,
        };
        try {
            const response = await addCupon(data);
            if (response.status === 'success') {
                toast.success('Coupon added successfully');
                navigate("../admin-discounts");
            }
        } catch (err) {
            let message = 'Something went wrong!';
            if (err.response?.data?.message) {
                message = err.response?.data?.message;
            }
            toast.error(message);
        }
    };

    // Main renderer
    return (
        <div className="container">
            <Link to="../admin-discounts">
                <FontAwesomeIcon icon={faChevronLeft} /> Coupons
            </Link>
            <div className="card-body bg-light shadow-sm">
                <h3>Add Coupon</h3>
            </div>
            <form onSubmit={cuponSubmit}>
                <input className="input-field mt-2" type="number" placeholder="Discount" name="discount" required />
                <input className="input-field mt-2" type="number" placeholder="Max Amount" name="maxAmount" required />
                <input className="input-field mt-2" type="text" placeholder="Code" name="code" id="" required />
                <p className="catalogue_Hint">Expire in</p>
                <input className="input-field" type="date" name="expire" required />
                <input className="input-field mt-2" placeholder="Enter Product EAN" type="number" name="products" required />
                <div className="d-flex justify-content-end">
                    <Button type="submit" className="btn btn-primary mr-auto float-right mt-2 px-5">
                        Add
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default DashboardAddDiscount;
