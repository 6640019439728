import React, { useEffect, useState } from "react";
// import moment from 'moment-timezone';
import { getAdminAllOrder, getAdminIndOrder, getAdminIndividualConsignment } from "../../api/AdminApis/AdminOrder";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import Pagination from "../../components/Pagination/Pagination";
import { toast } from "react-toastify";
import { Card, ListGroup } from "react-bootstrap";
import AdminConsignmentDetailsModal from "../../components/OrderProductModal/AdminConsignmentDetailsModal";
import AdminUserDetailsModal from "../../components/OrderProductModal/AdminUserDetailsModal";
import { Button, TextField } from "@mui/material";
import Accordion from 'react-bootstrap/Accordion';
import { currencyFormat } from "../../utils";

// Component definition
function DashboardOrders() {
    // state definitions
    const [order, setOrder] = useState([]);
    const [loader, setLoader] = useState(true);
    const [show, setShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalOrders, setTotalOrders] = useState(1);
    const [details, setDetails] = useState(null);
    const [modalName, setModalName] = useState(null);
    const [consignmentSelected, setConsignmentSelected] = useState(null);
    const [searchValue, setSearchValue] = useState("");

    const productsPerPage = 30;

    useEffect(() => {
        getOrders("", "Something went wrong in fetching All Orders", currentPage);
    }, [currentPage]);

    function getOrders(value = "", errorMessage, pageNumber) {
        setLoader(true);
        getAdminAllOrder(`limit=${productsPerPage}&page=${pageNumber}&${value}`)
            .then((res) => {
                if (res) {
                    setOrder(res.orders);
                    setTotalOrders(res.total_orders);
                    setLoader(false);
                } else toast.error(errorMessage)
            })
    }

    const seeProduct = (id) => {
        let seeProdBtn = document.querySelectorAll('#seeProducts')
        seeProdBtn.forEach((btn) => {
            btn.style.pointerEvents = "none"
        })
        getAdminIndOrder(id)
            .then(res1 => {
                if (res1?.details) {
                    setDetails(res1.details);
                    setShow(true);
                } else toast.error("Something went wrong in fetching User Details")
            }).finally(() => seeProdBtn.forEach((btn) => {
                btn.style.pointerEvents = 'auto'
            }))
    }

    const getOneConsignment = (id) => {
        getAdminIndividualConsignment(id)
            .then(res1 => {
                if (res1?.details) {
                    setDetails(res1.details);
                    setShow(true);
                } else toast.error("Something went wrong in fetching Individual Consignment")
            })
    }

    const getSearchConsignment = (value) => {
        value && getOrders(`search=${value}`, "Something went wrong in fetching Searched Orders", 1)
    }

    function handleSearchClick(e) {
        e.preventDefault();
        if (searchValue) getSearchConsignment(searchValue)
    }

    function handleSearchReset(e) {
        e.preventDefault();
        setSearchValue("");
        getOrders("", "Something went wrong in fetching All Orders", 1)
    }

    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber)
        window.scrollTo(0, 0)
    }

    return loader ? (
        <DashboardLoader />
    ) : (
        <>
            <div className="container">
                <div className="d-flex justify-content-between mb-3">
                    <h3>Orders</h3>
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                        <TextField
                            size="small"
                            id="outlined-basic"
                            label="Search Order ID"
                            variant="outlined"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <Button variant="contained" onClick={handleSearchClick} disabled={!searchValue}>Search</Button>
                        <Button variant="contained" onClick={handleSearchReset}>Reset</Button>
                    </div>
                </div>
                <div className="table-responsive">
                    {order?.length > 0 && order?.map((item, index) => (
                        <Card className="mb-3" key={index}>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <div>
                                    Order ID: {item._id}
                                </div>
                                {item?.combo.length > 0 && <div className="fw-bold" style={{
                                    background: "#f7e874",
                                    padding: "0.4rem"
                                }}>
                                    Combo Product
                                </div>}
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    {
                                        item?.consignments?.length > 0 &&
                                        <Card.Text>
                                            Consignments:
                                            {item.consignments.map((e, i) =>
                                                <span
                                                    key={`consignment_${i}`}
                                                    className="text-decoration-underline cursor-pointer small mx-2"
                                                    onClick={() => {
                                                        getOneConsignment(e);
                                                        setModalName("consignment");
                                                        setConsignmentSelected(e);
                                                    }}
                                                >
                                                    {e}
                                                </span>
                                            )}
                                        </Card.Text>
                                    }
                                    <Card.Text className="me-4">
                                        User:  <span className="text-decoration-underline cursor-pointer" onClick={() => { seeProduct(item._id); setModalName("user") }}>Get User Details</span>
                                    </Card.Text>
                                </div>
                            </Card.Body>
                            <Card.Footer className="text-muted" style={{
                                background: item?.isPaid ? "#b4f0c5" : "initial"
                            }}>
                                <div>
                                    Paid: <span className="fw-bold">{(item?.isPaid) ? "Paid" : "Not Paid"}</span>
                                </div>
                                <div>
                                    Total Price:  <span className="fw-bold">{currencyFormat(item?.totalPrice)}</span>
                                </div>
                                <div>
                                    Placed: <span className="fw-bold">{(item?.isPlaced) ? "Placed" : "Not Placed"}</span>
                                </div>
                                <div>
                                    Order Type: <span className="fw-bold">{(item?.orderType)}</span>
                                </div>
                                {item?.orderType !== "Automatic" &&
                                    <div>
                                        Offline Sale: <span className="fw-bold">{item?.manualCnmtReceive ? "Yes" : "No"}</span>
                                    </div>}
                                <div>
                                    <span className="fw-bold">
                                        {new Intl.DateTimeFormat("en-IN", {
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            second: "numeric",
                                            hour12: true,
                                            timeZone: "Asia/Calcutta",
                                        }).format(new Date(item?.createdAt))}
                                    </span>
                                </div>
                                {item?.consignments?.length === 0 &&
                                    <Accordion className="my-3">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Product Details</Accordion.Header>
                                            <Accordion.Body style={{
                                                background: "#cfcbc0"
                                            }}>
                                                <div className='row my-3'>
                                                    {item?.productDetails.map((product, index) => (
                                                        <div key={`product_not_paid_${index}`}>
                                                            <ListGroup as="ol" className="mb-2">
                                                                <h5>Product - {index + 1}</h5>
                                                                <ListGroup.Item
                                                                    as="li"
                                                                    className="d-flex justify-content-between align-items-start"
                                                                >
                                                                    Product Name: <span className='fw-bold'>{product?.name ?? "-"}</span>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item
                                                                    as="li"
                                                                    className="d-flex justify-content-between align-items-start"
                                                                >
                                                                    Product ID: <span className='fw-bold'>{product?.productId ?? "-"}</span>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item
                                                                    as="li"
                                                                    className="d-flex justify-content-between align-items-start"
                                                                >
                                                                    Quantity: <span className='fw-bold'>{product?.purchaseQty ?? "-"}</span>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item
                                                                    as="li"
                                                                    className="d-flex justify-content-between align-items-start"
                                                                >
                                                                    Price: <span className='fw-bold'>{currencyFormat(product?.selling_price) ?? "-"}</span>
                                                                </ListGroup.Item>
                                                                <ListGroup.Item
                                                                    as="li"
                                                                    className="d-flex justify-content-between align-items-start"
                                                                >
                                                                    Color: <span className='fw-bold'>{product?.color ?? "-"}</span>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                            {item?.combo?.length > 0 &&
                                                                item?.combo?.map((combo, i) => (
                                                                    <ListGroup as="ol" key={`${combo?.ean}_combo_${i}`}>
                                                                        <ListGroup.Item
                                                                            as="li"
                                                                            className="d-flex justify-content-between align-items-start"
                                                                        >
                                                                            Combo Product EAN : <span className='fw-bold'>{combo?.ean}</span>
                                                                        </ListGroup.Item>
                                                                        <ListGroup.Item
                                                                            as="li"
                                                                            className="d-flex justify-content-between align-items-start"
                                                                        >
                                                                            Combo Product Name: <span className='fw-bold'>{combo?.title}</span>
                                                                        </ListGroup.Item>
                                                                        <ListGroup.Item
                                                                            as="li"
                                                                            className="d-flex justify-content-between align-items-start"
                                                                        >
                                                                            Combo Product Discount Type: <span className='fw-bold'>{combo?.discountType}</span>
                                                                        </ListGroup.Item>
                                                                        <ListGroup.Item
                                                                            as="li"
                                                                            className="d-flex justify-content-between align-items-start"
                                                                        >
                                                                            Combo Product Discount Value: <span className='fw-bold'>{combo?.value}</span>
                                                                        </ListGroup.Item>
                                                                    </ListGroup>
                                                                ))
                                                            }
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>}
                            </Card.Footer>
                        </Card>
                    ))}
                </div>
                <div className="pagination_Container">
                    <Pagination productsPerPage={productsPerPage} totalProducts={totalOrders} pageChange={handlePageChange} currentPage={currentPage} />
                </div>
            </div>
            {
                show && (
                    modalName === "consignment" ?
                        <AdminConsignmentDetailsModal
                            show={show}
                            onHide={() => setShow(false)}
                            details={details}
                            consignmentSelected={consignmentSelected}
                        /> :
                        (
                            modalName === "user" ?
                                <AdminUserDetailsModal
                                    show={show}
                                    onHide={() => setShow(false)}
                                    details={details}
                                />
                                : null
                        )
                )
            }
        </>
    );
}

export default DashboardOrders;
