import React from "react";

import "./styles.css";

import RWO from "../../assets/MyOffers/RWO.jpg";
import One from "../../assets/MyOffers/One.png";
import Two from "../../assets/MyOffers/Two.png";
import Three from "../../assets/MyOffers/Three.png";
import Four from "../../assets/MyOffers/Four.png";
import Five from "../../assets/MyOffers/Five.png";
import BottomOne from "../../assets/MyOffers/BottomOne.png";
import NewOne from "../../assets/MyOffers/NewOne.png";
import NewTwo from "../../assets/MyOffers/NewTwo.jpg";


import Insta from "../../assets/MyOffers/insta.png";
import FB from "../../assets/MyOffers/fb.png";
import twitter from "../../assets/MyOffers/twitter.png";
import youtube from "../../assets/MyOffers/youtube.png";
import linkedin from "../../assets/MyOffers/linkedin.png";
import find from "../../assets/MyOffers/find.png";

import WebIcon from "../../assets/MyOffers/web_icon.png";
import PhoneIcon from "../../assets/MyOffers/phone_icon.png";

import { Link, useNavigate } from "react-router-dom";
import YoutubeEmbed from "../../components/YoutubeEmbed";

const AllOffers = () => {
    const nav = useNavigate();

    return (
        <div className="myRewardsWrapper">
            <div className="container">
                <h1 className="myRewardsTitle">
                    Check Out Our Latest Offers
                </h1>
                <div className="d-flex flex-column gap-3">
                    <Link className="offer-page-hover-banner" to={`/product/Realme-12-Pro-Plus`} target="_blank">
                        <img src={RWO} alt="Realme 12 Pro" className="w-100"/>
                    </Link>
                    <Link className="offer-page-hover-banner" to={`https://www.instagram.com/olineoindia/`} target="_blank">
                        <img src={One} alt="Santa Offer Main Banner" className="w-100" />
                    </Link>
                    <img src={Two} alt="0 Percent Interest Banner" />
                    <div className="d-flex gap-3 side-by-side-offer-banner">
                        <img src={Three} alt="Diwali Offer Spin Wheel" />
                        <img src={Four} alt="Two Years Extended Warranty" />
                    </div>
                    <img src={Five} alt="Exchange Offer" />
                    <div className="d-flex gap-3 side-by-side-offer-banner">
                        <img src={NewOne} alt="Delivery Bannner" />
                        <img src={NewTwo} alt="Five Percent Banner 2" onClick={() => nav(`/product/Realme-12-Pro-Plus`)}/>
                    </div>
                </div>
                <div className="card-display-row d-flex gap-3">
                    <YoutubeEmbed embedId="1FhJQFTJ7bs" />
                    <YoutubeEmbed embedId="h7FhJhczV9U" />
                    <YoutubeEmbed embedId="P0TqXTSye4Q" />
                    <YoutubeEmbed embedId="SUPkcQKBI5g" />
                    <YoutubeEmbed embedId="q9kDdt3nN-w" />
                </div>
                <div className="mt-4 bottom-section-offer">
                    <Link to={'/store-finder'} className="">
                        <img src={BottomOne} alt="Find Our O-LINE-O Store" className="find-store relative w-100 offer-page-hover-banner" />
                    </Link>
                </div>
                <div className="social-section my-4">
                    <div className="social-wrapper-top d-flex justify-content-center align-items-center mb-4">
                        <img alt="Find us On" src={find} />
                    </div>
                    <div className="social-wrapper-bottom">
                        <Link target="_blank" to={`https://www.instagram.com/olineoindia/`}><img alt="Instagram" src={Insta} /></Link>
                        <Link target="_blank" to={`https://www.facebook.com/OLineOIndia/`}><img alt="Facebook Icon" src={FB} /></Link>
                        <Link target="_blank" to={`https://twitter.com/olineoindia`}><img alt="Twitter X" src={twitter} /></Link>
                        <Link target="_blank" to={`https://www.linkedin.com/company/olineoindia/`}><img alt="LinkedIn" src={linkedin} /></Link>
                        <Link target="_blank" to={`https://www.youtube.com/@OLineOIndia`}><img alt="Youtube" src={youtube} /></Link>
                    </div>
                </div>
                <div className="last-section">
                    <Link to={'/store-finder'} className="visit-page">Visit Our O-LINE-O Store</Link>
                    <Link to={'/'}>
                        <img src={WebIcon} alt="web-icon" className="web-icon" />
                        <span>www.olineoindia.com</span>
                    </Link>
                    <Link to={'tel:6390063900'}>
                        <img src={PhoneIcon} alt="phone-icon" className="phone-icon" />
                        <span>63900 63900</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AllOffers;
