import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import { getUser, getUserPic, resendOtp, verifyOtpLogin, verifyOtpSignup } from "../api/Auth";
import { toast } from "react-toastify";
import { getCartData } from "../api/Cart";
import { getAllOrder } from "../api/OrdersApi";
import CircleLoading from "../components/CircleLoading";
import { getAddress } from "../api/Address";
import useSiteCtx from "../hooks/useSiteCtx";
import useUserCtx from "../hooks/useUserCtx";
import SignInBg from "../assets/productIcons/SignUpBG.svg"
import { useTranslation } from "react-i18next";

// toast.configure();

// Component Definition
const OtpValid = () => {
    const { loginRedirect } = useSiteCtx();
    const { t } = useTranslation();
    
    // state definitions
    const [otp, setOtp] = useState("");
    const [, setValidLength] = useState(false);
    const [btnDisable, setBtnDisable] = useState(true);
    const [seconds, setSeconds] = useState(60);
    const [loading, setLoading] = useState(false);
    // const [resend, setResend] = useState(true)

    const nav = useNavigate();
    const {
        setUserContext,
        setUserAddress,
        setCartArray,
        setUserOrderData,
    } = useUserCtx();
    const loc = useLocation();

    const handleLength = (length) => {
        if (length === 6) {
            setValidLength(true);
            return setBtnDisable(false);
        }
        setValidLength(false);
        setBtnDisable(true);
    };

    // componentDidMount
    useEffect(() => {
        if (loc.state?.otpSentSuccess) {
            toast.success(t("notifications.OTPSentSuccessfully"));            
        }
    }, []);

    useEffect(() => {
        let timer;
        if (seconds > 0) {
            timer = setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            setSeconds(0);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [seconds]);

    const existingUserLogin = (e) => {
        e.preventDefault();
        // let OTP = parseInt(otp)
        setLoading(true);
        setBtnDisable(true);
        const { notifyUser = false, productSlug} = loc.state ?? {};
        verifyOtpLogin(otp).then((res) =>
            res
                ? (setLoading(false),
                    setBtnDisable(false),
                    nav(notifyUser && productSlug ? '/product/' + productSlug : '/', {
                        state: loc?.state,
                        replace: true
                    }),
                    setUserContext((prev) => ({
                        ...prev,
                        JWT: res.JWT,
                    })),
                    getUser(res.JWT).then((res) => {
                        if (res) {
                            let user = res;
                            setUserContext((prev) => ({
                                ...prev,
                                id: user._id,
                                fullName: user.fullName,
                                mobileNumber: user.mobileNumber,
                                email: user.email,
                                dob: user.dob,
                            }));
                        }
                    }),
                    getUserPic(res.JWT).then((res) => {
                        if (res) {
                            setUserContext((prev) => ({
                                ...prev,
                                profilePic: res,
                            }));
                        }
                    }),
                    getAllOrder(res.JWT).then((res) => {
                        if (res) {
                            let orders = [...res.orders];
                            let newOrders = orders.filter((obj) => obj.itemId.length > 0);
                            setUserOrderData({
                                loaded: true,
                                no_of_orders: res.no_of_orders,
                                orders: newOrders,
                            });
                        }
                    }),
                    getCartData(res.JWT).then((res) => {
                        if (res) {
                            let prod = [];
                            res.cart.forEach((item, idx) => {
                                let product = { ...item?.productId };
                                product["quantity"] = item.qty;
                                prod.push(product);
                            });
                            setCartArray({
                                loaded: true,
                                no_of_carts: prod.length,
                                cart: prod,
                                combo: res.combo,
                            });
                        }
                    }),
                    getAddress(res.JWT).then((res) => {
                        // console.log(res);
                        if (res) {
                            setUserAddress({
                                loaded: true,
                                no_of_address: res.no_of_address,
                                address: res.address,
                            });
                        }
                    }))
                : (setLoading(false), setBtnDisable(false), toast.error("OTP Expired or invalid"))
        );
    };

    const newUserSignUp = (e) => {
        e.preventDefault();
        setLoading(true);
        setBtnDisable(true);
        // let OTP = parseInt(otp)
        verifyOtpSignup(otp).then((res) =>
            res
                ? (setLoading(false),
                    setBtnDisable(false),
                    nav("/adduser", { replace: true }),
                    setUserContext((prev) => ({
                        ...prev,
                        JWT: res,
                    })))
                : (setLoading(false), setBtnDisable(false), toast.error("OTP Expired or invalid"))
        );
    };

    const onResendOtp = (e) => {
        e.preventDefault();
        setLoading(true);
        setBtnDisable(true);
        const mobileNumber = loc?.state.mobileNumber
        if (mobileNumber) {
            resendOtp(mobileNumber).then((res) =>
                res
                    ? (setLoading(false),
                        setBtnDisable(false),
                        setSeconds(60),
                        toast.success(t("notifications.OTPSentSuccessfully"))
                    )
                    : (setLoading(false), setBtnDisable(false), toast.error(t('login.mobile-not-exists')))
            );
        }
    };

    return (
        <>
            <HeaderBar />
            <div className="signUpParentClass"
            style={{
                backgroundImage: `url(${SignInBg})`,
                backgroundSize:"cover"
            }}>
            <div className="signup-wrapper" >
                <div className="signup-header">
                    <h1 className="page-heading">{t('otp.confirm-text')}</h1>
                    <p className={"page-desc"}>{t('otp.confirm-subHeading')}</p>
                </div>
                <form action="" className={"signup-form"} onSubmit={loginRedirect ? existingUserLogin : newUserSignUp}>
                    <div className="inputfield-Container">
                        <div className="inputField">
                            <input
                                type="text"
                                name="Code"
                                id="code"
                                className="input-field"
                                value={otp}
                                autoComplete="off"
                                placeholder={t('otp.confirm-text')}
                                maxLength={6}
                                onChange={(e) => {
                                    setOtp(e.target.value);
                                    handleLength(e.target.value.length);
                                }}
                            />
                        </div>
                        <p onClick={onResendOtp} className={`resend-btn ${seconds === 0 ? "" : "btn-disable"}`} type="resend">
                            {seconds === 0 ? "Resend Code" : `${seconds} sec`}
                        </p>
                    </div>
                    <div className={"button-Container"}>
                        {loading ? (
                            <div style={{ height: "15px", textAlign: "center", position: "absolute", top: "-25px" }} className="loading_wrapper">
                                <CircleLoading />
                            </div>
                        ) : (
                            ""
                        )}
                        <button className="submit-button h-auto" type="submit" disabled={btnDisable}>
                            <p>{t('continue')}</p>
                        </button>
                        {/* {loginRedirect ? (
                            <Link state={{ emailLogin: true }} to={"/login"} className="extra-btn">
                                {t('otp.resend-email')}
                            </Link>
                        ) : (
                            ""
                        )} */}
                    </div>
                </form>
                </div>
            </div>
        </>
    );
};

export default OtpValid;
