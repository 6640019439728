import React from "react";

import "./styles.css";

import Banner1 from "../../assets/png/myRewards1.png";
import Banner2 from "../../assets/png/myRewards2.png";
import Banner3 from "../../assets/png/myRewards3.png";
import Banner4 from "../../assets/png/myRewards4.png";
import Person from "../../assets/png/myRewardsPerson.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MyRewards = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const breadCrumbsData = [
    {
      text: t('profile.profile') + " / ",
      url: "/myProfile",
      isActive: location.pathname === "/myProfile"
    },
    {
      text: t('myRewards'),
      url: "/myRewards",
      isActive: location.pathname === "/myRewards"
    },
  ];
  
  return (
    <div className="myRewardsWrapper">
      <div className="myRewardsContainer">
        <h1 className="myRewardsTitle">
          {breadCrumbsData.map((item, index) =>
            <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>{item.text}
            </Link>)}</h1>
        <h1 className="myRewardsHeading">My Rewards</h1>
        <div className="myRewardsBannerContainer">
          <div className="myRewardsBanner">
            <img className="myRewardsBannerImage" src={Banner1} alt="Banner1" />
            <img className="myRewardsBannerImage" src={Banner2} alt="Banner2" />
          </div>
          <div className="myRewardsBanner">
            <img className="myRewardsBannerImage" src={Banner3} alt="Banner3" />
            <img className="myRewardsBannerImage" src={Banner4} alt="Banner4" />
          </div>
        </div>
        <div className="myRewardsPersonImageContainer">
          <img src={Person} alt="Person" className="myRewardsPersonImage" />
        </div>
      </div>
    </div>
  );
};

export default MyRewards;
