import React, { useEffect, useState } from 'react';
import DashboardLoader from '../../components/DashboardContent/DashboardLoader';
import * as XLSX from "xlsx";
import { Button } from 'react-bootstrap';
import { formatDate } from '../../utils';
import { getAllUsersForOffers } from '../../api/OfferAPIs';
import { useNavigate } from "react-router-dom";

// Component definition
const AdminOffers = () => {
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    function getCurrentDateTime() {
        const now = new Date();

        // Get the day, month, and year components
        const day = now.getDate().toString().padStart(2, '0');
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const year = now.getFullYear().toString().slice(-2);

        // Get the hours and minutes
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
        const minutes = now.getMinutes().toString().padStart(2, '0');

        // Combine the components into the desired format
        const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;

        return formattedDateTime;
    }

    function downloadFile() {
        const currentDateTime = getCurrentDateTime();
        const filename = `Offer_Users_${currentDateTime}.xlsx`;
        const selectedData = data.map((d, index) => ({
            "S.No.": index + 1,
            Date: formatDate(d.createdAt),
            UserName: d?.userName,
            User_Email: d?.userEmail,
            User_Phone: d?.userPhone,
            Store_ID: d?.storeId,
            "Store Name": d?.storeName,
            "Prize Won": d?.offerText,
            "Prize Avail Time": d?.offerAvailTime ? formatDate(d.offerAvailTime) : "-",
            "Product Name": d?.productName,
            "Brand Name": d?.brandName,
            "Product Category": d?.productCategory,
            "Product Amount": d?.productAmount,
            "Invoice Number": d?.invoiceNumber,
            "IMEI Number": d?.imeiNumber,
            "Store Verified": d?.isStoreVerified ? "Yes" : "No",
        }));
        var ws = XLSX.utils.json_to_sheet(selectedData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Users-Offers-2024");
        XLSX.writeFile(wb, filename);
    }

    useEffect(() => {
        setLoader(true);
        getAllUsersForOffers().then((res) => {
            if (res?.status === "success") {
                setData(res.users);
            }
        }).finally(() => setLoader(false))

    }, []);


    return loader ? (
        <DashboardLoader />
    ) : (
        <>
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h3>All Users for Republic Week Offer 2024</h3>
                </div>
                <div className='d-flex gap-3'>
                    <Button variant='primary' size='small' onClick={downloadFile}>Download File</Button>
                    <Button variant='primary' size='small' onClick={() => navigate("coupons")}>View Coupons</Button>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">S.No.</th>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">Store ID</th>
                                <th scope="col">Store Name</th>
                                <th scope="col">Prize Won</th>
                                <th scope="col">Prize Won Time</th>
                                <th scope="col">Product Amount</th>
                                <th scope="col">Prouct Category</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">Brand Name</th>
                                <th scope="col">Invoice Number</th>
                                <th scope="col">IMEI Number</th>
                                <th scope="col">Store Verified</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 && data?.map((item, index) => (
                                <tr key={item._id} style={{
                                    background: item?.offerId === 10 ? "yellow" : "white"
                                }}>
                                    <td>{index + 1}.</td>
                                    <td>{item?.createdAt ? formatDate(item.createdAt) : "-"}</td>
                                    <td> {item?.userName ?? "-"} </td>
                                    <td>{item?.userEmail ?? "-"}</td>
                                    <td>{item?.userPhone ?? "-"}</td>
                                    <td>{item?.storeId ?? "-"}</td>
                                    <td>{item?.storeName ?? "-"}</td>
                                    <td>{item?.offerText ?? "-"}</td>
                                    <td>{item?.offerAvailTime ? formatDate(item.offerAvailTime) : "-"}</td>
                                    <td>{item?.productAmount ?? "-"}</td>
                                    <td>{item?.productCategory ?? "-"}</td>
                                    <td>{item?.productName ?? "-"}</td>
                                    <td>{item?.brandName ?? "-"}</td>
                                    <td>{item?.invoiceNumber ?? "-"}</td>
                                    <td>{item?.imeiNumber ?? "-"}</td>
                                    <td>{item?.isStoreVerified ? "Yes" : "No"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default AdminOffers;