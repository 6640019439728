import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./styles/dashAddproduct.css";
import * as XLSX from "xlsx";

//Images
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { updateMasterCatalogue } from "../../api/CatalogueApi";

// Component definition
const DashboardUpdateProductCsvFile = () => {

    const [productTable, setProductTable] = useState({ headers: [], rows: [] });

    // Hooks
    const nav = useNavigate();

    // Refs
    const productPayloadRef = useRef([]);

    const handleFile = async (event) => {
        // read file as a binary string
        const [file] = event.target.files;
        const fileReader = new FileReader();
        fileReader.onload = readEvt => {
            parseXLSXData(readEvt.target.result)
            event.target.value = "";
        };
        fileReader.readAsBinaryString(file);
    };

    // parses binary string to json data
    const parseXLSXData = binaryData => {
        try {
            const workBook = XLSX.read(binaryData, { type: 'binary' });
            const workSheetName = workBook.SheetNames[0];
            const workSheet = workBook.Sheets[workSheetName];
            const data = XLSX.utils.sheet_to_json(workSheet, { header: 1, defval: '', blankrows: false });
            processParsedData(data)
        } catch (err) {
            console.log(err)
        }
    };

    // intermediary processing of data from excel
    const processParsedData = (jsonData) => {
        const [headers, ...rowData] = jsonData;
        const productsData = rowData.map((columns) => {
            return columns.reduce((acc, col, idx) => {
                return acc = { ...acc, [headers[idx] ?? '']: col };
            }, {})
        })

        transformDataForCatalogue(productsData, headers);
    }

    // validates and transforms the data to be uploaded for cataloguing
    const transformDataForCatalogue = (productRaw, headers = []) => {
        let hasError = false;
        const tProducts = [];

        for (const prod of productRaw) {
            const product = generateProductSchema(prod);
            tProducts.push(product);
        }
        if (!hasError) {
            productPayloadRef.current = tProducts;
            setProductTable({ headers, rows: productRaw })
        }

    }

    function validateAndWrapProperties(obj) {
        const result = {};
        result.price = {};
      
        // Check if 'mrp' is present and wrap it in an object
        if (obj.hasOwnProperty('mrp')) {
          result.price = { ...result.price, mrp: obj.mrp };
        }
      
        // Check if 'mop' is present and wrap it in an object
        if (obj.hasOwnProperty('mop')) {
          result.price = { ...result.price, mop: obj.mop };
        }
      
        // Copy other properties to the result object
        for (const key in obj) {
          if (key !== 'mrp' && key !== 'mop') {
            result[key] = obj[key];
          }
        }

        if(Object.keys(result.price).length === 0) {
            delete result.price
        }
      
        return result;
      }

     // generates a product object that will be stored at the backend
     const generateProductSchema = (prod) => {
         const validatedObject = validateAndWrapProperties(prod);
         console.log(validatedObject);
        return validatedObject
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await doCatalogueUpload();
    };

    // Performs and invokes api to do product cataloguing
    const doCatalogueUpload = async () => {
        document.getElementById("submitCsvData").disabled = true;
        let apiRes = null
        try {
            if (productPayloadRef.current.length > 0) {
                apiRes = await updateMasterCatalogue(productPayloadRef.current);
            }
        } catch (err) {
            let errorMsg = err?.response?.data?.message ?? 'Something went wrong';
            toast.error(errorMsg);
        }
        console.log(productPayloadRef.current)
        const payload = apiRes.data;

        // products that were not uploaded
        if (payload?.productsFailed?.length > 0) {
            const failedEans = payload?.productsFailed?.map(({ ean }) => ean).join(', ')
            toast.error(`.Product upload failed for - ${failedEans}`);
            toast.error("Possible issues in product uploads could be duplicate EAN or any missing fields");
        }

        // show error if no products were uploaded
        if (!payload?.productsAdded?.length) {
            toast.error('Bulk products upload failed');
        }

        // Partial upload of products was done
        if (payload?.productsAdded?.length > 0 && payload?.productsAdded?.length !== productTable.rows.length) {
            toast.info('Cateloguing done partially!')
        }

        // all products in excel were uploaded
        if (payload?.productsAdded?.length === productTable.rows.length) {
            toast.success('Product cataloguing completed successfully!')
            setTimeout(() => {
                nav("../admin-products");
            }, 1000)
        }

        document.getElementById("submitCsvData").innerHTML = "Submit";
        document.getElementById("submitCsvData").disabled = false;
    }


    // resets current state
    const reset = () => {
        const userConfirm = window.confirm('Are you sure you want to reset the form?');
        if (userConfirm) {
            setProductTable({ headers: [], rows: [] })
        }
    }

    return (
        <div className="container">
            <Link to="../admin-products">
                <FontAwesomeIcon icon={faChevronLeft} /> Products
            </Link>

            <div className="catelogue_Page section_Wrapper">

                <div className="catelogue_Page_Header">
                    <h4 className="catelogue_Page_Heading">Update Products CSV</h4>
                    <button
                        type="button"
                        className="btn-sm btn btn-danger"
                        disabled={!productTable.headers.length}
                        onClick={reset}
                    >
                        Reset
                    </button>
                </div>

                {!productTable.headers.length && (
                    <div>
                        <input
                            style={{ height: 'revert' }}
                            type="file"
                            className="input-field"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(e) => handleFile(e)}
                        />
                    </div>
                )}

                {productTable.headers.length > 0 && (
                    <div>
                        <div className="catelogue_Table"
                        >
                            <table>
                                <thead>
                                    <tr>
                                        {productTable.headers.map((item, index) => (
                                            <th key={index}>{item}</th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    {productTable.rows.map((row, rowIdx) => {
                                        return (
                                            <tr key={rowIdx}>
                                                {productTable.headers.map((col, colIdx) => {
                                                    return (
                                                        <td key={`${rowIdx}-${colIdx}`}>
                                                            {row[col] ?? ''}
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className={"button-Container"}>
                            <button
                                id="submitCsvData"
                                type="submit"
                                className="submit-button"
                                onClick={handleSubmit}
                            >
                                <p>Submit</p>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashboardUpdateProductCsvFile;
