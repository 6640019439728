// top level imports
import { useState, useEffect } from "react";

// React-Toast
import { toast } from "react-toastify";

// API
import { getStockDetails } from "../../../api/AdminApis/StockTracker";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Components
import DashboardLoader from "../../../components/DashboardContent/DashboardLoader";

export function StockTracker() {

    // state definitions
    const [loading, setLoading] = useState(false);
    const [stocks, setStocks] = useState([]);
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        fetchStockDetails(`populate=yes`)
    }, []);


    const showLoader = () => setLoading(true);

    const hideLoader = () => setLoading(false);

    const fetchStockDetails = async (queryStr) => {
        showLoader();
        try {
            const response = await getStockDetails(queryStr);
            if (response.status === 'success') {
                setStocks(response.data);
            }
        } finally {
            hideLoader();
        }
    }

    const handleUpdate = async (stockId) => {
        navigate(`edit/${stockId}`)
    }


    function handleSearchClick(e) {
        e.preventDefault();
        if (searchValue) {
            fetchStockDetails(`populate=yes&ean=${searchValue}`)
        }
    }

    function handleSearchReset(e) {
        e.preventDefault();
        setSearchValue("");
        fetchStockDetails(`populate=yes`);
    }

    // Main renderer
    return (
        <div className="container">
            <div className="d-flex justify-content-between mb-2">
                <h3>Stocks (Store wise product quantity)</h3>
            </div>

            <div className="d-flex justify-content-between my-4">
                <div className="d-flex gap-2 justify-content-center align-items-center">
                    <TextField
                        size="small"
                        id="outlined-basic"
                        label="Search Product EAN"
                        variant="outlined"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <Button variant="contained" onClick={handleSearchClick} disabled={!searchValue}>Search</Button>
                    <Button variant="contained" onClick={handleSearchReset}>Reset</Button>
                </div>
                <Button size='small' variant='outlined' onClick={() => navigate("add")}>Add Stock</Button>
            </div>

            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>EAN</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {loading ?
                        <DashboardLoader />
                        :
                        stocks.length > 0 ?
                            <tbody>
                                {stocks?.map((el, index) => {
                                    return (
                                        <tr key={el._id}>
                                            <td>{index + 1}</td>
                                            <td>{el.ean}</td>
                                            <td>{el.product?.title ?? 'N.A'}</td>
                                            <td>
                                                {el.quantity}
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-success"
                                                    onClick={() => handleUpdate(el._id)}
                                                >
                                                    Edit
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            :
                            <h5 className='my-4'>No Stock Added</h5>
                    }
                </table>
            </div>
        </div>
    )
};
