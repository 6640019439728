import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { toast } from "react-toastify";

// Custom Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";

// Utils
import { debounce } from "../../utils";
// - API
import {  changeStoreCommentStatus, getAdminStoreComments } from "../../api/AdminApis/AdminReviews";
import { FormControlLabel, Switch, TextField } from "@mui/material";

// Component definitions
const AdminStoreComments = () => {
    // state definitions
    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState([]);
    const [filterObj, setFilterObj] = useState({
        status: 'All',
        category: 'all',
        searchText: '',
        startDate: '',
        endDate: ''
    });
    const [totalComments, setTotalComments] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const commentsPerPage = 10;
    const { isOpen: isSidebarOpen = false } = useOutletContext();


    useEffect(() => {
        setLoading(true);
        (async () => {
            let url = `?limit=${commentsPerPage}&page=${currentPage}`;
            // add filter params
            if (filterObj['status'] !== "All") {
                url += `&status=${filterObj['status']}`;
            }

            if (filterObj['category'].toLocaleLowerCase() !== 'all') {
                url += `&category=${filterObj['category']}`;
            }

            if (filterObj['searchText']) {
                url += `&searchText=${filterObj['searchText']}`;
            }

            if (filterObj['startDate']) {
                url += `&startDate=${filterObj['startDate']}`;
            }

            if (filterObj['endDate']) {
                url += `&endDate=${filterObj['endDate']}`;
            }
            fetchData(url);
           
        })();
    }, [currentPage, filterObj]);

    /** Handler funcions - starts */

    const fetchData = (url = "") => {
        setLoading(true)
        getAdminStoreComments(url).then(res => {
            if (res?.status === "success") {
                setTotalComments(res.data.total_comments)
                setComments(res.data.comments)
            }
        }).catch(err => {
            console.error(err)
            toast.error("Something went wrong! Internal Server Error")
        }).finally(() => setLoading(false))
    }

    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const handleStatusChange = (commentId, e, value) => {
        if (window.confirm("Sure?")) {
            changeStoreCommentStatus(commentId).then((res) => {
                if (res?.status === "success") {
                    toast.success(res.status?.message);
                    fetchData()
                } else {
                    toast.error("Oops! Some Error occured.");
                }
            });
        }
    };

    // handles filter change and updates filterObj
    const handleFilterChange = (key, value) => {
        if (!Object.keys(filterObj).includes(key)) return;

        setFilterObj(prev => ({ ...prev, [key]: value }));
    }

    // handles date change filter
    const handleDateChange = (dateKey, dateVal) => {
        if (filterObj[dateKey] === dateVal) return;
        setFilterObj(prev => ({ ...prev, [dateKey]: dateVal }));
    }

    const handleFreeTextChange = debounce((event) => {
        handleFilterChange('searchText', event.target.value)
    });

    /** Handler funcions - ends */

    return (
        <div className="container" style={{ maxWidth: isSidebarOpen ? '1140px' : '100%' }}>

            <div className="d-flex justify-content-between mb-2">
                <h3>Store Comments And Reviews</h3>
            </div>

            {/** Filter Sections */}
            <div className="d-flex mb-2">

                {/** Free text filter */}
                <div className="position-relative me-2">
                    <TextField id="standard-basic" onChange={handleFreeTextChange} label="Enter Store ID" variant="standard" />
                </div>

                {/** Status Filter */}
                <div className="me-2 d-flex" style={{ maxHeight: '50px' }}>
                    <label className="me-1 pt-3">Status: </label>
                    <Dropdown title="Query Status">
                        <Dropdown.Toggle className="dropdown-btn">
                            <p>{filterObj['status']}</p>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleFilterChange('status', 'All')}>
                                All
                            </Dropdown.Item>
                            <Dropdown.Item
                                key={"approved"}
                                onClick={() => handleFilterChange('visible', true)}
                            >
                                Approved
                            </Dropdown.Item>
                            <Dropdown.Item
                                key={"rejected"}
                                onClick={() => handleFilterChange('visible', false)}
                            >
                                Rejected
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                {/** Date filters */}
                <div className="d-flex" style={{ maxHeight: '50px' }}>
                    <div className="me-2 d-flex">
                        <label className="me-1 pt-3">Start: </label>
                        <DatePicker
                            name="startDate"
                            format="dd/MM/y"
                            value={filterObj['startDate']}
                            className={"input-field custom-date-picker"}
                            onChange={(dateVal) => handleDateChange('startDate', dateVal)}
                        />
                    </div>
                    <div className="me-2 d-flex">
                        <label className="me-1 pt-3">End: </label>
                        <DatePicker
                            name="endDate"
                            format="dd/MM/y"
                            value={filterObj['endDate']}
                            className={"input-field custom-date-picker"}
                            onChange={(dateVal) => handleDateChange('endDate', dateVal)}
                        />
                    </div>
                </div>
            </div>

            {!loading ? (
                <>
                    <div className="table-responsive mb-2">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">S.No.</th>
                                    <th scope="col">User ID</th>
                                    <th scope="col">Date & Time</th>
                                    <th scope="col">Store Rating</th>
                                    <th scope="col">Store Review</th>
                                    <th scope="col">Has user visited Store?</th>
                                    <th scope="col">Approved Status</th>
                                    <th scope="col">Likes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {comments?.length > 0 &&
                                    comments?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{(currentPage - 1) * commentsPerPage + index + 1}.</td>
                                            <td>{item?.user?._id ?? "-"}</td>
                                            <td>
                                                {new Intl.DateTimeFormat("en-IN", {
                                                    year: "numeric",
                                                    month: "short",
                                                    day: "numeric",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    second: "numeric",
                                                    hour12: true,
                                                    timeZone: "Asia/Calcutta",
                                                }).format(new Date(item?.createdAt))}
                                            </td>
                                            <td>{item?.storeRating ?? "-"}</td>
                                            <td>{item?.storeReview ?? "-"}</td>
                                            <td>{item?.userHasVisited ? "True": "False"}</td>
                                            <td><FormControlLabel control={<Switch checked={item?.visible} />}  onChange={(e) => handleStatusChange(item?._id, e, item?.visible)} /></td>
                                            <td>{item?.likes?.length ?? "0"}</td>

                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination_Container">
                        <Pagination
                            productsPerPage={commentsPerPage}
                            totalProducts={totalComments}
                            pageChange={handlePageChange}
                            currentPage={currentPage}
                        />
                    </div>
                </>
            ) : (
                <DashboardLoader />
            )}
        </div>
    )
};

export default AdminStoreComments;
