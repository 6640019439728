import { useState, useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// XLSX Parser
import * as XLSX from "xlsx";
// API
import { addProductGalleryImages, addProductImages, updateMasterCatalogue, initializeProductMaster } from "../../api/CatalogueApi";
import CatelogueModal from "../../components/ModalComponenr/CatelogueModal";
import { toast } from "react-toastify";
import { PRODUCT_CLASSIFICATION, DEFAULT_GST_PERCENTAGE } from "../../utils/Constants";

// toast.configure();

// Component definition
const BulkUploadSection = () => {
    // Constants
    const uploadTypes = [
        { key: 'initialise', label: 'Initialise Product Master' },
        { key: 'catalogue', label: 'Product Cataloguing' }
    ];
    const headingTitle = {
        initialise: 'Initialise Product Master',
        catalogue: 'Product Cataloguing'
    };
    const requiredMasterFields = ['ean', 'title', 'description', 'HSN', 'gstpercentage'];

    // state definitions
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState("");
    const [imagesObject, setImagesObject] = useState({});
    const [galleryImagesObject, setGalleryImagesObject] = useState({});
    const [productTable, setProductTable] = useState({ headers: [], rows: [] });
    const [uploadType, setUploadType] = useState('initialise');
    const [selectedProdCategory, setSelectedProdCategoory] = useState('');
    const [selectedEan, setSelectedEan] = useState("");

    // Hooks
    const nav = useNavigate();

    // Refs
    const productPayloadRef = useRef([]);

    const requiredFields = {
        Soundbar: ["power_output", "channel_configuration", "input_ports", "connection_type", "sattelite_channels", "included_subwoofer"],
        "True Wireless Earbuds": ["bluetooth_version", "total_playback_time", "quick_charge"],
        "Bluetooth Neckband": ["bluetooth_version", "total_playback_time", "quick_charge"],
        "Bluetooth Headphones": ["bluetooth_version", "total_playback_time", "quick_charge", "mic"],
        "Wired Headphones": ["mic", "wired_controls", "weight"],
        "Wired Earphones": ["mic", "wired_controls"],
        Powerbank: ["battery_capacity", "output_ports", "fast_charging"],
        Tablet: ["ram", "internal_storage", "screen_size", "screen_resolution", "screen_type", "color"],
        "Smart TV": ["operating_system", "screen_size", "resolution", "display_technology", "input_ports", "refresh_rate"],
        Laptop: ["ram", "internal_storage", "screen_size", "screen_resolution", "screen_type", "graphics_card", "color", "laptop_type", "processor" ],
        "Wifi Smart Speaker": ["supported_smart_assistants", "power_output"],
        "Security Camera": ["sensor_resolution"],
        Smartphone: ["internal_storage", "ram", "display_size", "battery_capacity", "processor", "warranty"],
        "Feature Phone": ["internal_storage", "ram", "display_size", "battery_capacity", "processor", "warranty"],
        Smartwatch: ["internal_storage", "bluetooth_version", "display_size", "display_type", "battery_capacity", "warranty"],
        "Smart Band": ["display_size", "battery_capacity", "sensors", "warranty"],
        "Wall Adapter": ["connector_type", "output_wattage", "output_current", "warranty"],
        "Charging Cable": ["cable_connection_type", "suitable_for", "cable_length", "warranty"],
        "Wall Adapter and Cable Combo": ["wall_adapter_combo", "cable_connection_type", "suitable_for", "cable_length", "output_wattage", "output_current", "warranty"],
        "HDMI Cable": ["cable_type", "cable_speed", "suitable_for", "cable_length", "warranty"],
        "3.5mm Headphone Adapter": ["connector_type", "suitable_for", "cable_length", "warranty"],
        "Bluetooth speaker": ["bluetooth_version", "power_output", "battery_capacity", "power_input_type", "weight", "warranty"],
    };

    const hierarchyL2Values = [
        "Soundbar",
        "True Wireless Earbuds",
        "Bluetooth Neckband",
        "Bluetooth Headphones",
        "Wired Headphones",
        "Wired Earphones",
        "Powerbank",
        "Tablet",
        "Smart TV",
        "Laptop",
        "Wifi Smart Speaker",
        "Security Camera",
        "Smartphone",
        "Feature Phone",
        "Smartwatch",
        "Smart Band",
        "Wall Adapter",
        "Charging Cable",
        "Wall Adapter and Cable Combo",
        "HDMI Cable",
        "3.5mm Headphone Adapter",
        "Bluetooth speaker",
        "Miscellaneous",
    ];

    // advance payment, coming soon, complimentiry products, image sequence

    const handleFile = async (event) => {
        // resetting images
        setImagesObject({});
        setGalleryImagesObject({});

        // read file as a binary string
        const [file] = event.target.files;
        const fileReader = new FileReader();
        fileReader.onload = readEvt => {
            parseXLSXData(readEvt.target.result)
            event.target.value = "";
        };
        fileReader.readAsBinaryString(file);
    };

    // parses binary string to json data
    const parseXLSXData = binaryData => {

        try {
            const workBook = XLSX.read(binaryData, { type: 'binary' });
            const workSheetName = workBook.SheetNames[0];
            const workSheet = workBook.Sheets[workSheetName];
            const data = XLSX.utils.sheet_to_json(workSheet, { header: 1, defval: '', blankrows: false });
            processParsedData(data)
        } catch (err) {
            console.log(err)
        }
    };

    // intermediary processing of data from excel
    const processParsedData = (jsonData) => {
        const [headers, ...rowData] = jsonData;
        const productsData = rowData.map((columns) => {
            return columns.reduce((acc, col, idx) => {
                return acc = { ...acc, [headers[idx] ?? '']: col };
            }, {})
        })

        // based on selected upload type tranform the data and upload to api correspondingly
        if (uploadType === 'initialise') {
            transformDataForMaster(productsData, headers)
        } else {
            transformDataForCatalogue(productsData, headers);
        }
    }

    // validates and transforms the data to be uploaded for cataloguing
    const transformDataForCatalogue = (productRaw, headers = []) => {
        let hasError = false;
        const tProducts = [];

        for (const prod of productRaw) {
            // check for hierarchyl2 of product
            if (!hierarchyL2Values.includes(prod.hierarchyL2)) {
                toast.error(`Invalid Hierarchy L2 value for ${prod.title} product`);
                hasError = true;
            }

            // check for product price
            if (parseFloat(prod.mop) > parseFloat(prod.mrp)) {
                toast.error(`MOP (${prod.mop}) for ${prod.title} product cannot be greater than its MRP (${prod.mrp})`);
                hasError = true;
            }

            if(!prod?.weight) {
                toast.error(`Weight field is not found for ${prod.title}`);
                hasError = true;
            }

            const product = generateProductSchema(prod);

            // check for existence of required fields and add it to the product payload
            for (const prop of requiredFields[prod.hierarchyL2]?.concat(requiredMasterFields) ?? []) {
                // adds miscellaneous info about product from required fields
                if (!requiredMasterFields.includes(prop)) product.productInfo[prop] = prod[prop];

                if (!prod[prop]) {
                    hasError = true;
                    toast.error(`Required - ${prop} in ${prod.title} product!`);
                    break;
                }

                // const imeiReg = /^\d{15}$/g;
                // if (
                //     ["Smartphone", "Tablet"].includes(prod.hierarchyL2) &&
                //     !imeiReg.test(prod['serial_no'])
                // ) {
                //     hasError = true;
                //     toast.error(`Invalid IMEI number format for field serial_no in ${prod.title} product! `);
                //     break;
                // }
            }

            tProducts.push(product);

            if (hasError) break;
        }

        if (!hasError) {
            productPayloadRef.current = tProducts;
            setProductTable({ headers, rows: productRaw })
        }

    }

    // generates a product object that will be stored at the backend
    const generateProductSchema = (prod) => {
        const slug = `${prod.title}-${prod.color}-${prod.ean}`
            .toString()
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");

        return {
            slug,
            hierarchyL1: prod.hierarchyL1,
            hierarchyL2: prod.hierarchyL2,
            hierarchyL3: prod.hierarchyL3,
            articleNo: prod.articleNo,
            title: prod.title,
            ean: prod.ean,
            modelNo: prod.modelNo,
            modelName: prod.modelName,
            brand: prod.brand,
            color: prod.color,
            size: prod.size,
            price: {
                mrp: prod.mrp,
                mop: prod.mop,
            },
            HSNDetails: { HSN: prod.HSN, store_id: "" },
            currency: prod.currency,
            entity: prod.entity,
            length: prod.length || 1,
            width: prod.width || 1,
            height: prod.height || 1,
            description: prod.description,
            qty: prod.quantity || 0,
            productInfo: {},
            altProduct: {
                color: [],
                spec: [],
            },
            complimentoryCatgories: {
                immediate: [],
                later: [],
            },
            classification: prod?.classification ?? PRODUCT_CLASSIFICATION?.NORMAL,
            hsn: prod?.HSN ?? '',
            gstpercentage: prod?.gstpercentage ?? DEFAULT_GST_PERCENTAGE,
            weight: prod?.weight ?? 1
        };
    }

    // const handleProductSort = (elem) => {
    //   let slug = "";
    //   let dynamicHeader = "";
    //   let dynamicArray = [];
    //   let dynamicArray2 = [];
    //   let dyHeader = "";
    //   let dyHeader1 = "";

    //   // productInfo = {
    //   //   os: elem.os,
    //   //   ram: elem.ram,
    //   //   rom: elem.rom,
    //   //   series: elem.series,
    //   //   color: elem.color,
    //   //   itemHeight: elem.itemHeight,
    //   //   itemWidth: elem.itemWidth,
    //   //   standingScreenDisplaySize: elem.standingScreenDisplaySize,
    //   //   batteries: elem.batteries,
    //   //   hardwarePlatform: elem.hardwarePlatform,
    //   //   wirelessTech: elem.wirelessTech,
    //   //   connectivityTech: elem.connectiveTech,
    //   //   bluetoothVersion: elem.bluetoothVersion,
    //   //   numberOfItems: elem.numberOfItems,
    //   //   gps: elem.hasGPS,
    //   //   processorBrand: elem.processorBrand,
    //   //   processorSpeed: elem.processorSpeed,
    //   //   processorCount: elem.processorCount,
    //   //   compatibleDevices: elem.compatibleDevices,
    //   //   specialFeatures: elem.specialFeatures,
    //   //   mountingHardware: elem.mountingHardware,
    //   //   mountingType: elem.mountingType,
    //   //   displayFeatures: elem.displayFeatures,
    //   //   screenResolution: elem.screenResolution,
    //   //   displayTechnology: elem.displayTechnology,
    //   //   colorsDisplayed: elem.colorsDisplayed,
    //   //   otherDisplayFeatures: elem.otherDisplayFeatures,
    //   //   deviceInterface: elem.primaryDeviceInterface,
    //   //   cameraFeatures: elem.cameraFeatures,
    //   //   otherCameraFeatures: elem.otherCameraFeatures,
    //   //   speakerType: elem.speakerType,
    //   //   playTime: elem.playTime,
    //   //   peakPowerHandlingSpeakers: elem.peakPowerHandlingSpeakers,
    //   //   RMSPowerRangeAmplifiers: elem.RMSPowerRangeAmplifiers,
    //   //   speakerSurroundSoundChannelConfiguration: elem.speakerSurroundSoundChannelConfiguration,
    //   //   audioOutputMode: elem.audioOutputMode,
    //   //   speakerAmplificationType: elem.speakerAmplificationType,
    //   //   speakerConnectivity: elem.speakerConnectivity,
    //   //   rearWebcamResolution: elem.rearWebcamResolution,
    //   //   frontWebcamResolution: elem.frontWebcamResolution,
    //   //   microphoneFormFactor: elem.microphoneFormFactor,
    //   //   headphonesFormFactor: elem.headphonesFormFactor,
    //   //   microphoneTech: elem.microphoneTech,
    //   //   audioJack: elem.audioJack,
    //   //   formFactor: elem.formFactor,
    //   //   batteryPowerRating: elem.batteryPowerRating,
    //   //   batteriesIncluded: elem.batteriesIncluded,
    //   //   batteriesRequired: elem.batteriesRequired,
    //   //   batteryCellComposition: elem.batteryCellComposition,
    //   //   containsLiquidContents: elem.containsLiquidContents,
    //   //   includesRechargableBattery: elem.includesRechargableBattery,
    //   //   totalUsbPorts: elem.totalUsbPorts,
    //   //   numberOfPorts: elem.numberOfPorts,
    //   //   cableFeature: elem.cableFeature,
    //   //   powerSource: elem.powerSource,
    //   //   connectorType: elem.connectorType,
    //   //   cableType: elem.cableType,
    //   //   numberOfMemorySticks: elem.numberOfMemorySticks,
    //   //   ACAdapterCurrent: elem.ACAdapterCurrent,
    //   //   maximumOperatingDistance: elem.maximumOperatingDistance,
    //   //   material: elem.material,
    //   //   wattage: elem.wattage,
    //   //   productTalkTime: elem.talkTime,
    //   //   productStandbyTime: elem.standbyTime,
    //   //   dataTransferRate: elem.dataTransferRate,
    //   //   inTheBox: elem.inTheBox,
    //   //   modelYear: elem.modelYear,
    //   //   modelName: elem.modelName,
    //   //   modelNo: elem.modelNo,
    //   //   importedBy: elem.importedBy,
    //   //   country: elem.countryOrigin,
    //   //   specText: elem.specText,
    //   // };

    //   dynamicHeader =
    //     elem.productName +
    //     "( " +
    //     elem.color +
    //     (elem.color ? ", " + elem.color : "") +
    //     (elem.ram ? ", " + elem.ram : "") +
    //     (elem.rom ? ", " + elem.rom : "") +
    //     (elem.bluetoothVersion ? ", " + elem.bluetoothVersion : "") +
    //     (elem.speakerConnectivity ? ", " + elem.speakerConnectivity : "") +
    //     (elem.connectorType ? ", " + elem.connectorType : "") +
    //     (elem.compatibleDevices ? ", " + elem.compatibleDevices : "") +
    //     (elem.wattage ? ", " + elem.wattage : "") +
    //     (elem.playtime ? ", " + elem.playtime : "") +
    //     (elem.microphoneTech ? ", " + elem.microphoneTech : "") +
    //     ")";

    //   dyHeader = dynamicHeader.replace(/[.]/g, "-");
    //   dyHeader1 = dyHeader.replace(/[\(,\)]/g, "");
    //   dynamicArray = dyHeader1.split(" ");
    //   dynamicArray2 = dynamicArray.filter((n) => n);
    //   slug = dynamicArray2.join("-");
    // };

    function addImageProcess(src) {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => resolve({ width: img.width, height: img.height });
            img.onerror = reject;
            img.src = src;
        });
    }

    const imageHandleChange = async (e, type, eanNum) => {
        if (e.target.files) {
            let imgObj = {
                imgs: [],
                imgsUrl: [],
            };

            for (let i = 0; i < e.target.files.length; i++) {
                let url = URL.createObjectURL(e.target.files[i]);
                const imgData = await addImageProcess(url);

                if (type === "product_image" && (imgData.width !== 424 || imgData.height !== 424)) {
                    toast.error(`Image resolution must be 424×424px.  File name - ${e.target.files[i].name}`);
                    imgObj = {
                        imgs: [],
                        imgsUrl: [],
                    };
                    e.target.value = "";
                    break;
                }
                imgObj.imgs.push(e.target.files[i]);
                imgObj.imgsUrl.push(url);
            }

            if (type === "product_image") {
                if (imgObj.imgs.length > 5) {
                    e.target.value = "";
                    imgObj = {
                        imgs: [],
                        imgsUrl: [],
                    };
                    return toast.error("Maximum 5 Images can be selected!");
                }
                setImagesObject({ ...imagesObject, [eanNum]: imgObj });
            } else if (type === "gallery_image") {
                if (imgObj.imgs.length > 4) {
                    e.target.value = "";
                    imgObj = {
                        imgs: [],
                        imgsUrl: [],
                    };
                    return toast.error("Maximum 4 Images can be selected!");
                }
                setGalleryImagesObject({ ...galleryImagesObject, [eanNum]: imgObj });
            }
        }
    };

    const handleOpen = (e, imagesPassed, ean) => {
        e.preventDefault();
        setModalOpen(true);
        setModalData(imagesPassed);
        setSelectedEan(ean);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (uploadType === 'initialise') {
            initMasterUpload()
        } else {
            doCatalogueUpload();
        }

    };

    // invokes API to initialise product master
    const initMasterUpload = async () => {
        document.getElementById("submitCsvData").disabled = true;
        let apiRes = null
        try {
            if (productPayloadRef.current.length > 0) {
                apiRes = await initializeProductMaster(productPayloadRef.current);
            }
        } catch (err) {
            let errorMsg = err?.response?.data?.message ?? 'Something went wrong';
            toast.error(errorMsg);
        }

        const payload = apiRes.data;

        // products that were not uploaded
        if (payload?.productsFailed?.length > 0) {
            const failedEans = payload?.productsFailed?.map(({ ean }) => ean).join(', ')
            toast.error(`.Product upload failed for - ${failedEans}`);
            toast.error('Possible issues in product uploads could be duplicate EAN or any missing fields');
        }

        // show error if no products were uploaded
        if (!payload?.productsAdded?.length) {
            toast.error('Product master initialisation failed');
        }

        // bulk upload (partially or fully) of products is done
        // all products in excel were uploaded
        if (payload?.productsAdded?.length === productTable.rows.length) {
            toast.success('Product master initialised successfully!');
            nav("../admin-products");
        } else if (payload?.productsAdded?.length > 0 && payload?.productsAdded?.length !== productTable.rows.length) {
            toast.info('Product master initialised partially!');
        } else {
            document.getElementById("submitCsvData").innerHTML = "Submit";
            document.getElementById("submitCsvData").disabled = false;
        }
    }

    // Performs and invokes api to do product cataloguing
    const doCatalogueUpload = async () => {
        document.getElementById("submitCsvData").disabled = true;
        let apiRes = null
        try {
            if (productPayloadRef.current.length > 0) {
                apiRes = await updateMasterCatalogue(productPayloadRef.current);
            }
        } catch (err) {
            let errorMsg = err?.response?.data?.message ?? 'Something went wrong';
            toast.error(errorMsg);
        }

        const payload = apiRes.data;

        // products that were not uploaded
        if (payload?.productsFailed?.length > 0) {
            const failedEans = payload?.productsFailed?.map(({ ean }) => ean).join(', ')
            toast.error(`.Product upload failed for - ${failedEans}`);
            toast.error("Possible issues in product uploads could be duplicate EAN or any missing fields");
        }

        // show error if no products were uploaded
        if (!payload?.productsAdded?.length) {
            toast.error('Bulk products upload failed');
        }

        // bulk upload (partially or fully) of products is done
        // now upload corressponding images
        if (payload?.productsAdded?.length > 0) {
            document.getElementById("submitCsvData").innerHTML = "<p>Please Wait. Uploading Images... </p>";
            let productArray = payload.productsAdded;

            try {
                for (const ean in imagesObject) {
                    if (imagesObject[ean].imgs.length !== 0) {

                        const element = imagesObject[ean];
                        const product = productArray.find((item) => {
                            return item.ean.toString() === ean.toString();
                        });

                        if (product && element.imgs.length > 0) {
                            await addProductImages(product._id, element.imgs);
                        }
                    }
                }

                for (const ean in galleryImagesObject) {
                    if (galleryImagesObject[ean].imgs.length !== 0) {
                        const element = galleryImagesObject[ean];
                        const product = productArray.find((item) => {
                            return item.ean.toString() === ean.toString();
                        });

                        if (product && element.imgs.length > 0) {
                            await addProductGalleryImages(product._id, element.imgs);
                        }
                    }
                }
            } catch (err) {
                toast.error(err?.response?.data?.message ?? 'Something went wrong')
            }
        }

        // Partial upload of products was done
        if (payload?.productsAdded?.length > 0 && payload?.productsAdded?.length !== productTable.rows.length) {
            toast.info('Cateloguing done partially!')
        }

        // all products in excel were uploaded
        if (payload?.productsAdded?.length === productTable.rows.length) {
            toast.success('Product cataloguing completed successfully!')
            setTimeout(() => {
                nav("../admin-products");
            }, 1000)
        }

        document.getElementById("submitCsvData").innerHTML = "Submit";
        revokeObjectUrls(imagesObject, galleryImagesObject);
        revokeObjectUrls(imagesObject, galleryImagesObject);
        document.getElementById("submitCsvData").disabled = false;
    }

    // revokes previously created object urls
    const revokeObjectUrls = (imagesObject, galleryImagesObject) => {
        let objectUrls = [];

        // image object urls
        for (const prop in imagesObject) {
            objectUrls = objectUrls.concat(imagesObject[prop].imgsUrl);
        }

        // image object urls
        for (const prop in galleryImagesObject) {
            objectUrls = objectUrls.concat(galleryImagesObject[prop].imgsUrl);
        }

        if (objectUrls?.length > 0) {
            for (const url of objectUrls) {
                URL.revokeObjectURL(url);
            }
        }
    }

    // resets current state
    const reset = () => {
        const userConfirm = window.confirm('Are you sure you want to reset the form?');
        if (userConfirm) {
            setImagesObject({});
            setGalleryImagesObject({});
            setProductTable({ headers: [], rows: [] })
        }
    }

    // validates and transform data to be uploaded for intialising Product master
    const transformDataForMaster = (productRaw, headers) => {
        let hasError = false;
        const tProducts = [];

        for (let i = 0; i < productRaw.length; i++) {
            const prod = productRaw[i];
            const product = generateProductSchema(prod);

            // check for existence of required fields that are neccessary to initialize master
            for (const requiredProp of requiredMasterFields) {
                if (!prod[requiredProp]) {
                    hasError = true;
                    toast.error(`Required - ${requiredProp} in product - ${i + 1}!`);
                    break;
                }
            }

            tProducts.push(product);

            if (hasError) break;
        }

        if (!hasError) {
            productPayloadRef.current = tProducts;
            setProductTable({ headers, rows: productRaw })
        }
    }

    const changeUploadType = (key) => {
        const userConfirm = window.confirm('Are you sure you want to change tabs? This will reset the form');
        if (userConfirm) {
            setImagesObject({});
            setGalleryImagesObject({});
            setProductTable({ headers: [], rows: [] });
            setUploadType(key);
        }
    }

    function swapFirstElementWithIndex(array, index) {
        if (array.length > 0 && index >= 0 && index < array.length) {
          const temp = array[0];
          array[0] = array[index];
          array[index] = temp;
        }
        return array;
    }

    function onProductImageCLick(imageLink) {
        const arr = [];
        setImagesObject(prev => {
           const obj = prev[selectedEan];
           const obj2 = {
            imgs: [],
            imgsUrl: []
           }
           const index = obj.imgsUrl.findIndex(e => e === imageLink);
           obj2.imgsUrl = [...swapFirstElementWithIndex(obj.imgsUrl, index)];
           arr.push(...obj2.imgsUrl)
           obj2.imgs = [...swapFirstElementWithIndex(obj.imgs, index)];
           prev[selectedEan] = obj2
           return prev
        });

        setModalData(arr)
    }

    // Main renderer
    return (
        <>
            <div className="catelogue_Page section_Wrapper">
                <div className="offers_Toggle_Container mb-2" style={{ maxWidth: 'unset' }}>
                    {uploadTypes.map(({ key, label }) => (
                        <p
                            className={uploadType === key ? 'offer_Selected' : ''}
                            onClick={() => changeUploadType(key)}
                        >
                            {label}
                        </p>
                    ))}
                </div>

                <div className="catelogue_Page_Header">
                    <h4 className="catelogue_Page_Heading">{headingTitle[uploadType]}</h4>
                    <button
                        type="button"
                        className="btn-sm btn btn-danger"
                        disabled={!productTable.headers.length}
                        onClick={reset}
                    >
                        Reset
                    </button>
                </div>

                {uploadType === 'initialise' ? (
                    <div className="alert alert-info alert-dismissible fade show" role="alert">
                        Fields <strong>{requiredMasterFields.join(', ')}</strong> are absolutely required to initialise <strong className="text-capitalize">product</strong> master!
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <select
                                className="form-select"
                                onChange={e => setSelectedProdCategoory(e.target.value)}
                                aria-label="Default select example"
                            >
                                <option value=''>Select product category to view required fields</option>
                                {Object.keys(requiredFields).map(cat => (<option value={cat}>{cat}</option>))}
                            </select>
                        </div>
                        {selectedProdCategory  && (
                            <div className="col-md-12 mt-2">
                                <div className="alert alert-info alert-dismissible fade show" role="alert">
                                Fields <strong>{requiredFields[selectedProdCategory]?.join(', ')}</strong> are required!
                            </div>
                            </div>
                        )}
                    </div>
                )}

                {!productTable.headers.length && (
                    <div>
                        <input
                            style={{ height: 'revert' }}
                            type="file"
                            className="input-field"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(e) => handleFile(e)}
                        />
                    </div>
                )}

                {productTable.headers.length > 0 && (
                    <div>
                        <div className="catelogue_Table" 
                        >
                            <table>
                                <thead>
                                    <tr>
                                        {productTable.headers.map((item, index) => (
                                            <th key={index}>{item}</th>
                                        ))}
                                        {uploadType === 'catalogue' && (
                                            <>
                                                <th>
                                                    <p>Upload Product Images</p>
                                                    <p>Add Maximum 5 images</p>
                                                </th>
                                                <th>Image Files Preview</th>
                                                <th>
                                                    <p>Upload Product Gallery Images</p>
                                                    <p>Add Maximum 4 images</p>
                                                </th>
                                                <th>Image Files Preview</th>
                                            </>
                                        )}
                                    </tr>
                                </thead>

                                <tbody>
                                    {productTable.rows.map((row, rowIdx) => {
                                        return (
                                            <tr key={rowIdx}>
                                                {productTable.headers.map((col, colIdx) => {
                                                    return (
                                                        <td key={`${rowIdx}-${colIdx}`}>
                                                            {row[col] ?? ''}
                                                        </td>
                                                    )
                                                })}

                                                {uploadType === 'catalogue' && (
                                                    <>
                                                        <td>
                                                            <form action="" encType="multipart/form-data">
                                                                <input
                                                                    style={{ height: 'revert' }}
                                                                    type="file"
                                                                    name="Product Images"
                                                                    multiple
                                                                    id="productImageInput"
                                                                    className="input-field"
                                                                    accept="image/*"
                                                                    placeholder="Enter Product Images"
                                                                    onChange={(e) => imageHandleChange(e, "product_image", row['ean'])}
                                                                    disabled={imagesObject[row['ean']]?.imgs.length >= 5}
                                                                />
                                                            </form>
                                                        </td>
                                                        <td>
                                                            <div className={"button-Container"}>
                                                                <button
                                                                    type="submit"
                                                                    className="submit-button"
                                                                    onClick={(e) => handleOpen(e, imagesObject[row['ean']].imgsUrl, row['ean'])}
                                                                    disabled={!imagesObject[row['ean']]?.imgs.length}
                                                                >
                                                                    <p>Preview Images</p>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <input
                                                                style={{ height: 'revert' }}
                                                                type="file"
                                                                name="galleryImageInput"
                                                                multiple
                                                                id="Product Images"
                                                                className="input-field"
                                                                accept="image/*"
                                                                placeholder="Enter Product Gallery"
                                                                onChange={(e) => imageHandleChange(e, "gallery_image", row['ean'].toString())}
                                                                disabled={galleryImagesObject[row['ean']]?.imgs.length >= 4}
                                                            />
                                                        </td>
                                                        <td>
                                                            <div className={"button-Container"}>
                                                                <button
                                                                    type="submit"
                                                                    className="submit-button"
                                                                    onClick={(e) => handleOpen(e, galleryImagesObject[row['ean']].imgsUrl)}
                                                                    disabled={!galleryImagesObject[row['ean']]?.imgs.length}
                                                                >
                                                                    <p>Preview Images</p>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className={"button-Container"}>
                            <button
                                id="submitCsvData"
                                disabled={
                                    uploadType === 'catalogue'
                                        ? (Object.keys(imagesObject).length !== productTable.rows.length
                                            || Object.keys(galleryImagesObject).length !== productTable.rows.length)
                                        : !productTable.rows.length
                                }
                                type="submit"
                                className="submit-button"
                                onClick={handleSubmit}
                            >
                                <p>Submit</p>
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/** Image preview modal */}
            <CatelogueModal
                modalShow={modalOpen}
                setModalShow={setModalOpen}
                imageData={modalData}
                // onProductImageCLick={onProductImageCLick}
            />
        </>
    );
};

export default BulkUploadSection;
