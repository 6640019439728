import React, { useEffect } from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    addNewCardSection,
    getHomePageSections,
    updateCardSection,
} from "../../api/AdminApis/HomePageSections";
import useAdminCtx from "../../hooks/useAdminCtx";

const StaticCard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [id, setId] = useState(null);
    const [data, setData] = useState({
        sectionHeading: "",
        products: [],
        seeMoreUrl: "",
        priority: null,
    });

    const { allHomePageSections, setAllHomePageSections } = useAdminCtx();

    useEffect(() => {
        getHomePageSections((err, res) => {
            if (err) toast.error("Unable to Fetch the Sections properly");
            setAllHomePageSections(res.sections);
        });
    }, []);

    useEffect(() => {
        setData((prev) => ({
            ...prev,
            sectionHeading: location.state?.data?.sectionHeading ?? "",
            products: location.state?.data?.products ?? [],
            seeMoreUrl: location.state?.data?.moreInfo?.seeMoreUrl ?? "",
            priority: location.state?.data?.priority ?? null,
        }));
        setId(location.state?.data?._id);
    }, [location.state?.data]);

    const handleInputChnage = (e) => {
        setData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };
    const handleProductChange = (e) => {
        const value = e.target.name !== "priority" ? e.target.value.replace(/\s/g, "").split(",") : e.target.value;
        setData((prev) => {
            return { ...prev, [e.target.name]: value };
        });
    };

    const handleSubmit = (e) => {
        const newData = {
            sectionHeading: data.sectionHeading,
            products: data.products.filter((ean) => ean !== ""),
            sectionType: "static_card",
            moreInfo: {},
            priority: data.priority,
        };
        if (
            data.seeMoreUrl.match(/((\r\n|\n|\r)$)|(^(\r\n|\n|\r))|^\s*$/gm) ===
            null
        ) {
            newData.moreInfo["seeMoreUrl"] = data.seeMoreUrl.replace(/ /g, "");
        }
        if (id) {
            updateCardSection(newData, id).then((res) => {
                if (res) {
                    navigate("../admin-home-page");
                    toast.success("StaticCard Updated!!");
                } else toast.error("Oops! Something Went wrong");
            });
        } else {
            newData.priority =
               !data.priority ? (allHomePageSections.length === 0
                    ? 1
                    : allHomePageSections[allHomePageSections.length - 1]
                          .priority + 1) : data.priority;
            addNewCardSection(newData).then((res) => {
                if (res) {
                    navigate("../admin-home-page");
                    toast.success("StaticCard added!!");
                } else toast.error("Oops! Something Went wrong");
            });
        }
    };

    return (
        <div>
            <h2>Add Static Card Section</h2>
            <Form className="mt-4" encType="multipart/form-data">
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Section Heading *</Form.Label>
                    <Form.Control
                        type="text"
                        name="sectionHeading"
                        value={data.sectionHeading}
                        onChange={handleInputChnage}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                >
                    <Form.Label>
                        Products EAN numbers (comma separated) *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="products"
                        value={data.products?.join(",")}
                        onChange={handleProductChange}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                >
                    <Form.Label>See More Button URL (Optional)</Form.Label>
                    <Form.Control
                        type="text"
                        name="seeMoreUrl"
                        value={data.seeMoreUrl}
                        onChange={handleInputChnage}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                >
                    <Form.Label>Order (Optional)</Form.Label>
                    <Form.Control
                        type="number"
                        name="priority"
                        value={data?.priority}
                        onChange={handleProductChange}
                    />
                </Form.Group>
            </Form>
            <div className="my-5">
                <button
                    type="submit"
                    className="submit-button"
                    onClick={handleSubmit}
                >
                    <p>Submit</p>
                </button>
            </div>
        </div>
    );
};

export default StaticCard;
