import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PopperSignIn from "./PopperSignIn";

function MyProfileButton(props) {
  // POPUP
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClosePOP = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className="user_profile" ref={anchorRef} onClick={handleToggle}>
      {!props.isMobile && <p>{props.t('header.my-profile')}</p>}
      <img src={props.userDPPic} alt="profile-pic" />
      <span
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
      >
        <ExpandMoreIcon sx={{ color: "white", cursor: "pointer" }} />
      </span>
      <PopperSignIn
        isMobile={props.isMobile}
        open={open}
        current={anchorRef.current}
        handleClosePOP={handleClosePOP}
        handleListKeyDown={handleListKeyDown}
      />
    </div>
  );
}

export default MyProfileButton;
