import React from "react";
import { Button, Modal } from "react-bootstrap";
import { updateCupon } from "../../api/AdminApis/Cupon";
import { toast } from "react-toastify";

const EditCuponModal = (props) => {
    const { data } = props;
    const id = data._id;

    const submitHandle = async (e) => {
        e.preventDefault();
        const submitData = {
            discount: e.target.discount.value,
            maxAmount: e.target.maxAmount.value,
            expire: e.target.expire.value,
            products: e.target.products.value.split(','),
            code: e.target.code.value,
        };

        const response = await updateCupon(id, submitData);
        if (response.status === 'success') {
            toast.success('Coupon updated successfully!');
        }
        props.onHide();
    };
    
    // computed
    let expiryDate = new Date(data.expire);
    if (expiryDate.toString() !== "Invalid Date") {
        expiryDate = expiryDate.toISOString()?.substring(0, 10);
    }

    // Main renderer
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className=""
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Coupon
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitHandle}>
                        <input
                            className="input-field mt-2"
                            type="number"
                            placeholder="Discount"
                            defaultValue={data.discount}
                            name="discount"
                            required
                        />
                        <input
                            className="input-field mt-2"
                            type="number"
                            placeholder="Max Amount"
                            defaultValue={data.maxAmount}
                            name="maxAmount"
                            required
                        />
                        <input
                            className="input-field mt-2"
                            disabled
                            readOnly
                            type="text"
                            defaultValue={data.code}
                            placeholder="Code"
                            name="code"
                            required
                        />
                        <p className="catalogue_Hint">Expire in</p>
                        <input
                            type="date"
                            className="input-field"
                            defaultValue={expiryDate}
                            name="expire"
                            required
                        />
                        <input
                            className="input-field mt-2"
                            placeholder="Enter Product EAN"
                            type="number"
                            name="products"
                            defaultValue={data.products}
                        />
                        <div className="d-flex justify-content-end">
                            <Button
                                type="submit"
                                className="btn btn-primary mr-auto float-right mt-2 px-5"
                            >
                                Update
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditCuponModal;
