import React, { useCallback, useEffect, useRef, useState } from "react";
import "./findStore.css";
import FindStoreMap from "./findStoreMap";
import FindStoreContact from "./findStoreContact";
import { getAllStores, getAllStoresLatAndLong, getStoreUsingSearchKey } from "../../api/StoreApi";
import Loader from "../../components/Loader/Loader";
import useGeolocation from "../../hooks/useGeolocation";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import useUserCtx from "../../hooks/useUserCtx";
import locationWarningYellowIcon from "../../assets/vector/location_warning_yellow.svg";
import { Link, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

export default function FindStore({ storeLocations, setStoreLocations }) {
  const { location, locationFetch } = useGeolocation();
  const { setUserLocation } = useUserCtx();
  const routeLocation = useLocation();

  const { t } = useTranslation();
  const [storeSearchKey, setStoreSearchKey] = useState("");
  const [debounceQuery, setDebounceQuery] = useState("");
  const [showStore, setShowStore] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [totalStores, setTotalStores] = useState(0);
  const storeLimitPerPage = 20;
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [stores, setStores] = useState([]);

  const [storeLatLong, setStoreLatLang] = useState([]);

  const breadCrumbsData = [
    {
      text: t('home') + " / ",
      url: "/",
      isActive: routeLocation.pathname === "/"
    },
    {
      text: t('storeFinder'),
      url: "/store-finder",
      isActive: routeLocation.pathname === "/store-finder"
    },
  ];

  useEffect(() => window.scrollTo(0, 0), [])

  useEffect(() => {
    setShowStore(false);
    setShowLoader(true);
    if (storeSearchKey.toString().length !== 0) return
    const delay = 400;
    const timer = setTimeout(() => {
      getAllStores(storeLimitPerPage, pageNumber).then(res => {
        if (res?.status === "success") {
          setStoreLocations(prev => ({
            ...prev,
            loaded: true,
            no_of_stores: prev?.no_of_stores + (res?.data?.no_of_stores ?? 0),
            stores: [...prev.stores, ...res?.data?.stores?.results]
          }));
          setStores(prev => [...prev, ...res?.data?.stores?.results]);
          setTotalStores(res?.data?.stores?.total);
          setHasMore(res?.data?.stores?.next ? true : false);
          if (!res?.data?.stores?.next) {
            setHasMore(false);
          }
        }
      }).finally(() => {
        setShowStore(true);
        setShowLoader(false);
      })
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [pageNumber, storeSearchKey])

  useEffect(() => {
    if (location.loaded && location.error) {
      setShowStore(false);
    } else if (location.loaded && location.coordinates) {
      setUserLocation({
        loaded: true,
        useThis: false,
        coordinates: location.coordinates,
        address: location.address,
      });
      getStoreUsingSearchKey(location.address.zip).then((res) =>
        res
          ? (setStoreLocations({
            loaded: true,
            no_of_stores: res?.stores?.length,
            stores: res?.stores,
          }),
            setStores(res?.stores),
            setTotalStores(res?.stores?.length),
            setShowStore(true),
            setShowLoader(false))
          : ""
      );
    }
  }, [location]);

  const handleStoreSelectButton = () => {
    locationFetch();
    setShowLoader(true);
  };

  const refreshPage = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    setPageNumber(1);
    setStores([])
    const timeOutId = setTimeout(() => {
      if (storeSearchKey) {
        setShowLoader(true);
        setDebounceQuery(storeSearchKey);
      }
    }, 700);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [storeSearchKey]);

  const Caller = useCallback((value) => {
    if (!value) return
    setShowStore(false);
    setShowLoader(true);
    getStoreUsingSearchKey(value).then((res) => {
      if (res.status === "success") {
        setStoreLocations({
          loaded: true,
          no_of_stores: res?.stores?.length ?? 0,
          stores: res?.stores ?? [],
        });
        setStores(res?.stores ?? []);
        setTotalStores(res?.stores?.length)
      } else setStoreLocations({
        loaded: true,
        no_of_stores: 0,
        stores: [],
      });
    })
      .finally(() => {
        setShowStore(true);
        setShowLoader(false);
      })
  }, [])

  useEffect(() => {
    Caller(debounceQuery)
  }, [debounceQuery]);

  const handleInputChange = (e) => {
    setStoreSearchKey(e.target.value)
    if (e.target.value === "") setShowStore(false)
  };

  const observer = useRef();
  const lastStoreRef = useCallback(node => {
    if (showLoader) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prev => prev + 1);
      }
    }, {
      root: null, // Use the viewport as the root
      threshold: 0.2, // 20% of the target visible
    })
    if (node) observer.current.observe(node)
  }, [showLoader, hasMore]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllStoresLatAndLong();
        if (response?.status === "success") {
          setStoreLatLang(response?.data);
        } else {
          toast.error("Something went wrong in Fetching Stores.");
        }
      } catch (error) {
        toast.error("Oops! Something went wrong");
      } 
    };

    fetchData();

    return () => {
      setStoreLatLang([]);
    }
  }, []);

  return (
    <div className="findStoreContainer">
      <h1 className="findStoreTitle">
        {breadCrumbsData.map((item, index) =>
          <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>{item.text}
          </Link>)}</h1>
      <div id="outermostDiv">
        <h1 className="findStoreMapTitle">{t("store.find-store-title")}</h1>
        <p className="findStoreMapInputTitle">{t("store.nearest-store")}</p>
        <div className="findStoreMapSearchContainer">
          <div className="findStoreMapSearch">
            <SearchIcon className="findStoreMapSearchIcon" />
            <input
              type="search"
              placeholder={t("store.placeholder")}
              className="findStoreMapSearchbar"
              value={storeSearchKey}
              onChange={handleInputChange}
            />
          </div>
        </div>
        {location && location.loaded && location.error && (
          <>
            <div className="location_Alert">
              <img src={locationWarningYellowIcon} alt="" />
              <p>
                {t('store.enable-from-browser')}
              </p>
            </div>
            <div className="location_Refresh">
              <p>{t('store.refresh-page')}</p>
              <div className="location_Refresh_Button">
                <button
                  type="submit"
                  className="submit-button"
                  onClick={refreshPage}
                >
                  <p>{t('store.refresh')}</p>
                </button>
              </div>
            </div>
          </>
        )}
        <div className="store_Page_Separtor store_Finder_Separtor" >
          <span className="hr_Line"></span>
          <p>{t('store.or')}</p>
          <span className="hr_Line"></span>
        </div>
        <div className="submit_Button_2 store_Finder_Submit_Button" style={{
          marginBottom: "1rem"
        }}>
          <button
            type="submit"
            className="submit-button"
            onClick={handleStoreSelectButton}
          >
            <p>{t('store.show-stores')}</p>
          </button>
        </div>
        {showLoader && (
          <div className="store_Finder_Loader">
            <Loader />
          </div>
        )}
        {!location.error ?
          <>
            {totalStores > 0 ?
              <>
                <FindStoreMap stores={storeLatLong} />
                <FindStoreContact stores={stores} totalStores={totalStores} storeSearchKey={storeSearchKey} lastStoreRef={lastStoreRef} showStore={showStore} />
                {
                  hasMore && !storeSearchKey &&
                  <div className="loader-div"><CircularProgress color="inherit" /></div>
                }
              </>
              :
              <div className="nostore_Found_Text">
                <p>{t('store.no-store')}</p>
              </div>
            }
          </> : null
        }
      </div >
    </div >
  );
}