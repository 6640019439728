// top level imports
import React from "react";
import { toast } from "react-toastify";

// React-Router
import { Link, useParams } from "react-router-dom";

// i18n
import { useTranslation } from "react-i18next";

// React-Select
import { default as Select, components } from "react-select";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";

// Utils
//API
import { allUsers } from "../../api/AdminApis/Users";
import { getNotification, sendNotification } from "../../api/AdminApis/NotificationApi";


// Email Template component
function EmailTemplate( {notification, onnotificationChange, isView} ) {
    const { t } = useTranslation();

    return (
        <>
            <div className="form-field mb-3">
                <input
                    type="text"
                    name="subject"
                    className="input-field"
                    placeholder={`${t('email', 'Email')} ${t('subject', 'Subject')}`}
                    value={notification.subject}
                    onChange={onnotificationChange}
                    disabled={isView}
                />
                <p className="catalogue_Hint">{`${t('email', 'Email')} ${t('subject', 'Subject')}`}</p>
            </div>

            <div className="form-field">
                <textarea
                    type="text"
                    name="message"
                    placeholder={`${t('email', 'Email')} ${t('message', 'Message')}`}
                    className="support_Form_Input support_Form_Query mb-0"
                    value={notification.message}
                    onChange={onnotificationChange}
                    disabled={isView}
                />
                <p className="catalogue_Hint">{`${t('email', 'Email')} ${t('message', 'Message')}`}</p>
            </div>
        </>
    )
}

// Phone Template component
function PhoneTemplate({ notification, onnotificationChange, isView }) {
    const { t } = useTranslation();

    return (
        <div className="form-field">
            <textarea
                type="text"
                name="message"
                id="message"
                placeholder={`${t('message', 'Message')}`}
                className="support_Form_Input support_Form_Query mb-0"
                value={notification.message}
                onChange={onnotificationChange}
                disabled={isView}
            />
            <p className="catalogue_Hint">{t('message', 'Message')}</p>
        </div>
    )
}

function Option(props) {
    return (
        <div className="form-check">
            <components.Option {...props}>
                <input
                    id={props.value}
                    type="checkbox"
                    className="form-check-input"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <label className="form-check-label" htmlFor={props.value}>{props.label}</label>
            </components.Option>
        </div>
    );
}

// component definition
export function AdminNotificationsCreate() {
    const { t } = useTranslation();

    // Constants
    const notificationMediums = [
        { key: 'email', label: t('email') },
        { key: 'phone', label: t('phone') }
    ];

    // state definitions
    const [notificationMedia, setNotificationMedia] = React.useState('email');
    const [notification, setNotification] = React.useState({
        subject: '',
        message: ''
    });
    const [users, setUsers] = React.useState([]);
    const [recipients, setRecipients] = React.useState([]);
    const [willBroadcast, setWillBroadcast] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    //Miscellaneous hooks
    const { id } = useParams();
    const isView = id !== "create";

    // ComponentDidMount
    React.useEffect(() => {
        fetchUsers();
    }, [])

    // ComponentDidMount and ComponentDidUpdate
    React.useEffect(() => {
        fetchNotificationDetails(id);
    }, [id])


    // Computed
    const userOptions = users.map((user) => ({
        value: user._id, label: user.fullName, email: user.email, mobileNumber: user.mobileNumber
    }))

    /** Handler functions - starts */
    const fetchUsers = async () => {
        setLoading(true)
        try {
            const response = await allUsers();
            setUsers(response.users);
        } finally {
            setLoading(false);
        }
    }

    const handleBroadcastStatusChange = event => {
        setWillBroadcast(event.target.checked);
    }

    const onnotificationChange = (event) => {
        const { name, value } = event.target;
        setNotification(prev => ({ ...prev, [name]: value }));
    }

    const handleReceipentSelection = (selected) => {
        setRecipients(selected);
    }

    // Submit handler for the notification form
    const handleSubmit = async () => {
        // Initialize payload
        const payload = {
            ...notification,
            recipients
        }

        if (notificationMedia === "phone") delete payload.subject;

        if (willBroadcast) payload.recipients = 'all';

        setLoading(true)
        try {
            // call service to send notification
            const response = await sendNotification(payload);

            if (response.status === "success") {
                toast.success(response.data)
            } else {
                toast.error("Something went wrong!");
            }
        } finally {
            setLoading(false);
        }
    }

    const fetchNotificationDetails = async (id) => {
        setLoading(true);
        try {
            const response = await getNotification(id);
            if (response.status === "success") {
                const notificationRes = response.data;
                setNotification({ message: notificationRes.message, subject: notificationRes.subject });
                setNotificationMedia(notificationRes.medium);
                setRecipients(notificationRes.recipients);
                setWillBroadcast(notificationRes.recipients === "all");
            } else {
                toast.error('Notification not found!')
            }
        } finally {
            setLoading(false);
        }
    }

    /** Handler functions - ends */
    
    // Main renderer
    return (
        <div className="container">
            {loading ? (
                <DashboardLoader />
            ) : (
                <>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <Link className="me-2" title="Go back to notification list" to="../admin-notifications">
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </Link>
                            <h4>{isView ? t('view') : t('create')} {t('notification')} </h4>
                        </div>
                        {/** Media Togglers */}
                        {!isView && (
                            <div className="offers_Sort_Header">
                                <div className="offers_Toggle_Header">
                                    <div className="offers_Toggle_Container">
                                        {notificationMediums.map(({ key, label }) => (
                                            <p
                                                className={`${notificationMedia === key ? "offer_Selected" : ""}`}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => setNotificationMedia(key)}
                                            >
                                                {label}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="bg-white rounded mt-3 p-4">
                        <div className="row mb-2">
                            <div className="col-md-2">
                                <h5>{notificationMediums.find(el => el.key === notificationMedia)?.label}</h5>
                            </div>
                            <div className="col-md-3">
                                <div className="form-check">
                                    <input
                                        id="broadcast-checkbox"
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={willBroadcast}
                                        onChange={handleBroadcastStatusChange}
                                        disabled={isView}
                                    />
                                    <label className="form-check-label" htmlFor="broadcast-checkbox">
                                        {t('broadcast')}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {!willBroadcast && (
                                    <>
                                        <label>Select users to which notification should be sent</label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isMulti
                                            options={userOptions}
                                            closeMenuOnScroll={false}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            components={{
                                                Option
                                            }}
                                            onChange={handleReceipentSelection}
                                            value={recipients}
                                            isDisabled={isView}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                        {notificationMedia === 'email' ? (
                            <EmailTemplate
                                notification={notification}
                                onnotificationChange={onnotificationChange}
                                isView={isView}
                            />
                        ) : (
                            <PhoneTemplate
                                notification={notification}
                                onnotificationChange={onnotificationChange}
                                isView={isView}
                            />
                        )}

                        {/** Save/Cancel buttons */}
                        {!isView && (
                            <div className="text-end mt-1">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                    disabled={
                                        (!willBroadcast && !recipients.length) ||
                                        !notification.message
                                    }
                                >
                                    {t('save')}
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}

        </div>
    )
};
