import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import SkeletonElement from "../Skeletons/SkeletonElement";
// import { Carousel } from 'react-responsive-carousel';

//CSS
import "./Section1.css";
import useSiteCtx from "../../hooks/useSiteCtx";

const Section1 = ({ id }) => {
    const { homePageSections } = useSiteCtx();

    const [carouselData, setCarouselData] = useState(
        () => homePageSections.products
    );

    useEffect(() => {
        let topBanners = homePageSections.find(
            (section) => section.sectionType === "scroll_banner"
        );
        if (!topBanners) return;
        setCarouselData(topBanners.products);
    }, [homePageSections]);

    return (
        <div className="section1_contianer" id={id}>
            {
                carouselData?.length > 0
                    ?
                    <Carousel interval={5000}>
                        {
                            carouselData?.map((item, index) => (
                                <Carousel.Item key={index}>
                                    {item.imgUrl.split(".").pop() !== "mp4" ? (
                                        <a
                                            title={item.title}
                                            href={item.targetUrl}
                                            rel="noreferrer"
                                        >
                                            <img
                                                className="d-block w-100 carousel_image"
                                                src={item.imgUrl}
                                                alt={item.title}
                                            />
                                        </a>
                                    ) : (
                                        <div className="video-container">
                                            <video controls autoPlay loop playsInline muted className="carousel_video">
                                                <source src={item.imgUrl} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    )}
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                    :
                    <SkeletonElement type={"productBanner"} />
            }
        </div>
    );
};

export default Section1;
