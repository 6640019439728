import axios from "../axiosInstance";

export const getHomePageSections = async (cb) => {
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}/product/home-page-sections`)
    .then((res) => {
      cb(null, res.data.data);
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const deleteHomePageSection = async (id, cb) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };

  await axios
    .delete(`${process.env.REACT_APP_BASE_URL}/product/home-page-sections/${id}`, { headers })
    .then((res) => {
      cb(null, res.data.data);
    })
    .catch((err) => {
      cb(err, null);
    });
};
export const addNewCardSection = async (data) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  let addResponse;
  try {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/product/admin/home-page/card-section`, data, { headers })
      .then(res => addResponse = res)
        return addResponse;
  } catch (error) {
    console.error(error)
  }
}

export const updateCardSection = async (data, id) => {
  try {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };
    let updateResponse;
    await axios
      .patch(`${process.env.REACT_APP_BASE_URL}/product/admin/home-page/card-section/${id}`, JSON.stringify(data), { headers })
      .then(res => updateResponse = res)
      return updateResponse;
  } catch (error) {
    console.error(error)
  }
  
};


export const addNewStaticBannerSection = async (data) => {
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
  };
 
  try {
    let addResponse;
    await axios
    .post(`${process.env.REACT_APP_BASE_URL}/product/admin/home-page/static-banner-section`, data, { headers })
    .then(res => addResponse = res)
      return addResponse;
  } catch(err) {
    console.log(err)
  }

};

export const updateStaticBannerSectionImage = async (data, id) => {
  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
  };
   try {
    let updateResponse;
     await axios
       .patch(`${process.env.REACT_APP_BASE_URL}/product/admin/home-page/static-banner-section/image/${id}`, data, { headers })
       .then(res => updateResponse = res)
       return updateResponse;
   } catch(err) {
    console.error(err)
   }
  
};

export const updateStaticBannerSection = async (data, id) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
   try {
    let updateResponse;
     await axios
       .patch(`${process.env.REACT_APP_BASE_URL}/product/admin/home-page/static-banner-section/${id}`, JSON.stringify(data), { headers })
       .then(res => updateResponse = res)
       return updateResponse;
   } catch(err) {
    console.error(err)
   }
  
};

// Deals/Offers Banner Section
export const addNewStaticDealsSection = async (data) => {
  let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adminToken}`,
  };
  try {
    let addResponse;
    await axios
    .post(`${process.env.REACT_APP_BASE_URL}/product/admin/home-page/static-deals-section`, data, { headers })
    .then(res => addResponse = res)
      return addResponse;
  } catch(err) {
    console.log(err)
  }
};

export const updateStaticDealsSection = async (id, data) => {
  try {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };
    let updateResponse;
    await axios
      .patch(`${process.env.REACT_APP_BASE_URL}/product/admin/home-page/static-deals-section/${id}`, JSON.stringify(data), { headers })
      .then(res => updateResponse = res)
      return updateResponse;
  } catch (error) {
    console.error(error)
  }
  
};

export const updateStaticDealsSectionImage = async (id, images, indexes) => {
  try {
    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
    };
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
        formData.append("gallery", images[i]);
    }
    formData.append("indexes", indexes);
    let updateResponse;
    await axios
      .patch(`${process.env.REACT_APP_BASE_URL}/product/admin/home-page/static-deals-section/updateimages/${id}`,  formData, { headers })
      .then(res => updateResponse = res)
      return updateResponse;
  } catch (error) {
    console.error(error)
  }
  
};

export const addStaticDealsSectionImages = async (id, data) => {
  try {
    const headers = {
      "Access-Control-Allow-origin": "*",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
      "Content-Type": "application/json",
    };
  
    const formData = new FormData();
    for (let i = 0; i < data.length; i++) {
        formData.append("gallery", data[i]);
    }
    let addResponse;
    await axios.patch(`${process.env.REACT_APP_BASE_URL}/product/admin/home-page/static-deals-section/images/${id}`, formData, { headers })
    .then(res => addResponse = res)
      return addResponse;
  } catch(err) {
    console.log(err)
  }
};

export const deleteDealsImages = async (id,  deleteImages) => {
  let deleteImagesResponse;

  const headers = {
    "Access-Control-Allow-origin": "*",
    "Content-Type": "application/json",
  };
 
  if(deleteImages?.length > 0) {
     let obj = {
      delete: deleteImages
     }
  
  await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/admin/home-page/static-deals-section/deleteImages/${id}`, { headers, data: JSON.stringify(obj) }).then((res) => {
      deleteImagesResponse = res;
  });
}

  return deleteImagesResponse;
};
