import React, { useEffect, useState } from "react";
import { getNewArrivalProductsForHomePage, getProductsFromEans } from "../../api/Product";
import Products from "../Products/Products";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Section4 = ({ id, heading, products, targetUrl, isVisible }) => {
  const [productArray, setProductArray] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isVisible) return;
        if (heading === "NEW ARRIVALS") {
          const res = await getNewArrivalProductsForHomePage();
          if (res.status === "success") {
            setProductArray(res.data.products);
          }
        } else {
          const res = await getProductsFromEans(products);
          if (res.status === "success") {
            setProductArray(res.data.products);
          }
        }
      } catch (error) {
        toast.error(t("writeToUs.error"));
      }
    };

    fetchData();
  }, [isVisible]);

  return (
    <div className="section4_container" id={id}>
      <div className="section4_card_container1">
        <Products productArray={productArray} heading={heading} targetUrl={targetUrl} />
      </div>
    </div>
  );
};

export default Section4;
