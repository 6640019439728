import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';

import "./styles/dashboardProducts.css";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import { deleteProductAPI, getAllAdminProducts, toggleLiveStatus } from "../../api/Product";
import EditProductModal from "../../components/EditProductModal/EditProductModal";
import Pagination from "../../components/Pagination/Pagination";
import { toast } from "react-toastify";
import {currencyFormat, formatDate} from "../../utils/index"

// Component definition
function DashboardProducts() {
    const nav = useNavigate();

    // state definitions
    const [loader, setLoader] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [indProduct] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProducts, setTotalProducts] = useState(1);
    const [searchText, setSearchText] = useState("");

    const productsPerPage = 40;

    //set the loader and get products
    useEffect(() => {
        setLoader(true);
        //the function to get products
        fetchPaginatedProducts();
    }, [currentPage]);

    /* const handleEditProd = (id) => {
        setModalShow(true);
        //the function to get products
        getIndiProduct(id).then((res) => {
            setIndProduct(res);
        });
    }; */

    const fetchPaginatedProducts = () => {
        getAllAdminProducts(`search=${searchText}&limit=${productsPerPage}&page=${currentPage}`).then((res) => {
            setAllProducts(res.products);
            setTotalProducts(res.total_products);
            setLoader(false);
        });
    }

    const deletProduct = async (id) => {
        let text = "Are you sure for delete?";
        if (window.confirm(text) === true) {
            deleteProductAPI(id).then((res) => {
                if (res?.status === 401) {
                    toast.error("Unauthorized");
                    localStorage.removeItem(process.env.REACT_APP_ADMIN_TOKEN_KEY);
                    nav("/admin-login");
                } else setAllProducts(allProducts.filter((message) => message._id !== id));
            });
        }
    };

    // handles go live toggle - calls api to change go live status
    const handleGoLiveToggle = async (event) => {
        const { name: productId, checked } = event.target;
        setLoader(true);
        try {
            const response = await toggleLiveStatus(productId);
            const payload = response.data;
            if (payload.status === "success") {
                toast.success(`Product live status ${checked ? 'activated' : 'deactivated'}`)
                fetchPaginatedProducts();
            }
        } catch (err) {
            toast.error(err.response?.data?.message ?? 'Something went wrong!')
        } finally {
            setLoader(false);
        }
    }

    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    function handleSearchSubmit() {
        setLoader(true);
        fetchPaginatedProducts();
    }

    return loader ? (
        <DashboardLoader />
    ) : (
        <>
            <EditProductModal show={modalShow} onHide={() => setModalShow(false)} data={indProduct} />
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h3>Products</h3>
                    <span className="d-flex justify-content-center align-items-center">
                        <TextField type="search" id="outlined-basic" size="small" className="me-2" label="Product Name" onChange={(e) => setSearchText(e.target.value)} value={searchText} variant="outlined" />
                        <Button className="btn-sm" onClick={handleSearchSubmit}>Search</Button>
                    </span>
                    <div className="d-flex flex-row" style={{ gap: "10px" }}>
                        <Button className="btn-sm" onClick={() => nav("../admin-add-product")} style={{ marginBottom: 20 }}>
                            Add product
                        </Button>
                        <Button className="btn-sm" onClick={() => nav("../admin-add-product-csv")} style={{ marginBottom: 20 }}>
                            Add CSV File
                        </Button>
                        <Button className="btn-sm" onClick={() => nav("../admin-products-ean")} style={{ marginBottom: 20 }}>
                            Check New EAN
                        </Button>
                        <Button className="btn-sm" onClick={() => nav("../admin-update-product-csv")} style={{ marginBottom: 20 }}>
                            Update Products CSV
                        </Button>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">S. No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">EAN</th>
                                <th scope="col">Price MRP</th>
                                <th scope="col">Price MOP</th>
                                <th scope="col">Uploaded At</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {allProducts?.length > 0 &&
                                allProducts?.map((item, index) => (
                                    <tr key={index} className={`${!item.goLive ? 'bg-light' : ''}`}>
                                        <td>{((currentPage - 1 )* productsPerPage) + index + 1}</td>
                                        <td className={`${!item.goLive ? 'bg-light' : ''}`}>{item?.title}</td>
                                        <td>{item?.ean}</td>
                                        <td>{currencyFormat(item?.price?.mrp)}</td>
                                        <td>{currencyFormat(item?.price?.mop)}</td>
                                        <td>{formatDate(item?.createdAt)}</td>
                                        {/* <td className="text-center">
                                            <div className="d-flex justify-content-center form-check form-switch">
                                                <input
                                                    checked={item.goLive}
                                                    className="form-check-input fs-4"
                                                    type="checkbox"
                                                    role="switch"
                                                    name={item._id}
                                                    onChange={handleGoLiveToggle}
                                                />
                                            </div>
                                        </td> */}
                                        <td>
                                            <Link to={"/admin/admin-add-product"} state={(item = { item })} className="btn p-0">
                                                <FontAwesomeIcon className={"table-icon"} icon={faPenToSquare} />
                                            </Link>
                                            <button className="btn p-0" onClick={() => deletProduct(item.item._id)}>
                                                <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                        {!allProducts.length && !loader && (
                            <tbody>
                                <tr>
                                    <td className="text-center" colSpan={6}>No products found!</td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
                <div className="pagination_Container">
                    <Pagination productsPerPage={productsPerPage} totalProducts={totalProducts} pageChange={handlePageChange} currentPage={currentPage} />
                </div>
            </div>
        </>
    );
}

export default DashboardProducts;
