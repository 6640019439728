import React, { useEffect, useState } from "react";
import { getAdminAllOrder } from "../../api/AdminApis/AdminOrder";
import { getAllStore } from "../../api/AdminApis/AdminStore";
import { allUsers } from "../../api/AdminApis/Users";
import { getAllProducts } from "../../api/Product";
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import "./styles/dashboardHome.css"
import { useAdminOrRetailer } from "../../utils/useAdminOrRetailer";

// Component definition
function DashBoardHome() {
    // state definitions
    const [order, setOrder] = useState(0);
    const [loader, setLoader] = useState(true);
    const [user, setUser] = useState(0);
    const [products, setProducts] = useState(0)
    const [stores, setStores] = useState(0);

    // hooks
    const admin = useAdminOrRetailer(process.env.REACT_APP_ADMIN_TOKEN_KEY);

    useEffect(() => {
        if (!admin) return;

        setLoader(true);
        getAdminAllOrder().then((res) => {
            setOrder(res?.total_orders);
            setLoader(false);
        });
    }, []);

    useEffect(() => {
        if (!admin) return;

        setLoader(true);
        allUsers().then((res) => {
            if (res) {
                setUser(res.total_users);
            }
        }).finally(() => setLoader(false))
    }, []);

    useEffect(() => {
        if (!admin) return;

        setLoader(true)
        getAllProducts().then((res) => {
            setProducts(res.total_products)
            setLoader(false)
        })
    }, [])

    useEffect(() => {
        if (!admin) return;
        
        setLoader(true)
        getAllStore().then(res => {
            if (res) {
                setStores(res.total_stores)
            }
        }).finally(() => setLoader(false))
    }, [])

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <div className="card-counter primary">
                        <i className="fa fa-code-fork"></i>
                        <span className="count-numbers">{loader ? <DashboardLoader /> : order}</span>
                        <span className="count-name">Total Orders</span>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="card-counter danger">
                        <i className="fa fa-ticket"></i>
                        <span className="count-numbers">{loader ? <DashboardLoader /> : products}</span>
                        <span className="count-name">Products</span>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="card-counter success">
                        <i className="fa fa-database"></i>
                        <span className="count-numbers">{loader ? <DashboardLoader /> : stores}</span>
                        <span className="count-name">Stores</span>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="card-counter info">
                        <i className="fa fa-users"></i>
                        <span className="count-numbers">{loader ? <DashboardLoader /> : user}</span>
                        <span className="count-name">Users</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashBoardHome;
