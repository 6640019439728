// top level imports
import React from "react";
// Moment
import Moment from "react-moment";
// i18n
import { useTranslation } from "react-i18next";
// Toast
import { toast } from "react-toastify";

// React-Router
import { useNavigate } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEye } from "@fortawesome/free-solid-svg-icons";

// Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import Pagination from "../../components/Pagination/Pagination";

// Utils
import { truncatedText } from "../../utils";
// API
import { getNotifications, deleteNotification } from "../../api/AdminApis/NotificationApi";

// Component definition
export function AdminNotifications() {
    // translation function
    const { t } = useTranslation();

    // state definitions
    const [notifications, setNotifications] = React.useState({});
    const [currentPage, setCurrentPage] = React.useState(1);
    const [loading, setLoading] = React.useState([]);

    // ComponentDidMount
    React.useEffect(() => {
        fetchNotifications(currentPage);
    }, [currentPage]);

    /** Handler functions - starts */

    const fetchNotifications = async (currentPage) => {
        const query = `limit=${10}&page=${currentPage}`;
        setLoading(true);
        try {
            const response = await getNotifications(query);

            if (response?.status === "success") {
                setNotifications(response.data);
            } else {
                // show error
            }
        } finally {
            setLoading(false);
        }
    };

    // page change handler for pagination
    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    }

    const viewNotification = async (notificationId) => {
        nav(notificationId);
    }

    const deleteNotificationHandler = async (notificationId) => {
        const allowDelete = window.confirm("Are you sure. You want to delete?");
        if (!allowDelete) return;

        setLoading(true);

        try {
            const response = await deleteNotification(notificationId);

            if (response?.status === "success") {
                toast.success(response.data);
                fetchNotifications(currentPage);
            } else {
                toast.error('Something went wrong!');
            }
        } finally {
            setLoading(false);
        }
    }

    /** Handler functions - ends */
    // hooks
    const nav = useNavigate();

    // Main renderer
    return (
        <>
            {loading ? (
                <DashboardLoader />
            ) : (
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <h3>{t('notification')}</h3>
                        <div className="d-flex flex-row" style={{ gap: "10px" }}>
                            <button className="btn btn-primary" onClick={() => nav("create")} style={{ marginBottom: 20 }}>
                                {t('add')} {t('notification')}
                            </button>
                        </div>
                    </div >

                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">{t('subject')}</th>
                                    <th scope="col">{t('message')}</th>
                                    <th scope="col">{t('module')}</th>
                                    <th scope="col">{t('sent_on')}</th>
                                    <th scope="col">{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notifications?.notifications?.map((el) => {
                                    return (
                                        <tr key={el._id}>
                                            <td>{el.subject}</td>
                                            <td className="text-wrap" title={el.message}>{truncatedText(el.message)}</td>
                                            <td>{el.feature}</td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">{el.sent_at}</Moment>
                                            </td>
                                            <td>
                                                <button className="btn p-0" onClick={() => viewNotification(el._id)} title="View Notification">
                                                    <FontAwesomeIcon className={"table-icon"} icon={faEye} />
                                                </button>

                                                <button className="btn p-0" onClick={() => deleteNotificationHandler(el._id)} title="Delete Notification">
                                                    <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination_Container mt-1 text-end">
                        <Pagination
                            productsPerPage={10}
                            totalProducts={notifications.total_notifications}
                            pageChange={handlePageChange}
                            currentPage={currentPage}
                        />
                    </div>
                </div >
            )}
        </>
    )
}