import React, { useState, useEffect } from "react";

const useGeolocation = () => {
    const [location, setLocation] = useState({
        loaded: false,
        coordinates: { lat: "", lng: "" },
        address: { city: "", state: "", zip: "" },
    });

    const locationFetch = () => {
        const onSuccess = (location) => {
            setLocation((prev) => ({
                ...prev,
                loaded: true,
                coordinates: {
                    lat: location.coords.latitude,
                    lng: location.coords.longitude,
                },
            }));
            getAddress(location.coords.latitude, location.coords.longitude, process.env.REACT_APP_GOOGLE_KEY);
        };

        const onError = (error) => {
            setLocation({
                loaded: true,
                error,
            });
        };

        const getAddress = (lat, long, googleKey) => {
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleKey}`)
                .then((res) => res.json())
                .then((addrss) => processUserAddress(addrss));
        };
        function reformat(result) {
            var me = {};
            var components = result.address_components;
            var len = components.length;
            for (var i = 0; i < len; i++) {
                for (var ii = 0; ii < components[i].types.length; ii++) {
                    me[components[i].types[ii]] = components[i].short_name;
                    me[components[i].types[ii] + "_long"] = components[i].long_name;
                }
            }
            for (var prop in result) {
                if (prop != "address_components") me[prop] = result[prop];
            }
            return me;
        }

        const processUserAddress = (addrss) => {
            const address = addrss?.results?.find((item) => {
                return item.address_components.length > 5;
            });
            // console.log(address);
            const fullData = reformat(address);
            const city = address?.address_components[2]?.short_name;
            const state = address?.address_components[4]?.short_name;
            // const postal = addrss.results[0]?.address_components[addrss.results[0].address_components.length - 1]?.short_name;
            const postal = fullData.postal_code;
            // console.log(postal);

            setLocation((prev) => ({
                ...prev,
                address: {
                    city: city,
                    state: state,
                    zip: postal,
                },
            }));
        };

        if (!("geolocation" in navigator)) {
            onError({
                code: 0,
                message: "Geolocation not supported",
            });
        }
        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    };

    return { location, locationFetch };
};

export default useGeolocation;
