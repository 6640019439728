import React, { useEffect, useRef, useState, useMemo } from "react";
import Section4 from "../components/Section4/Section4";
import Section1 from "../components/Section1/Section1";

// React-Router
import { useLocation, useNavigate } from "react-router-dom";

//Images
import { getHomePageSections } from "../api/AdminApis/HomePageSections";
import useSiteCtx from "../hooks/useSiteCtx";
import useProductCtx from "../hooks/useProductCtx";

// Utils
import { PRODUCT_CLASSIFICATION } from "../utils/Constants";
import Banners from "../components/Banners/Banners";
import DealsSection from "../components/DealsSection";

// Toast
import { toast } from "react-toastify";

// Component Definition
const Home = () => {
  // hooks
  // const { allProducts } = useProductCtx();
  const { homePageSections, setHomePageSections } = useSiteCtx();
  const location = useLocation();
  const navigate = useNavigate();
  const sectionRefs = [useRef(null), useRef(null), null, useRef(null), useRef(null)];
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollCardSections, setScrollCardSections] = useState([]);

  // component mounted
  useEffect(() => {
    getHomePageSections((err, res) => {
      if (err) return console.log(err);
      setHomePageSections(res.sections);
      setScrollCardSections(() => {
        return Array.from(res.sections).filter((ele, index) => {
          if (index !== 0) return ele
        });
      });
    });

    if (location.search) {
      handleLogoutRedirection(location);
    }
  }, []);

  // // get ean of coming soon products
  // const comingSoonEans = allProducts.products.reduce((eanAccumaltor, product) => {
  //     if (product.classification === PRODUCT_CLASSIFICATION.COMING_SOON) {
  //         eanAccumaltor.push(product.ean);
  //     }
  //     return eanAccumaltor;
  // }, []);

  // // get ean of temp hidden products
  // const tempHiddenEans = allProducts.products.reduce((accumalator, product) => {
  //     if (product.classification === PRODUCT_CLASSIFICATION.TEMP_HIDDEN) {
  //         accumalator.push(product.ean);
  //     }
  //     return accumalator;
  // }, []);

  const handleLogoutRedirection = (location) => {
    const searchQuery = new URLSearchParams(location.search.slice(1));
    if (searchQuery.has('action') && searchQuery.get('action') === 'logout') {
      toast.error('User logged out due to token expiration! Please login again.');
      navigate('/')
    }
  }


  useEffect(() => {
    const createObserver = (sectionRef, index) => {
      if (!sectionRef || !sectionRef.current || visibleSections.includes(index)) return null;
      return new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setVisibleSections((prev) => [...new Set([...prev, index])]);
              if (observers[index]) {
                observers[index].unobserve(sectionRef.current);
              }
            }
          });
        },
        { threshold: 0 } // Adjust the threshold as needed
      );
    };
  
    // Create and store the observers for each sectionRef
    const observers = sectionRefs.map((sectionRef, index) => createObserver(sectionRef, index));
  
    // Start observing each target section
    sectionRefs.forEach((sectionRef, index) => {
      if (sectionRef && sectionRef.current && !visibleSections.includes(index)) {
        observers[index].observe(sectionRef.current);
      }
    });
  
    // Cleanup: Stop observing when the component unmounts
    return () => {
      sectionRefs.forEach((sectionRef, index) => {
        if (sectionRef && sectionRef.current && observers[index]) {
          observers[index].unobserve(sectionRef.current);
        }
      });
    };
  }, [sectionRefs]);
  
  

  // Main renderer

  return (
    <div className="homepage_wrapper">
      <Section1 id={"homepage_hero"} />
      <Banners />

      {
        scrollCardSections?.map((sec, i) => (
          sec.sectionType === "static_deals" ?
            <DealsSection
              heading={sec.sectionHeading}
              products={sec.products}
              key={`Deals_Wrapper_${i}`}
            />
            :
            <div ref={sectionRefs[i]} data-ref={`index_${i}`} key={`Home_Page_Sections_${i}`}>
              <Section4
                id={`product-card-${i}`}
                heading={sec.sectionHeading}
                products={sec.products}
                isVisible={visibleSections.includes(i)}
                // products={section.products.filter((pEan) => {
                //     return !comingSoonEans.concat(tempHiddenEans).includes(pEan);
                // })}
                targetUrl={sec?.targetUrl}
              />
            </div>
        ))
      }
    </div>
  );
};

export default Home;
