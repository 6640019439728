import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

function UserAddressModal(props) {
    const { details, ...otherProps } = props;

    return (
        <Dialog {...otherProps} fullWidth maxWidth="md">
            <DialogTitle>Address</DialogTitle>
            <DialogContent>
                {details.length > 0 ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Address</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Customer Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Phone</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Address Line 1</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>City</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>State</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Pincode</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Landmark</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {details.map((address, index) => (
                                    <TableRow key={`user_address_${index}`}>
                                        <TableCell>{`${index + 1}.`}</TableCell>
                                        <TableCell>{address?.customerName ?? "-"}</TableCell>
                                        <TableCell>{address?.phone ?? "-"}</TableCell>
                                        <TableCell>{address?.address_line1 ?? "-"}</TableCell>
                                        <TableCell>{address?.city ?? "-"}</TableCell>
                                        <TableCell>{address?.state ?? "-"}</TableCell>
                                        <TableCell>{address?.zip ?? "-"}</TableCell>
                                        <TableCell>{address?.landmark ?? "-"}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <p>No Address Found</p>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserAddressModal;
