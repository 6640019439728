import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrashAlt,
    faEdit,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import useAdminCtx from "../../hooks/useAdminCtx";
import {
    deleteHomePageSection,
    getHomePageSections,
} from "../../api/AdminApis/HomePageSections";

import "./styles/dashboardProducts.css";
import "./styles/DashboardHomePage.css";

// Component definition
function DashboardHomePage() {
    const navigate = useNavigate();
    const location = useLocation();

    // state definitions
    const [loader] = useState(false);
    const [allBottomSections, setAllBottomSections] = useState([]);

    const { allHomePageSections, setAllHomePageSections } = useAdminCtx();

    const handleDelete = (id) => {
        if (!window.confirm("Are you sure?")) return;

        deleteHomePageSection(id, (err, res) => {
            if (err) return toast.error("Something went wrong!");
            getHomePageSections((err, res) => {
                if (err) return console.log(err);
                setAllHomePageSections(res.sections);
            });
        });
    };

    const handleNavigate = (type) => {
        navigate("../admin-home-add-section", {
            state: { type: type, from: location.pathname },
        });
    };

    useEffect(() => {
        getHomePageSections((err, res) => {
            if (err) return console.log(err);
            setAllHomePageSections(res.sections);
        });
    }, []);

    useEffect(() => {
        setAllBottomSections(
            allHomePageSections.filter(
                (item) => item.sectionType !== "scroll_banner"
            )
        );
    }, [allHomePageSections]);

    return loader ? (
        <DashboardLoader />
    ) : (
        <>
            <div className="container">
                <div className="d-flex justify-content-between">
                    <h3>Home Page Sections</h3>

                    <div
                        className="d-flex flex-row"
                        style={{ gap: "10px", minHeight: "150px" }}
                    >
                        <Dropdown>
                            <Dropdown.Toggle variant="info" id="dropdown-basic">
                                Add New Section
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/* <Dropdown.Item onClick={() => handleNavigate("scroll_banner")}>Scroll Banner</Dropdown.Item> */}
                                <Dropdown.Item
                                    onClick={() =>
                                        handleNavigate("static_banner")
                                    }
                                >
                                    Static Banner
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() =>
                                        handleNavigate("scroll_card")
                                    }
                                >
                                    Scroll Card
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() =>
                                        handleNavigate("static_card")
                                    }
                                >
                                    Static Card
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() =>
                                        handleNavigate("static_deals")
                                    }
                                >
                                    Static Deals/Offers
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

                <Link to={"top-banners"}>
                    <div className="top-scroll-banners-section">
                        <span>Manage Top Scrolling Banners</span>
                        <FontAwesomeIcon
                            cursor={"pointer"}
                            icon={faArrowRight}
                        />
                    </div>
                </Link>

                <div className=" hidden-md-up">
                    {allBottomSections.length > 0 ? (
                        allBottomSections.map(
                            (section, index) =>
                                (section.sectionType === "static_banner" && (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            gap: "5px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <section className="home-page-section-body">
                                            {
                                                <h5>
                                                    {section.sectionHeading &&
                                                        `${section.sectionHeading} `}{" "}
                                                    (Static Banner Section)
                                                </h5>
                                            }
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "15px",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <img
                                                    width={300}
                                                    src={
                                                        section.products[0]
                                                            .imgUrl
                                                    }
                                                    alt={
                                                        section.products[0]
                                                            .title
                                                    }
                                                />
                                                <div>
                                                    <p>
                                                        Title -{" "}
                                                        {
                                                            section.products[0]
                                                                .title
                                                        }
                                                    </p>
                                                    <p>
                                                        Target URL -{" "}
                                                        {
                                                            section.products[0]
                                                                .targetUrl
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div>Order: {section.priority}</div>
                                        </section>
                                        <aside className="home-page-section-aside">
                                            <FontAwesomeIcon
                                                size="lg"
                                                onClick={() =>
                                                    navigate(
                                                        "../admin-home-add-section",
                                                        {
                                                            state: {
                                                                type: "static_banner",
                                                                updateProduct: true,
                                                                data: section,
                                                                from: location.pathname,
                                                            },
                                                        }
                                                    )
                                                }
                                                cursor={"pointer"}
                                                icon={faEdit}
                                            />
                                            <FontAwesomeIcon
                                                size="lg"
                                                cursor={"pointer"}
                                                onClick={() =>
                                                    handleDelete(section._id)
                                                }
                                                icon={faTrashAlt}
                                            />
                                        </aside>
                                    </div>
                                )) ||
                                (section.sectionType === "static_card" && (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            gap: "5px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <section className="home-page-section-body">
                                            {
                                                <h5>
                                                    {section.sectionHeading &&
                                                        `${section.sectionHeading}`}{" "}
                                                    (Static Card Section)
                                                </h5>
                                            }
                                            <div style={{}}>
                                                <p>Products</p>
                                                <ul>
                                                    {section.products.map(
                                                        (ean) => (
                                                            <li>{ean}</li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                            <div>Order: {section.priority}</div>
                                        </section>
                                        <aside className="home-page-section-aside">
                                            <FontAwesomeIcon
                                                size="lg"
                                                cursor={"pointer"}
                                                onClick={() =>
                                                    navigate(
                                                        "../admin-home-add-section",
                                                        {
                                                            state: {
                                                                type: "static_card",
                                                                updateProduct: true,
                                                                data: section,
                                                                from: location.pathname,
                                                            },
                                                        }
                                                    )
                                                }
                                                icon={faEdit}
                                            />
                                            <FontAwesomeIcon
                                                size="lg"
                                                cursor={"pointer"}
                                                onClick={() =>
                                                    handleDelete(section._id)
                                                }
                                                icon={faTrashAlt}
                                            />
                                        </aside>
                                    </div>
                                )) ||
                                (section.sectionType === "scroll_card" && (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            gap: "5px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <section className="home-page-section-body">
                                            {
                                                <h5>
                                                    {section.sectionHeading &&
                                                        `${section.sectionHeading} `}{" "}
                                                    (Scroll Card Section)
                                                </h5>
                                            }
                                            <div style={{}}>
                                                <p>Products</p>
                                                <ul>
                                                    {section.products.map(
                                                        (ean) => (
                                                            <li>{ean}</li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                            <div>Order: {section.priority}</div>
                                        </section>
                                        <aside className="home-page-section-aside">
                                            <FontAwesomeIcon
                                                size="lg"
                                                onClick={() =>
                                                    navigate(
                                                        "../admin-home-add-section",
                                                        {
                                                            state: {
                                                                type: "scroll_card",
                                                                updateProduct: true,
                                                                data: section,
                                                                from: location.pathname,
                                                            },
                                                        }
                                                    )
                                                }
                                                cursor={"pointer"}
                                                icon={faEdit}
                                            />
                                            <FontAwesomeIcon
                                                size="lg"
                                                cursor={"pointer"}
                                                onClick={() =>
                                                    handleDelete(section._id)
                                                }
                                                icon={faTrashAlt}
                                            />
                                        </aside>
                                    </div>
                                )) ||
                                (section.sectionType === "static_deals" && (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            gap: "5px",
                                            margin: "20px 0",
                                        }}
                                    >
                                        <section className="home-page-section-body">
                                            {
                                                <h5>
                                                    {section.sectionHeading &&
                                                        `${section.sectionHeading} `}{" "}
                                                    (Static Deals Section)
                                                </h5>
                                            }
                                            {section.products.map(
                                                (banner, index) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            gap: "15px",
                                                            marginTop: "20px",
                                                        }}
                                                        key={index}
                                                    >
                                                        <img
                                                            width={300}
                                                            src={banner.image}
                                                            alt={banner.title}
                                                        />
                                                        <div>
                                                            <p>
                                                                Title -{" "}
                                                                {banner.title}
                                                            </p>
                                                            <p>
                                                                Target URL -{" "}
                                                                {
                                                                    banner.targetUrl
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                            <div>Order: {section.priority}</div>
                                        </section>
                                        <aside className="home-page-section-aside">
                                            <FontAwesomeIcon
                                                size="lg"
                                                onClick={() =>
                                                    navigate(
                                                        "../admin-home-add-section",
                                                        {
                                                            state: {
                                                                type: "static_deals",
                                                                updateProduct: true,
                                                                data: section,
                                                                from: location.pathname,
                                                            },
                                                        }
                                                    )
                                                }
                                                cursor={"pointer"}
                                                icon={faEdit}
                                            />
                                            <FontAwesomeIcon
                                                size="lg"
                                                cursor={"pointer"}
                                                onClick={() =>
                                                    handleDelete(section._id)
                                                }
                                                icon={faTrashAlt}
                                            />
                                        </aside>
                                    </div>
                                ))
                        )
                    ) : (
                        <>
                            <div className="d-flex justify-content-center align-items-center p-3 mt-5">
                                No Other Sections
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default DashboardHomePage;
