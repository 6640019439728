import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Carousel, { consts } from "react-elastic-carousel";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { lazy } from 'react';

//CSS
import "./HeaderBar2.css";

//Images

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IndianFlagIcon from "../../assets/vector/india-flag-icon.svg";
import logo_mob from "../../assets/vector/navbar_logo_mob.svg";
import trolley_cart from "../../assets/vector/trolley_cart.svg";
import logo_desk from "../../assets/png/navbar_logo_desk.png";
import logo_tab from "../../assets/vector/navbar_logo_tab.svg";
import storeWhite from "../../assets/vector/store_outline_white.svg";
import locationWhite from "../../assets/vector/location_white.svg";
import userDefaultDP from "../../assets/png/account_circle.png";
import { useRef } from "react";
import useUserCtx from "../../hooks/useUserCtx";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useSiteCtx from "../../hooks/useSiteCtx";
import useProductCtx from "../../hooks/useProductCtx";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";

import MenuItem from "@mui/material/MenuItem";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";

import TWS from "../../assets/vector/Header_Carousel/TWS.svg";
import Headphone from "../../assets/vector/Header_Carousel/HeadphoneNew.svg";
import Smartwatches from "../../assets/vector/Header_Carousel/Smartwatches.svg";
import Powerbank from "../../assets/vector/Header_Carousel/Powerbank.svg";
import Speaker from "../../assets/vector/Header_Carousel/Speaker.svg";
import Laptops from "../../assets/vector/Header_Carousel/Laptops.svg";
import newArrival from "../../assets/vector/Header_Carousel/New_Arrivals.svg";
import smartTV from "../../assets/vector/Header_Carousel/Smart_TV.svg";
import Smartphones from "../../assets/vector/Header_Carousel/Smartphones.svg";
import Tablets from "../../assets/vector/Header_Carousel/Tablets.svg";
import topOffers from "../../assets/vector/Header_Carousel/Top_Offers.svg";
import SignInButton from "./SignInButton";

import { CircularProgress, MenuList, Paper, Skeleton, useMediaQuery } from "@mui/material";

import MobileSignInButton from "./MobileSignInButton";
import LocationModal from "./locationModal";
import MyProfileButton from "./MyProfileButton";
import MobileProfileButton from "./MobileProfileButton";
import { getSearchedProduct } from "../../api/Product";
import { PRODUCT_CLASSIFICATION } from "../../utils/Constants";
import useScrollDirection from "../../hooks/useScrollDirection";
import HeaderDropDown from "../HeaderDropDown/HeaderDropDown";


const CustomArrow = ({ type, onClick, isEdge }) => {
  const pointer = type === consts.PREV ? <ArrowBackIosIcon
    style={{
      marginTop: "-12px",
      height: '100%',
      fontSize: "16px",
      cursor: "pointer",
      color: '#707070'
    }}
    onClick={onClick} disabled={isEdge}
  /> : <ArrowForwardIosIcon
    style={{
      marginTop: "-12px",
      height: '100%',
      fontSize: "16px",
      cursor: "pointer",
      color: '#707070'
    }}
    onClick={onClick} disabled={isEdge}
  />
  return (
    pointer
  )
}


const HeaderBar2 = () => {
  const isMobile = useMediaQuery(`(max-width: 500px)`);
  const { t } = useTranslation();
  const {
    userLoggedIn,
    setLanguageFunc,
    language,
  } = useSiteCtx();
  const { headerData } =
    useSiteCtx();
  const searchBarInputRef = useRef(null);
  const dropdownRef = useRef(null);

  const scrollDirection = useScrollDirection();

  const { width } = useWindowDimensions();

  const carouselRef = useRef();
  const mobileCarouselRef = useRef();
  let resetTimeout;

  const matches768 = width >= 768;
  const matches1290 = width >= 1290;
  const isDesktop = width > 992;

  const [modalShow, setModalShow] = useState(false);
  const [userDPPic, setUserDPPic] = useState({ locataion: "" });
  const [filteredData, setFilteredData] = useState([]); // Holds product names to display in search dropdown
  const [searchedQuery, setSearchedQuery] = useState(""); //Sets searched product name in search input box
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [debounceQuery, setDebounceQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);

  const nav = useNavigate();
  let [searchParams] = useSearchParams();
  const {
    header3Cond,
  } = headerData;

  const languages = {
    en: { nativeName: "English", abbr: "EN" },
    hi: { nativeName: "Hindi", abbr: "HI" },
    kn: { nativeName: "Kannada", abbr: "KN" },
    mr: { nativeName: "Marathi", abbr: "MR" },
  };

  const {
    setCurrentPage,
  } = useProductCtx();

  const {
    userContext,
    cartArray,
    userZip,
  } = useUserCtx();

  const categoriesList2 = [
    {
      categoryImage: topOffers,
      categoryName: t("topOffers"),
      categoryLink: "all",
    },
    {
      categoryImage: newArrival,
      categoryName: t('newArrivals'),
      categoryLink: "all",
    },
    {
      categoryImage: Smartphones,
      categoryName: t('category.smartphone'),
      categoryLink: 'Smartphone',
    },
    {
      categoryImage: Laptops,
      categoryName: t('laptop'),
      categoryLink: "Laptop",
    },

    {
      categoryImage: Tablets,
      categoryName: t('category.tablet'),
      categoryLink: "Tablet",
    },

    {
      categoryImage: smartTV,
      categoryName: t('category.smart-tv'),
      categoryLink: "Smart TV",
    },
    {
      categoryImage: TWS,
      categoryName: "TWS",
      categoryLink: "True Wireless Earbuds",
    },

    {
      categoryImage: Powerbank,
      categoryName: t('Powerbank'),
      categoryLink: "Powerbank",
    },
    {
      categoryImage: Speaker,
      categoryName: t('speaker'),
      categoryLink: "Bluetooth Speaker",
    },
    {
      categoryImage: Headphone,
      categoryName: t('headphones'),
      categoryLink: "Bluetooth Headphones",
    },
    {
      categoryImage: Smartwatches,
      categoryName: t('Smartwatches'),
      categoryLink: "Smartwatch",
    },
  ];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedSearchIndex, setSelectedSearchIndex] = useState(-1);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClosePOP = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleModalShow = () => {
    setModalShow(true);
  };

  useEffect(() => {
    if (userContext && userContext.profilePic) {
      setUserDPPic(userContext.profilePic);
    } else {
      setUserDPPic(userDefaultDP);
    }
  }, [userContext]);

  useEffect(() => {
    !searchParams.has("search") && setSearchedQuery("");
  }, [searchParams]);

  useEffect(() => {
    if (searchedQuery === "" && searchParams.has("search"))
      nav("/Category=all");
  }, [searchedQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        searchBarInputRef.current !== event.target
      ) {
        setIsOptionsOpen(false);
      }
    };

    function handleCloseDropDownScroll() {
      setIsOptionsOpen(false);
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('scroll', handleCloseDropDownScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleCloseDropDownScroll);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === 'k') {
      event.preventDefault();
      searchBarInputRef.current.focus();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (searchedQuery) {
        setDebounceQuery(searchedQuery);
      }
    }, 600);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [searchedQuery]);

  useEffect(() => {
    Caller(debounceQuery)
  }, [debounceQuery]);


  const Caller = useCallback((searchWord) => {
    if (!searchWord) return
    setSearchLoading(true)
    getSearchedProduct(`search=${searchWord}`).then((res) => {
      const products = res.products.filter(
        (prod) => prod.classification !== PRODUCT_CLASSIFICATION.TEMP_HIDDEN
      );
      setFilteredData(products)
    }).finally(() => setSearchLoading(false));
    setSearchedQuery(searchWord);
    setIsOptionsOpen(true);
  }, [])

  const handleFilter = (e) => {
    const searchWord = e.target.value;
    if (searchWord === "") {
      setFilteredData([]);
      setSearchedQuery("");
      setDebounceQuery("")
    } else {
      setSearchedQuery(searchWord);
    }
    setSelectedSearchIndex(-1);
  };

  function handleSearchEnterKey(e) {
    let nextIndexCount = selectedSearchIndex;
    if (e.key === 'ArrowUp') {
      // Move selection up
      nextIndexCount = (selectedSearchIndex + filteredData.length - 1) % filteredData.length;
    } else if (e.key === 'ArrowDown') {
      // Move selection down
      nextIndexCount = (selectedSearchIndex + 1) % filteredData.length;
    }
    setSelectedSearchIndex(nextIndexCount);
    const selectedFilter = [...filteredData].slice(nextIndexCount, nextIndexCount + 1)?.[0];
    if (selectedFilter) {
      setSearchedQuery(selectedFilter?.title)
    }
    if (e.key === "Enter") {
      if (selectedSearchIndex >= 0) {
        handleSearchClick(
          { name: selectedFilter?.title },
          selectedFilter?.slug
        )
      }
      else {
        searchData(searchBarInputRef.current.value);
      }
    }
  }

  const searchData = (query) => {
    //Function gets called whenever user submits the input form ( presses enter after typing in input)
    if (query.match(/((\r\n|\n|\r)$)|(^(\r\n|\n|\r))|^\s*$/gm)) return;
    let searchTerm = "search=" + query;
    nav(`/search?${searchTerm}`);
    setFilteredData([]);
  };

  const handleSearchClick = (value, slug) => {
    setFilteredData([]);
    setSearchedQuery("");
    nav(`/product/${slug}`);
  };

  const handleCategorySearch = (value) => {
    setCurrentPage(1);
    let searchURL;
    if (value !== "all") {
      searchURL = "Category=" + value;
    } else {
      searchURL = "Category=" + value;
    }
    nav(`/${searchURL}`);
  };

  const [categoryImageLoaded, setCategoryImageLoaded] = useState(new Array(10).fill(false));

  const handleCategoryImageLoad = (index) => {
    setCategoryImageLoaded(prev => {
      const arr = [...prev];
      arr[index] = true;
      return arr
    });
  };

  return (
    <>
      {isDesktop ? (
        <header
          className={`headerbarContainer ${header3Cond ? "header2_tab" : ""}`}
        >
          <div className="headerbarWrapper">
            <div className="headerbarLeft">
              <div className="headerbarLeft_Inner">
                <Link to={"/"} className="navLogo">
                  <img src={logo_mob} alt="logo_mob" className="logo_mob" />
                  <img src={logo_desk} alt="logo_desk_1200" className="logo_desk_1200" />
                  <img src={logo_tab} alt="logo_tab" className="logo_tab" />
                </Link>
              </div>
              <div
                className="left_location"
                onClick={handleOpen}
              >
                <img
                  src={locationWhite}
                  alt=""
                  className="location-logo"
                  style={{ width: "18px" }}
                />
                {userContext?.JWT ? (
                  userZip.loaded ? (
                    <p>{userZip.value}</p>
                  ) : (
                    <>
                      <p>{t("header.default-location")}</p>
                    </>
                  )
                ) : userZip.loaded ? (
                  <p>{userZip.value}</p>
                ) : (
                  <>
                    <p className="headerLocation">
                      <span className="header-pincode">
                        {t("header.location")}
                      </span>
                    </p>
                  </>
                )}
              </div>

            </div>

            <div className="headerbarCenter">
              <div className="searchbar_Container">
                <div className="search">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    ref={searchBarInputRef}
                    placeholder={`${t("header.search")}...`}
                    value={searchedQuery}
                    className="searchbar"
                    onChange={handleFilter}
                    // onBlur={() => setIsOptionsOpen(false)}
                    onFocus={() => setIsOptionsOpen(true)}
                    onKeyDown={(e) => handleSearchEnterKey(e)}
                  />
                  {searchLoading && <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 7px"
                  }}><CircularProgress color="inherit" sx={{
                    width: "20px!important",
                    height: "20px!important"
                  }} /></div>}
                  <button
                    className="search-btn"
                    style={{ color: "white", fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      searchData(searchBarInputRef.current.value);
                    }}
                  >
                    {t("header.search")}
                  </button>
                </div>
              </div>
              {filteredData.length !== 0 && isOptionsOpen && (
                <div ref={dropdownRef} className="search_Results">
                  {filteredData.map((filterItem, index) => (
                    <div
                      onClick={() =>
                        handleSearchClick(
                          { name: filterItem?.title },
                          filterItem?.slug
                        )
                      }
                      className={`search_Result_Item ${selectedSearchIndex === index ? 'selected_search_item' : ''}`}
                      key={index}
                    >
                      <p className="ellipsis">{filterItem?.title}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="headerbarRight">
              <div className="map-location_res" style={{ zIndex: "1000" }} ref={anchorRef} onClick={handleToggle}>
                <img
                  className="IndianFlagIcon_res"
                  src={IndianFlagIcon}
                  alt="img"
                />
                <button
                  style={{
                    cursor: "pointer",
                    background: "transparent",
                    outline: "none",
                    border: "none",
                    color: "white",
                  }}
                >
                  <p
                    className="lang-item"
                    value=""
                    style={{ marginTop: "-3px", marginLeft: "3px" }}
                  >
                    {languages[language].abbr}
                  </p>
                </button>
                <span
                  id="composition-button"
                // aria-controls={open ? "composition-menu" : ""}
                // aria-expanded={open ? "true" : ""}
                // aria-haspopup="true"
                // onClick={handleToggle}
                >
                  <ExpandMoreIcon sx={{ color: "white", marginTop: "-6px" }} />
                </span>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{ zIndex: "10000" }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClosePOP}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                            className="language_dropdown"
                            sx={{ width: "200px", paddingTop: "0" }}
                          >
                            <p
                              style={{
                                backgroundColor: "#F0F2F2",
                                padding: "10px 40px",
                              }}
                            >
                              {t('ChooseyourLanguage')}
                            </p>
                            {Object.keys(languages).map((lng) => (
                              <MenuItem
                                onClick={(e) => { setLanguageFunc(lng); handleClosePOP(e); }}
                                sx={{
                                  paddingTop: isMobile ? "0" : "10px",
                                  paddingBottom: isMobile ? "0" : "10px",
                                }}
                              >
                                <input type="radio" className="radio_input" style={{ accentColor: language === lng ? 'orange' : '' }} checked={language === lng && true} />{" "}

                                <span className="lang_list_item">
                                  {`${languages[lng].nativeName}-${languages[lng].abbr}`}
                                </span>
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              <Link to={`/store-finder`} className="storeIcon storeLogo">
                <p>
                  <span>{t("header.store")}</span>
                </p>
                <img
                  src={storeWhite}
                  alt="store-icon"
                  style={{ marginBottom: "-10px" }}
                />
              </Link>
              {userLoggedIn ? (
                <MyProfileButton
                  t={t}
                  isMobile={isMobile}
                  userDPPic={userDPPic}
                />
              ) : (
                <>
                  <SignInButton
                    t={t}
                    matches768={matches768}
                    matches1290={matches1290}
                    isMobile={isMobile}
                  />
                </>
              )}
            </div>
            <div
              className="cartIcon_res"
              style={{
                marginLeft: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
              }}
              onClick={() => {
                userLoggedIn ? nav("/mycart") : nav("/login");
              }}
            >
              <div style={{ height: "33px" }}>
                <img alt="trolley" src={trolley_cart} />
                <div id="cartItems_res">{cartArray.no_of_carts}</div>
              </div>
            </div>
          </div>
          <div
            className="locationbarWrapper logo_mob"
            onClick={() => handleModalShow()}
          >
            <img src={locationWhite} alt="" style={{ width: "25px" }} />
            <p>{t("location-tab")}</p>
          </div>
          <div className="categories_Container" style={{
            top: scrollDirection === "down" ? "-66px" : "66px",
            opacity: scrollDirection === "down" ? "0" : "1"
          }}>

            <div style={{
              position: "relative",
            }}>
              <div
                className="hamburger"
                // onClick={() => setSidebar(true)}
                onClick={() => setOpenDropDown(prev => !prev)}
              >
                <span className="dropDownText"  >
                  <MenuIcon id="hamburgerIcon" fontSize="large" />
                </span>
              </div>
              <HeaderDropDown open={openDropDown} setOpen={setOpenDropDown} />

              <span
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                All
              </span>
            </div>

            <Carousel
              ref={carouselRef}
              showArrows={true}
              renderArrow={CustomArrow}
              divsToShow={1}
              itemsToScroll={1}
              itemsToShow={10}
              transitionMs={1000}
              autoPlaySpeed={3000}
              style={{ marginLeft: "20px", marginBottom: "-18px" }}
              enableAutoPlay={true}
              onNextEnd={({ index }) => {
                clearTimeout(resetTimeout);
                resetTimeout = setTimeout(() => {
                  carouselRef?.current?.goTo(0);
                }, 3000); // same time
              }}
            >
              {categoriesList2.map((item, index) => (
                <div
                  className="category carousel-itm"
                  key={index}
                  onClick={() => handleCategorySearch(item.categoryLink)}
                >
                  {
                    <>
                      {!categoryImageLoaded[index] && <Skeleton variant="circular" width={65} height={65} />}
                      <img
                        src={item.categoryImage}
                        alt={`Category_img-${item.categoryName}`}
                        onLoad={() => handleCategoryImageLoad(index)}
                        style={{ display: categoryImageLoaded[index] ? 'block' : 'none' }}
                      />
                    </>
                  }
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      fontFamily: "Quicksand",
                    }}
                  >
                    {item.categoryName}
                  </p>
                </div>
              ))}
            </Carousel>
          </div>
        </header>
      ) : (
        <header
          className={`headerbarContainer ${header3Cond ? "header2_tab-res" : ""
            }`}
        >
          <div className="header-res-top" style={{ width: "95%" }}>
            <div className="headerbarLeft_Inner_res">
              <Link to={"/"} className="navLogo-res smallLogo">
                <img src={logo_desk} alt="" className="" />
              </Link>
              <div
                style={{ display: "flex", flexDirection: "row", gap: isMobile ? "5px" : "20px", justifyContent: "center", "alignItems": "center" }}
              >
                <div
                  className="header-mob-signIn-res"
                  style={{
                    width: userLoggedIn && isMobile ? "50px" : "",
                  }}
                  onClick={() => !userLoggedIn && nav("/login")}
                >
                  {userLoggedIn ? (
                    <MobileProfileButton
                      t={t}
                      isMobile={isMobile}
                      userDPPic={userDPPic}
                    />
                  ) : (
                    <>
                      <MobileSignInButton t={t} isMobile={isMobile} />
                    </>
                  )}
                </div>
                <div
                  className="cartIcon_res"
                  onClick={() => {
                    userLoggedIn ? nav("/mycart") : nav("/login");
                  }}
                >
                  <div style={{ height: "37px" }}>
                    {/* <ShoppingCartOutlinedIcon fontSize="large" /> */}
                    <img
                      alt="trolley"
                      src={trolley_cart}
                      style={{
                        width: "32px",
                        marginTop: userLoggedIn && isMobile ? "-10px" : "",
                      }}
                    />
                    <div id="cartItems_res">{cartArray.no_of_carts}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-res-center">
            <div className="headerbarCenter">
              <div className="searchbar_Container">
                <div className="search" style={{ paddingLeft: "10px" }}>
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    ref={searchBarInputRef}
                    placeholder={`${t("header.search")}...`}
                    value={searchedQuery}
                    className="searchbar"
                    onChange={handleFilter}
                    onBlur={() => setIsOptionsOpen(false)}
                    onFocus={() => setIsOptionsOpen(true)}
                    onKeyDown={(e) => handleSearchEnterKey(e)}
                  />
                  {searchLoading && <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 7px"
                  }}><CircularProgress color="inherit" sx={{
                    width: "20px!important",
                    height: "20px!important"
                  }} /></div>}
                  <button
                    className="search-btn"
                    style={{ color: "white", fontSize: "12px", height: "33px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      searchData(searchBarInputRef.current.value);
                    }}
                  >
                    {t("header.search")}
                  </button>
                </div>
              </div>
              {filteredData.length !== 0 && (
                <div className="search_Results">
                  {filteredData.map((filterItem, index) => (
                    <div
                      onClick={() =>
                        handleSearchClick(
                          { name: filterItem?.title },
                          filterItem?.slug
                        )
                      }
                      className="search_Result_Item"
                      key={index}
                    >
                      <p className="ellipsis">{filterItem?.title}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="header-res-bottom">
            <div className="headerbarLeft_res" style={{ height: isMobile ? "2.2rem" : "3.5rem" }}>
              <div
                className="left_location_res"
                onClick={handleOpen}
              >
                <img
                  src={locationWhite}
                  alt="location-icon"
                  className="location-logo_res"
                  style={{ marginRight: "4px", width: isMobile ? "12px" : "25px" }}
                />
                {userContext?.JWT ? (
                  userZip.loaded ? (
                    <p>{userZip.value}</p>
                  ) : (
                    <>
                      <p>{t("header.default-location")}</p>
                    </>
                  )
                ) : userZip.loaded ? (
                  <p>{userZip.value}</p>
                ) : (
                  <>
                    <p className="headerLocation_res">
                      <span className="header-pincoden_res">
                        {"Hello, " + t("header.location")}
                      </span>
                    </p>
                  </>
                )}
              </div>

              <div className="headerbarWrapper_res">
                <div className="headerbarRight_res">
                  <div className="map-location_res" style={{ zIndex: "1000" }} ref={anchorRef} onClick={handleToggle}>
                    <img
                      className="IndianFlagIcon_res"
                      src={IndianFlagIcon}
                      alt="img"
                    />
                    <button

                      id="composition-button"
                      aria-controls={open ? "composition-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                      style={{
                        cursor: "pointer",
                        background: "transparent",
                        outline: "none",
                        border: "none",
                        color: "white",
                      }}
                    >
                      <p
                        className="lang-item"
                        value=""
                        style={{ marginLeft: "3px" }}
                      >
                        {languages[language].abbr}
                      </p>
                    </button>
                    <span
                      id="composition-button"
                      aria-controls={open ? "composition-menu" : ""}
                      aria-expanded={open ? "true" : ""}
                      aria-haspopup="true"
                    >
                      <ExpandMoreIcon sx={{ color: "white" }} />
                    </span>
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                      sx={{ zIndex: "100000" }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom" ? "center top" : "center bottom",
                            transform: isMobile && "translateX(30px)"
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClosePOP}>
                              <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                                className="language_dropdown"
                                sx={{ width: "200px", paddingTop: "0" }}
                              >
                                <p
                                  style={{
                                    backgroundColor: "#F0F2F2",
                                    padding: "10px 40px",
                                  }}
                                >
                                  {t('ChooseyourLanguage')}
                                </p>
                                {Object.keys(languages).map((lng) => (
                                  <MenuItem
                                    key={lng}
                                    onClick={(e) => { setLanguageFunc(lng); handleClosePOP(e); }}
                                    sx={{ paddingTop: "0", paddingBottom: "0", minHeight: isMobile ? "28px" : "initial" }}
                                  >
                                    <input type="radio" className="radio_input" style={{ accentColor: language === lng ? 'orange' : '' }} checked={language === lng && true} />{" "}
                                    <span className="lang_list_item">
                                      {`${languages[lng].nativeName}-${languages[lng].abbr}`}
                                    </span>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                  <Link to={`/store-finder`} className="storeIcon storeLogo">
                    <p>
                      <span>{t("header.store")}</span>
                    </p>
                    <img
                      src={storeWhite}
                      alt="store-icon"
                      style={{ marginBottom: "0px", width: "20px" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            className="categories_Container"
            style={{
              height: "95px",
              top: scrollDirection === "down" ? "-99px" : "140px",
              opacity: scrollDirection === "down" ? "0" : "1"
            }}
          >
            <div style={{
              position: "relative",
            }}>
              <div
                className="hamburger"
                style={{ marginTop: "-20px" }}
                // onClick={() => setSidebar(true)}
                onClick={() => setOpenDropDown(prev => !prev)}
              >
                <span className="dropDownText"  >
                  <MenuIcon id="hamburgerIcon" fontSize="large" />
                </span>
              </div>
              <HeaderDropDown open={openDropDown} setOpen={setOpenDropDown} />

              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                All
              </span>
            </div>

            <Carousel
              ref={mobileCarouselRef}
              divsToShow={1}
              itemsToScroll={1}
              itemsToShow={4}
              style={{ marginTop: "20px" }}
              enableAutoPlay={true}
              transitionMs={1000}
              enableSwipe={true}
              autoPlaySpeed={3000}
              renderArrow={CustomArrow}
              onNextEnd={({ index }) => {
                clearTimeout(resetTimeout);
                resetTimeout = setTimeout(() => {
                  mobileCarouselRef?.current?.goTo(0);
                }, 3000); // same time
              }}
            >
              {categoriesList2.map((item, index) => (
                <div
                  className="category carousel-itm"
                  key={index}
                  onClick={() => handleCategorySearch(item.categoryLink)}
                >
                  {!categoryImageLoaded[index] && <Skeleton variant="circular" width={40} height={40} />}
                  <img
                    src={item.categoryImage}
                    alt={`Category_img_Mobile-${item.categoryName}`}
                    onLoad={() => handleCategoryImageLoad(index)}
                    style={{ display: categoryImageLoaded[index] ? 'block' : 'none' }}
                  />
                  <p>{item.categoryName}</p>
                </div>
              ))}
            </Carousel>
            {/* <ArrowRightIcon
              style={{
                marginTop: "-26px",
                fontSize: "35px",
                cursor: "pointer",
              }}
            /> */}
          </div>
        </header>
      )}


      <LocationModal
        open={openModal}
        handleClose={handleClose}
        setOpenModal={setOpenModal}
        isMobile={isMobile}
      />
      {/* <Sidebar
        sidebar={sidebar}
        setSidebar={setSidebar}
        userLoggedIn={userLoggedIn}
        handleCategorySearch={handleCategorySearch}
      /> */}
    </>
  );
}
export default HeaderBar2;