import React, { useState } from 'react';
import Rating from '@mui/material/Rating';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ReplyIcon from '@mui/icons-material/Reply';
import useUserCtx from '../../hooks/useUserCtx';
import { Button, TextField } from '@mui/material';
import defaultUserImage from "../../assets/png/default_user_image.png";

const ReviewsCard = ({ review, onLikeReviews, onReplyToReview, onShowMoreClick, isNested, parentReviewId }) => {
  const { userContext } = useUserCtx();
  const [showReplies, setShowReplies] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [text, setText] = useState('');
  const [nestedComments, setNestedComments] = useState([]);

  const onShowMore = async (commentId) => {
    if (!showReplies) {
      const data = await onShowMoreClick(commentId);
      setNestedComments(data);
      setShowReplies(true);
    }
    else {
      setShowReplies(!showReplies);
    }
  }

  return (
    <div className={isNested ? 'ms-4 mt-3' : ''} style={{ display: "flex", gap: "1rem" }}>
      <img src={review?.user?.photo ?? defaultUserImage} alt="profile" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}>
        <span className='reviewHead' style={{ fontSize: "1.1rem" }}>{review.user.fullName}</span>
        {!isNested && <Rating
          precision={0.5}
          name="simple-controlled"
          readOnly
          value={review?.productRating}
          sx={{ fontSize: "1rem"}}
        />}
        <span style={{ fontSize: "0.8rem", margin: "0.4rem 0", fontWeight: "500" }}>{review?.productReview}</span>
        <div style={{
          display: "flex",
          gap: "10px",
          alignItems: 'center'
        }}>
          <div className='d-flex align-items-center cursor-pointer' onClick={() => onLikeReviews(review)}>
            {review.likes.includes(userContext?.id) ? <ThumbUpIcon /> : <ThumbUpOffAltOutlinedIcon />}
          </div>
          <span>{review.likes?.length}</span>
          {/* <ReplyIcon />
        <span className='cursor-pointer' onClick={() => setIsReplying(!isReplying)}>Reply</span>
        {review.comments.length && !isNested ? <span className='text-primary cursor-pointer' onClick={() => {
          onShowMore(review._id)
        }}>
          {showReplies ? 'Hide Replies' : 'Show Replies'}
        </span> : null} */}
        </div>
      </div>

      {isReplying && <>
        <TextField
          required
          id="review-input"
          label="Review"
          multiline
          rows={1}
          name="reviewText"
          className='mt-4 ms-3 w-75'
          style={{ background: "white" }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <div className='d-flex justify-content-end mt-1'>
          <Button onClick={() => {
            onReplyToReview(review._id, text, parentReviewId);
            setIsReplying(false);
            isNested && setShowReplies(false);
          }} style={{ background: 'rgb(27, 50, 94)' }} variant='contained'>Reply</Button>
        </div>
      </>}
      {
        showReplies && nestedComments.map((i) => (
          <ReviewsCard isNested review={i} parentReviewId={review._id} onLikeReviews={onLikeReviews} onReplyToReview={onReplyToReview}
            onShowMoreClick={onShowMoreClick} />
        ))
      }
      <hr className="horizontal-line" />
    </div>
  )
}

export default ReviewsCard