import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SiteContext = createContext(null);

const SiteContextProvider = (props) => {

    const { i18n } = useTranslation();
    
    const [language, setLanguage] = useState(() => {
        const lng = localStorage.getItem("language");
        if (lng) {
            i18n.changeLanguage(lng);
            return lng
        } else return "en"
    });
    const [loginRedirect, setLoginRedirect] = useState(false);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [modalDataMobile, setModalDataMobile] = useState({
        number: null,
        oldData: "",
        newData: "",
    });
    const [headerData, setHeaderData] = useState({
        header3Cond: false,
        headerText: "",
        categoriesCond: false,
        header3Store: false,
        header3Cart: false,
        header3Profile: false,
    });

    const [homePageSections, setHomePageSections] = useState([]);

    function setLanguageFunc(lang) {
        setLanguage(lang);
        localStorage.setItem("language", lang);
        i18n.changeLanguage(lang);
    }

    return (
        <SiteContext.Provider
            value={{
                homePageSections,
                setHomePageSections,
                loginRedirect,
                setLoginRedirect,
                userLoggedIn,
                setUserLoggedIn,
                modalDataMobile,
                setModalDataMobile,
                headerData,
                setHeaderData,
                language,
                setLanguageFunc
            }}
        >
            {props.children}
        </SiteContext.Provider>
    );
};

export { SiteContext };
export default SiteContextProvider;
