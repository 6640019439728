import axios from "./axiosInstance";

//GET ALL PRODUCTS DATA
export const getAllProducts = async (query = "") => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
    };

    let allProductsResponse;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product?${query}`, { headers }).then((res) => {
        allProductsResponse = res.data.data;
        // console.log(allProductsResponse);
    });

    return allProductsResponse;
};

//GET ALL PRODUCTS DATA
export const getAllAdminProducts = async (query = "") => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
        Authorization: `Bearer ${adminToken}`,
    };
    let allProductsResponse;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product/admin/all?${query}`, { headers }).then((res) => {
        allProductsResponse = res.data.data;
    });

    return allProductsResponse;
};



//DELETE PARTICULAR PRODUCT
export const deleteProductAPI = async (id) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
        Authorization: `Bearer ${adminToken}`,
    };
    let allProductsResponse;

    await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/${id}`, { headers }).then((res) => {
        allProductsResponse = res.data.data;
    }).catch(err => allProductsResponse = err?.response)

    return allProductsResponse;
};

//GET SPECIFIC PRODUCT DATA FROM EANS
export const getProductsFromEans = async (products) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
    };
    let allProductsResponse;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product/getMultipleProductsFromEans?eans=${products}`, { headers }).then((res) => {
        allProductsResponse = res.data;
    });

    return allProductsResponse;
};

//HOME PAGE ---> NEW ARRIVALS SECTION 
export const getNewArrivalProductsForHomePage = async (products) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
    };
    let allProductsResponse;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product/home-page/new-arrivals`, { headers }).then((res) => {
        allProductsResponse = res.data;
    });

    return allProductsResponse;
};

//GET INDIVIDUAL PRODUCT
export const getIndiProduct = async (id) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
    };
    let indiProductResponse;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product/${id}`, { headers }).then((res) => {
        indiProductResponse = res.data.data.product;
        // console.log(indiProductResponse);
    });

    return indiProductResponse;
};

//GET SEARCHED PRODUCT
export const getSearchedProduct = async (query) => {

    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
    };
    let searchedProductRes;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product?${query}`, { headers }).then((res) => {
        searchedProductRes = res.data.data;
    });
    return searchedProductRes
    
};

export const getProductsFromBrand = async(slug, query) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
    };
    let searchedProductRes;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product/brand/${slug}?${query}`, { headers }).then((res) => {
        searchedProductRes = res.data.data;
    });
    return searchedProductRes
}

//Get the values for dynamic filtering for Product Brands
export const getValuesForDynamicFiltersForBrands = async (slug) => {
    let dynamicFilterValue;
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
    };

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product/brand/filters/${slug}`, { headers }).then((res) => {
        dynamicFilterValue = res.data;
    });
    
    return dynamicFilterValue
};

//Get Product Serviceability
export const getProductServiceability = async (zip, prodArray) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
    };
    let productServiceabilityRes;

    let serviceBody = {
        location: zip,
        items: prodArray,
    };

    await axios.post(`${process.env.REACT_APP_BASE_URL}/product/order/checkServiceability/`, JSON.stringify(serviceBody), { headers }).then((res) => {
        productServiceabilityRes = res?.data?.data?.items;
    });

    return productServiceabilityRes;
};

//Get all interested product leads
//Pass query with api call for store wise leads
export const getAllInterestedProds = async (query) => {
    let getAllInterestedProdsRes;
    let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product/interested-product?${query}`, { headers }).then((res) => {
        getAllInterestedProdsRes = res;
    });

    return getAllInterestedProdsRes;
};

//Add Product Interested
export const addProductInterested = async (storeCode, prodId) => {
    let addProdInterestedRes = true;

    let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    let interestedProdBody = {
        storeId: storeCode,
        productId: prodId,
    };

    await axios.post(`${process.env.REACT_APP_BASE_URL}/product/interested-product/`, JSON.stringify(interestedProdBody), { headers }).then((res) => {
        addProdInterestedRes = res;
    });

    return addProdInterestedRes;
};

//Remove interested Product lead
export const removeInterestedProduct = async (id) => {
    let removeInterestedProductRes;

    let userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/interested-product/${id}`, { headers }).then((res) => {
        removeInterestedProductRes = res;
    });

    return removeInterestedProductRes;
};

export const storeProdNotificationService = async (productId) => {
    const userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    const url = `${process.env.REACT_APP_BASE_URL}/user/store-notification/`
    const res = await axios.put(url, { productId }, { headers });

    return res;
}

// gets details of an offer
export const getOfferDetails = async (offerKey) => {
    const userToken = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).JWT : "";

    const headers = {
        "Access-Control-Allow-origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    return await axios.get(`${process.env.REACT_APP_BASE_URL}/product/offer/${offerKey}`, { headers });
}

// get new eans from the system
export const getNewEans = async (query) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
        Authorization: `Bearer ${adminToken}`,
    };

    return await axios.get(`${process.env.REACT_APP_BASE_URL}/product/pos/getEans?${query}`, { headers });
}

// updates new ean record
export const updateEan = async (eanPayload) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
        Authorization: `Bearer ${adminToken}`,
    };

    return await axios.put(`${process.env.REACT_APP_BASE_URL}/product/pos/updateEans/${eanPayload.ean}`, eanPayload, { headers });
}

// saves the new ean into product master
export const saveEanToMaster = async (product) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
        Authorization: `Bearer ${adminToken}`,
    };

    return await axios.post(
      `${process.env.REACT_APP_BASE_URL}/product/pos/saveInMaster/${product.ean}`,
      product,
      { headers }
    );
}

// toggles live status of a product
export const toggleLiveStatus = async (productId) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";

    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
        Authorization: `Bearer ${adminToken}`,
    };

    return await axios.patch(`${process.env.REACT_APP_BASE_URL}/product/toggleGoLiveMaster/${productId}`, {}, { headers });
}

//Get the values for dynamic filtering
export const getValuesForDynamicFilters = async (hierarchyL2) => {
    let dynamicFilterValue;
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
    };

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product/getDynamicFiterValues/${hierarchyL2}`, { headers }).then((res) => {
        dynamicFilterValue = res.data;
    });
    return dynamicFilterValue
    
};
export const getSearchValuesForDynamicFilters = async (searchValue) => {
    let dynamicFilterValue;
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
        "Access-Control-Allow-origin": "*",
    };

    await axios.get(`${process.env.REACT_APP_BASE_URL}/product/getSearchDynamicFiterValues/search/${searchValue}`, { headers }).then((res) => {
        dynamicFilterValue = res.data;
    });
    return dynamicFilterValue
    
};

// bulk delete the Eans
export const bulkDeleteEans = async () => {
    let adminToken = localStorage.getItem("admin-token") ?? "";
    
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${adminToken}`,
    };
    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/pos/bulkDeleteEan`, { headers });
}

// get product stock quantity details
export const getProductStockQuantity = async (ean) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/store/stock-sync/fetch?ean=${ean}`);
}

// get product stock quantity details
export const getProductRatingDetails = async (productId) => {
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/product/ratingDetails/${productId}`)
}

// check service availability of a product against serviceable stores
export const checkProdServiceabilityStatus = async (item, pincode) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    const payload = {
        ...item,
        pincode
    };

    return await axios.post(`${process.env.REACT_APP_BASE_URL}/product/check-servicability-status`, payload, headers);
}

// Product review, rating and comments apis

// add comment o product
export const addNewProductComment = async (payload) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.post(`${process.env.REACT_APP_BASE_URL}/product/comments`, payload, headers);
}

// get product reviews
export const getProductComment = async (url) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.get(`${process.env.REACT_APP_BASE_URL}/product/comments${url}`, headers);
}

// add like to existing products reviews
export const addLikeProductComment = async (payload) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.post(`${process.env.REACT_APP_BASE_URL}/product/comments/like`, payload, headers);
}

// remove like to existing products reviews
export const removeLikeProductComment = async (payload) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.delete(`${process.env.REACT_APP_BASE_URL}/product/comments/like`, { data: payload }, headers);
}

// reply to product reviews
export const replyToProductComments = async (payload) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.post(`${process.env.REACT_APP_BASE_URL}/product/comments`, payload, headers);
}

// get nested comments
export const getNestedComments = async (parentId) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.get(`${process.env.REACT_APP_BASE_URL}/product/comments/parent/${parentId}`, headers);
}

// get store detais
export const getStoreDetailsForDeliveredProducts = async (id) => {
    const headers = {
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
        "Content-Type": "application/json",
    };

    return await axios.get(`${process.env.REACT_APP_BASE_URL}/product/getStoreDetails/${id}`, headers);
}

// get users' subscribed products
export const getUsersSubProds = async () => {
    const userToken = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")).JWT
        : "";;

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/subscribed-products`,
        { headers }
    );

    return response;
};

// unsubscribes a product
export const unsubsAProduct = async (prodId) => {
    const userToken = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")).JWT
        : "";;

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/user/unsubscribe-product/${prodId}`,
        {},
        { headers }
    );

    return response;
};

export const suggestedProducts = async (userId = null) => {
    const userToken = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")).JWT
        : "";;

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/product/suggestions/${userId}`,
        { headers }
    );

    return response;
};

export const getAlternateProductDetails = async (productId) => {
    const userToken = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")).JWT
        : "";;

    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
    };

    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/product/alternate-product?productId=${productId}`,
        { headers }
    );

    return response;
};