import React, { useEffect, useMemo, useRef, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "./HeaderDropDown.css";
import DropDownItemModal from "../DropDownItemModal/DropDownItemModal";
import { Box, Modal } from "@mui/material";
import useScreenSize from "../../utils/ScreenSizeCalculation";

import apple from "../../assets/companyLogo/appleLogo.svg";
import samsung from "../../assets/companyLogo/samsungLogo.svg";
import realMe from "../../assets/companyLogo/realMeLogo.svg";
import google from "../../assets/companyLogo/googleLogo.svg";
import oppo from "../../assets/companyLogo/oppoLogo.svg";
import vivo from "../../assets/companyLogo/vivoLogo.svg";
import iqoo from "../../assets/companyLogo/iqooLogo.svg";
import onePlus from "../../assets/companyLogo/onePlusLogo.svg";
import poco from "../../assets/companyLogo/pocoLogo.svg";
import asus from "../../assets/companyLogo/asusLogo.svg";
import lava from "../../assets/companyLogo/lavaLogo.svg";
import nokia from "../../assets/companyLogo/nokiaLogo.svg";
import techno from "../../assets/companyLogo/tecnoLogo.svg";
import boat from "../../assets/companyLogo/boatLogo.svg";
import stuffcool from "../../assets/companyLogo/stuffcool-logo.svg";

const HeaderDropDown = ({ open, setOpen }) => {

  const [rotate, setRotate] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef(null);
  const screenType = useScreenSize();

  useEffect(() => {
    const handleClickOutside = (event, type) => {
      if (type === "scroll") handleClose()
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        !event.target.closest(".hamburger") && handleClose()
      }
    };

    document.addEventListener('mousedown', (e) => handleClickOutside(e, "click"));
    document.addEventListener('scroll', (e) => handleClickOutside(e, "scroll"));

    return () => {
      document.removeEventListener('mousedown', (e) => handleClickOutside(e, "click"));
      document.removeEventListener('scroll', (e) => handleClickOutside(e, "scroll"));
    };
  }, []);

  const handleClose = () => {
    setOpenModal(false);
    setRotate("");
    setOpen(false);
  };

  const handleOpen = (item) => {
    if (openModal === true && rotate === item) {
      setOpenModal(false);
    } else {
      setOpenModal(true);
    }
    if (rotate === item) {
      setRotate("");
    } else {
      setRotate(item);
    }
  }

  const itemArray = useMemo(() => [
    {
      display: "Smartphones",
      name: "Smartphone",
      children: [
        [
          { image: apple, name: "Apple" },
          { image: samsung, name: "samsung" },
          { image: realMe, name: "realme" },
        ],
        [
          { image: google, name: "google" },
          { image: oppo, name: "oppo" },
          { image: vivo, name: "vivo" },
        ],
        [
          { image: iqoo, name: "iqoo" },
          { image: onePlus, name: "onePlus" },
          { image: poco, name: "poco" },
        ],
        [
          { image: asus, name: "asus" },
          { image: lava, name: "lava" },
          { image: nokia, name: "nokia" },
        ],
        [{ image: techno, name: "techno" }]
      ]
    },
    {
      display: "Tablets",
      name: "Tablet",
      children: [
        [
          { image: samsung, name: "samsung" },
        ],
      ]
    },
    { display: "TWS", name: "True Wireless Earbuds", children: [
      [
        { image: boat, name: "boat" },
        { image: realMe, name: "realme" },
        { image: oppo, name: "oppo" },
      ],
    ] },
    { display: "Powerbanks", name: "powerbank", children: [
      [
        { image: stuffcool, name: "stuffcool" },
        { image: realMe, name: "realme" },
      ],
    ] },
    { display: "Speakers", name: "Speaker", children: [
      [
        { image: realMe, name: "realme" },
      ],
    ] },
  ], []) 


  return (
    open && (
      <>
        <div className="dropDownContainer" ref={modalRef} data-categorydropdownopen={open ? "open" : "close"}>
          <div className="dropDownItemsCon">
            {itemArray.map((item, index) => (
              <div
                key={index}
                className="dropDownItem"
                onMouseEnter={screenType === "desktop" ? () => handleOpen(item.name) : () => { }}
                onPointerEnter={screenType !== "desktop" ? () => handleOpen(item.name) : () => { }}
              >
                <h4>{item.display}</h4>
                {rotate === item.name && (
                  <DropDownItemModal
                    index={index}
                    open={openModal}
                    handleClose={handleClose}
                    item={item.name}
                    logos={item.children}
                  />
                )}
                <span className="rightArrowIcon">
                  <ArrowRightIcon className={rotate === item.name ? "rotate" : ""} />
                </span>
              </div>
            ))}
          </div>
        </div>
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box></Box>
          </Modal>
        </div>
      </>
    )
  );
};

export default HeaderDropDown;
