import React, { useCallback, useMemo, useState } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import "./ProductCard.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { currencyFormat } from "../../utils";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useUserCtx from "../../hooks/useUserCtx";
import NothingImage from "../../assets/png/your_order_image.png";

import { PRODUCT_CLASSIFICATION } from "../../utils/Constants";
// APIs
import { getSearchedProduct, checkProdServiceabilityStatus } from "../../api/Product";
import { addToCart, getCartData, removeAllFromCart, removeFromCart } from "../../api/Cart";
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { addToWishlist, deleteFromWishlist, getAllWishlistItems } from "../../api/wishlistApi";

const ProductCard = ({
    id,
    productImg,
    productName,
    productDescription,
    productRating,
    productPriceObj,
    productDiscountObj,
    productSlug,
    wishlist,
    cart
}) => {
    const { userContext, setCartArray, setOrderInit, userZip, setUserWishlist, setUserComboCart } = useUserCtx();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery("(max-width:450px)");
    const { t } = useTranslation();

    const [isLoadingBuyNow, setIsLoadingBuyNow] = useState(false);

    // Computed
    const maxMRPPrice = productPriceObj?.mrp ? productPriceObj?.mrp : 0;

    const getDiscount = useMemo(() => {
        if (productPriceObj) {
            let productPrice = productPriceObj?.mop;

            let price = productPriceObj?.mrp;
            let discountPercentage = 0;

            // deduct flat discount
            if (productDiscountObj?.flatDiscount?.value > 0) {
                if (productDiscountObj?.flatDiscount?.discountType === "flat") {
                    productPrice -= productDiscountObj?.flatDiscount?.value;
                } else {
                    productPrice -=
                        (productPrice *
                            productDiscountObj?.flatDiscount?.value) /
                        100;
                }
            }
            if (price !== productPrice) {
                discountPercentage = Math.round(
                    ((price - productPrice) / price) * 100
                );
            }
            return {
                discountPrice: currencyFormat(productPrice),
                discountPercentage,
            };
        }
        return { discountPercentage: 0 };
    }, [productPriceObj, productDiscountObj]);

    const setCartFunc = () => {
        getCartData().then((res) => {
            if (res) {
                let prod = [];
                res.cart.forEach((item) => {
                    if (item?.productId === null) return;
                    let product = { ...item?.productId };
                    product["quantity"] = item.qty;
                    product["from"] = item.from;
                    prod.push(product);
                });
                setCartArray({
                    loaded: true,
                    no_of_carts: prod.length,
                    cart: prod,
                    combo: res.combo,
                });
            }
        })
    }

    const handleAddToCart = (id, fromCart = true) => {
        let userToken = userContext ? userContext.JWT : "";
        if (userToken) {
            let brand_store_id = "";
            if (location.pathname.split("/")[1] === "store") {
                brand_store_id = location.pathname.split("/")[2];
            }
            addToCart(id, 1, brand_store_id).then((res) => {
                if (res?.data?.status === "error") {
                    if (window.confirm(res.data.message)) {
                        removeAllFromCart().then(response => {
                            if (response) {
                                setCartFunc();
                                setOrderInit(prev => ({ ...prev, type: brand_store_id ? "Manual" : "", brand_store_id }))
                                toast.success(t('manualAddToCart'));
                                setIsLoadingBuyNow(false);
                            }
                        })
                    }
                }
                else if (res?.data?.status === "Success") {
                    setCartFunc();
                    setIsLoadingBuyNow(false);
                    if (fromCart) {
                        toast.success(t("product-page.added-to-cart"));
                    }
                    else navigate("/payment");
                }
                else toast.error(t('writeToUs.error'))
            });
        } else {
            navigate("/login");
        }
    };

    // check whether prod is serviceable and then adds it to cart
    const prepareAddToCart = async (productId) => {
        if (!userZip.value) {
            toast.error(t('NoUserPincode'))
            return;
        }

        const userToken = userContext ? userContext.JWT : "";
        if (userToken) {
            setIsLoadingBuyNow(true);
            try {
                const res = await getSearchedProduct("slug=" + productSlug);
                let product = res.products[0];
                if (!product || product.classification === PRODUCT_CLASSIFICATION.TEMP_HIDDEN) {
                    navigate("/not-found");
                } else {
                    product["quantity"] = 1
                };

                if (
                    product &&
                    (await checkThisProductServiceability(product))
                ) {
                    handleAddToCart(productId, true);
                }
            } finally {
                setIsLoadingBuyNow(false);
            }
        } else {
            navigate("/login");
        }
    }

    const handleRemoveFromCart = (id) => {
        removeFromCart(id).then((res) =>
            res
                ? (setUserComboCart([]),
                    toast.error(t('cart.remove-from-cart')),
                    setCartFunc())
                : ""
        );
    };


    // check whether the product is serviceable to the user
    const checkThisProductServiceability = async (product) => {
        let isProductServiceable = false;
        const pincode = userZip.value;
        if (product.ean) {
            const item = {
                ean: product.ean,
                title: product.title,
                quantity: 1,
            };

            const response = await checkProdServiceabilityStatus(item, pincode);
            const payload = response.data;
            if (payload.status === "success") {
                isProductServiceable = payload.data;
            } else {
                toast.error(t('productNotServicable'));
            }
        }

        return isProductServiceable;
    };

    const handleBuyNow = async (id) => {
        const userToken = userContext ? userContext.JWT : "";
        if (userToken) {
            if (!userZip.value) {
                toast.error(t('NoUserPincode'))
                return;
            }
            let productId = id;
            let quantity = [1];
            setOrderInit((prev) => ({
                ...prev,
                productId: [productId],
                quantity: quantity,
            }));
            setIsLoadingBuyNow(true);
            try {
                const res = await getSearchedProduct("slug=" + productSlug);
                let product = res.products[0];
                if (!product || product.classification === PRODUCT_CLASSIFICATION.TEMP_HIDDEN) {
                    navigate("/not-found");
                } else {
                    product["quantity"] = 1
                };

                if (
                    product &&
                    (await checkThisProductServiceability(product))
                ) {
                    handleAddToCart(productId, false);
                }
            } finally {
                setIsLoadingBuyNow(false);
            }
        } else {
            navigate("/login");
        }
    };

    const from = useMemo(() => {
        const arr = location.pathname.split("/");
        const type = location.pathname.split("/")[1] === "store";
        return {
            brand_store_id: type ? arr[2] : "",
            type: type ? "Manual" : ""
        }
    }, [location]);

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#FFCC0A',
        },
        '& .MuiRating-iconHover': {
            color: '#FFCC0A',
        },
    });

    const handleAddToWishlist = useCallback((id, wishlist) => {
        let userToken = userContext ? userContext?.JWT : "";
        if (userToken) {
            if (!wishlist) {
                addToWishlist(id).then((res) => {
                    if (res.status === 200) {
                        toast.success(t('product-page.added-to-wishlist'));
                        getAllWishlistItems(true).then(response => {
                            if (response) setUserWishlist(prev =>
                            ({
                                ...prev,
                                loaded: true,
                                no_of_wishlist_items: response?.no_of_wishlist_items,
                                wishlist_items: response?.wishlist_items
                            }))
                        })
                    } else toast.error(t('addProductInWishlistError'))
                }
                );
            } else {
                deleteFromWishlist(id).then(res => {
                    if (res.status === 200) {
                        toast.error(t('notifications.ProductRemovedFromWishlist'));
                        getAllWishlistItems(true).then(response => {
                            if (response) setUserWishlist(prev =>
                            ({
                                ...prev,
                                loaded: true,
                                no_of_wishlist_items: response?.no_of_wishlist_items,
                                wishlist_items: response?.wishlist_items
                            }))
                        })
                    } else toast.error(t('deleteProductInWishlistError'))
                })
            }
        } else {
            navigate("/login");
        }
    }, [])


    return (
        <div className="product-card">
            <Link
                className="product-img cursor-pointer"
                to={{
                    pathname: `/product/${productSlug}`,
                }}
                state={{ ...from }}
            >
                <div className="tags-container">
                    <div className="tags">
                        {getDiscount.discountPercentage > 0 ? (
                            <div
                                className="tag-item tag-discount"
                                style={{ padding: "5px" }}
                            >
                                <span>-{getDiscount.discountPercentage}%</span>
                            </div>
                        ) : null}
                        <div className="tag-item tag-best-seller">
                            <span
                                style={{ fontSize: "8px", fontWeight: "500" }}
                            >
                                {t("product-page.best-seller")}
                            </span>
                        </div>
                    </div>
                    <div
                        className="tag-fav"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            handleAddToWishlist(id, wishlist);
                        }}
                    >
                        <StyledRating
                            name="wishlist"
                            title={
                                wishlist
                                    ? t('removeFromWishlist')
                                    : t('product-page.add-to-wishlist')
                            }
                            max={1}
                            defaultValue={wishlist ? 1 : 0}
                            icon={<FavoriteIcon fontSize="inherit" />}
                            emptyIcon={
                                <FavoriteBorderIcon fontSize="inherit" />
                            }
                        />
                    </div>
                </div>

                <img
                    src={productImg ?? NothingImage}
                    alt={productImg ?? `image-${id}`}
                />
            </Link>
            <div className="product-info">
                <p
                    className="prod-item prod-name cursor-pointer"
                    onClick={() => navigate(`/product/${productSlug}`)}
                >
                    {productName}
                </p>
                {/* <p className="prod-item prod-desc mb-2 mb-lg-3">
                    {productDescription?.length ? productDescription : ""}
                </p> */}
                <div className="d-flex flex-column flex-lg-row">
                    <div className="mt-0 mt-lg-2" style={{ width: "46%" }}>
                        <Rating
                            style={{ width: "80%", marginBottom: "10px" }}
                            name="product-rating"
                            readOnly
                            value={productRating}
                            size="small"
                        />

                        {maxMRPPrice - getDiscount.discountPrice > 0 && (
                            <p className="prod-item prod-price">
                                <s>{currencyFormat(maxMRPPrice)}</s>
                            </p>
                        )}
                        <p className="prod-item prod-discount-price mt-1 mt-lg-3 mb-2">
                            {getDiscount.discountPrice}
                        </p>
                    </div>
                    <div>
                        <div className="emi-offer">
                            <span>*{t("EMIAvailable")}</span>
                            <span>
                                *{t("Checkbank")}{' '}{t("offer")} {isMobile && <br />}{" "}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="prod-buy">
                <button
                    className="prod-buy-btn"
                    onClick={() => handleBuyNow(id)}
                >
                    {t("product-page.buy-now")}
                    {isLoadingBuyNow && (
                        <span
                            className="spinner-border spinner-border-sm align-text-bottom ms-2"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    )}
                </button>
                {!cart ? (
                    <>
                        <ShoppingCartOutlinedIcon
                            fontSize="large"
                            className="CardShoppingCartIcon"
                            onClick={() => prepareAddToCart(id)}
                        />
                        {isLoadingBuyNow && (
                            <span
                                className="spinner-border spinner-border-sm align-text-bottom ms-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                    </>
                ) : (
                    <ShoppingCartRoundedIcon
                        fontSize="large"
                        className="CardShoppingCartIcon"
                        onClick={() => handleRemoveFromCart(id)}
                    />
                )}
            </div>
        </div>
    );
};

export default ProductCard;
