import React from 'react'

import ShareIcon from "@mui/icons-material/Share";
import useUserCtx from '../../hooks/useUserCtx';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import defaultUserImage from "../../assets/png/default_user_image.png";

function WishListUserDetails(props) {

  const { userContext } = useUserCtx();
  const { t } = useTranslation();
  const { fullName = 'John Doe', profilePic = null } = userContext ?? '';

  const location = useLocation();

  const breadCrumbsData = [
    {
      text: t('profile.profile') + " / ",
      url: "/myProfile",
      isActive: location.pathname === "/myProfile"
    },
    {
      text: t('profile.wishlist'),
      url: "/wishlist",
      isActive: location.pathname === "/wishlist"
    },
  ];

  return (
    <div className="newWishListDetails">
      <div className="newWishListTitle">
        <h1>{breadCrumbsData.map((item, index) =>
          <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>{item.text}
          </Link>)}
        </h1>
      </div>
      <div className="newWishListUserContainer">
        <div className="newWishListUser">
          <div className="newWishListStart">
            <h1 className="newWishListHeading">{t("wishList")}</h1>
            {props.isMobile && (
              <div>
                <button
                  className="tagList"
                  style={{
                    border: "none",
                    background: "none",
                    fontSize: "10px",
                  }}
                >
                  <ShareIcon
                    sx={{
                      color: "#5A99CD",
                      backgroundColor: "#f9f8f9",
                      padding: "3px",
                      borderRadius: "4px",
                    }}
                  />{" "}
                  {t("product-page.share")}
                </button>
              </div>
            )}
          </div>
          <div className="newWishListUserContent">
            <div className="newWishListImage">
              <img src={profilePic ? profilePic : defaultUserImage} alt="userImage" />
              <h1 className="newWishListUserName">{fullName}</h1>
            </div>
            {/* {!props.isMobile && (
              <div>
                <button
                  className="tagList"
                  style={{
                    border: "none",
                    background: "none",
                  }}
                >
                  <ShareIcon
                    sx={{
                      color: "#5A99CD",
                      backgroundColor: "#f9f8f9",
                      padding: "3px",
                      borderRadius: "4px",
                    }}
                  />{" "}
                  {t("product-page.share")}
                </button>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WishListUserDetails
