import React, { useEffect, useState } from "react";
import "./ThankYouPage.css";

import rating from "../../assets/productIcons/rating.svg";
import Correct from "./correct.svg";
import NoImage from "../../assets/vector/noImage.svg";
import checked_circle from "../../assets/vector/check_circle_FILL0_wght400_GRAD200_opsz48.svg";
import processing_gif from "../../assets/gif/processing.gif";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserOrders } from "../../api/OrdersApi";
import { getCartData, removeFromCart } from "../../api/Cart";
import { getSearchedProduct } from "../../api/Product";
import useUserCtx from "../../hooks/useUserCtx";
import { useTranslation } from "react-i18next";
import { currencyFormat } from "../../utils";

const ThankyouPage = () => {
    const { t } = useTranslation();
    const { setCartArray } = useUserCtx();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get("orderId");
    if (!orderId) navigate("/");

    // state definitions
    // products that are added as combo offer to product in cart
    const [comboProducts, setComboProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const [allOrders, setAllOrders] = useState({
        loaded: false,
    });
    const [currentOrder, setCurrentOrder] = useState({});

    /* useEffect(() => {
        function beforeUnloadHandler (event) {
            event.returnValue = '';
        }
        
        window.addEventListener('beforeunload', beforeUnloadHandler);

        return () => {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        }
    }, []) */

    useEffect(() => {
        getUserOrders()
            .then((res) => {
                if (res) {
                    setAllOrders({
                        loaded: true,
                        orders: res.data.placed,
                    });
                }
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true);
        if (!allOrders.loaded) return;
        if (allOrders.orders.length === 0) return navigate("/yourOrders");
        let order = allOrders.orders.find((order) => order._id === orderId);
        if (!order) {
            alert(t("writeToUs.error"));
            navigate("/yourOrders");
        }
        setCurrentOrder(order);
        setLoading(false);
    }, [allOrders]);

    useEffect(() => {
        if (Object.keys(currentOrder).length > 0) {
            currentOrder?.productId?.forEach((prod) => {
                removeFromCart(prod);
            });
            getCartData().then((res) => {
                if (res) {
                    let prod = [];
                    res.cart.forEach((item, idx) => {
                        if (item?.productId === null) return;
                        let product = { ...item?.productId };
                        product["quantity"] = item.qty;
                        prod.push(product);
                    });
                    setCartArray({
                        loaded: true,
                        no_of_carts: prod.length,
                        cart: prod,
                        combo: res.combo,
                    });
                }
            });

            // check the product has combo offer and if yes fetch that product
            const comboProductsEan = currentOrder?.combo;
            if (comboProductsEan.length > 0) {
                for (const ean of comboProductsEan) {
                    fetchComboProduct(ean);
                }
            }
        }

        // cleanup function
        return () => {
            setComboProducts([]);
        };
    }, [currentOrder]);

    // invoke combo products
    const fetchComboProduct = async (productEan) => {
        const response = await getSearchedProduct(`ean=${productEan}`);
        const product = response.products[0];
        if (product) {
            setComboProducts((prev) => {
                return [...prev, { ...product, isComboProduct: true }];
            });
        }
    };

    // Product Card Component
    const ProductContainer = ({
        index,
        images,
        title,
        description,
        productPrice,
        quantity = 1,
        isComboProduct = false,
        prouductMrpPrice = 0
    }) => {
        return (
            <div className="product_finall row-md w-100" key={index}>
                <div className="col col-sm-3"
                    style={{
                        borderRadius: "0px",
                    }}
                >
                    <img
                        src={images[0] ?? NoImage}
                        alt="consignment"
                        style={{
                            borderRadius: "0px",
                            height: "100%",
                            width: "150px",
                        }}
                    />

                    {isComboProduct && (
                        <div style={{ maxWidth: "max-content" }} className="tag-item tag-best-seller fs-6 text-center">
                            Combo Product
                        </div>
                    )}
                </div>
                <div className="product_final__details col col-sm-6">
                    <div>
                        <span className="product_final__details__name">
                            {title}
                        </span>
                    </div>
                    <div>
                        <span className="product_final__details__name">
                            Price: {productPrice}
                        </span>
                    </div>
                    <div>
                        <span className="product_final__details__name">
                            Quantity: {quantity}
                        </span>
                    </div>
                    <div className="product_final__details__desc">
                        <span
                            style={{
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "#707070",
                                lineHeight: "10px",
                                marginTop: "-4px",
                            }}
                        >
                            {description[0]?.split("\n")[0]}
                        </span>
                    </div>
                    <div>
                        <img
                            style={{ width: "70px" }}
                            src={rating}
                            alt="ratings"
                        />
                    </div>
                    <div>
                        <strong>
                            {currencyFormat(productPrice * quantity)}
                        </strong>&nbsp;
                        {(prouductMrpPrice * quantity) > (productPrice * quantity) && (
                            <strong className="text-decoration-line-through text-muted">
                            {currencyFormat(prouductMrpPrice * quantity)}
                        </strong>
                        )}
                    </div>
                </div>
                <div className="col col-sm-3">
                </div>
            </div>
        );
    };

    // main renderer
    return !loading ? (
        <div className="thankyou_container page_Wrapper pt-5">
            <div className="thankyou-sec-1">
                {currentOrder.consignments.map((consignment, index) => {
                    const thisComboProd =
                        comboProducts?.filter(
                            (cProd) => consignment.combo?.childEan === cProd?.ean
                        )?.[0] ?? null;

                    return (
                        <>
                            <ProductContainer
                                images={consignment?.product?.images ?? []}
                                title={consignment?.product?.title ?? ""}
                                description={
                                    consignment?.product?.description ?? ""
                                }
                                productPrice={
                                    consignment?.computed_selling_price
                                }
                                quantity={consignment?.quantity}
                                prouductMrpPrice={consignment?.product?.price?.mrp ?? 0}
                                index={index}
                            />

                            {thisComboProd && (
                                <ProductContainer
                                    images={thisComboProd?.images ?? []}
                                    title={thisComboProd?.title ?? ""}
                                    description={
                                        thisComboProd?.description ?? ""
                                    }
                                    productPrice={
                                        consignment?.combo_child_price
                                    }
                                    index={`${thisComboProd.ean}`}
                                    isComboProduct={true}
                                />
                            )}
                        </>
                    );
                })}
            </div>

            <div className="thankyou-sec-1">
                <div className="product_finall">
                    <div className="text-uppercase">{t("total")}</div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div className="ms-2" id="price-thk2">
                        {currencyFormat(currentOrder.totalPrice)}
                    </div>
                </div>
            </div>
            <hr
                style={{
                    width: "100%",
                    border: "1px solid #000000",
                    marginBottom: "39px",
                }}
                className="hrThankyou"
            />
            <div className="thankyou-sec-2">
                <div className="thk2">
                    <div className="thk2-sub color-font">
                        {t("thankForOrder")}
                    </div>
                    <div className="thk2-sub">
                        <div className="tick-thk">
                            <img src={Correct} alt="" />
                        </div>
                        <div className="color-font2">
                            {t("orderPlacedSuccess")}
                        </div>
                    </div>
                    <div className="thk2-sub ">
                        <span className="thk-sub-content">
                            {t("thankyouMessage")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="order_proessing">
            <img src={processing_gif} alt="processing" />
            <img
                style={{ display: "none" }}
                src={checked_circle}
                alt="circle"
            />

            <h3>{t("orderProcessing")}</h3>
        </div>
    );
};

export default ThankyouPage;
