import { useState, useEffect } from "react";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup, Dropdown } from "react-bootstrap";

// API services
import {
    deletPayment,
    getAllPayment
} from "../../api/AdminApis/PaymentsApi";
import { getAdminIndOrder } from "../../api/AdminApis/AdminOrder";

// Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import Pagination from "../../components/Pagination/Pagination";
import OrderProductModal from "../../components/OrderProductModal/OrderProductModal";

import Moment from "react-moment";

// Component definition
const Payments = () => {
    //Constants
    const filterOptions = ["success", "failure"];
    const queriesMap = {
        [filterOptions[0]]: "&isSuccess=true",
        [filterOptions[1]]: "&isSuccess=false",
    };

    // state definitions
    const [loader, setLoader] = useState(false);
    const [payments, setPayments] = useState([]);
    const [totalPayments, setTotalPayments] = useState(1);
    const [statusFilter, setStatusFilter] = useState("payment status");
    const [show, setShow] = useState(false);
    const [order, setOrder] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const paymentsPerPage = 30;


    useEffect(() => {
        setLoader(true);
        const filterQuery = queriesMap[statusFilter] ?? "";
        getAllPayment(
            `?limit=${paymentsPerPage}&page=${currentPage}${filterQuery}`
        ).then((res) => {
            if (res) {
                setTotalPayments(res.total_payments);
                setPayments(res.payments);
            }
        }).finally(() => setLoader(false));
    }, [statusFilter, currentPage]);

    const handleDeletPayement = (id) => {
        const text = "Are you sure for delete?";
        if (window.confirm(text)) {
            setLoader(true);
            deletPayment(id).then((res) => {
                setPayments(payments.filter((message) => message._id !== id));
                setLoader(false);
            });
        }
    };

    /* const handleUpdatePayment = (id, status) => {
        const data = {
            isSuccess: !status,
        };
        updatePayment(id, data).then((res) => {
            const filterQuery = queriesMap[statusFilter] ?? "";
            getAllPayment(filterQuery).then((res) => {
                setPayments(res.payments);
            });
        });
    }; */

    const handleFilterChange = (status) => {
        if (status !== statusFilter) {
            setStatusFilter(status);
        }
    };

    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    const displayOrder = (orderId, userInfo) => {
      
        setLoader(true)
        setShow(true) // displays modal
        getAdminIndOrder(orderId)
            .then((data) => {
                console.log(data)
                setOrder(data?.details);
                setUserDetails(userInfo);
            })
            .catch(() => setShow(false))
            .finally(() => setLoader(false))
    }

    return loader ? (
        <DashboardLoader />
    ) : (
        <div className="container mh-100">
            <div className="d-flex justify-content-between">
                <h3>Payments</h3>

                {/** Filter dropdown */}
                <Dropdown>
                    <Dropdown.Toggle className="text-capitalize">
                        {statusFilter ? statusFilter : "payment status"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {["payment status"].concat(filterOptions).map((item, index) => (
                            <Dropdown.Item
                                key={index}
                                value={item}
                                className="text-capitalize"
                                onClick={() => handleFilterChange(item)}
                            >
                                {item}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {payments?.length > 0 && (
                <ListGroup as="ol">
                    {payments?.map((item, index) => (
                        <ListGroup.Item
                            key={index}
                            as="li"
                            className="d-flex justify-content-between align-items-start h-100"
                        >
                            <div>{(currentPage - 1) * paymentsPerPage + index + 1}.</div>
                            <div className="ms-2 me-auto">
                                <div>
                                    <span className="fw-bold">Amount:</span> {item.amount}
                                </div>
                                <span className="fw-bold"> Payment Success:</span>{" "}
                                {item.isSuccess ? "Yes" : "No"}

                                <div>
                                    <span className="fw-bold"> Payment date:</span>{" "}
                                    <Moment format="DD/MM/YYYY HH:mm:ss">{item.createdAt}</Moment>
                                </div>
                                {/** Customer Details */}
                                <div className="fw-bold">Customer</div>
                                <div>
                                    &nbsp;&nbsp;<span className="fw-bold">Name:</span>
                                    &nbsp;&nbsp;
                                    <span>
                                        {typeof item.userId === "object"
                                            ? item.userId?.fullName
                                            : ""}
                                    </span>
                                </div>
                                <div>
                                    &nbsp;&nbsp;<span className="fw-bold">Email:</span>
                                    &nbsp;&nbsp;
                                    {typeof item.userId === "object" ? item.userId?.email : ""}
                                </div>
                                <div>
                                    &nbsp;&nbsp;<span className="fw-bold">Mobile:</span>
                                    &nbsp;&nbsp;
                                    {typeof item.userId === "object"
                                        ? item.userId?.mobileNumber
                                        : ""}
                                </div>
                                {/** Order detail link */}
                                <div className="fw-bold">
                                    Order:
                                    <span
                                        className="cursor-pointer text-primary"
                                        onClick={() => displayOrder(item?.orderId, item?.userId)}
                                    >
                                        {item?.orderId}
                                    </span>
                                </div>
                            </div>

                            {/* <div>
                                <button
                                    onClick={() => handleDeletPayement(item._id)}
                                    className="btn"
                                >
                                    <FontAwesomeIcon className={"table-icon"} icon={faTrashCan} />
                                </button>
                            </div> */}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}

            {!payments?.length && (
                <h3 className="text-center p-1">No payments found</h3>
            )}

            <div className="pagination_Container mt-1">
                <Pagination
                    productsPerPage={paymentsPerPage}
                    totalProducts={totalPayments}
                    pageChange={handlePageChange}
                    currentPage={currentPage}
                />
            </div>

           { show && <OrderProductModal
                show={show}
                onHide={() => setShow(false)}
                details={{
                    consignee: userDetails,
                    order
                }}
            />}
        </div>
    );
};

export default Payments;
