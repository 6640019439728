import React from "react";
import { Link } from "react-router-dom";
import phoneIcon from "../../assets/vector/phoneIcon.svg";

export default function FindStoreContactPerson({ store, reference }) {
  return (
    <div ref={reference}>
      <Link
        className="text-decoration-none"
        to={`/store/${store.brand_store_id}`}
        state={store}
      >
        <h1 className="findStoreContactPersonName">{store.display_name}</h1>
        <p className="findStoreContactPersonAddress">
          {store.street_name}
        </p>
        <div className="findStoreContactPersonPhone">
          <img alt="phoneIcon" src={phoneIcon} className="findStoreContactPersonIcon" />
          <p className="findStoreContactPersonNumber" >{store.contact_no}</p>
        </div>
      </Link>
    </div>

  );
}