import React from 'react';
import "./CareerPage.css";
import { Input, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useMediaQuery } from "@mui/material";

import EO1 from "../../assets/career/EO-1.svg";
import EO2 from "../../assets/career/EO-2.svg";
import EO3 from "../../assets/career/EO-3.svg";
import DummyManImage from "../../assets/career/DummyManImage.svg";
import CareerDummybottomImage from "../../assets/career/CareerDummybottomImage.svg";

import logo_mob from "../../assets/vector/navbar_logo_mob.svg";
import logo_desk from "../../assets/png/navbar_logo_desk.png";
import logo_tab from "../../assets/vector/navbar_logo_tab.svg";
import { Link } from 'react-router-dom';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';

function CareerPage() {
    const isMobile = useMediaQuery("(max-width:450px)");    
    return (
        <>

        <header className={`headerbarContainer`}>
          <div className="headerbarWrapper">
            <div className="headerbarLeft">
              <div className="headerbarLeft_Inner">
                <Link to={"/"} className="navLogo">
                  <img src={logo_desk} alt="" />
                </Link>
                <div className='verticalLine'></div>
                <span className='headerText'>Jobs</span>
              </div>
              <span className='JobBtn'><BusinessCenterOutlinedIcon/> Apply for jobs</span>
            </div>
        </div>
        </header>

            <div className='sec1Parent'>
                <div className='section-1'>
                    <div className='firstSearch'>
                        <span style={{marginLeft:"2rem"}}>Find jobs</span>
                        <div>
                            <SearchIcon className='serachIcon'/>
                            <Input placeholder="Designer" className='DesignationInput'/>
                        </div>
                    </div>
                    <div className='secondSearch'>
                        <span style={{marginLeft:"1rem"}}>State</span>
                        <Input placeholder="Maharashtra"
                            style={{
                                background:"white",
                                borderRadius:"10px",
                                height:"60px",
                                paddingLeft:"10%",
                            }}/>
                    </div>
                </div>
                {!isMobile && <div className='sec1Learn'>Learn More..</div>}
            </div>

            <div className='section-2'>
                {[0,1,2].map(()=>(
                <div className='OpportunityCard'>
                    <span className='headingOC'>Opportunities for students</span>
                    <span className='OCPara'>
                        Learn about, search for, and 
                        apply to internships and 
                        full-time opportunities
                        for students.
                    </span>
                </div>
                ))}
            </div>

            <div className='section-3'>
                <span className='EO-text'>Explore opportunities</span>
                <div className='EOClass'>
                    {[EO1,EO2,EO3].map((item)=>(
                        <div style={{display:"flex", flexDirection:"column",justifyContent:"center",alignItems:"center" }}>
                            <img style={{}} className='EO-image' src={item}/>
                            <span style={{
                                position:"relative",
                                bottom:"50px",
                                color:"white",
                                fontWeight:"500",
                                fontSize:"25px"
                            }} >Explore opportunities</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className='sec4main'>
                <div className='section-4'>
                    <span className='sec4Heading'>krishna Mishra</span>
                    <div className='parentProfile'>
                        <img src={DummyManImage} style={{borderRadius:"50%"}} className='profileImageCareer' />
                        <span className='sec4Para'>
                            We’ve created a trust-driven network of brands, 
                            products, and services powered by our in-house
                            distribution system, thus bringing their latest 
                            inventories and services directly to you. 
                            We provide an agglomeration of the most popular 
                            products & services at the most affordable prices 
                            along with a never-ending series of best offers, 
                            schemes, and benefits. Also, we are tirelessly 
                            working to add better services and options to 
                            buy from at our stores. So whether it be 2 years 
                            warranty, exchange offer, assured cashback, 
                            multiple finance options, 0% down payment, 
                            paper finance, easy EMI options, or anything 
                            else, you wish it, we have it!
                        </span>
                    </div>
                </div>
                <span style={{
                    display:"flex",
                    justifyContent:"flex-end",
                    marginRight:"4%",
                    cursor:"pointer"
                }}>Read More..</span>
            </div>

            <div className='section-5'>
                <img src={CareerDummybottomImage} />
                <span>Build Your Future with us......</span>
            </div>
        </>
    )
}

export default CareerPage