import React, { useEffect, useState } from "react";

import "./styles.css";

import RealmeOne from "../../assets/MyOffers/Realme/One_Realme.png";
import RealmeTwo from "../../assets/MyOffers/Realme/Realme_Two.png";
import RealmeThree from "../../assets/MyOffers/Realme/Realme_Three.png";
import RealmeFour from "../../assets/MyOffers/Realme/Realme_Four.png";
import RealmeFive from "../../assets/MyOffers/Realme/Realme_Five.png";
import RealmeSix from "../../assets/MyOffers/Realme/Realme_Six.png";
import RealmeSeven from "../../assets/MyOffers/Realme/Realme_Seven.png";

import { Link } from "react-router-dom";
import { getProductsFromEans } from "../../api/Product";
import { toast } from "react-toastify";
import ProductCard from "../../components/Cards/ProductCard";
import useUserCtx from "../../hooks/useUserCtx";
import ProductBoxSkeleton from "../../components/ProductBoxSkeleton";

const Realme_EANS = ['6941764424371', '6941764424340', '6941764424357', '6941764424388', '6941764424333', '6941764424364'];

const RealmeProPlus = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { userWishlist, cartArray } = useUserCtx();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getProductsFromEans(Realme_EANS.toString());
                if (response?.status === "success") {
                    setData(response?.data?.products);
                } else {
                    toast.error("Something went wrong in Fetching Products.");
                }
            } catch (error) {
                toast.error("Oops! Something went wrong");
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            setData([]);
        }
    }, []);


    return (
        <div className="myRewardsWrapper">
            <div className="container">
                <h1 className="myRewardsTitle mt-4" style={{
                    fontSize: "1.5rem"
                }}>
                    Realme 12 Pro Plus
                </h1>
                <div className="d-flex flex-column gap-3">
                    <Link to={'/product/Realme-12-Pro-5G-8-256GB---Submarine-Blue'} >
                        <img src={RealmeOne} alt="Realme 12 Pro Plus 5G Banner 1" className="w-100 offer-page-hover-banner"/>
                    </Link>
                    <div className="d-flex gap-3 side-by-side-offer-banner">
                        <img src={RealmeTwo} alt="Realme 12 Pro Plus 5G Sampdragon 7s Gen" />
                        <img src={RealmeThree} alt="Realme 12 Pro Plus 5G Submarine Blue" />
                    </div>
                    <img src={RealmeFour} alt="Be a Portrait Master" />

                    {
                        loading ?
                            <div className="card-display-row d-flex gap-4">
                                {new Array(5).fill(true).map((_, index) => (
                                    <ProductBoxSkeleton key={`Product_Box_${index}`} height='40vh' />
                                ))}
                            </div>
                            :
                            data.length > 0 &&
                            <div className="card-display-row d-flex gap-3">
                                {
                                    data.map((e, i) => (
                                        <ProductCard
                                            key={`${i}-${e._id}`}
                                            id={e._id ?? ""}
                                            productImg={e.images[0]}
                                            productName={e.title}
                                            productDescription={e.description[0]?.split(" ")?.slice(0, 18)?.join(" ")}
                                            productRating={e?.rating ?? 0}
                                            productPriceObj={e?.price}
                                            productDiscountObj={e?.discount}
                                            productSlug={e?.slug}
                                            wishlist={userWishlist.wishlist_items?.some(obj => obj._id?.toString() === e._id?.toString())}
                                            cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some(obj => obj._id?.toString() === e._id?.toString())}
                                        />
                                    ))
                                }
                            </div>
                    }

                    <div className="d-flex gap-3 side-by-side-offer-banner">
                        <img src={RealmeFive} alt="Delivery Bannner" />
                        <img src={RealmeSix} alt="Five Percent Banner 2" />
                    </div>
                </div>

                <div className="mt-4">
                    <img src={RealmeSeven} alt="Realme 12 Pro Plus Eye Protector" className="find-store relative w-100" />
                </div>

            </div>
        </div>
    );
};

export default RealmeProPlus;
