import axios from "axios";

const headers = {
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
    'Content-Type': 'application/json',
    "Access-Control-Allow-origin": "*"
}

export const saveUserDetails = async (body) => {
    let result = null;

    await axios.post(`${process.env.REACT_APP_BASE_URL}/offers/step1`, JSON.stringify(body), { headers })
        .then(res => {
            result = res?.data
        }).catch(err => {
            result = err?.response
        })

    return result;
}

export const sendOTPToStore = async (body) => {
    let result = null;

    await axios.post(`${process.env.REACT_APP_BASE_URL}/offers/step2-send-storecode`, JSON.stringify(body), { headers })
        .then(res => {
            result = res?.data
        }).catch(err => {
            result = err?.response?.data
        })

    return result;
}

export const verifyOTPForStore = async (body) => {
    let result = null;

    await axios.post(`${process.env.REACT_APP_BASE_URL}/offers/step2-verify-store`, JSON.stringify(body), { headers })
        .then(res => {
            result = res?.data
        }).catch(err => {
            result = err?.response?.data
        })

    return result;
}

export const saveRating = async (body) => {
    let result = null;

    await axios.post(`${process.env.REACT_APP_BASE_URL}/offers/save-rating`, JSON.stringify(body), { headers })
        .then(res => {
            result = res?.data
        }).catch(err => {
            result = err?.response?.data
        })

    return result;
}

export const productCategoryDetails = async (body) => {
    let result = null;

    await axios.post(`${process.env.REACT_APP_BASE_URL}/offers/step3`, JSON.stringify(body), { headers })
        .then(res => {
            result = res?.data
        }).catch(err => {
            result = err?.response?.data
        })

    return result;
}

export const revealOffer = async (userId) => {
    let result = null;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/offers/reveal-offer?OfferUserID=${userId}`, { headers })
        .then(res => {
            result = res?.data
        }).catch(err => {
            result = err?.response?.data
        })

    return result;
}

export const getOfferImageURLAPI = async (userId) => {
    let result = null;

    await axios.get(`${process.env.REACT_APP_BASE_URL}/offers/offer-image-url?OfferUserID=${userId}`, { headers })
        .then(res => {
            result = res?.data
        }).catch(err => {
            result = err?.response?.data
        })

    return result;
}


//ADMIN
export const getAllUsersForOffers = async () => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  
    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };
  
    let response;
  
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/offers/admin-get-users`, { headers })
      .then((res) => {
        response = res?.data;
      })
      .catch((err) =>  response = err?.response);
    
    return response;
  };

  //GET ALL COUPONS
export const getAllCouponsOffers = async (value) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  
    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };
  
    let response;
  
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/offers/coupons-total?${value}`, { headers })
      .then((res) => {
        response = res?.data;
      })
      .catch((err) =>  response = err?.response);
    
    return response;
  };


//Add coupons to store
export const addCouponsToStores = async (payload) => {
    let adminToken = localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN_KEY) ?? "";
  
    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${adminToken}`,
    };
  
    let response;
  
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/offers/add-coupons-to-store`, JSON.stringify(payload), { headers })
      .then((res) => {
        response = res?.data;
      })
      .catch((err) =>  response = err?.response);
    
    return response;
  };


//RETAILER
export const getAllUsersForifdForRetailer = async (storeid) => {
    let Token = localStorage.getItem(process.env.REACT_APP_RETAILER_TOKEN_KEY) ?? "";
  
    const headers = {
      "Access-Control-Allow-origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
  
    let response;
  
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/ifd/retailer-get-users/${storeid}`, { headers })
      .then((res) => {
        response = res?.data;
      })
      .catch((err) =>  response = err?.response);
    
    return response;
  };