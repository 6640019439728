import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRetailerContext } from "../../Contexts/RetailerContext";
import { AdminLogout } from "../../utils";

function NavBar({ toggle, userType, heading }) {
  const nav = useNavigate();
  const { setStore } = useRetailerContext();
  function handleLogout() {
    if(userType === "retailer") {
      setStore(null);
      localStorage.removeItem("retailer_id");
      localStorage.removeItem("retailer-token");
      nav('/retailer-login')
    } else {
        AdminLogout(userType, nav)
    }
  }

  return (
    <Navbar
      bg="light"
      className="navbar shadow-sm p-3 mb-5 bg-white rounded"
      expand
    >
      <Button variant="outline-info" onClick={toggle}>
        <FontAwesomeIcon icon={faAlignLeft} />
      </Button>
      <Navbar.Text className="fw-bold ms-3 text-uppercase">{heading ?? "Admin"}</Navbar.Text>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
      
        <Nav className="ml-auto" navbar>
          <Button className="btn-sm btn-dark" onClick={handleLogout}>Logout</Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
