import React from "react";
import { Link, useNavigate } from "react-router-dom";
//CSS
import "./Footer.css";
import FooterSub1 from "../../assets/vector/Subtraction1.svg";
import FooterSub2 from "../../assets/vector/Subtraction2.svg";
//Image
import footerLogo from "../../assets/png/navbar_logo_desk.png";
import instagramIcon from "../../assets/vector/instagramIcon.svg";
import twitterIcon from "../../assets/vector/twitter_white1.svg";
import linkedinIcon from "../../assets/vector/linkedin.svg";
import youtubeIcon from "../../assets/vector/youtube.svg";
import facebookIcon from "../../assets/vector/facebook_white.svg";
import copyrightWhite from "../../assets/vector/copyright_white.svg";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../utils/ScreenSizeCalculation";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import CartBanner from "../CartProductCard/CartBanner";

const Footer = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const socialIcon = [
    { icon: twitterIcon, link: 'https://twitter.com/olineoindia' },
    { icon: facebookIcon, link: 'https://www.facebook.com/OLineOIndia/' },
    { icon: instagramIcon, link: 'https://www.instagram.com/olineoindia/' },
    { icon: linkedinIcon, link: 'https://www.linkedin.com/company/olineoindia' },
    { icon: youtubeIcon, link: 'https://www.youtube.com/@OLineOIndia' },
  ];
  const isMobile = useMediaQuery("(max-width: 500px)");
  const loc = useLocation();

  return (
    <>
      {useScreenSize() === "desktop" ? (
        <>

          {loc.pathname === "/login" ||
            loc.pathname === "/signup" ||
            loc.pathname === "/password/forgot" ||
            loc.pathname === "/create-new-password" ||
            loc.pathname === "/otp" ? (
            <div style={{ marginBottom: "40px" }}></div>
          ) : (
            <>
              <CartBanner isMobile={isMobile} />
              <footer className="footerContainer">
                <div className="footer_company_detail">
                  <div className="footer_logo" onClick={() => nav("/")}>
                    <img src={footerLogo} alt="main_logo" />
                  </div>
                </div>
                <div className="footer-items">
                  <div className="footer_last ">
                    <div className="about-container">
                      <span className="about-heading heading-2xl">
                        {t('aboutOlineo')}
                      </span>
                      <div className="about">
                        <Link to="/about-us" className="about-item">{t('footer.about-us')}</Link>
                        <Link to="/career" className="about-item">{t('Careers')}</Link>
                        <Link to="/store-finder" className="about-item">{t('StoreLocator')}</Link>
                      </div>
                    </div>
                    <div className="policy-container">
                      <span className="policy-heading heading-2xl">{t('policy')}</span>
                      <div className="policy">
                        <Link to="/terms-condition" className="policy-item">{t('footer.terms')}</Link>
                        <Link to="/terms-condition#order-cancellation" className="policy-item">
                          <span>{t('CancellationPolicy')}</span>
                        </Link>
                        <Link to="/terms-condition#return-exchange" className="policy-item">{t('ReturnExchange')}</Link>
                        <Link to="/terms-condition#disclaimer" className="policy-item">{t('Disclaimer')}</Link>
                        {/* <span className="policy-item">{t('EMIPlans')}</span> */}
                        {/* <span className="policy-item">{t('ERPPolicy')}</span> */}
                        <Link to="/privacy-policy" className="policy-item">{t('privacyPolicy')}</Link>
                      </div>
                    </div>
                    <div className="help-container">
                      <span className="help-heading heading-2xl">{t('Help')}</span>
                      <div className="help">
                        <Link to="/write-to-us" className="help-item">{t('footer.contact')}</Link>
                        <Link to="/customer-support" className="help-item">{t('Customer')} {t('Support')}</Link>
                        <Link to="/customer-support" className="help-item">{t('Servicecenter')}</Link>
                        <Link to="/customer-support" className="help-item">{t('OLINEOMember')}</Link>
                        <Link to="/customer-support" className="help-item">{t('BecomeASupplier')}</Link>
                      </div>
                    </div>
                    <div className="payment-container">
                      <div className="payment-method">
                        <div className="payment-heading heading-2xl">{t('Payment')}</div>
                        <div className="payment-cards">
                          <img className="pay-card1" src={FooterSub1} alt="img1" />
                          <img className="pay-card2" src={FooterSub2} alt="img2" />
                        </div>
                      </div>
                      <div className="payment-heading heading-2xl">{t('Connect')}</div>
                      <div className="social_links_wrapper">
                        {socialIcon.map((data, index) => (
                          <a href={data.link} target="_blank" rel="noreferrer" key={`social_Icons_${index}`}>
                            <img
                              className="socialMedia-icon"
                              key={index}
                              src={data.icon}
                              alt={`footer_${index}`}
                              style={{ marginBottom: "5px" }}
                            />
                          </a>
                        ))}
                      </div>
                    </div>


                    <div className="footer_terms_container d-block d-md-none">
                      <p className="footer_last_heading">
                        {t("footer.useful-links")}
                      </p>
                      <div className="footer_terms_wrapper">
                        <div className="footer_terms_column1">

                          <Link to={"/wishlist"}>
                            <p className="footer_terms">{t("footer.wishlist")}</p>
                          </Link>

                        </div>
                        <div className="footer_terms_column2">
                          <Link to={"/customer-support"}>
                            <p className="footer_terms">
                              {t("footer.cust-service")}
                            </p>
                          </Link>
                          <Link to={"/customer-support"}>
                            <p className="footer_terms">{t("footer.terms")}</p>
                          </Link>
                          <Link to={"/customer-support"}>
                            <p className="footer_terms">{t("footer.privacy")}</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vertical-div tx-white "></div>
                  <div className="new-con ">
                    <div className="mail-us tx-white">
                      <span className=" heading-2xl">{t('MailUs')}</span>
                      <span id="mail-neg-margin"><a target="_blank" rel="noreferrer" href="mailto:contactus@olineoindia.com">contactus@olineoindia.com</a></span>
                    </div>
                    <div className="customer-care tx-white">
                      <span className="heading-2xl">{t('CustomerCare')}</span>
                      <span id="mail-neg-margin"><a href="tel:63900 63900" target="_blank" rel="noreferrer">(+91) 63900 63900</a> </span>
                    </div>
                  </div>
                  <div
                    className="last_contact_details"
                    id="last_contact_details-sm-q"
                  >
                    <span className="footer_last_heading d-block d-md-none">
                      {t("footer.contact")}
                    </span>
                    <span className="heading-2xl footer_last_heading_desk  ">
                      {t("footer.contact")}
                    </span>
                    <div className="contact_link_container">

                      <span className="contact-address">
                        A-501, Neelkanth Business Park, Near Vidyavihar Skywalk,
                        Vidyavihar (W), Mumbai – 400086
                      </span>
                    </div>
                  </div>
                </div>
                <div className="footer_copyright">
                  <img src={copyrightWhite} alt="" />
                  <p id="copy-right">
                    COPYRIGHT (2023) O-LINE-O NEXUS IND. PVT. LTD. ALL RIGHTS
                    RESERVED. DESIGNED & DEVELOPED BY BRAND AURA
                  </p>
                </div>
              </footer>
            </>

          )}
        </>
      ) : (
        <>
          {loc.pathname === "/login" ||
            loc.pathname === "/signup" ||
            loc.pathname === "/password/forgot" ||
            loc.pathname === "/create-new-password" ||
            loc.pathname === "/otp" ? (
            <div style={{ marginBottom: "40px" }}></div>
          ) : (
            <>
              <CartBanner isMobile={isMobile} />
              <footer className="footerContainer-res">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="company-detail-res">
                    <div className="detail-left">
                      <div className="details-res">
                        <span className="footer-heading-2xl">{t('aboutOlineo')}</span>
                        <Link to="/about-us"><span>{t('footer.about-us')}</span></Link>
                        <Link to="/career"><span>{t('Careers')}</span></Link>
                        <Link to="/store-finder"><span>{t('StoreLocator')}</span></Link>
                      </div>
                      <div className="details-res">
                        <span className="footer-heading-2xl">{t('Help')}</span>
                        <Link to="/write-to-us"><span>{t('footer.contact')}</span></Link>
                        <Link to="/customer-support"><span>{t('Customer')} {t('Support')}</span></Link>
                        <Link to="/customer-support"><span>{t('Servicecenter')}</span></Link>
                        <Link to="/customer-support"><span>{t('OLINEOMember')}</span></Link>
                        <Link to="/customer-support"><span>{t('BecomeASupplier')}</span></Link>
                      </div>
                    </div>
                    <div className="detail-right">
                      <div className="details-res">
                        <span className="footer-heading-2xl">{t('policy')}</span>
                        <Link to="/terms-condition"><span>{t('footer.terms')}</span></Link>
                        <Link to="/terms-condition#order-cancellation">
                          <span>{t('CancellationPolicy')}</span>
                        </Link>
                        <Link to="/terms-condition#return-exchange"><span>{t('ReturnExchange')}</span></Link>
                        <Link to="/terms-condition#disclaimer"><span>{t('Disclaimer')}</span></Link>
                        {/* <span>{t('EMIPlans')}</span> */}
                        {/* <span>{t('ERPPolicy')}</span> */}
                        <Link to="/privacy-policy"><span>{t('privacyPolicy')}</span></Link>
                      </div>
                      <div className="details-res">
                        <span className="footer-heading-2xl">{t('footer.contact')}</span>
                        <span>
                          A-501, Neelkanth Business Park, Near Vidyavihar Skywalk,
                          Vidyavihar (W), Mumbai - 400086
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "40px",
                      marginLeft: "6%",
                      marginRight: "6%",
                    }}
                  >
                    <div className="details-res">
                      <span style={{ color: "#8d98ae" }}>{t('MailUs')}</span>
                      <span><a target="_blank" rel="noreferrer" href="mailto:contactus@olineoindia.com">contactus@olineoindia.com</a></span>
                    </div>
                    <div className="details-res">
                      <span style={{ color: "#8d98ae" }}>{t('CustomerCare')}</span>
                      <span><a href="tel:63900 63900" target="_blank" rel="noreferrer">(+91) 63900 63900</a> </span>
                    </div>
                  </div>
                </div>

                <div className="footer-details-bottom"></div>
                <div className="footer-logo-res ">
                  <img src={footerLogo} alt="footerLogo" />
                </div>
                <div className="footer-last-res">
                  <div className="footer-conn ">
                    <span
                      className="footer-heading-2xl"
                      style={{ fontSize: "20px" }}
                    >
                      Connect
                    </span>
                    <div className="footer social_links_wrapper">
                      {socialIcon.map((data, index) => (
                        <a href={data.link} target="_blank" rel="noreferrer" key={`social_Icons_1_${index}`}>
                          <img
                            className="socialMedia-icon  mb-1"
                            key={index}
                            src={data.icon}
                            alt={`social_${index}`}
                          />

                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="footer-pay">
                    <span
                      id="ft-pay"
                      className="footer-heading-2xl"
                      style={{ fontSize: "20px" }}
                    >
                      Payment
                    </span>
                    <div className="footer-payment-last footer-heading-2xl">
                      <img
                        style={{ width: "100%", marginBottom: "10px" }}
                        src={FooterSub1}
                        alt="FooterSub1"
                      />
                    </div>
                  </div>
                </div>
                <div className="footer-copy-right">
                  <span>
                    &copy; COPYRIGHT (2023) O-LINE-O NEXUS IND. PVT. LTD. ALL RIGHTS
                    RESERVED. DESIGNED & DEVELOPED BY BRAND AURA
                  </span>
                </div>
              </footer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Footer;
