//Dependencies
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import SegmentIcon from "@mui/icons-material/Segment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { CircularProgress, Drawer, Paper } from "@mui/material";
//CSS
import "./ProductCategory.css";

//Components
import { getSearchValuesForDynamicFilters, getSearchedProduct, getValuesForDynamicFilters } from "../../api/Product";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useProductCtx from "../../hooks/useProductCtx";
import ProductCard from "../../components/Cards/ProductCard";
import Pagination from "@mui/material/Pagination";
//Utils
import { PRODUCT_CLASSIFICATION } from "../../utils/Constants";
import { useTranslation } from "react-i18next";

import useScreenSize from "../../utils/ScreenSizeCalculation";

//new images
import Laptop1 from "../../assets/vector/All_Categories/pngaaa_1.svg";
import Laptop2 from "../../assets/vector/All_Categories/pngaaa_2.svg";
import Laptop3 from "../../assets/vector/All_Categories/pngaaa_3.svg";
import Laptop4 from "../../assets/vector/All_Categories/pngegg_1.svg";
import Laptop5 from "../../assets/vector/All_Categories/lovoLap.svg";
import Laptop6 from "../../assets/vector/All_Categories/kindpng_6093321_1.svg";
import intel3 from "../../assets/vector/All_Categories/Intel_Core_i3_Logo_2020.svg";
import intel5 from "../../assets/vector/All_Categories/Intel_Core_i5_Logo_2020.svg";
import intel7 from "../../assets/vector/All_Categories/Intel_Core_i7_Logo_2020.svg";
import intel9 from "../../assets/vector/All_Categories/Intel_Core_i9_Logo_2020.svg";
import pro3 from "../../assets/vector/All_Categories/pro3.svg";
import pro5 from "../../assets/vector/All_Categories/pro5.svg";
import pro7 from "../../assets/vector/All_Categories/amd_ryzen_7_logo.svg";
import pro9 from "../../assets/vector/All_Categories/amd_ryzen_9_logo.svg";
import apple from "../../assets/vector/All_Categories/apple.svg";
import hp from "../../assets/vector/All_Categories/hp.svg";
import vaio from "../../assets/vector/All_Categories/vaio.svg";
import Asus from "../../assets/vector/All_Categories/ASUS.svg";
import Samsung from "../../assets/vector/All_Categories/Samsuung.svg";
import Dell from "../../assets/vector/All_Categories/dell.svg";
import Microsoft from "../../assets/vector/All_Categories/Microsoft_logo.svg";
import Msi from "../../assets/vector/All_Categories/msi.svg";
import Acer from "../../assets/vector/All_Categories/acer.svg";
import Lenovo2 from "../../assets/vector/All_Categories/lenovo.svg";
import { useMediaQuery } from "@mui/material";
import DrawerSection from "./DrawerSection";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import FilterListData from "../../components/FilterListData";
import useUserCtx from "../../hooks/useUserCtx";

//Category Banner
import NewArrivalBanner from "../../assets/CategoryBanner/NewArrivalaBanner.jpg";
import BluetoothHeadphones from "../../assets/CategoryBanner/Headphone.png";
import Laptop from "../../assets/CategoryBanner/Laptop.png";
import Smartphone from "../../assets/CategoryBanner/Smartphone.png";
import Powerbank from "../../assets/CategoryBanner/Powerbank.png";
import SmartTVBanner from "../../assets/CategoryBanner/SmartTV.png";
import BluetoothSpeaker from "../../assets/CategoryBanner/Speaker.png";
import Tablet from "../../assets/CategoryBanner/TabletBanner.png";
import TrueWirelessEarbuds from "../../assets/CategoryBanner/TWS.png";
import SmartwatchBanner from "../../assets/CategoryBanner/SmartwatchBanner.jpg";


//Lower Banners
import HeadphoneR from "../../assets/ProductPage/headphoneR.jpg";
import HeadphoneS from "../../assets/ProductPage/HeadphoneS.png";
import LaptopR from "../../assets/ProductPage/LaptopR.png";
import LaptopS from "../../assets/ProductPage/LaptopS.png";
import PowerbankR from "../../assets/ProductPage/powerbankR.png";
import PowerbankS from "../../assets/ProductPage/powerbankS.png";
import SmartphoneR from "../../assets/ProductPage/SmartphoneR.png";
import SmartphoneS from "../../assets/ProductPage/SmartphoneS.png";
import SpeakerR from "../../assets/ProductPage/SpeackerR.png";
import SpeakerS from "../../assets/ProductPage/SpeackerS.png";
import TabletR from "../../assets/ProductPage/TabletR.png";
import TabletS from "../../assets/ProductPage/TabletS.png";
import TVR from "../../assets/ProductPage/TVR.png";
import TVS from "../../assets/ProductPage/TVS.png";
import TWSS from "../../assets/ProductPage/TWSS.png";
import TWSR from "../../assets/ProductPage/TWSR.png";
import SmartwatchL from "../../assets/ProductPage/SmartwatchL.jpg";
import SmartwatchR from "../../assets/ProductPage/SmartwatchR.jpg";
import NewArrivalL from "../../assets/ProductPage/NewArrivalL.jpg";
import NewArrivalR from "../../assets/ProductPage/NewArrivalR.jpg";
import { Carousel } from "react-bootstrap";
import SkeletonElement from "../../components/Skeletons/SkeletonElement";


// Component definition

const ProductCategory = () => {
  const isMobile = useMediaQuery("(max-width:568px)");

  // translation
  const { t } = useTranslation();
  const { userWishlist, cartArray } = useUserCtx();

  const [minMaxPrice, setMinMaxPrice] = useState({ highest: 100, lowest: 0 });
  const [priceRange, setPriceRange] = useState([minMaxPrice.lowest, minMaxPrice.highest]);
  const [step, setStep] = useState(1);

  const scrollToProductCard = () => {
    document.querySelector(".main_Content_Show ").scrollIntoView()
  }

  const handleChange = (event, newValue) => {
    setPriceRange(() => newValue);
  };

  function handlePriceRangeSubmit() {
    setFilterSelected((prev) => prev.some(e => e.type === "price.mop") ?
      [...prev.filter(e => e.type !== "price.mop"), { type: "price.mop", searchQ: priceRange.join("-") }] :
      [...prev, { type: "price.mop", searchQ: priceRange.join("-") }]
    );
    isMobile && handleClose()
    scrollToProductCard()
  }

  function onPriceClick(range) {
    if (range[0] === priceRange[0] && range[1] === priceRange[1]) {
      setPriceRange([minMaxPrice.lowest, minMaxPrice.highest]);
      setFilterSelected((prev) => [...prev.filter(e => e.type !== "price.mop"), { type: "price.mop", searchQ: `${minMaxPrice.lowest}-${minMaxPrice.highest}` }])
    } else {
      setPriceRange(range);
      setFilterSelected((prev) => prev.some(e => e.type === "price.mop") ?
        [...prev.filter(e => e.type !== "price.mop"), { type: "price.mop", searchQ: range.join("-") }] :
        [...prev, { type: "price.mop", searchQ: range.join("-") }]
      );
    }
    isMobile && handleClose()
    scrollToProductCard()
  }

  function handlePriceReset() {
    setPriceRange([minMaxPrice.lowest, minMaxPrice.highest]);
    isMobile && handleClose()
    scrollToProductCard()
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterSelected, setFilterSelected] = useState([]);
  const [totalProducts, setTotalProducts] = useState(1);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [sorting, setSorting] = useState("");
  const [filterListData, setFilterListData] = useState([]);

  const { width } = useWindowDimensions();

  const matches = width >= 768;
  const nav = useNavigate();

  const { searchedProduct, setSearchedProduct, currentPage, setCurrentPage } =
    useProductCtx();
  const productsPerPage = 12;

  const location = useLocation();

  const handleOpen = (event) => {
    if (isMobile) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAll2 = (event, resp) => {
    setCurrentPage(1);
    setFilterSelected((filterObj) => {
      if (
        filterObj.some(
          (filter) => filter.type === resp.type && filter.id === resp.id
        )
      ) {
        return [...filterObj.filter((filter) => filter.id !== resp.id)];
      }
      return [...filterObj, resp];
    });
  };

  const handleSingleFilterSelection = (resp) => {
    setCurrentPage(1);
    setFilterSelected((filterObj) => {
      if (
        filterObj.some(
          (filter) => filter.type === resp.type
        )
      ) {
        if (filterObj.some(
          (filter) => filter.id !== resp.id
        ))
          return [...filterObj.filter((filter) => filter.type !== resp.type), resp];
        else return [...filterObj.filter((filter) => filter.type !== resp.type)];
      }
      return [...filterObj, resp];
    });
  };

  useEffect(() => {
    setFilterSelected([]);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    if (Object.keys(FilterListData).includes(location.pathname.split("=")[1])) {
      getValuesForDynamicFilters(location.pathname.split("=")[1]).then(res => {
        if (res.status === "success") generateDynamicFilterArray(res.obj)
      })
    } else if (location.search !== "") {
      getSearchValuesForDynamicFilters(location.search.split("=")[1]).then(res => {
        if (res.status === "success") generateDynamicFilterArray(res.obj)
      })
    }
    else {
      setFilterListData(FilterListData['all'])
    }
  }, [FilterListData, location]);

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams(location.search);
    if (params.get("search") === "") return;
    if (
      location.pathname !== "/Category=all" &&
      location.pathname !== "/search"
    ) {
      let urlArray = location.pathname.slice(1).split("=");
      params.append("hierarchyL2", urlArray[1]);
    }
    if (sorting === "descending") {
      params.append("sort", "descending");
    }
    if (sorting === "ascending") {
      params.append("sort", "ascending");
    }
    params.append("limit", productsPerPage);
    params.append("page", currentPage);
    filterSelected?.length > 0 &&
      filterSelected.forEach((value) => {
        if (value.type === "price.mop") {
          params.append(`${value.type}`, value.searchQ);
        }
        if (value.type === "brand") {
          params.append("brand", value.data);
        }
        if (value.type === "Category") {
          params.append("hierarchyL2", value.data);
        }
        if (value.type === "Offer") {
          if (value.data === "Any") return;
          params.append("discount.flatDiscount.value[gte]", value?.Qdata);
        }
        if (value?.dynamic) {
          params.append("dynamic", true);
          params.append(`${value.type}`, value.searchQ.toString().trim());
        }
      });
    getSearchedProduct(params).then((res) => {
      const products = res.products.filter(
        (prod) => prod.classification !== PRODUCT_CLASSIFICATION.TEMP_HIDDEN
      );
      setSearchedProduct({
        loaded: true,
        products,
        no_of_products: res.total_products,
      });
      setTotalProducts(res.total_products);
      setLoading(false);
    });
  }, [filterSelected, setSearchedProduct, currentPage, location, sorting]);

  const generateDynamicFilterArray = useCallback((objValues) => {
    const values = {
      ram: "GB",
      internal_storage: "GB",
      display_size: "Inches",
      screen_size: "Inches",
      warranty: "Year",
      total_playback_time: "Hours",
      refresh_rate: "Hz"
    };
    function checkIfAlreadyPresent(str, value) {
      return str?.toString()?.replace(/[0-9]/, "ig")?.trim()?.toLowerCase() === value?.toLowerCase();
    }
    setFilterListData(() => {
      const arr = FilterListData['all'];
      if (!objValues) return
      const arr2 = Object.entries(objValues).map(entries => {
        if (entries[0].toString() === "brand") return {
          filter_heading: entries[0].toString(),
          filter_data: entries[1].map((e, index) => ({
            type: `${entries[0]}`,
            data: e,
            searchQ: e,
            id: `${entries[0]}-${e}-${index}`,
          }))
        }
        else if (entries[0].toString() === "price") return {
          filter_heading: entries[0].toString(),
          filter_data: entries[1].map((e, index) => {
            if (index !== entries[1].length - 1)
              return {
                type: "price.mop",
                data: e.data,
                searchQ: e.searchQ,
                id: `${entries[0]}-${e.data}-${index}`,
              }
            else return null
          }
          )
        }
        // Dynamic Filters
        else {
          return {
            filter_heading: entries[0].toString().split("_").join(" "),
            filter_data: entries[1].map((e, index) => ({
              type: `productInfo.${entries[0]}`,
              data: values[entries[0]?.toString()] && checkIfAlreadyPresent(e, values[entries[0]?.toString()]) ? `${e} ${values[entries[0].toString()]}` : e,
              searchQ: e,
              id: `${entries[0]}_${index}`,
              dynamic: true
            }))
          }
        }
      });
      return arr.concat(...arr2)
    })
    const { lowest, highest } = objValues.price[objValues.price.length - 1] ?? { lowest: 0, highest: 0 }
    setMinMaxPrice(prev => ({ ...prev, lowest, highest }));
    setStep(() => Math.ceil((highest - lowest) / 100));
    setPriceRange(prev => [lowest, highest])
  }, []);

  const handlePageChange = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    scrollToProductCard()
  };

  // POPUP
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClosePOP = (event, sortValue) => {
    if (sortValue) {
      setSorting(sortValue);
      setCurrentPage(1);
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const headingName = useMemo(() => {
    if (location.pathname?.split("=")[1]) return `${location.pathname?.split("=")[1]} Category`.toString().replaceAll("%20", " ")
    else return 'Search Result'
  }, [location]);

  const mainBanner = useCallback(() => {
    const bannerName = location.pathname.split("=")[1]?.toString()?.replaceAll("%20", "");
    switch (bannerName) {
      case "Smartphone": return Smartphone
      case "Laptop": return Laptop
      case "BluetoothHeadphones": return BluetoothHeadphones
      case "Powerbank": return Powerbank
      case "Tablet": return Tablet
      case "SmartTV": return SmartTVBanner
      case "BluetoothSpeaker": return BluetoothSpeaker
      case "TrueWirelessEarbuds": return TrueWirelessEarbuds
      case "Smartwatch": return SmartwatchBanner
      default: return NewArrivalBanner
    }
  }, [location]);

  const lowerBanners = useCallback(() => {
    const bannerName = location.pathname.split("=")[1]?.toString()?.replaceAll("%20", "");
    switch (bannerName) {
      case "Smartphone": return [SmartphoneS, SmartphoneR]
      case "Laptop": return [LaptopS, LaptopR]
      case "BluetoothHeadphones": return [HeadphoneS, HeadphoneR]
      case "Powerbank": return [PowerbankS, PowerbankR]
      case "Tablet": return [TabletS, TabletR]
      case "SmartTV": return [TVS, TVR]
      case "BluetoothSpeaker": return [SpeakerS, SpeakerR]
      case "TrueWirelessEarbuds": return [TWSS, TWSR]
      case "Smartwatch": return [SmartwatchL, SmartwatchR]
      default: return [NewArrivalL, NewArrivalR]
    }
  }, [location]);

  //Funtions For Laptop Category
  // Function to get filter data by heading
  function getFilterDataByHeading(heading) {
    const filter = filterListData.find(item => item.filter_heading === heading);
    return filter ? filter.filter_data : [];
  }

  function handleBrandImageClick(brandName, index) {
    // Retrieve filter data for "brand" heading
    const brandFilterData = getFilterDataByHeading("brand");
    // Retrieve the "data" field from filter_data
    const brandDataList = brandFilterData.map(item => item.data?.toString()?.toLowerCase());
    scrollToProductCard();
    if (Array.from(brandDataList).includes(brandName)) {
      const obj = brandFilterData.find(obj => obj.data?.toString()?.toLowerCase() === brandName);
      handleSingleFilterSelection(obj);
    } else {
      const obj = {
        type: `brand`,
        data: brandName,
        searchQ: brandName,
        id: `${brandName}_brand_${index}`,
      };
      handleSingleFilterSelection(obj)
    }
  }

  function handleProcessorImageClick(processorName, index) {
    // Retrieve filter data for "processor" heading
    const processorFilterData = getFilterDataByHeading("processor");
    // Retrieve the "data" field from filter_data
    const processorDataList = processorFilterData.map(item => item.data?.toString()?.toLowerCase());
    scrollToProductCard();
    const regex = new RegExp(processorName, "i");
    if (Array.from(processorDataList).some(e => regex.test(e))) {
      const obj = processorFilterData.find(obj => {
        if (regex.test(obj.data)) return obj
        else return null
      })
      obj && handleSingleFilterSelection(obj);
    } else {
      const obj = {
        type: `productInfo.processor`,
        data: processorName,
        searchQ: processorName,
        id: `${processorName}_processor_${index}`,
        dynamic: true
      };
      handleSingleFilterSelection(obj)
    }
  }

  function handleLaptopTypeImageClick(typeName, index) {
    // Retrieve filter data for "processor" heading
    const typeFilterData = getFilterDataByHeading("processor");
    // Retrieve the "data" field from filter_data
    const typeDataList = typeFilterData.map(item => item.data?.toString()?.toLowerCase());
    scrollToProductCard();
    const regex = new RegExp(typeName, "i");
    if (Array.from(typeDataList).some(e => regex.test(e))) {
      const obj = typeFilterData.find(obj => {
        if (regex.test(obj.data)) return obj
        else return null
      })
      obj && handleSingleFilterSelection(obj);
    } else {
      const obj = {
        type: `productInfo.laptop_type`,
        data: typeName,
        searchQ: typeName,
        id: `laptop_type_${index}`,
        dynamic: true
      };
      handleSingleFilterSelection(obj)
    }
  }

  const screenSize = useScreenSize();

  const SmartphoneBanners = useMemo(() => [
    { link: 'https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/iphone/Series+13.jpg', target: '/product/Iphone-14-Pro-Max-128-GB---Space-Black' },
    { link: 'https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/iphone/Series+14+%26+plus.jpg', target: '/product/Iphone-13-256-GB---Midnight' },
    { link: 'https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/VIVO/VIVO+V27.jpg', target: '/product/Vivo-V27-12-256GB---Magic-Blue' },
    { link: 'https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/VIVO/VIVO+Y100A.jpg', target: '/product/Vivo-Y100A-8-128GB---Twilight-Gold' },
    { link: 'https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/VIVO/VIVO+Y36.jpg', target: '/product/Vivo-Y36-8-128GB---Vibrant-Gold' },
    { link: 'https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/VIVO/VIVO+Y56+5G.jpg', target: '/product/Vivo-Y56-5G-4-128GB---Black-Engine' },
    { link: 'https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/OPPO/OPPO+A78.jpg', target: '/product/Oppo-A78-5G-8-128GB---Glowing-Black' },
    { link: 'https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/OPPO/OPPO+F23.jpg', target: '/product/Oppo-F23-5G-8-256GB---Bold-Gold' },
    { link: 'https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/OPPO/OPPO+Reno+10PRO-.jpg', target: '/product/Oppo-Reno10-Pro-Plus-12-256GB---Glossy-Purple' },
    { link: 'https://olineo-banner-prod.s3.ap-south-1.amazonaws.com/Category_Page_Banners/OPPO/OPPO+Reno8T+5G-1.jpg', target: "/product/Oppo-Reno-8T-5G-8-128GB---Sunrise-Gold" }
  ], []);

  return (
    <>
      {(location && location?.pathname !== "/search") &&
        location.pathname.split("=")[1] === "Smartphone" ?
        <Carousel interval={2000}>
          {
            (SmartphoneBanners ? (
              SmartphoneBanners?.map((item, index) => (
                <Carousel.Item key={index}>
                  <Link to={item.target}>
                    <div className="all-categories-banner">
                      <img src={item.link} loading="lazy" alt="main_banner_smartphone" />
                    </div>
                  </Link>
                </Carousel.Item>
              ))
            ) : (
              <SkeletonElement type={"productBanner"} />
            ))
          }
        </Carousel>
        :
        (
          location.pathname === "/Category=all" ?
            <Link className="all-categories-banner offer-banners" to={`/My-Offers`}>
              <img src={mainBanner()} loading="lazy" alt="main_banner" title="My Offers" />
            </Link>
            :
            <div className="all-categories-banner">
              <img src={mainBanner()} loading="lazy" alt="main_banner"/>
            </div>
        )

      }
      <div style={{
        marginTop: location.pathname === "/search" ? "var(--pageMarginTop)" : "0",
        width: "100%"
      }}>
        <div className="all-categories-section-1">
          {
            location.pathname.split("=")[1] === "Laptop" &&
            <>
              <div id="all-mid-heading">
                <span>{t('ShopByType')}</span>
              </div>
              <div className="all-categories-devices">
                {[{ source: Laptop4, name: "MacBook" },
                { source: Laptop6, name: "Clamshell" },
                { source: Laptop5, name: "UltraBook" },
                { source: Laptop1, name: "Gaming" },
                { source: Laptop2, name: "2 in 1" },
                { source: Laptop3, name: "Budget" }
                ].map((laptopType, index) => (
                  <div
                    className="all-cat-items"
                    key={`laptopType_${laptopType.name}_${index}`}
                    onClick={() =>
                      handleLaptopTypeImageClick(laptopType.name?.toString().toLowerCase(), index
                      )}
                    data-selected={filterSelected?.some((res) => new RegExp(laptopType.name, "i").test(res.data)) && "selected"}
                    style={{
                      opacity: filterSelected?.some((res) => new RegExp(laptopType.name, "i").test(res.data)) && "selected" ? "1" : "0.8"
                    }}
                  >
                    <img src={laptopType.source} alt={laptopType.name} />
                    <span>{laptopType.name}</span>
                  </div>
                ))
                }
              </div>
              <div id="all-mid-heading">
                <span>{t('ShopByProcessor')}</span>
              </div>
              <div className="all-cat-logos">
                <div className="logo-con-1">
                  {
                    [{ source: intel3, name: "i3" },
                    { source: intel5, name: "ios" },
                    { source: intel7, name: "i7" },
                    { source: intel9, name: "i9" }
                    ].map((obj, index) => (
                      <img
                        className="logo-img"
                        src={obj.source}
                        alt={obj.name}
                        onClick={() =>
                          handleProcessorImageClick(obj.name?.toString().toLowerCase(), index
                          )}
                        data-selected={filterSelected?.some((res) => new RegExp(obj.name, "i").test(res.data)) && "selected"}
                        key={`processorLaptop_${obj.name}_${index}`}
                        style={{
                          opacity: filterSelected?.some((res) => new RegExp(obj.name, "i").test(res.data)) && "selected" ? "1" : "0.8"
                        }} />
                    ))
                  }
                </div>
                <div className="logo-con-2">
                  {
                    [{ source: pro3, name: "ryzen pro 3" },
                    { source: pro5, name: "ryzen pro 5" },
                    { source: pro7, name: "ryzen 7" },
                    { source: pro9, name: "ryzen 9" }
                    ].map((obj, index) => (
                      <img
                        className="logo-img"
                        src={obj.source} alt={obj.name}
                        onClick={() =>
                          handleProcessorImageClick(obj.name?.toString().toLowerCase()
                          )}
                        data-selected={filterSelected?.some((res) => new RegExp(obj.name, "i").test(res.data)) && "selected"}
                        key={`processorLaptop_${obj.name}_${index}`}
                        style={{
                          opacity: filterSelected?.some((res) => new RegExp(obj.name, "i").test(res.data)) && "selected" ? "1" : "0.8"
                        }} />
                    ))
                  }
                </div>
              </div>

              <div id="all-mid-heading">
                <span>{t('ShopbyBrand')}</span>
              </div>
              <div className="prod-brand-logo">
                <div className="brand-logo-cont">
                  {[
                    { source: apple, name: "apple" },
                    { source: hp, name: "hp" },
                    { source: vaio, name: "vaio" },
                    { source: Asus, name: "asus" },
                    { source: Samsung, name: "samsung" },
                  ]
                    .map((brand, index) => (
                      <div
                        className="brand-logo-img"
                        key={`${brand.name}_${index}`}
                        onClick={() =>
                          handleBrandImageClick(brand.name?.toString().toLowerCase(), index
                          )} data-selected={filterSelected?.some((res) => res.data?.toString()?.toLowerCase() === brand.name?.toString().toLowerCase()) && "selected"}
                        style={{
                          opacity: filterSelected?.some((res) => res.data?.toString()?.toLowerCase() === brand.name?.toString().toLowerCase()) && "selected" ? "1" : "0.7"
                        }}>
                        <img
                          src={brand.source}
                          alt={brand.name}
                          loading="lazy"
                        />
                      </div>
                    ))
                  }
                </div>
                <div className="brand-logo-cont">
                  {
                    [
                      { source: Dell, name: "dell" },
                      { source: Microsoft, name: "microsoft" },
                      { source: Msi, name: "msi" },
                      { source: Acer, name: "acer" },
                      { source: Lenovo2, name: "lenevo" },
                    ]
                      .map((brand, index) => (
                        <div
                          className="brand-logo-img"
                          key={`${brand.name}_${index}`}
                          onClick={() =>
                            handleBrandImageClick(brand.name?.toString().toLowerCase(), index)
                          }
                          data-selected={filterSelected?.some((res) => res.data?.toString()?.toLowerCase() === brand.name?.toString().toLowerCase()) && "selected"}
                          style={{
                            opacity: filterSelected?.some((res) => res.data?.toString()?.toLowerCase() === brand.name?.toString().toLowerCase()) && "selected" ? "1" : "0.7"
                          }}>
                          <img src={brand.source} alt={brand.name} loading="lazy" />
                        </div>
                      ))
                  }
                </div>
              </div></>}
          {filterListData?.find((filterData) =>
            filterData.filter_heading === "price")?.filter_data?.length ? <div id="all-mid-heading">
            <span>{t('ShopbyBudget')}</span>
          </div> : null}
          {loading && !isMobile ? <div className="loader-div"><CircularProgress color="inherit" /></div> :
            <>
              {isMobile ? (
                <>
                  {filterListData.map((filterData, index) =>
                    filterData.filter_heading === "price" ?
                      (<>
                        <div className="all-offer-comp">
                          {filterData.filter_data.slice(0, 2).map((filter, i) => (
                            filter &&
                            <div className="all-offer-items" onClick={() => {
                              onPriceClick(filter.searchQ.split("-").map(data => data));
                            }} key={`price-display-1-${index}-${i}`}
                              data-priceselected={(priceRange[0] === filter.searchQ.split("-")[0]) && (priceRange[1] === filter.searchQ.split("-")[1]) ? "priceselected" : "false"}
                            >
                              {filter.data.split("-").map(data => <span>{data}</span>)}
                            </div>
                          ))}
                        </div>
                        <div className="all-offer-comp">
                          {filterData.filter_data.slice(2).map((filter, i) => (
                            filter &&
                            <div className="all-offer-items" onClick={() => {
                              onPriceClick(filter.searchQ.split("-").map(data => data));
                            }} key={`price-display-2-${index}`}
                              data-priceselected={(priceRange[0] === filter.searchQ.split("-")[0]) && (priceRange[1] === filter.searchQ.split("-")[1]) ? "priceselected" : "false"}>
                              {filter.data.split("-").map(data => <span>{data}</span>)}
                            </div>
                          ))}
                        </div>
                      </>
                      )
                      : null
                  )}
                </>
              ) : (
                <div className="all-offer-comp">
                  {filterListData?.length > 0 && filterListData.map((filterData, index) =>
                    filterData.filter_heading === "price" ?
                      filterData.filter_data.map((filter, i) => (
                        filter && <div onClick={() => {
                          onPriceClick(filter.searchQ.split("-").map(data => data));
                        }} className="all-offer-items"
                          data-priceselected={(priceRange[0] === filter.searchQ.split("-")[0]) && (priceRange[1] === filter.searchQ.split("-")[1]) ? "priceselected" : "false"}
                          key={`price-display-3-${index}-${i}`}>
                          {filter.data.split("-").map(data => <span>{data}</span>)}
                        </div>
                      ))
                      : null
                  )}
                </div>
              )}
            </>
          }
          {(location && location?.pathname !== "/search") &&
            <div
              style={{
                display: isMobile ? "none" : "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "10px",
                padding: !isMobile ? "0 20px" : "0px",
              }}
            >
              <img
                style={{
                  width: screenSize === "desktop" ? "30%" : "49%",
                  height: screenSize === "desktop" ? "400px" : "150px",
                  objectFit: "cover",
                  borderRadius: !isMobile ? "10px" : "0px 10px 10px 0px",
                }}
                src={lowerBanners()[0]}
                alt="leftbanner"
              />
              <img
                style={{
                  width: screenSize === "desktop" ? "69%" : "49%",
                  height: screenSize === "desktop" ? "400px" : "150px",
                  objectFit: "cover",
                  borderRadius: !isMobile ? "10px" : "10px 0px 0px 10px",
                }}
                src={lowerBanners()[1]}
                alt="rightbanner"
              />
            </div>
          }
        </div>

        <div
          className={`main_Content_Show ${toggleFilter && !matches ? "main_Content_Hide" : ""
            }`}
        >
          {loading ?
            <div className="loader-div"><CircularProgress color="inherit" /></div>
            :
            <div className="desk_Page_Wrapper_res">
              {isMobile ? (
                <div>
                  <Drawer
                    varaint="temporary"
                    anchor="right"
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{ style: { zIndex: 1200 } }}
                  >
                    <DrawerSection
                      isMobile={isMobile}
                      onClick={handleClose}
                      value={priceRange}
                      handleChange={handleChange}
                      filterListData={filterListData}
                      handleAll2={handleAll2}
                      filterSelected={filterSelected}
                      handlePriceRangeSubmit={handlePriceRangeSubmit}
                      minMaxPrice={minMaxPrice}
                      sliderStep={step}
                      handlePriceReset={handlePriceReset}
                      headingName={headingName}
                      priceRange={priceRange}
                    />
                  </Drawer>
                </div>
              ) : (
                <DrawerSection
                  filterListData={filterListData}
                  isMobile={isMobile}
                  value={priceRange}
                  handleChange={handleChange}
                  handleAll2={handleAll2}
                  filterSelected={filterSelected}
                  handlePriceRangeSubmit={handlePriceRangeSubmit}
                  minMaxPrice={minMaxPrice}
                  sliderStep={step}
                  handlePriceReset={handlePriceReset}
                  headingName={headingName}
                  priceRange={priceRange}
                />
              )}

              <div className="order_Page_Right_res">
                {searchedProduct.no_of_products > 0 ? (
                  <>
                    <div className="right-wrapper">
                      <div className="SpotLight_res">
                        <div>
                          <span>{t('Spotlight')}</span>
                        </div>
                        <div className="SpotLight_res-items" onClick={handleOpen}>
                          {isMobile ? (
                            <FilterAltOutlinedIcon />
                          ) : (
                            <SegmentIcon fontSize="large" />
                          )}
                          {isMobile ? (
                            <span>{t('Filter')}</span>
                          ) : (
                            <span
                              ref={anchorRef}
                              id="composition-button"
                              aria-controls={
                                open ? "composition-menu" : undefined
                              }
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={handleToggle}
                              style={{ cursor: "pointer" }}
                            >
                              {t("sortAndFilter.sort-by")}
                            </span>
                          )}
                          <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                            style={{ zIndex: "100000" }}
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom-start"
                                      ? "left top"
                                      : "left bottom",
                                }}
                              >
                                <Paper >
                                  <ClickAwayListener
                                    onClickAway={handleClosePOP}
                                  >
                                    <MenuList
                                      autoFocusItem={open}
                                      id="composition-menu"
                                      aria-labelledby="composition-button"
                                      onKeyDown={handleListKeyDown}
                                    >
                                      <MenuItem onClick={(event) => handleClosePOP(event, "")}>
                                        <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "" && true} /> <span>{t("default")}</span>
                                      </MenuItem>
                                      <MenuItem onClick={(event) => handleClosePOP(event, "ascending")}>
                                        <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "ascending" && true} /> <span>{t("sortAndFilter.price-ascending")}</span>
                                      </MenuItem>
                                      <MenuItem onClick={(event) => handleClosePOP(event, "descending")}>
                                        <input type="radio" style={{ accentColor: "orange" }} checked={sorting === "descending" && true} /> <span>{t("sortAndFilter.price-descending")}</span>
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </div>
                      </div>
                      {/* { notFound !== "" && <div className="empty_order_sec_res">
                    <p className="empty_order_text_res">
                      No {notFound} Products Found
                    </p>
                    <button
                      type="submit"
                      className="submit-button"
                      onClick={() => setFilterSelected([])}
                    >
                      <p>Clear Filters</p>
                    </button>
                  </div>} */}
                      <div className={"Product_Category_Container_res"}>
                        {searchedProduct.products.map((product, index) => (
                          <ProductCard
                            key={`product-${index}`}
                            id={product._id}
                            productImg={product.images?.length > 0 ? product.images[0] : null}
                            productName={product.title}
                            productDescription={product?.description?.length > 0 ? product?.description[0]?.split(" ")?.slice(0, 18)?.join(" ") : null}
                            productRating={product?.rating ?? 0}
                            productPriceObj={product.price}
                            productDiscountObj={product.discount}
                            productSlug={product.slug}
                            wishlist={userWishlist.wishlist_items?.some(obj => obj._id?.toString() === product._id?.toString())}
                            cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some(obj => obj._id?.toString() === product._id?.toString())}
                          />
                        ))}
                      </div>
                      <div className="pagination-con">
                        <Pagination
                          sx={{
                            color: "primary.main",
                          }}
                          page={currentPage}
                          defaultPage={1}
                          onChange={handlePageChange}
                          count={Math.ceil(totalProducts / productsPerPage)}
                          siblingCount={isMobile ? 0 : 1}
                          variant="outlined"
                          shape="rounded"
                          size="large"
                        />
                      </div>
                      <div className="pagination_Container">
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="empty_order_sec_res">
                    <p className="empty_order_text_res">
                      {t("sortAndFilter.no-product")}
                    </p>
                    <button
                      type="submit"
                      className="submit-button"
                      onClick={() => nav("/")}
                    >
                      <p>{t("back-to-home")}</p>
                    </button>
                  </div>
                )}
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default ProductCategory;