import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../pages/Dashboard/styles/dashAddproduct.css";
import { useState } from "react";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import useAdminCtx from "../../hooks/useAdminCtx";
import {
    addNewStaticBannerSection,
    updateStaticBannerSectionImage,
    updateStaticBannerSection,
    getHomePageSections,
} from "../../api/AdminApis/HomePageSections";

const StaticBanner = () => {
    const [id, setId] = useState(null);
    const location = useLocation();
    const { allHomePageSections, setAllHomePageSections } = useAdminCtx();
    const navigate = useNavigate();
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

    const [productData, setProductData] = useState({
        title: "",
        targetUrl: "",
        image: null,
        imageUrl: null,
        imgKey: "",
        sectionHeading: "",
        priority: null,
    });

    useEffect(() => {
        getHomePageSections((err, res) => {
            if (err) toast.error("Unable to Fetch the Sections properly");
            setAllHomePageSections(res.sections);
        });
    }, []);

    useEffect(() => {
        setProductData((prev) => ({
            ...prev,
            title: location.state?.data?.products[0]?.title ?? "",
            targetUrl: location.state?.data?.products[0]?.targetUrl ?? "",
            sectionHeading: location.state?.data?.sectionHeading ?? "",
            priority: location.state?.data?.priority ?? null,
            image: location.state?.data?.products[0]?.imgUrl ?? null,
            imageUrl: location.state?.data?.products[0]?.imgUrl ?? null,
            imgKey: location.state?.data?.products[0]?.imgKey ?? null,
        }));
        setId(() => location?.state?.data?._id ?? null);
    }, [location.state?.data]);

    const handleFileUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setProductData({
                        ...productData,
                        image: e.target.files[0],
                        imageUrl: reader.result,
                    });
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            if (location.state.updateProduct)
                return setProductData({
                    ...productData,
                    image: null,
                    imageUrl: location.state.data.imgUrl,
                });
            setProductData({ ...productData, image: null, imageUrl: null });
        }
    };

    const handleChange = (e) => {
        setProductData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = () => {
        if (!productData.title) return toast.error("Please Enter the Title!");
        if (!productData.targetUrl)
            return toast.error("Please Enter the Target URL!");
        if (!location.state.updateProduct && !productData.image)
            return toast.error("Please Choose an Image!");

        const formData = new FormData();
        formData.append("title", productData.title);
        formData.append("targetUrl", productData.targetUrl);
        formData.append("sectionHeading", productData.sectionHeading);
        formData.append("sectionType", "static_banner");
        formData.append("moreInfo", null);
        formData.append("image", productData.image);

        setSubmitBtnLoading(true);
        if (!id) {
            formData.append(
                "priority",
                !productData.priority
                    ? allHomePageSections.length === 0
                        ? 1
                        : allHomePageSections[allHomePageSections.length - 1]
                              .priority + 1
                    : productData.priority
            );
            addNewStaticBannerSection(formData, id)
                .then((res) => {
                    toast.success(res.data.message || "Success!");
                    navigate(location.state.from);
                })
                .catch((err) => {
                    if (err) {
                        toast.error("Something went wrong!");
                        return;
                    }
                });
        } else {
            const obj = {
                title: productData.title,
                targetUrl: productData.targetUrl,
                sectionHeading: productData.sectionHeading,
                sectionType: "static_banner",
                moreInfo: null,
                imgUrl: productData.image,
                imgKey: productData.imgKey,
            };
            if (productData.image?.slice(0, 4) === "http") {
                updateStaticBannerSection(obj, id)
                    .then((res) => {
                        navigate("../admin-home-page");
                    })
                    .catch((err) => {
                        if (err) {
                            toast.error("Something went wrong!");
                            return;
                        }
                    });
            } else {
                updateStaticBannerSectionImage(formData, id)
                    .then((res) => {
                        navigate("../admin-home-page");
                    })
                    .catch((err) => {
                        if (err) {
                            toast.error("Something went wrong!");
                            return;
                        }
                    });
            }
        }
        setSubmitBtnLoading(false);
    };

    return (
        <>
            <div>
                <h2>Add Static Banner Section</h2>
            </div>

            <Form className="mt-4" encType="multipart/form-data">
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Section Heading *</Form.Label>
                    <Form.Control
                        type="text"
                        name="sectionHeading"
                        value={productData.sectionHeading}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Product Title *</Form.Label>
                    <Form.Control
                        type="text"
                        name="title"
                        value={productData.title}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                >
                    <Form.Label>Target URL *</Form.Label>
                    <Form.Control
                        type="text"
                        name="targetUrl"
                        value={productData.targetUrl}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                    <label
                        htmlFor="staticBanner-upload"
                        className="custom-fileSelect"
                    >
                        Select Banner Image *
                    </label>
                    <input
                        type="file"
                        name="Gallery Images"
                        id="staticBanner-upload"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleFileUpload}
                        hidden
                    />
                </Form.Group>
                {productData.imageUrl && (
                <>
                    <Form.Label className="w-100">Image Preview</Form.Label>
                    <img src={productData.imageUrl} width="400" alt="" />
                </>
            )}
                <Form.Group className="mb-3" controlId="formFile">
                    <Form.Label>Order (Optional)</Form.Label>
                    <Form.Control
                        type="number"
                        name="priority"
                        value={productData?.priority}
                        onChange={handleChange}
                    />
                </Form.Group>
            </Form>

            <div className="my-5">
                <button
                    disabled={submitBtnLoading}
                    type="submit"
                    className="submit-button"
                    onClick={handleSubmit}
                >
                    {/* <p>{location.state.updateProduct === false ? "Submit" : "Update"}</p> */}
                    <p>{"Submit"}</p>
                </button>
            </div>
        </>
    );
};

export default StaticBanner;
