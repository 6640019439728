// top level imports
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

// Components
import DashboardLoader from "../../components/DashboardContent/DashboardLoader";
import Pagination from "../../components/Pagination/Pagination";

// API calls
import { bulkDeleteEans, getNewEans, saveEanToMaster, updateEan } from "../../api/Product";

// Utils
import { truncatedText } from "../../utils";
import { Button } from "react-bootstrap";
import { DEFAULT_GST_PERCENTAGE } from "../../utils/Constants";

// Component definition
export function NewEan() {
    // Constants
    const itemsPerPage = 5;

    // state definitions
    const [newEans, setNewEans] = useState([]);
    const [selectedEan, setSelectedEan] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalEans, setTotalEans] = useState(1);
    const [loading, setLoading] = useState(false);

    // ComponentDidMount
    useEffect(() => {
        fetchNewEans()
    }, [currentPage]);

    /** Handler functions - starts */

    const fetchNewEans = async () => {
        showLoader();
        try {
            const response = await getNewEans(`limit=${itemsPerPage}&page=${currentPage}`);
            const payload = response.data;
            if (payload.status === 'success') {
                const data = payload.data.eanDetails.map(el => {
                    const t = el;
                    if (!el.gstpercentage) t.gstpercentage = DEFAULT_GST_PERCENTAGE;
                    if (!el.hsn) t.hsn = el.HSN[0];
                    return t;
                });
                setNewEans(data);
                setTotalEans(payload.data.total_eans)
            }
        } finally {
            hideLoader();
        }
    }

    const onEanEdit = ean => {
        setSelectedEan(newEans.find(el => el.ean === ean));
    }

    // saves new EAN product into master
    const onEanSave = async (newProd) => {
        showLoader();
        try {
            const response = await saveEanToMaster(newProd);
            const payload = response.data;
            if (payload.status === 'success') {
                toast.success('Product added to master successfully!')
                fetchNewEans();
            }
        } finally {
            hideLoader();
        }
    }

    const handleEanInputChange = event => {
        const { name, value } = event.target;
        setSelectedEan(prev => ({ ...prev, [name]: value }));
    }

    const updateNewEans = async () => {
        const toBeUpdatedEan = selectedEan;
        if (toBeUpdatedEan.gstpercentage < 18 || toBeUpdatedEan.gstpercentage > 28) {
            toast.error('Invalid GST rate');
            return;
        }

        setSelectedEan(null);
        showLoader();
        try {
            const response = await updateEan(toBeUpdatedEan);
            const payload = response.data;
            if (payload.status === 'updated') {
                toast.success('Product info updated successfully!')
                fetchNewEans();
            }
        } catch (err) { 
            toast.error(err?.response?.data?.message ?? 'Something went wrong!');
        } finally {
            hideLoader();
        }
    }

    const showLoader = () => setLoading(true)

    const hideLoader = () => setLoading(false)

    // copies reference url to clipboard
    const copyReferenceUrl = (reference_url) => {
        navigator.clipboard.writeText(reference_url);
        toast.info('Reference url copied to clipboard', {  })
    }

    async function handleBulkDeleteEans() {
        try {
            if(window.confirm("Confirm?")) {
                showLoader();
                const response = await bulkDeleteEans();
                const payload = await response.data;
                if (payload.status === 'deleted') {
                    fetchNewEans();
                    toast.success('Eans Deleted Successfully');
                }
            }
        } catch (error) {
            toast.error("Something Went Wrong");
        } finally {
            hideLoader();
        }
       
    }

    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
    };

    /** Handler functions - ends */

    // Main renderer
    return (
        <div className="container">
            <div className="d-flex justify-content-between mb-4">
                <h4>New Product EANs</h4>
                {newEans.length > 0 &&
                <Button variant="outline-danger" onClick={handleBulkDeleteEans}>Delete All Eans</Button>}
            </div>

            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">EAN</th>
                            <th scope="col">Product Name(S)</th>
                            <th scope="col">Store code(s)</th>
                            <th scope="col">HSN</th>
                            <th scope="col">GST rate</th>
                            <th scope="col">Reference URL</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {newEans.map(el => (
                            <tr key={el._id}>
                                <td>{el.ean}</td>
                                <td>
                                    {el.product_name?.map((e, index) => (
                                        <p key={`${e}-${index}`}>{e}</p>
                                    ))}
                                </td>
                                <td>
                                    {el.store_id?.map((e, index) => (
                                        <p key={`${e}-${index}`}>{e}</p>
                                    ))}
                                </td>
                                <td>
                                    {el.HSN?.map((e, index) => (
                                        <p key={`${e}-${index}`}>{e}</p>
                                    ))}
                                </td>
                                <td>
                                    {el.gstpercentage}
                                </td>
                                <td title={el.reference_url ?? ''}>
                                    <div className="d-flex justify-content-between">
                                        <p>{truncatedText(el.reference_url ?? '', 40)}</p>
                                        {el.reference_url && (
                                            <FontAwesomeIcon
                                                className="fs-6 cursor-pointer"
                                                title="Copy to clipboard"
                                                icon={faCopy}
                                                onClick={() => copyReferenceUrl(el.reference_url)}
                                            />
                                        ) }
                                    </div>
                                </td>
                                <td>
                                    <button
                                        className="btn btn-primary me-3"
                                        onClick={() => onEanEdit(el.ean)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="btn btn-success"
                                        onClick={() => onEanSave(el)}
                                    >
                                        Save in master
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    {!newEans.length && (
                        <tbody>
                            <tr className="text-center">
                                <td colSpan={6}> No records found </td>
                            </tr>
                        </tbody>
                    )}

                    {loading && (
                        <tbody>
                            <tr className="text-center">
                                <td colSpan={6}>
                                    <DashboardLoader />
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>

            <div className="pagination_Container mt-2">
                <Pagination
                    productsPerPage={itemsPerPage}
                    totalProducts={totalEans}
                    pageChange={handlePageChange}
                    currentPage={currentPage}
                />
            </div>

            {selectedEan?.ean && (
                <div className="mt-4">
                    <h4>Edit EAN</h4>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="ean" className="form-label">EAN</label>
                            <input
                                type="number"
                                className="form-control"
                                id="ean"
                                name="ean"
                                value={selectedEan?.ean}
                                disabled
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="product_name" className="form-label">Product Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="product_name"
                                name="product_name"
                                value={selectedEan?.product_name}
                                onChange={handleEanInputChange}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="hsn" className="form-label">HSN</label>
                            <input
                                type="text"
                                className="form-control"
                                id="hsn"
                                name="hsn"
                                value={selectedEan?.HSN}
                                disabled
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="reference_url" className="form-label">Reference URL</label>
                            <input
                                type="text"
                                className="form-control"
                                id="reference_url"
                                name="reference_url"
                                value={selectedEan?.reference_url ?? ''}
                                onChange={handleEanInputChange}
                            />
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="gstpercentage" className="form-label">GST rate</label>
                            <input
                                type="number"
                                min="18"
                                max="28"
                                className="form-control"
                                id="gstpercentage"
                                name="gstpercentage"
                                value={selectedEan?.gstpercentage ?? ''}
                                onChange={handleEanInputChange}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12 text-end">
                            <button className="btn btn-success me-3" onClick={updateNewEans}>Update</button>
                            <button className="btn btn-danger" onClick={() => setSelectedEan(null)}>Cancel</button>
                        </div>
                    </div>
                </div>
                
            )}
        </div>
    )
}