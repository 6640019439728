import React, { useEffect, useState } from "react";
import "./styles.css";
import { CircularProgress, useMediaQuery } from "@mui/material";
import YourOrderDetails from "./YourOrderDetails";
import YourOrderCart from "./YourOrderCart";
import ProductCard from "../../components/Cards/ProductCard";
import { downloadInvoice, getUserOrders } from "../../api/OrdersApi";
import useUserCtx from "../../hooks/useUserCtx";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProductReviewModal from "../../components/ProductReview";
import { suggestedProducts } from "../../api/Product";

const YourOrder = () => {
    const isMobile = useMediaQuery("(max-width: 500px)");
    const { t } = useTranslation();
    const { userWishlist, cartArray, setUserOrderData, userContext } = useUserCtx();
    const [searchValue, setSearchValue] = useState("");
    const [productId, setProductId] = useState(null);
    const [consignmentId, setConsignmentId] = useState(null);
    const nav = useNavigate();
    const location = useLocation();

    const breadCrumbsData = [
        {
            text: t('profile.profile') + " / ",
            url: "/myProfile",
            isActive: location.pathname === "/myProfile"
        },
        {
            text: t('order'),
            url: "/yourOrders",
            isActive: location.pathname === "/yourOrders"
        },
    ];

    // state definitions
    const [totalOrders, setTotalOrders] = useState({ placed: [], cancel: [] });
    const [filterOrders, setFilterOrders] = useState([]);
    const [emptyOrdersProductArray, setEmptyOrdersProductArray] = useState([]);
    const [statusSelected, setStatusSelected] = useState('placed'); // placed or cancel
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [ratingProductsArray, setRatingProductsArray] = useState([]);

    const handleOpen = (id, consg) => {
        setOpenModal(true);
        setProductId(id);
        setConsignmentId(consg)
    }
    const handleClose = () => setOpenModal(false);

    async function handleInvoiceDownload(orderId) {
        const response = await downloadInvoice(orderId);
        const file = new Blob(
            [response],
            { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        URL.revokeObjectURL(file)
    }

    useEffect(() => window.scrollTo(0, 0), [])

    useEffect(() => {
        getUserOrders().then((res) => {
            setLoading(true);
            if (res?.status === "success") {
                let { placed, cancel } = res.data;
                const cancelOrders = cancel.map(c => c.orderId);
                setTotalOrders(prev => ({ ...prev, placed, cancel: cancelOrders }));
                setFilterOrders(() => placed);
                setUserOrderData({
                    loaded: true,
                    no_of_orders: placed.length + cancel.length,
                    orders: [...placed, ...cancelOrders]
                });
                setRatingProductsArray(res?.updatedProducts)
            }
        }).finally(() => setLoading(false))
    }, [setUserOrderData, nav]);

    useEffect(() => {
        if (userContext.id) {
            suggestedProducts(userContext.id).then(res => {
                setEmptyOrdersProductArray(res.data?.status === "success" ? res.data.productsWithRatings : []);
            })
        }
    }, [userContext.id]);

    function trackOrder(id) {
        nav(`/trackingOrder/${id}`);
    }

    function cancelOrder(id) {
        nav(`/order-cancel/${id}`);
    }

    function handleChange(event) {
        setSearchValue(event.target.value);
        let pattern = new RegExp(event.target.value, "ig");
        setFilterOrders(() => {
            let placedOrders = [...totalOrders.placed];
            const arr = [];
            placedOrders.forEach(order => {
                if (pattern.test(order._id) || order.productDetails.some(details => pattern.test(details.name))) arr.push(order)
            })
            return [...arr]
        })
    }

    return (
        <div className="your_Order_Container">
            <div className="headerSection">
                <span className='breadcrumbs'>
                    {breadCrumbsData.map((item, index) =>
                        <Link className={`normal-link ${item.isActive ? "text-black" : "text-black-50"} `} to={item.url} key={index}>{item.text}
                        </Link>)}
                </span>
            </div>
            {totalOrders.placed.length > 0 && <YourOrderDetails isMobile={isMobile} orderDetails={totalOrders.placed[0]} loading={loading} searchValue={searchValue} handleChange={handleChange} />}
            {!loading && <p style={{
                fontWeight: "600",
                fontSize: "1.3rem",
                display: "grid",
                placeContent: "center"
            }}>{t("order-page.totalOrders")}: {filterOrders.length}</p>}
            {filterOrders.length === 0 && !loading && totalOrders.placed.length ? <button
                className="your_order_submit_button"
                style={{
                    background: "#1B325E",
                    maxWidth: "300px",
                    margin: "auto"
                }}
                onClick={() => { setFilterOrders(totalOrders.placed); setSearchValue(""); }}
            >
                <p
                    style={{
                        color: "white",
                    }}
                >
                    {t("order-page.clearSearch")}
                </p>
            </button> : null}

            {
            !loading && 
            (!totalOrders.placed.length ? 
            <button type="submit" className="submit-button" onClick={() => nav("/")}>
                <p>{t('continueShopping')}</p>
            </button> 
            : null)
            }

            {
                loading ?
                    <div className="loader-div"><CircularProgress color="inherit" /></div>
                    :
                    (filterOrders.length > 0 &&
                        <YourOrderCart
                            orderDetails={filterOrders}
                            loading={loading}
                            isMobile={isMobile}
                            totalOrders={totalOrders}
                            statusSelected={statusSelected}
                            trackOrder={trackOrder}
                            cancelOrder={cancelOrder}
                            handleInvoiceDownload={handleInvoiceDownload}
                            handleOpen={handleOpen}
                            productRating={ratingProductsArray}
                        />)
            }

            {emptyOrdersProductArray.length > 0
                && <div className="suggested-product-container">
                    <div className="suggested-heading">
                        <div
                            className="sub-head-cart1"
                            style={{
                                width: "26%",
                            }}
                        >
                            <span
                                className="text4_xl"
                            >
                                {t('FrequentlyBroughtTogether')}
                            </span>
                        </div>
                        <div className="sub-head-cart2" id="head-bar-sugges"></div>
                    </div>
                    <div className="card-display-row">
                        {
                            emptyOrdersProductArray
                                .map((e, i) => (
                                    <ProductCard
                                        key={`${i}-${e?._id}`}
                                        id={e?._id ?? ""}
                                        productImg={e?.images?.[0] ?? null}
                                        productName={e?.title}
                                        productDescription={e?.description?.[0]?.split(" ")?.slice(0, 18)?.join(" ") ?? ''}
                                        productRating={e?.rating ?? 0}
                                        productPriceObj={e?.price}
                                        productDiscountObj={e?.discount}
                                        productSlug={e?.slug}
                                        wishlist={userWishlist.wishlist_items?.some(obj => obj?._id?.toString() === e?._id?.toString())}
                                        cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some(obj => obj?._id?.toString() === e?._id?.toString())}
                                    />
                                ))}
                    </div>
                </div>}
            {productId && <ProductReviewModal
                open={openModal}
                handleClose={handleClose}
                setOpenModal={setOpenModal}
                isMobile={isMobile}
                productId={productId}
                consignmentId={consignmentId}
            />}
        </div>

    );
};

export default YourOrder;
