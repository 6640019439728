import React from 'react';
import { useMediaQuery } from "@mui/material";
import useScreenSize from "../../utils/ScreenSizeCalculation"
import { useNavigate } from 'react-router-dom';

export default function DealsSection({ heading, products }) {
  const isMobile = useMediaQuery("(max-width: 570px)");
  const screenSize = useScreenSize();
  const navigate = useNavigate();

  return (
    <div
    style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        marginBottom: "10px",
        padding: !isMobile ? "0 20px" : "0px",
    }}
>
    {products.map((product, index) => (
        <img
            style={{
                width: screenSize === "desktop" ? (index === 0 ? "30%" : "69%") : (index === 1 ? "49%": "49%"),
                height: screenSize === "desktop" ? "400px" : "150px",
                objectFit: "cover",
                borderRadius: !isMobile ? "10px" : index === 0 ? "0px 10px 10px 0px" : "10px 0px 0px 10px",
            }}
            title={product.title}
            src={product.image ?? ""}
            alt={`${product.title}-${index}`}
            onClick={() => navigate(product.targetUrl ?? "/")}
            key={`Deals_Section_${index}`}
        />
    ))}
</div>
  )
}
