import React, { useEffect, useMemo, useState } from 'react'
import "./MyProfile.css";
import CartImage from "../../assets/productIcons/CartImage.svg"
import Map from "../../assets/productIcons/Map.svg"
import ShieldImage from "../../assets/productIcons/shieldImage.svg"
import { useMediaQuery } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useUserCtx from '../../hooks/useUserCtx';
import { getItemFromStorage } from '../../utils';
import defaultUserImage from "../../assets/png/default_user_image.png";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { saveUserPic } from '../../api/Auth';
import { toast } from 'react-toastify';

function MyProfile() {
  const isMobile = useMediaQuery("(max-width:700px)");
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const { t } = useTranslation();
  const nav = useNavigate();
  const {
    userContext,
    setUserContext
  } = useUserCtx();

  useEffect(() => {
    const user = JSON.parse(getItemFromStorage("user") ?? {});
    if (!user.JWT) {
      nav("/");
    }
  }, [nav]);

  useEffect(() => {
    if (userContext && userContext.profilePic) {
      setProfilePic(userContext.profilePic);
    } else if (newProfilePic !== null) {
      setProfilePic(newProfilePic);
    } else {
      setProfilePic(defaultUserImage);
    }
  }, [userContext, newProfilePic]);

  const details = useMemo(() => {
    return [
      { name: "Your Orders", image: CartImage, link: "/yourOrders" },
      { name: "Your Addresses", image: Map, link: "/yourAddresses" },
      { name: "Login and Security", image: ShieldImage, link: "/loginAndSecurity" },
    ]
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setNewProfilePic(reader.result);
          setUserContext((prev) => ({
            ...prev,
            profilePic: reader.result,
            newProfilePic: e.target.files[0],
          }));
          saveUserPic(e.target.files[0])
            .then((res) => {
              if (res.data?.status === "success") {
                toast.success(t('profileUpdated'));
              }
            })
            .catch((err) => console.log(err));
        }
      };
      reader.readAsDataURL(e.target.files[0]);

    };
  }

  return (
    <div className='parentClass'>
      <div className='userHeader'>
        <div className="user_Profile_Pic_Container">
          <div className="user_Profile_Pic">
            <img src={profilePic} className='profileImage' alt="ProfilePic"/>
          </div>
          <div className="user_Camera_Icon">
           <CameraAltIcon style={{ color: "#1b325e" }} />
            <form action="" encType="multipart/form-data">
              <input
                type="file"
                name="Profile Image"
                id="Profile Image"
                capture
                onChange={handleImageChange}
                className="profile_Image"
                accept=".jpg, .jpeg, .png"
              />
            </form>
          </div>
        </div>
        <span className="userName">{userContext.fullName}</span>
      </div>

      {isMobile ?
        <div className="mobileCard">
          <div style={{
            display: "flex",
            justifyContent: "space-around",
          }}>
            {details.slice(0, 2).map((detail, index) => (
              <>
                <Link key={index} to={detail.link} style={{ display: "flex", width: "40%", flexDirection: "column", gap: "10px", alignItems: "center", cursor: "pointer", textDecoration: "none" }}>
                  <img src={detail.image} style={{ width: "50%", height: "50%", objectFit: "contain" }} alt="CartImage" />
                  <span className="userName">{detail.name}</span>
                </Link>
                {index !== 1 &&
                  <div className="divider"></div>}
              </>
            ))}
          </div>
          <div style={{
            display: "flex",
            justifyContent: "space-around",
          }}>
            {details.slice(2).map((detail, index) => (
              <>
                <Link key={"profile-" + index} to={detail.link} style={{ display: "flex", width: "40%", flexDirection: "column", gap: "10px", alignItems: "center", cursor: "pointer", textDecoration: "none" }}>
                  <img src={detail.image} style={{ width: "50%", height: "50%", objectFit: "contain" }} alt="CartImage" />
                  <span className="userName">{detail.name}</span>
                </Link>
              </>
            ))}
          </div>

        </div>
        :
        <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "50px",
          justifyContent: "center",
          background: "#F8F8F8",
          borderRadius: "10px",
          padding: "20px 0",
          margin: "2% 2%"
        }}>
          {details.map((detail, index) => (
            <>
              <Link key={"profile2-" + index} to={detail.link} style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", cursor: "pointer", textDecoration: "none" }}>
                <img src={detail.image} style={{ width: "70%", height: "70%", objectFit: "contain" }} alt="CartImage" />
                <span className="userName">{detail.name}</span>
              </Link>
              {index !== details.length - 1 &&
                <div className="divider"></div>}
            </>
          ))}

        </div>}
    </div>
  )
}

export default MyProfile