import React from 'react';
import orderCancelledImage from "../../assets/vector/Cartpage/orderCancelledImage.svg";
import Correct from "../../assets/vector/correct.svg";

const OrderCancelled = () => {
    return (
        <div className="my-3 px-3 d-flex flex-column align-items-center page_Wrapper pt-5">
            <img className='orderCancelledImage' src={orderCancelledImage} alt="cancelled" />
            <h1 className='my-3'>Your order is canceled!</h1>
            <div className="order-cancel-tick">
              <img src={Correct} alt="correct" />
            </div>
        </div>
    )
}

export default OrderCancelled