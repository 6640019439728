import "./step1.css";
import FB from "../../assets/bharatmahostav/Asset 52.png";
import INSTA from "../../assets/bharatmahostav/Asset 54.png";
import TWITTER from "../../assets/bharatmahostav/Asset 53.png";
import YOUTUBE from "../../assets/bharatmahostav/youtube.png";

function Page5() {

  return (
    <div className="container sub-container" style={{ maxWidth: "520px", position: "relative", height: "67vh" }}>
      <div className="pt-4 text-center pb-5" style={{
        display: "flex",
        justifyContent: "flex-start",
      }}>
        <div style={{ position: "absolute", top: 100, width: "95%"}}>
          <p className="checkout-offer-text mt-10 mb-0" style={{
            fontSize: "1rem"
          }}>Follow us for latest Deals and Offers</p>
          <div className="d-flex gap-2 justify-content-center align-items-center my-2">
            <a href="https://www.facebook.com/OLineOIndia/" target="_blank" rel="noreferrer">
              <img
                alt="facebook"
                src={FB}
                width={40}
              />
            </a>
            <a
              className="pl-2 "
              href="https://www.instagram.com/olineoindia/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="insta"
                src={INSTA}
                width={40}
              />
            </a>
            <a
              href="https://twitter.com/olineoindia"
              target="_blank"
              className="pl-2 "
              rel="noreferrer"
            >
              <img
                alt="twitter"
                src={TWITTER}
                width={40}
              />
            </a>
            <a
              href="https://www.youtube.com/@OLineOIndia"
              target="_blank"
              className="pl-2 icon-container"
              rel="noreferrer"
            >
              <img
                alt="youtube"
                src={YOUTUBE}
                width={40}
              />
            </a>

          </div>
          <p className="checkout-offer-text mt-2 mb-0" style={{ fontSize: "1rem" }}>Thankyou for visiting O-LINE-O India</p>
        </div>
      </div>
  
    </div>


  );
}

export default Page5;