import React from 'react';
import { ListGroup, Modal } from 'react-bootstrap';
import DashboardLoader from '../DashboardContent/DashboardLoader';

const OrderProductModal = (props) => {
    const { details, loading = false } = props;
    console.log({ details })

    return (
        <Modal
            {...props}
            size="lg"
            // centered
            className=''
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Ordered Details
                </Modal.Title>
            </Modal.Header>
            {loading ? (
                <DashboardLoader />
            ) : (
                <Modal.Body>
                    <div className='row mt-2 mb-4'>
                        <p className='fw-bold fa-lg'>User Details</p>
                        <div className="row p-2">
                            <div className="col-sm-3">
                                <p className="mb-0">Full Name</p>
                            </div>
                            <div className="col-sm-9">
                                <p className="text-muted mb-0 fw-bold">{details?.consignee?.fullName}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row p-2">
                            <div className="col-sm-3">
                                <p className="mb-0">Email Id</p>
                            </div>
                            <div className="col-sm-9">
                                <p className="text-muted mb-0 fw-bold">{details?.consignee?.email}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row p-2">
                            <div className="col-sm-3">
                                <p className="mb-0">Contact Number</p>
                            </div>
                            <div className="col-sm-9">
                                <p className="text-muted mb-0 fw-bold">{details?.consignee?.mobileNumber}</p>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='row'>
                        <p className='fw-bold fa-lg'>Product Details</p>
                        <ListGroup as="ol" numbered>
                            <ListGroup.Item>
                                <div className="ms-2 me-auto d-flex align-items-center equal-children">
                                    <div className="fw-bold">Product Name</div>
                                    <div className="fw-bold">Quantity</div>
                                    <div className="fw-bold mx-4">Consignment id</div>
                                    <div className="fw-bold">Delivery Status</div>
                                </div>
                            </ListGroup.Item>
                            { details &&
                                ((details.order?.productDetails?.length === 0) ||
                                    (details.order?.productId?.length === 0) ||
                                    (details.order?.item?.length === 0)
                                    ? <p className='d-flex justify-content-center align-items-center p-3'>Product Details Not Available</p> :
                                    details.order?.item?.map((item, index) => (
                                        <ListGroup.Item
                                            key={index}
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto d-flex align-items-center equal-children">
                                                <div className="fw-bold">{details.order?.productDetails[index]?.name}</div>
                                                <div className="fw-bold mx-4 text-center">{item}</div>
                                                <div className="fw-bold mx-4 text-center">Consignment Id</div>
                                                <div className="fw-bold">{details.order?.itemStatus[index] ?? "Not Found"}</div>
                                            </div>
                                        </ListGroup.Item>
                                    )))
                            }
                        </ListGroup>
                    </div>
                </Modal.Body>
            )}
        </Modal>
    );
};

export default OrderProductModal;