import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

//Components
import Section2 from "../../components/Section2/Section2";
import { addToCart as updateCart, getCartData, removeFromCart } from "../../api/Cart";
import { getProductServiceability, getSearchedProduct } from "../../api/Product";
import { addSaveForLaterItem, deleteSaveForLaterItem, getSaveForLater } from "../../api/SaveForLaterApi";
import getMixedProducts from "../../hooks/getMixedProducts";
import useUserCtx from "../../hooks/useUserCtx";
import useProductCtx from "../../hooks/useProductCtx";

// utils
import { currencyFormat, isOfferActive } from "../../utils";
import { PRODUCT_CLASSIFICATION } from "../../utils/Constants";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import PlusMinus from "../../components/PlusMinus/PlusMinus";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import NothingImage from "../../assets/png/your_order_image.png";

// toast.configure();

// Component definitions
const CartSection = ({ comboProducts, resetCartDetails }) => {
    const { allProducts, setAllProducts } = useProductCtx();
    const {
        setUserComboCart,
        cartArray,
        setCartArray,
        userSaveForLater,
        setUserSaveForLater,
        userDefaultAddress,
        setDeliveryEstDays,
        setOrderInit,
    } = useUserCtx();
    const { t } = useTranslation();
    const nav = useNavigate();
    const mounted = useRef(false);
    const isMobile = useMediaQuery("(max-width: 540px)");

    // state definitions
    const [cartSuggestions, setCartSuggestions] = useState([]);
    const [cartSuggestProducts, setCartSuggestProducts] = useState({
        loaded: false,
        products: [],
    });
    const [suggesProducts, setSuggesProducts] = useState([]);
    const [emptyCartFeaturedProducts, setEmptyCartFeaturedProducts] = useState(
        []
    );
    const [cartSuggestionArray, setCartSuggestionArray] = useState([]);
    // products that are added as combo offer to product in cart
    // const [comboProducts, setComboProducts] = useState([])
    useEffect(() => {
        setAllProducts({
            ...allProducts,
            products: allProducts.products.filter(
                (prod) =>
                    prod.classification !== PRODUCT_CLASSIFICATION.TEMP_HIDDEN
            ),
        });
    }, []);

    useEffect(() => {
        if (cartArray && cartArray.no_of_carts > 0) {
            cartArray.cart.forEach((prod) => {
                let immediatRecom = prod?.complimentoryCatgories?.immediate
                    ? [...prod?.complimentoryCatgories?.immediate]
                    : [];
                immediatRecom?.forEach((recom) => {
                    let ind = cartSuggestions.findIndex(
                        (prod) => prod === recom
                    );
                    if (ind === -1) {
                        setCartSuggestions([...cartSuggestions, recom]);
                    }
                });

                // check the product has combo offer and if yes fetch that product
                /* const comboOffer = prod.discount?.combo;
                if (comboOffer?.value && isOfferActive(comboOffer)) {
                    fetchComboProduct(comboOffer.childEan);
                } */
            });
        }
    }, [cartArray]);

    useEffect(() => {
        setEmptyCartFeaturedProducts(
            getMixedProducts(allProducts.products, allProducts.np1, 10)
        );
        setCartSuggestionArray(
            getMixedProducts(
                allProducts.products,
                cartSuggestProducts.products,
                10
            )
        );
    }, [allProducts]);

    //suggestions
    useEffect(() => {
        if (cartSuggestions && cartSuggestions.length > 0) {
            cartSuggestions.map((category) => {
                let searchTerm = "hierarchyL2=" + category;
                getSearchedProduct(searchTerm).then((res) => {
                    if (res.no_of_products > 0) {
                        let prod = {};
                        prod = res.products[0];
                        if (prod && res) {
                            setSuggesProducts([...suggesProducts, prod]);
                        }
                    }
                });
            });
        }
    }, [cartSuggestions]);

    useEffect(() => {
        if (suggesProducts && suggesProducts.length > 0) {
            setCartSuggestProducts((prev) => ({
                ...prev,
                loaded: true,
                products: suggesProducts,
            }));
        }
    }, [suggesProducts]);

    const handleQuantityInc = (product) => {
        const storeId = cartArray.cart[0]?.from[0];
        updateCart(product._id, product.quantity + 1, storeId).then((res) => {
            if (!res) return toast.error(t("writeToUs.error"));
            /* getCartData().then((res) => {
                if (res) {
                    let prod = [];
                    res.cart.forEach((item, idx) => {
                        if (item?.productId === null) return;
                        let product = { ...item?.productId };
                        product["quantity"] = item.qty;
                        product["from"] = item.from;
                        prod.push(product);
                    });
                    setCartArray({
                        loaded: true,
                        no_of_carts: prod.length,
                        cart: prod,
                        combo: res.combo,
                    });
                }
            }); */
            resetCartDetails();
        });
    };

    const handleQuantityDec = (product) => {
        const storeId = cartArray.cart[0]?.from[0];
        if (product.quantity === 1) return handleRemoveFromCart(product._id);
        updateCart(product._id, product.quantity - 1, storeId).then((res) => {
            if (!res) return toast.error(t("writeToUs.error"));
            /* getCartData().then((res) => {
                if (res) {
                    let prod = [];
                    res.cart.forEach((item, idx) => {
                        if (item?.productId === null) return;
                        let product = { ...item?.productId };
                        product["quantity"] = item.qty;
                        product["from"] = item.from;
                        prod.push(product);
                    });
                    setCartArray({
                        loaded: true,
                        no_of_carts: prod.length,
                        cart: prod,
                        combo: res.combo,
                    });
                }
            }); */
            resetCartDetails();
        });
    };

    //ORDER INITIALIZATION CODE+++++++++++++++++++++++++++++++++++++++++
    const handleOrderInit = (e) => {
        e.preventDefault();
        if (cartArray.no_of_carts > 0) {
            let productId = [];
            let quantity = [];
            cartArray.cart.forEach((item) => productId.push(item._id));
            cartArray.cart.forEach((item) =>
                quantity.push(parseInt(item.quantity))
            );
            setOrderInit((prev) => ({
                ...prev,
                productId: productId,
                quantity: quantity,
            }));
            nav("/delivery-option");
        } else {
            toast.error(t("cart.add-to-cart"));
        }
    };

    //Remove Product from cart
    const handleRemoveFromCart = (id) => {
        removeFromCart(id).then((res) =>
            res
                ? (setUserComboCart([]),
                  toast.error(t("cart.remove-from-cart")),
                  getCartData().then((res) => {
                      if (res) {
                          let prod = [];
                          res.cart.forEach((item, idx) => {
                              if (item?.productId === null) return;
                              let product = { ...item?.productId };
                              product["quantity"] = item.qty;
                              product["from"] = item.from;
                              prod.push(product);
                          });
                          setCartArray({
                              loaded: true,
                              no_of_carts: prod.length,
                              cart: prod,
                              combo: res.combo,
                          });
                      }
                  }))
                : ""
        );
    };

    const handleAddItemToSaveForLater = (id) => {
        addSaveForLaterItem(id).then((res) =>
            res
                ? (toast.success(t("cart.addedToSaveForLater")),
                  removeFromCart(id).then((res) =>
                      res
                          ? (setUserComboCart([]),
                            toast.error(t("cart.remove-from-cart")),
                            getCartData().then((res) => {
                                if (res) {
                                    let prod = [];
                                    res.cart.forEach((item, idx) => {
                                        if (item?.productId === null) return;
                                        let product = { ...item?.productId };
                                        product["quantity"] = item.qty;
                                        product["from"] = item.from;
                                        prod.push(product);
                                    });
                                    setCartArray({
                                        loaded: true,
                                        no_of_carts: prod.length,
                                        cart: prod,
                                        combo: res.combo,
                                    });
                                }
                            }))
                          : ""
                  ),
                  getSaveForLater().then((res) => {
                      setUserSaveForLater({
                          loaded: true,
                          no_of_save_for_later_items:
                              res.no_of_save_for_later_items,
                          save_for_later_items: res.save_for_later_items,
                      });
                  }))
                : toast.error(t("writeToUs.error"))
        );
    };

    const handleAddToCart = (id) => {
        updateCart(id, 1).then((res) => {
            if (!res) return toast.success(t("writeToUs.error"));
            toast.success(t("product-page.added-to-cart"));
            deleteSaveForLaterItem(id).then((res) => {
                getSaveForLater().then((res) => {
                    setUserSaveForLater({
                        loaded: true,
                        no_of_save_for_later_items:
                            res.no_of_save_for_later_items,
                        save_for_later_items: res.save_for_later_items,
                    });
                });
            });
            getCartData().then((res) => {
                if (res) {
                    let prod = [];
                    res.cart.forEach((item, idx) => {
                        if (item?.productId === null) return;
                        let product = { ...item?.productId };
                        product["quantity"] = item.qty;
                        product["from"] = item.from;
                        prod.push(product);
                    });
                    setCartArray({
                        loaded: true,
                        no_of_carts: prod.length,
                        cart: prod,
                        combo: res.combo,
                    });
                }
            });
        });
    };

    const handleRemoveFromSaveForLater = (id) => {
        deleteSaveForLaterItem(id).then((res) =>
            res
                ? (toast.success(t("cart.removeFromSaveForLater")),
                  getSaveForLater().then((res) => {
                      setUserSaveForLater({
                          loaded: true,
                          no_of_save_for_later_items:
                              res.no_of_save_for_later_items,
                          save_for_later_items: res.save_for_later_items,
                      });
                  }))
                : toast.error(t("writeToUs.error"))
        );
    };

    const pageSwitch = (e) => {
        e.preventDefault();
        nav("/");
    };

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    //Get Product Delivery Estimate in cart
    useEffect(() => {
        if (
            cartArray &&
            cartArray.no_of_carts > 0 &&
            userDefaultAddress.loaded
        ) {
            let itemArray = cartArray.cart.map((item) => {
                let itemObj = {
                    skuId: item.ean,
                    quantity: item.quantity,
                };
                return itemObj;
            });
            getProductServiceability(
                userDefaultAddress?.address?.zip,
                itemArray
            ).then((res) => {
                if (res) {
                    setDeliveryEstDays({
                        loaded: true,
                        value: res,
                    });
                }
            });
        }
    }, [cartArray, userDefaultAddress, mounted.current]);

    // invoke combo products
    /* const fetchComboProduct = async (productEan) => {
        const response = await getSearchedProduct(`ean=${productEan}`);
        const product = response.products[0];
        setComboProducts(prev => {
            return [
                ...prev.filter((prevProd) => prevProd._id !== product._id),
                { ...product, isComboProduct: true }
            ];
        })

    } */

    const getTotalPriceOfProduct = (price, qty) => {
        if (price && qty) {
            return currencyFormat(price * qty);
        }
        return "-";
    };

    const getComboProdPrice = (comboProdMop, comboOffer) => {
        let result = comboProdMop;
        const discountVal = parseFloat(comboOffer.value);

        if (comboOffer.discountType !== "flat") {
            result = result - (result * discountVal) / 100;
        } else {
            result = result - discountVal;
        }
        return result;
    };

    const getFinalDisplayPrice = (productData) => {
        const mop = parseFloat(productData.price?.mop);
        if (!mop) return 0;
        const flatDiscount = productData.discount?.flatDiscount;

        let result = mop;

        if (flatDiscount?.value > 0 && isOfferActive(flatDiscount)) {
            if (flatDiscount?.discountType === "flat") {
                result -= flatDiscount?.value;
            } else {
                result -= (result * flatDiscount?.value) / 100;
            }
        }

        return result;
    };

    // Main renderer
    return (
        <>
            {cartArray.no_of_carts === 0 &&
            userSaveForLater.no_of_save_for_later_items === 0 ? (
                <div className="order_Page_Right">
                    <div className="empty_order_sec">
                        <p className="empty_order_text">
                            {t("cart.empty-cart")}
                        </p>
                        <button
                            type="submit"
                            className="submit-button"
                            onClick={() => nav("/")}
                        >
                            <p>{t("start-shopping")}</p>
                        </button>
                    </div>
                    <Section2
                        id={"Top-sellers-sec"}
                        heading={t("top-sellers")}
                        productData={allProducts}
                        productArray={emptyCartFeaturedProducts}
                    />
                </div>
            ) : (
                <>
                    <div className="cart-content">
                        <div
                            style={{
                                display: "flex",
                                position: "relative",
                                fontWeight: "700",
                                fontSize: "40px",
                                width: isMobile ? "100%" : "88%",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "40px",
                                    marginLeft: isMobile ? "30px" : "",
                                }}
                            >
                                Cart:
                            </span>
                        </div>

                        {cartArray.no_of_carts > 0 ? (
                            // cartArray.cart.map((item, index) => (
                            //     <CartProductCard
                            //         key={index}
                            //         product={item}
                            //         handleRemoveFromCart={handleRemoveFromCart}
                            //         handleQuantityInc={handleQuantityInc}
                            //         handleQuantityDec={handleQuantityDec}
                            //         handleAddItemToSaveForLater={handleAddItemToSaveForLater}
                            //         handleAddToCart={handleAddToCart}
                            //         handleRemoveFromSaveForLater={handleRemoveFromSaveForLater}
                            //         deliveryEstDays={deliveryEstDays}
                            //     />
                            // )).concat(
                            //     comboProducts.map((item) => (
                            //         <CartProductCard
                            //             key={item._id}
                            //             product={item}
                            //             comboProduct={item.isComboProduct}
                            //         />
                            //     ))
                            // )
                            <Paper
                                className="myTable"
                                sx={{
                                    width: isMobile ? "100%" : "88%",
                                    border: "none",
                                    outline: "none",
                                    fontFamily: "Quicksand",
                                }}
                            >
                                <TableContainer
                                    id="font-c"
                                    sx={{
                                        // maxHeight: 1040,
                                        // paddingLeft: "12rem",
                                        // paddingRight: "12rem",
                                        fontWeight: "700",
                                        fontFamily: "Quicksand",
                                    }}
                                >
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                        sx={{
                                            fontWeight: 700,
                                            fontFamily: "Quicksand",
                                        }}
                                    >
                                        <TableHead
                                            sx={{ backgroundColor: "#E0E0E0" }}
                                        >
                                            {!isMobile && (
                                                <TableRow>
                                                    <TableCell
                                                        align="center"
                                                        colSpan={1}
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: 18,
                                                        }}
                                                    ></TableCell>
                                                    <TableCell
                                                        align="center"
                                                        colSpan={1}
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: 18,
                                                        }}
                                                    >
                                                        IMAGE
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        colSpan={1}
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: 18,
                                                        }}
                                                    >
                                                        PRODUCT
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        colSpan={1}
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: 18,
                                                        }}
                                                    >
                                                        PRICE
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        colSpan={1}
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: 18,
                                                        }}
                                                    >
                                                        QTY
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        colSpan={1}
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: 18,
                                                        }}
                                                    >
                                                        TOTAL
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableHead>
                                        <TableBody>
                                            {cartArray.cart.map(
                                                (product, i) => (
                                                    <>
                                                        <TableRow
                                                            sx={{
                                                                "& > *": {
                                                                    borderBottom:
                                                                        "1px solid #E0E0E0",
                                                                },
                                                                "& > :first-child":
                                                                    {
                                                                        borderLeft:
                                                                            "1px solid #E0E0E0",
                                                                    },
                                                                "& > :last-child":
                                                                    {
                                                                        borderRight:
                                                                            "1px solid #E0E0E0",
                                                                    },
                                                                backgroundColor:
                                                                    "transparent",
                                                                fontFamily:
                                                                    "Quicksand",
                                                            }}
                                                        >
                                                            <TableCell
                                                                align="center"
                                                                colSpan={1}
                                                                sx={{
                                                                    border: isMobile
                                                                        ? "none"
                                                                        : "1px solid #E0E0E0",
                                                                }}
                                                            >
                                                                <div className="img-static">
                                                                    <input type="checkbox" />
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                sx={{
                                                                    borderRight:
                                                                        "none",
                                                                }}
                                                            >
                                                                <div className="d-flex flex-column align-items-center">
                                                                    {/* <h3 className="cart-combo-offer">COMBO OFFER</h3> */}
                                                                    <div
                                                                        style={{
                                                                            width: isMobile
                                                                                ? "100px"
                                                                                : "150px",
                                                                            height: isMobile
                                                                                ? "100px"
                                                                                : "150px",
                                                                            border: "1px solid #B7B7B7",
                                                                            borderRadius:
                                                                                "10px",
                                                                            marginRight:
                                                                                isMobile
                                                                                    ? "10px"
                                                                                    : "2rem",
                                                                            marginLeft:
                                                                                isMobile
                                                                                    ? "10px"
                                                                                    : "2rem",
                                                                            boxShadow:
                                                                                "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className="w-100 h-100 rounded-3"
                                                                            alt="product-1image"
                                                                            src={
                                                                                product
                                                                                    .images
                                                                                    ?.length
                                                                                    ? product
                                                                                          .images[0]
                                                                                    : NothingImage
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {!isMobile && (
                                                                        <div className="delSave">
                                                                            <h3
                                                                                className="delSaveContent"
                                                                                onClick={() =>
                                                                                    handleRemoveFromCart(
                                                                                        product._id
                                                                                    )
                                                                                }
                                                                            >
                                                                                Delete
                                                                            </h3>
                                                                            <h3
                                                                                className="delSaveContent"
                                                                                onClick={() =>
                                                                                    handleAddItemToSaveForLater(
                                                                                        product._id
                                                                                    )
                                                                                }
                                                                            >
                                                                                Save
                                                                                for
                                                                                later
                                                                            </h3>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </TableCell>

                                                            <TableCell
                                                                align="center"
                                                                colSpan={1}
                                                                sx={{
                                                                    width: isMobile
                                                                        ? "100%"
                                                                        : "40%",
                                                                    borderRight:
                                                                        "1px solid #E0E0E0",
                                                                    borderLeft:
                                                                        "1px solid #E0E0E0",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            isMobile
                                                                                ? "left"
                                                                                : "center",
                                                                        // justifyContent: "space-evenly",
                                                                        padding:
                                                                            isMobile
                                                                                ? "0.1rem"
                                                                                : "1.5rem",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            flexDirection:
                                                                                "column",
                                                                            // justifyContent: "space-evenly",
                                                                            // height: "120px",
                                                                            alignItems:
                                                                                "flex-start",
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                color: "#707070",
                                                                                fontWeight:
                                                                                    "700",
                                                                                fontSize:
                                                                                    isMobile
                                                                                        ? "13px"
                                                                                        : "25px",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            {
                                                                                product?.title
                                                                            }
                                                                        </span>
                                                                        <span
                                                                            className="cart-product-desc"
                                                                            style={{
                                                                                color: "#3581C1",
                                                                                fontWeight:
                                                                                    "700",
                                                                                fontSize:
                                                                                    isMobile
                                                                                        ? "8px"
                                                                                        : "15px",
                                                                                width: isMobile
                                                                                    ? "100%"
                                                                                    : "70%",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            {
                                                                                product?.description?.[0]?.split(
                                                                                    "\n"
                                                                                )[0]
                                                                            }
                                                                        </span>
                                                                        {isMobile && (
                                                                            <>
                                                                                <h1 className="mobileViewPrice">
                                                                                    {currencyFormat(getFinalDisplayPrice(product))}
                                                                                </h1>
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        justifyContent:
                                                                                            "space-between",
                                                                                        alignContent:
                                                                                            "center",
                                                                                        gap: "10px",
                                                                                        marginBottom:
                                                                                            "10px",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            border: "1px solid #F8F9FA",
                                                                                            backgroundColor:
                                                                                                "#F8F9FA",
                                                                                            borderRadius:
                                                                                                "3px",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            handleQuantityDec(
                                                                                                product
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <RemoveIcon
                                                                                            sx={{
                                                                                                color: "#3C96DB",
                                                                                            }}
                                                                                            fontSize="14px"
                                                                                        />
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            border: "1px solid #F8F9FA",
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontStyle:
                                                                                                    "normal",
                                                                                                fontWeight:
                                                                                                    "700",
                                                                                                fontSize:
                                                                                                    "12px",
                                                                                                lineHeight:
                                                                                                    "19px",
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                product.quantity
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            border: "1px solid #F8F9FA",
                                                                                            backgroundColor:
                                                                                                "#F8F9FA",
                                                                                            borderRadius:
                                                                                                "3px",
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            handleQuantityInc(
                                                                                                product
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <AddIcon
                                                                                            sx={{
                                                                                                color: "#3C96DB",
                                                                                            }}
                                                                                            fontSize="14px"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="delSave">
                                                                                    <h3
                                                                                        className="delSaveContent"
                                                                                        onClick={() =>
                                                                                            handleRemoveFromCart(
                                                                                                product._id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Delete
                                                                                    </h3>
                                                                                    <h3
                                                                                        className="delSaveContent"
                                                                                        onClick={() =>
                                                                                            handleAddItemToSaveForLater(
                                                                                                product._id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Save
                                                                                        for
                                                                                        later
                                                                                    </h3>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            {!isMobile && (
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={1}
                                                                    sx={{
                                                                        borderRight:
                                                                            "1px solid #E0E0E0",
                                                                        fontWeight:
                                                                            "700",
                                                                        fontSize:
                                                                            "19px",
                                                                        lineHeight:
                                                                            "26px",
                                                                        color: "#262626",
                                                                    }}
                                                                >
                                                                    {currencyFormat(getFinalDisplayPrice(product))}
                                                                </TableCell>
                                                            )}
                                                            {!isMobile && (
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={1}
                                                                    sx={{
                                                                        borderRight:
                                                                            "1px solid #E0E0E0",
                                                                    }}
                                                                >
                                                                    <PlusMinus
                                                                        quantity={
                                                                            product.quantity
                                                                        }
                                                                        handleQuantityInc={() =>
                                                                            handleQuantityInc(
                                                                                product
                                                                            )
                                                                        }
                                                                        handleQuantityDec={() =>
                                                                            handleQuantityDec(
                                                                                product
                                                                            )
                                                                        }
                                                                    />
                                                                </TableCell>
                                                            )}
                                                            {!isMobile && (
                                                                <TableCell
                                                                    align="center"
                                                                    colSpan={1}
                                                                    rowSpan={
                                                                        comboProducts.filter(
                                                                            (
                                                                                CProduct
                                                                            ) =>
                                                                                product
                                                                                    ?.discount
                                                                                    ?.combo
                                                                                    ?.value ===
                                                                                CProduct?.ean
                                                                        )
                                                                            .length +
                                                                        1
                                                                    }
                                                                    sx={{
                                                                        borderRight:
                                                                            "1px solid #E0E0E0",
                                                                        fontWeight:
                                                                            "700",
                                                                        fontSize:
                                                                            "19px",
                                                                        lineHeight:
                                                                            "26px",
                                                                        color: "#262626",
                                                                    }}
                                                                >
                                                                    {getTotalPriceOfProduct(getFinalDisplayPrice(product),product?.quantity)}
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                        {comboProducts
                                                            .filter(
                                                                (CProduct) =>
                                                                    product
                                                                        ?.discount
                                                                        ?.combo
                                                                        ?.childEan ===
                                                                    CProduct?.ean
                                                            )
                                                            .map(
                                                                (
                                                                    comboProd,
                                                                    i
                                                                ) => (
                                                                    <TableRow
                                                                        sx={{
                                                                            "& > *":
                                                                                {
                                                                                    borderBottom:
                                                                                        "1px solid #E0E0E0",
                                                                                },
                                                                            "& > :first-child":
                                                                                {
                                                                                    borderLeft:
                                                                                        "1px solid #E0E0E0",
                                                                                },
                                                                            "& > :last-child":
                                                                                {
                                                                                    borderRight:
                                                                                        "1px solid #E0E0E0",
                                                                                },
                                                                            backgroundColor:
                                                                                "transparent",
                                                                            fontFamily:
                                                                                "Quicksand",
                                                                        }}
                                                                    >
                                                                        <TableCell
                                                                            align="center"
                                                                            colSpan={
                                                                                1
                                                                            }
                                                                            sx={{
                                                                                border: isMobile
                                                                                    ? "none"
                                                                                    : "1px solid #E0E0E0",
                                                                            }}
                                                                        ></TableCell>
                                                                        <TableCell
                                                                            align="center"
                                                                            sx={{
                                                                                borderRight:
                                                                                    "none",
                                                                            }}
                                                                        >
                                                                            <div className="d-flex flex-column align-items-center">
                                                                                <div
                                                                                    style={{
                                                                                        width: isMobile
                                                                                            ? "100px"
                                                                                            : "150px",
                                                                                        height: isMobile
                                                                                            ? "100px"
                                                                                            : "150px",
                                                                                        border: "1px solid #B7B7B7",
                                                                                        borderRadius:
                                                                                            "10px",
                                                                                        marginRight:
                                                                                            isMobile
                                                                                                ? "10px"
                                                                                                : "2rem",
                                                                                        marginLeft:
                                                                                            isMobile
                                                                                                ? "10px"
                                                                                                : "2rem",
                                                                                        boxShadow:
                                                                                            "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        className="w-100 h-100 rounded-3"
                                                                                        alt="product-1image"
                                                                                        src={
                                                                                            comboProd
                                                                                                .images
                                                                                                ?.length
                                                                                                ? comboProd
                                                                                                      .images[0]
                                                                                                : NothingImage
                                                                                        }
                                                                                    />

                                                                                    <div className="tag-item tag-best-seller fs-6 mt-2">
                                                                                        Combo
                                                                                        Product
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </TableCell>

                                                                        <TableCell
                                                                            align="center"
                                                                            colSpan={
                                                                                1
                                                                            }
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? "100%"
                                                                                    : "40%",
                                                                                borderRight:
                                                                                    "1px solid #E0E0E0",
                                                                                borderLeft:
                                                                                    "1px solid #E0E0E0",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        "flex",
                                                                                    alignItems:
                                                                                        isMobile
                                                                                            ? "left"
                                                                                            : "center",
                                                                                    // justifyContent: "space-evenly",
                                                                                    padding:
                                                                                        isMobile
                                                                                            ? "0.1rem"
                                                                                            : "1.5rem",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                        // justifyContent: "space-evenly",
                                                                                        // height: "120px",
                                                                                        alignItems:
                                                                                            "flex-start",
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            color: "#707070",
                                                                                            fontWeight:
                                                                                                "700",
                                                                                            fontSize:
                                                                                                isMobile
                                                                                                    ? "13px"
                                                                                                    : "25px",
                                                                                            textAlign:
                                                                                                "left",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            comboProd?.title
                                                                                        }
                                                                                    </span>
                                                                                    <span
                                                                                        className="cart-product-desc"
                                                                                        style={{
                                                                                            color: "#3581C1",
                                                                                            fontWeight:
                                                                                                "700",
                                                                                            fontSize:
                                                                                                isMobile
                                                                                                    ? "8px"
                                                                                                    : "15px",
                                                                                            width: isMobile
                                                                                                ? "100%"
                                                                                                : "70%",
                                                                                            textAlign:
                                                                                                "left",
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            comboProd?.description[0]?.split(
                                                                                                "\n"
                                                                                            )[0]
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </TableCell>
                                                                        {!isMobile && (
                                                                            <TableCell
                                                                                align="center"
                                                                                colSpan={
                                                                                    1
                                                                                }
                                                                                sx={{
                                                                                    borderRight:
                                                                                        "1px solid #E0E0E0",
                                                                                    fontWeight:
                                                                                        "700",
                                                                                    fontSize:
                                                                                        "19px",
                                                                                    lineHeight:
                                                                                        "26px",
                                                                                    color: "#262626",
                                                                                }}
                                                                            >
                                                                                {currencyFormat(
                                                                                    getComboProdPrice(
                                                                                        comboProd
                                                                                            ?.price
                                                                                            ?.mop,
                                                                                        product
                                                                                            ?.discount
                                                                                            ?.combo
                                                                                    )
                                                                                ) ??
                                                                                    ""}
                                                                            </TableCell>
                                                                        )}
                                                                        {!isMobile && (
                                                                            <TableCell
                                                                                align="center"
                                                                                colSpan={
                                                                                    1
                                                                                }
                                                                                sx={{
                                                                                    borderRight:
                                                                                        "1px solid #E0E0E0",
                                                                                }}
                                                                            >
                                                                                -
                                                                            </TableCell>
                                                                        )}
                                                                        {!isMobile && (
                                                                            <TableCell
                                                                                align="center"
                                                                                colSpan={
                                                                                    1
                                                                                }
                                                                                rowSpan={
                                                                                    comboProducts.filter(
                                                                                        (
                                                                                            CProduct
                                                                                        ) =>
                                                                                            product
                                                                                                ?.discount
                                                                                                ?.combo
                                                                                                ?.childEan ===
                                                                                            CProduct?.ean
                                                                                    )
                                                                                        .length +
                                                                                    1
                                                                                }
                                                                                sx={{
                                                                                    borderRight:
                                                                                        "1px solid #E0E0E0",
                                                                                    fontWeight:
                                                                                        "700",
                                                                                    fontSize:
                                                                                        "19px",
                                                                                    lineHeight:
                                                                                        "26px",
                                                                                    color: "#262626",
                                                                                }}
                                                                            >
                                                                                {currencyFormat(
                                                                                    getComboProdPrice(
                                                                                        comboProd
                                                                                            ?.price
                                                                                            ?.mop,
                                                                                        product
                                                                                            ?.discount
                                                                                            ?.combo
                                                                                    )
                                                                                ) ??
                                                                                    ""}
                                                                            </TableCell>
                                                                        )}
                                                                    </TableRow>
                                                                )
                                                            )}
                                                    </>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        ) : (
                            <div className="empty_order_sec">
                                <p className="empty_order_text">
                                    {t("cart.empty-cart")}
                                </p>
                                <button
                                    type="submit"
                                    className="submit-button"
                                    onClick={pageSwitch}
                                >
                                    <p>{t("start-shopping")}</p>
                                </button>
                            </div>
                        )}
                    </div>
                    {/* <div className="">
                    <div className="cart_Subtotal_Section section_Wrapper">
                        <p>
                            {t('cart.subTotal')} ({priceBoxDetails.cartItemsNumber} {t('cart.items')}): <span> ₹{priceBoxDetails.totalAmount}</span>
                        </p>
                        <div className="cart_Footer_Right">
                            <button type="submit" className="submit-button" onClick={handleOrderInit}>
                                <p>{t('cart.checkout')}</p>
                            </button>
                        </div>
                    </div>

                    <div className={"tab_None"}>
                        <PriceDetailsBox HideDetails={false} />
                    </div>

                    <Section2 id={"Top-sellers-sec"} heading={t('top-sellers')} productData={allProducts} productArray={cartSuggestionArray} />

                    <div className="cart_Save_Later">
                        <div className="save_Later_Header section_Wrapper">
                            <p className="">{t('cart.saved-for-later')}</p>
                        </div>
                        <div className="cards_Container">
                            {userSaveForLater.no_of_save_for_later_items > 0 ? (
                                userSaveForLater.save_for_later_items.map((item, index) => (
                                    <CartProductCard
                                        key={index}
                                        product={item}
                                        saveForLaterItem={true}
                                        handleQuantityInc={handleQuantityInc}
                                        handleQuantityDec={handleQuantityDec}
                                        handleRemoveFromCart={handleRemoveFromCart}
                                        handleAddItemToSaveForLater={handleAddItemToSaveForLater}
                                        handleAddToCart={handleAddToCart}
                                        handleRemoveFromSaveForLater={handleRemoveFromSaveForLater}
                                    />
                                ))
                            ) : (
                                <div className="empty_order_sec">
                                    <p className="empty_order_text">{t('cart.empty-save-for-later')}</p>
                                    <button type="submit" className="submit-button" onClick={pageSwitch}>
                                        <p>{t('start-shopping')}</p>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="cart_Footer ">
                        <div className="cart_Footer_Left">
                            <p className="footer_Price">₹{`${priceBoxDetails.totalAmount}`}</p>
                            <p className="footer_Left_Text">{t('cart.view-price-details')}</p>
                        </div>
                        <div className="cart_Footer_Right">
                            <button type="submit" className="submit-button" onClick={handleOrderInit}>
                                <p>{t('cart.checkout')}</p>
                            </button>
                        </div>
                    </div>
                    <Section2 id={"Top-sellers-sec"} heading={t('top-sellers')} productData={allProducts} productArray={cartSuggestionArray} />
                </div> */}
                </>
            )}
        </>
    );
};

export default CartSection;
