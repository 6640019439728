import React, { useMemo } from 'react';

const Pagination = ({ productsPerPage, totalProducts, pageChange, currentPage }) => {
  const pageNumbers = useMemo(() => {
    const arr = [];
    for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
      arr.push(i)
    }
    return arr;
  }, [totalProducts, productsPerPage]);

  const active = { backgroundColor: "#1b325e", color: "white", cursor: "pointer"};

  return (
    <nav>
      <ul className='pagination flex-wrap'>
        {
          pageNumbers.map((number) => (
            <li key={number} className='page-item'>
              {currentPage === number ?
               <a className='page-link' style={active} onClick={(e) => pageChange(e, number)}>
               {number}
             </a>
             :
                <a className='page-link' style={{ cursor: "pointer"}} onClick={(e) => pageChange(e,number)}>
                {number}
              </a>}
            </li>
          ))
        }
      </ul>
    </nav>
  )
}

export default Pagination