import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import TextField from '@mui/material/TextField';
import { toast } from "react-toastify";
import { productCategoryDetails } from "../../api/OfferAPIs";
import OfferContext from "../../Contexts/OffersContext";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { OFFERS_CONSTANT } from "../../utils/Constants";
import SVGLoader from "./SVGLoader";

function Page3() {
    const { OfferId } = useContext(OfferContext);
    const [productCategoryInfo, setproductCategoryInfo] = useState({
        productCategory: "",
        productName: "",
        productAmount: "",
        invoiceNumber: "",
        productBrandName: "",
        imeiNumber: ""
    });
    const [loading, setLoading] = useState(false);

    const [openCategory, setOpenCategory] = useState(false);
    const [openBrandSelect, setOpenBrandSelect] = useState(false);

    const navigate = useNavigate();

    const validateData = () => {
        let isValid = true;
        if (!OFFERS_CONSTANT.OfferCategories.includes(productCategoryInfo.productCategory)) {
            isValid = false
        }

        return isValid;
    }

    const onContinue = async () => {
        const validate = validateData();
        if (!validate) return toast.error("Fields are Invalid")
        const payload = {
            productCategory: productCategoryInfo.productCategory,
            productName: productCategoryInfo.productName,
            productAmount: productCategoryInfo.productAmount,
            invoiceNumber: productCategoryInfo.invoiceNumber,
            OfferUserID: OfferId
        };
        if (payload.productCategory === OFFERS_CONSTANT.OfferCategories[0]) {
            payload['brandName'] = productCategoryInfo.productBrandName;

            if (payload['brandName'] === "Realme") {
                payload['imeiNumber'] = productCategoryInfo.imeiNumber;
            }
        }
        try {
            setLoading(true);
            const data = await productCategoryDetails(payload);
            if (data?.status === "error") {
                toast.error(data?.message ?? "Something went wrong")
            }
            else {
                toast.success(data?.message);
                navigate('/Republic-Week-Offer/4');
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ?? "Something went wrong");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mt-3 position-relative conatiner-parent" >

            <div className="input-group mt-2" >
                <FormControl
                    fullWidth
                    className="mb-3"
                >
                    <InputLabel
                        variant="filled"
                        id="demo-simple-select-label"
                        sx={{
                            fontWeight: "bold",
                            textTransform: "uppercase"
                        }}
                    >Product Category
                    </InputLabel>

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select-1"
                        value={productCategoryInfo.productCategory}
                        label="Product Category"
                        open={openCategory}
                        onClose={() => setOpenCategory(false)}
                        onOpen={() => setOpenCategory(true)}
                        onChange={(e) => {
                            const value = e.target.value;
                            let getBrandName = productCategoryInfo.productBrandName;
                            if (value !== OFFERS_CONSTANT.OfferCategories[0] && getBrandName !== "") {
                                getBrandName = '';
                            }
                            setproductCategoryInfo(prev => ({ ...prev, productCategory: value, productBrandName: getBrandName }));
                        }
                        }
                        sx={{
                            background: "white",
                            textTransform: "uppercase"
                        }}
                    >
                        {OFFERS_CONSTANT.OfferCategories.map((e, index, self) => (
                            <MenuItem
                                value={e}
                                key={`Product_Category_${index}`}
                                className="d-flex justify-content-start align-items-center"
                                sx={{
                                    textTransform: "uppercase",
                                    borderBottom: self.length - 1 === index ? "none" : "1px solid"
                                }}
                            >
                                {e}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {productCategoryInfo.productCategory === OFFERS_CONSTANT.OfferCategories[0] &&
                <div className="input-group mt-2" >
                    <FormControl
                        fullWidth
                        className="mb-3"
                    >
                        <InputLabel
                            variant="filled"
                            id="demo-simple-select-label-2"
                            sx={{
                                fontWeight: "bold",
                                textTransform: "uppercase"
                            }}
                        >Product Brand Name
                        </InputLabel>

                        <Select
                            labelId="demo-simple-select-label-2"
                            id="demo-simple-select-2"
                            value={productCategoryInfo.productBrandName}
                            label="Product Brand Name"
                            open={openBrandSelect}
                            onClose={() => setOpenBrandSelect(false)}
                            onOpen={() => setOpenBrandSelect(true)}
                            onChange={(e) => {
                                const value = e.target.value;
                                setproductCategoryInfo(prev => ({ ...prev, productBrandName: value }));
                            }
                            }
                            sx={{
                                background: "white",
                                textTransform: "uppercase"
                            }}
                        >
                            {OFFERS_CONSTANT.SmartPhones.map((e, index, self) => (
                                <MenuItem
                                    value={e}
                                    key={`Product_Brand_${index}`}
                                    className="d-flex justify-content-start align-items-center"
                                    sx={{
                                        textTransform: "uppercase",
                                        borderBottom: self.length - 1 === index ? "none" : "1px solid"
                                    }}
                                >
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            }

            {
                (productCategoryInfo.productCategory === OFFERS_CONSTANT.OfferCategories[0] &&
                    productCategoryInfo.productBrandName === "Realme") &&
                <div className="input-group mt-2">
                    <TextField
                        fullWidth
                        type="number"
                        size="small"
                        className="form-control fw-700 mb-3"
                        label="Product IMEI Number"
                        variant="filled"
                        value={productCategoryInfo.imeiNumber}
                        onChange={(e) => {
                            setproductCategoryInfo(prev => ({ ...prev, imeiNumber: e.target.value }));
                        }}
                        inputMode="numeric"
                    />
                </div>
            }


            <div className="input-group mt-2">
                <TextField
                    fullWidth
                    type="text"
                    size="small"
                    className="form-control fw-700 mb-3"
                    label="Product Name"
                    variant="filled"
                    value={productCategoryInfo.productName}
                    onChange={(e) => {
                        setproductCategoryInfo(prev => ({ ...prev, productName: e.target.value }));
                    }}
                    inputMode="text"
                />
            </div>


            <div className="input-group mt-2">
                <TextField
                    fullWidth
                    type="text"
                    size="small"
                    className="form-control fw-700 mb-3"
                    label="Invoice Number"
                    variant="filled"
                    value={productCategoryInfo.invoiceNumber}
                    onChange={(e) => {
                        setproductCategoryInfo(prev => ({ ...prev, invoiceNumber: e.target.value }));
                    }}
                    inputMode="text"
                />
            </div>

            <div className="d-flex justify-content-center align-items-center gap-2 mt-2 invoice-group">
                <TextField
                    style={{
                        width: "152px"
                    }}
                    type="number"
                    size="small"
                    className="form-control mt-2 fw-700"
                    label="Invoice Value"
                    variant="filled"
                    InputProps={{
                        readOnly: true,
                    }}
                    onClick={() => document.getElementById("invoice-amount").focus()}
                />
                <span style={{
                    fontSize: "2rem"
                }}>₹</span>
                <TextField
                    type="number"
                    size="small"
                    className="form-control mt-2 fw-700"
                    label="Amount"
                    variant="filled"
                    id="invoice-amount"
                    value={productCategoryInfo.productAmount}
                    onChange={(e) => {
                        setproductCategoryInfo({ ...productCategoryInfo, productAmount: e.target.value });
                    }}
                    inputMode="numeric"
                    data-value="invoice-value"
                />
                <br />
            </div>

            <br />
            <button
                onClick={onContinue}
                type="button"
                className="btn btn-warning mt-3 w-100 fw-bolder page_1_submit_btn page3_submit_btn"
                id="submit-otp-btn"
                style={{
                    background: "var(--primaryYellow)",
                    color: "black"
                }}
                disabled={!validateData() || loading}
            >
                {
                    loading ?
                        <SVGLoader />
                        :
                        <span>Continue</span>
                }
            </button>

        </div >
    );
}

export default Page3;



