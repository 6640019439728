import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {  useLocation, useNavigate } from "react-router-dom";
import { deleteTopBannerProduct } from "../../api/AdminApis/Banner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import useAdminCtx from "../../hooks/useAdminCtx";
import { getHomePageSections } from "../../api/AdminApis/HomePageSections";

// Component definition
const DashboardHomeTopBanners = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { allHomePageSections, setAllHomePageSections } = useAdminCtx();

    // state definitions
    const [topBannerSection, setTopBannerSection] = useState([]);

    const handleDelete = (key) => {
        const cnf = window.confirm("Are you sure?");
        if (!cnf) return;
        deleteTopBannerProduct(key, (err, res) => {
            if (err) return toast.error("Something went wrong!");
            if (res.status === 200) {
                getHomePageSections((err, res) => {
                    if (err) return console.log(err);
                    setAllHomePageSections(res.sections);
                });
            }
        });
    };

    useEffect(() => {
        const topSection = allHomePageSections.find(
            (section) => section.sectionType === "scroll_banner"
        );
        setTopBannerSection(topSection);
    }, [allHomePageSections]);
    useEffect(() => {
        getHomePageSections((err, res) => {
            if (err) return console.log(err);
            setAllHomePageSections(res.sections);
        });
    }, []);

    return (
        <div className="container">
            <button
                onClick={() => navigate("../admin-home-page")}
                style={{
                    all: "unset",
                    marginBottom: "25px",
                    cursor: "pointer",
                }}
            >
                <FontAwesomeIcon icon={faChevronLeft} /> Home Page Sections
            </button>
            <div
                className="d-flex flex-row align-items-center justify-content-between"
                style={{ gap: "10px" }}
            >
                <h3>Home Top Scrolling Banners</h3>
                <Button
                    className="btn-sm"
                    onClick={() =>
                        navigate("../admin-home-add-section", {
                            state: {
                                type: "scroll_banner",
                                updateProduct: false,
                                from: location.pathname,
                            },
                        })
                    }
                >
                    Add Banner
                </Button>
            </div>
            <div className=" hidden-md-up">
                {topBannerSection?.products?.length > 0 ? (
                    topBannerSection?.products?.map((banner, index) => (
                        <>
                            <div className="banner-product-card" key={index}>
                                {banner.imgUrl.split(".").pop() === "mp4" ? (
                                    <video controls autoPlay loop muted>
                                        <source
                                            src={banner.imgUrl}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video
                                        tag.
                                    </video>
                                ) : (
                                    <img
                                        src={banner.imgUrl}
                                        alt=""
                                        width={500}
                                    />
                                )}
                                <div className="banner-product-card-body">
                                    <p>
                                        <u>Title</u> - {banner.title}{" "}
                                    </p>
                                    <p>
                                        <u> Image URL</u> -{" "}
                                        <a href={banner.imgUrl}>
                                            {banner.imgUrl}
                                        </a>{" "}
                                    </p>
                                    <p>
                                        <u> Target URL</u> -{" "}
                                        <a href={banner.targetUrl}>
                                            {banner.targetUrl}
                                        </a>{" "}
                                    </p>
                                </div>
                                <div className="icons">
                                    <FontAwesomeIcon
                                        onClick={() =>
                                            navigate(
                                                "../admin-home-add-section",
                                                {
                                                    state: {
                                                        type: "scroll_banner",
                                                        updateProduct: true,
                                                        data: banner,
                                                        from: location.pathname,
                                                    },
                                                }
                                            )
                                        }
                                        cursor={"pointer"}
                                        icon={faEdit}
                                    />
                                    <FontAwesomeIcon
                                        cursor={"pointer"}
                                        onClick={() =>
                                            handleDelete(banner.imgKey)
                                        }
                                        icon={faTrashAlt}
                                    />
                                </div>
                            </div>
                        </>
                    ))
                ) : (
                    <>
                        <div className="d-flex mt-5">No Banner Images!</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DashboardHomeTopBanners;
