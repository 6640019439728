import React from "react";
import "./Products.css";
import ProductCard from "../Cards/ProductCard";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import useScreen from "../../utils/ScreenSizeCalculation";
import { Skeleton, useMediaQuery } from "@mui/material";
import Banner1 from "../../assets/vector/Rectangle 238.svg";
import Banner2 from "../../assets/productIcons/product_off.svg";
import NewArrival from "../../assets/png/NewArrival.png";
import BestSeller from "../../assets/png/BeatSeller.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useUserCtx from "../../hooks/useUserCtx";
import ProductBoxSkeleton from "../ProductBoxSkeleton";

const Products = ({ productArray, heading, targetUrl }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:500px)");
  const { userWishlist, cartArray } = useUserCtx();

  const btnpressprev = (e) => {
    const element = e.currentTarget.nextElementSibling;
    let width = element.clientWidth;
    element.scrollLeft = element.scrollLeft - width;
  };

  const btnpressnext = (e) => {
    const element = e.currentTarget.previousElementSibling;
    let width = element.clientWidth;
    element.scrollLeft = element.scrollLeft + width;
  };


  return (
    <div
      style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
    >
      {useScreen() !== "desktop" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <h3
            style={{
              fontSize: "0.9rem",
              fontWeight: "700",
            }}
          >
            {heading}
          </h3>
          <Link to={targetUrl ?? "/"}>
            <button
              style={{
                backgroundColor: "#FFCC0A",
                padding: "0.2rem",
                fontSize: "14px",
                fontWeight: "700",
                width: "80px",
                borderRadius: "10px",
                border: "none",
                cursor: "pointer",
                color: "white",
              }}
            >
              {t('ViewAll')}
            </button>
          </Link>
        </div>
      )}
      <div className="products-component">
        {useScreen() === "desktop" && (
          <div
            className="aside"
            style={{
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              position: "relative"
            }}
          >
            <div
              style={{
                fontSize: "20px",
                fontWeight: "600",
                flexWrap: "wrap",
                width: "74%",
                height: "100px",
                position: "absolute",
                top: "25px",
                left: "30px",
              }}
            >
              {heading}
            </div>

            {heading === "TOP SAVER TODAY" && (
              <img
                src={Banner1}
                alt={heading}
                style={{ objectFit: "cover" }}
              />
            )}
            {heading === "PRODUCT FROM YOUR INTEREST" && (
              <img
                src={Banner2}
                alt={heading}
                style={{ objectFit: "cover" }}
              />
            )}
            {heading === "NEW ARRIVALS" && (
              <img
                src={NewArrival}
                alt={heading}
                style={{ objectFit: "cover" }}
              />
            )}
            {heading === "BEST SELLER" && (
              <img
                src={BestSeller}
                alt={heading}
                style={{ objectFit: "cover" }}
              />
            )}
            <Link to={targetUrl ?? "/"}>
              <button
                style={{
                  backgroundColor: "#FFCC0A",
                  padding: "0.5rem",
                  fontSize: "16px",
                  fontWeight: "700",
                  width: "100px",
                  borderRadius: "10px",
                  border: "none",
                  cursor: "pointer",
                  color: "white",
                }}
              >
                {t('ViewAll')}
              </button>
            </Link>
          </div>
        )}
        {!isMobile && <button className="pre-btn" onClick={btnpressprev}>
          <KeyboardArrowLeftIcon />
        </button>}

        <div className="products">
          {productArray?.length <= 0 ?
            <div className="d-flex gap-4">
              {new Array(5).fill(true).map((_, index) => (
                <ProductBoxSkeleton key={`Product_Box_${index}_${heading}`}/>
              ))}
            </div>
            :
            productArray
              .map((e, i) => (
                <ProductCard
                  key={`${i}-${e._id}`}
                  id={e._id ?? ""}
                  productImg={e.images[0]}
                  productName={e.title}
                  productDescription={e.description[0]?.split(" ")?.slice(0, 18)?.join(" ")}
                  productRating={e?.rating ?? 0}
                  productPriceObj={e?.price}
                  productDiscountObj={e?.discount}
                  productSlug={e?.slug}
                  wishlist={userWishlist.wishlist_items?.some(obj => obj._id?.toString() === e._id?.toString())}
                  cart={cartArray?.no_of_carts > 0 && cartArray?.cart.some(obj => obj._id?.toString() === e._id?.toString())}
                />
              ))}
        </div>
        {!isMobile && <button className="next-btn" onClick={btnpressnext}>
          <KeyboardArrowRightIcon />
        </button>}
      </div>
    </div>
  );
};

export default Products;

