import React from "react";
import "./Banners.css";
import OneM from "../../assets/home/1M.jpg";
import TwoM from "../../assets/home/2M.png";
import TwoD from "../../assets/home/2D.png";
import ThreeD from "../../assets/home/3D.png";
import ThreeM from "../../assets/home/3M.jpg";
import OneD from "../../assets/home/1D.png";

const Banners = () => {
  return (
    <div className="banner-component">
      <div className="banner mobilescreen">
        <img src={OneM} alt="Apple Airpods Pro Mobile" />
        <img src={ThreeM} alt="OPPO A16 Mobile" />
        <img src={TwoM} alt="VIVO T2-5G Mobile" />
      </div>
      <div className="banner desktop">
        <img src={OneD} alt="Apple Airpods Pro Desktop" />
        <img src={ThreeD} alt="OPPO A16 Desktop" />
        <img src={TwoD} alt="VIVO T2-5G Desktop" />
      </div>
    </div>
  );
};

export default Banners;
